import { Injectable } from '@angular/core';
import { AddStepDependency } from '@razroo-zeta/data-graphql';
import { Apollo } from 'apollo-angular';
import { RemoveStepDependency } from 'libs/data-graphql/src/lib/step-dependency/step-dependency.mutations';
import { from, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepDependencyService {

  constructor(private apollo: Apollo) {}

  addStepDependency(
    orgId: string,
    pathId: string,
    recipeId: string,
    stepId: string,
    dependentOrgId: string,
    dependentPathId: string,
    dependentRecipeId: string,
    dependentStepId: string) {
    const mutation = AddStepDependency;
    const variables = {
      orgId,
      pathId,
      recipeId,
      stepId,
      dependentOrgId,
      dependentPathId,
      dependentRecipeId,
      dependentStepId
    };
    const addStepDependency$ = this.apollo.mutate({ mutation, variables });
    return from(addStepDependency$).pipe(map((addStepDependency: any) => addStepDependency?.data?.addStepDependency));
  }

  removeStepDependency(
    orgId: string,
    pathId: string,
    recipeId: string,
    stepId: string,
    dependentRecipeId: string,
    dependentStepId: string) {
    const mutation = RemoveStepDependency;
    const variables = {
      orgId,
      pathId,
      recipeId,
      stepId,
      dependentRecipeId,
      dependentStepId
    };
    const removeStepDependency$ = this.apollo.mutate({ mutation, variables });
    return from(removeStepDependency$).pipe(map((removeStepDependency: any) => removeStepDependency?.data?.removeStepDependency));
  }
}

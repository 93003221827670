import gql from 'graphql-tag';
import { GithubInfoFragment } from './githubInfo.fragments';

export const GetGithubInfo = gql`
  query GetGithubInfo($org: String) {
    githubInfo(org: $org) {
      people
      repos {
        ...reposFields
      }
    }
  }
  ${GithubInfoFragment}
`;

export const GithubFileTree = gql`
  query GithubFileTree(
    $gitHubOrg: String!
    $gitHubRepoName: String!
    $gitHubBranch: String!
  ){
    githubFileTree(
      gitHubOrg: $gitHubOrg,
      gitHubRepoName: $gitHubRepoName,
      gitHubBranch: $gitHubBranch
    ){
      content
    }
  }
`;
<div class="comment-header">
  <div class="pic-info">
    <div class="user-initials">
      <img
        class="comment-image"
        src="{{ comment?.picture }}"
        onerror="this.onerror=null; this.remove()"
        />
      {{ comment | razrooName | initials }}
    </div>
    <div class="header-text">
      <div>
        <b>{{ comment | razrooName }}</b>
      </div>
      <div>{{ comment.timestamp | date: 'E dd, MMM - h:mma' }}</div>
    </div>
  </div>
  @if (comment?.commentId) {
    <mat-chip-listbox class="Comment__chip-list">
      <mat-chip
        [ngClass]="comment?.commentType + '-chip'"
        class="type-chip small-mat-chip"
        >{{ comment?.commentType }}</mat-chip
        >
      </mat-chip-listbox>
    }
    @if (comment.userId === currentUserId) {
      <button
        mat-icon-button
        [matMenuTriggerFor]="commentMenu"
        >
        <i class="menu-trigger fa-solid fa-ellipsis"></i>
      </button>
    }
    <mat-menu #commentMenu>
      <button mat-menu-item (click)="delete.emit()">
        @if (comment?.commentId) {
          <span>Delete comment</span
            >
            }@if (!comment.commentId) {
            <span>Delete reply</span>
          }
        </button>
        <button mat-menu-item (click)="editView()">
          @if (comment?.commentId) {
            <span>Edit comment</span
              >
              }@if (!comment.commentId) {
              <span>Edit reply</span>
            }
          </button>
        </mat-menu>
      </div>
      @if (comment.textCommentedOn) {
        <div class="text-commented-on">
          @if(comment.componentName === 'Ticket'){
            <mark class="ticket-commented-on">{{
              comment.textCommentedOn
            }}</mark>
          }
          @if (comment.componentName === 'Instructional Content') {
            <mark>{{
              comment.textCommentedOn
            }}</mark>
          }
          @if (comment.componentName === 'Code Sandbox') {
            <pre
            ><code [highlight]="comment.textCommentedOn"></code></pre>
          }
        </div>
      }
      <div class="text-and-vote">
        @if (comment?.commentId) {
          <div class="voting">
            @if (!codeModBug) {
              <i class="arrow fa-solid fa-angle-up" (click)="upvote.emit(comment)"></i>
            }
            @if (codeModBug) {
              <div class="votes-code-mod"><small>Votes</small></div>
            }
            <br />
            {{ comment.voteCount || 0 }}
            <br />
            @if (!codeModBug) {
              <i class="arrow fa-solid fa-angle-down" (click)="downvote.emit(comment)"></i>
            }
          </div>
        }
        @if (!editMode) {
          <div class="comment">{{ comment.comment }}</div>
        }
        @if (editMode) {
          <div class="edit-view">
            <form [formGroup]="editForm">
              <mat-form-field appearance="outline" class="edit-form">
                <mat-label>Edit</mat-label>
                <textarea
                  matInput
                  rows="4"
                  formControlName="text"
                  (keydown.enter)="saveEdit()"
                ></textarea>
              </mat-form-field>
            </form>
            <div class="edit-buttons">
              <button mat-flat-button color="primary" (click)="cancelEdit()">
                Cancel
              </button>
              <button mat-flat-button color="accent" (click)="saveEdit()">Edit</button>
            </div>
          </div>
        }
      </div>
      <div class="resolve-div">
        @if (codeModBug) {
          <button
            mat-flat-button
            color="accent"
            (click)="resolveBug.emit(comment)"
            >
            RESOLVE
          </button>
        }
      </div>
      <razroo-zeta-social-media-share
        [comment]="comment"
        [currentUserId]="currentUserId"
        [template]="template$ | async"
      ></razroo-zeta-social-media-share>
      
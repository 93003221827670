import { createAction, props } from '@ngrx/store';
import { FlatFolderFile, TerminalFile } from '@razroo-zeta/common-services';
import { Template,  RecipeUpdateParams, TemplateInputParameter, Step, OrganizationUser } from '@razroo-zeta/data-models';
import { PathCmsTreeName } from './path-cms.models';

export const init = createAction('[PathCms Page] Init');

export const loadFilesToGenerate = createAction(
  '[PathCms/API] Load Files To Generate',
  props<{ orgId: string, pathId: string, recipeId: string, id: string}>()
);

export const loadFilesToGenerateSuccess = createAction(
  '[PathCms/API] Load Files To Generate Success',
  props<{ filesToGenerate: FlatFolderFile[] }>()
);

export const loadFilesToGenerateFailure = createAction(
  '[PathCms/API] Load Files To Generate Failure',
  props<{ error: any }>()
);

export const loadTerminalFilesToGenerate = createAction(
  '[PathCms/API] Load Terminal Files To Generate',
  props<{ template: Template }>()
);

export const loadTerminalFilesToGenerateSuccess = createAction(
  '[PathCms/API] Load Terminal Files To Generate Success',
  props<{ terminalFiles: TerminalFile[] }>()
);

export const loadTerminalFilesToGenerateFailure = createAction(
  '[PathCms/API] Load Terminal Files To Generate Failure',
  props<{ error: any }>()
);

export const loadStarterWithFilesToGenerate = createAction(
  '[PathCms/API] Load Starter With Files To Generate',
  props<{ template: Template, filesToGenerateTree: FlatFolderFile[] }>()
);

export const loadStarterWithFilesToGenerateSuccess = createAction(
  '[PathCms/API] Load Starter With Files To Generate Success',
  props<{ starterWithFilesToGenerate: FlatFolderFile[], starterTemplate?: any }>()
);

export const loadStarterWithFilesToGenerateFailure = createAction(
  '[PathCms/API] Load Starter With Files To Generate Failure',
  props<{ error: any }>()
);

export const toggleFolder = createAction(
  '[PathCms/API] Toggle Folder',
  props<{ folder: FlatFolderFile, treeName: PathCmsTreeName}>()
);

export const loadFirstFile = createAction(
  '[PathCms/API] Load First File',
  props<{ template: Template, filesToGenerate: FlatFolderFile[]}>()
);

export const loadFirstFileSuccess = createAction(
  '[PathCms/API] Load First File Success',
  props<{ flatFolderFile: FlatFolderFile | undefined}>()
);

export const loadFirstTerminalFileSuccess = createAction(
  '[PathCms/API] Load First Terminal File Success',
  props<{ terminalFile: TerminalFile}>()
);

export const loadFirstFileError = createAction(
  '[PathCms/API] Load First File Error',
  props<{ error: any}>()
);

export const loadFile = createAction(
  '[PathCms/API] Load File',
  props<{ flatFolderFile: FlatFolderFile, template: Template, starterTemplate?: any}>()
);

export const loadDependentStepFile = createAction(
  '[PathCms/API] Load Dependent Step File',
  props<{ flatFolderFile: FlatFolderFile, template: Template}>()
);

export const loadFileSuccess = createAction(
  '[PathCms/API] Load File Success',
  props<{ flatFolderFile: FlatFolderFile}>()
);

export const loadFileError = createAction(
  '[PathCms/API] Load File Error',
  props<{ error: any}>()
);

export const loadTerminalFile = createAction(
  '[PathCms/API] Load Terminal File',
  props<{ terminalFile: TerminalFile, template: Template}>()
);

export const loadFileTerminalSuccess = createAction(
  '[PathCms/API] Load Terminal File Success',
  props<{ terminalFile: TerminalFile}>()
);

export const loadTerminalFileError = createAction(
  '[PathCms/API] Load Terminal File Error',
  props<{ error: any}>()
);

export const closeFile = createAction(
  '[PathCms/API] Close File',
  props<{ flatFolderFile: FlatFolderFile}>()
);

export const resetStore = createAction(
  '[PathCms/API] Reset Store'
);

export const editFile = createAction(
  '[PathCms/API] Edit File',
  props<{ codeChanged: string, openFile: FlatFolderFile}>()
);

export const editTerminalFile = createAction(
  '[PathCms/API] Edit Terminal File',
  props<{ terminalTextChanged: string, terminalFile: TerminalFile}>()
);

export const saveFile = createAction(
  '[PathCms/API] Save File',
  props<{ codeChanged: string, openFile: FlatFolderFile, template: Template, userOrgId: string}>()
);

export const saveFileSuccess = createAction(
  '[PathCms/API] Save File Success',
  props<{ codeChanged: string, openFile: FlatFolderFile}>()
);

export const saveFileError = createAction(
  '[PathCms/API] Save File Error',
  props<{ codeChanged: string, openFile: FlatFolderFile}>()
);

export const saveTerminalFile = createAction(
  '[PathCms/API] Save Terminal File',
  props<{ fileText: string, terminalFile: TerminalFile, template: Template, userOrgId: string}>()
);

export const saveTerminalFileSuccess = createAction(
  '[PathCms/API] Save Terminal File Success',
  props<{ fileText: string, terminalFile: TerminalFile}>()
);

export const saveTerminalFileError = createAction(
  '[PathCms/API] Save Terminal File Error',
  props<{ fileText: string, terminalFile: TerminalFile}>()
);

export const changeDynamicFilePath = createAction(
  '[PathCms/API] Change Dynamic File Path',
  props<{ openFile: FlatFolderFile, template: Template, source: string, destination: string}>()
);

export const changeDynamicFilePathSuccess = createAction(
  '[PathCms/API] Change Dynamic File Path Success',
  props<{ openFile: FlatFolderFile, template: Template, destination: string}>()
);

export const renameFile = createAction(
  '[PathCms/API] Rename File',
  props<{ openFile: FlatFolderFile, template: Template, newName: string, source: string, destination: string}>()
);

export const renameFileSuccess = createAction(
  '[PathCms/API] Rename File Success',
  props<{openFile: FlatFolderFile, template: Template, newName: string}>()
);

export const renameFileError = createAction(
  '[PathCms/API] Rename File Error',
  props<{ openFile: FlatFolderFile, template: Template, newName: string}>()
);

export const renameTerminalFile = createAction(
  '[PathCms/API] Rename Terminal File',
  props<{ terminalFile: TerminalFile, template: Template, newName: string}>()
);

export const renameTerminalFileSuccess = createAction(
  '[PathCms/API] Rename Terminal File Success',
  props<{terminalFile: TerminalFile, template: Template, newName: string}>()
);

export const renameTerminalFileError = createAction(
  '[PathCms/API] Rename Terminal File Error',
  props<{ terminalFile: TerminalFile, template: Template, newName: string}>()
);

export const renameFolder = createAction(
  '[PathCms/API] Rename Folder',
  props<{ openFolder: FlatFolderFile, template: Template, newFolderName: string}>()
);

export const renameFolderSuccess = createAction(
  '[PathCms/API] Rename Folder Success',
  props<{ openFolder: FlatFolderFile, template: Template, newFolderName: string}>()
);

export const renameFolderError = createAction(
  '[PathCms/API] Rename Folder Error',
  props<{ openFolder: FlatFolderFile, template: Template, newFolderName: string}>()
);

export const renameStaticFolder = createAction(
  '[PathCms/API] Rename Static Folder',
  props<{ openFolder: FlatFolderFile, template: Template, newFolderName: string}>()
);

export const renameStaticFolderSuccess = createAction(
  '[PathCms/API] Rename Static Folder Success',
  props<{ openFolder: FlatFolderFile, template: Template, newFolderName: string}>()
);

export const renameStaticFolderError = createAction(
  '[PathCms/API] Rename Static Folder Error',
  props<{ openFolder: FlatFolderFile, template: Template, newFolderName: string}>()
);

export const deleteFile = createAction(
  '[PathCms/API] Delete File',
  props<{ openFile: FlatFolderFile, template: Template}>()
);

export const deleteFileSuccess = createAction(
  '[PathCms/API] Delete File Success',
  props<{openFile: FlatFolderFile, template: Template}>()
);

export const deleteFileError = createAction(
  '[PathCms/API] Delete File Error',
  props<{ openFile: FlatFolderFile, template: Template}>()
);

export const deleteFolder = createAction(
  '[PathCms/API] Delete Folder',
  props<{ folderName: string, template: Template, fileIdsToDelete: string[]}>()
);

export const deleteFolderSuccess = createAction(
  '[PathCms/API] Delete Folder Success',
  props<{folderName: string, template: Template, fileIdsToDelete: string[]}>()
);

export const deleteFolderError = createAction(
  '[PathCms/API] Delete Folder Error',
  props<{ folderName: string, template: Template, fileIdsToDelete: string[]}>()
);

export const deleteTerminalFile = createAction(
  '[PathCms/API] Delete Terminal File',
  props<{ terminalFile: TerminalFile, template: Template, terminalFiles: Array<any>}>()
);

export const deleteTerminalFileSuccess = createAction(
  '[PathCms/API] Delete Terminal File Success',
  props<{terminalFile: TerminalFile, template: Template}>()
);

export const deleteTerminalFileError = createAction(
  '[PathCms/API] Delete Terminal File Error',
  props<{ terminalFile: TerminalFile, template: Template}>()
);

export const newFile = createAction(
  '[PathCms/API] New File',
  props<{ openFile: FlatFolderFile, template: Template, newFileName: string, filePath: string,
    starterFilesToGenerate: FlatFolderFile[], fileText: string, userOrgId: string, dynamicPath: string}>()
);

export const newFileSuccess = createAction(
  '[PathCms/API] New File Success',
  props<{openFile: FlatFolderFile, template: Template, newFileName: string, starterFilesToGenerate: FlatFolderFile[]}>()
);

export const newFileError = createAction(
  '[PathCms/API] New File Error',
  props<{ openFile: FlatFolderFile}>()
);

export const newStarterFile = createAction(
  '[PathCms/API] New Starter File',
  props<{ openFile: FlatFolderFile, template: Template, newFileName: string, filePath: string,
    starterFilesToGenerate: FlatFolderFile[], fileText: string, userOrgId: string}>()
);

export const addUploadedFilesToTree = createAction(
  '[PathCms/API] Add Uploaded Files To Tree',
  props<{ uploadedFiles: Array<any> }>()
)

export const updateCodemod = createAction(
  '[PathCms/API] Update Codemod',
  props<{ user: OrganizationUser, openFile: FlatFolderFile, template: Template, codeMod: string}>()
);

export const updateCodemodSuccess = createAction(
  '[PathCms/API] Update Codemod Success',
  props<{ openFile: FlatFolderFile, template: Template, fileText: string, updates: string}>()
);

export const updateCodemodError = createAction(
  '[PathCms/API] Update Codemod Error',
  props<{ openFile: FlatFolderFile}>()
);

export const newTerminalFile = createAction(
  '[PathCms/API] New Terminal File',
  props<{ terminalFile: TerminalFile, template: Template, userOrgId: string }>()
);

export const newTerminalFileSuccess = createAction(
  '[PathCms/API] New Terminal File Success',
  props<{ terminalFile: TerminalFile, template: Template}>()
);

export const newTerminalFileError = createAction(
  '[PathCms/API] New Terminal File Error',
  props<{ terminalFile: TerminalFile}>()
);

export const addStepDependency = createAction(
  '[PathCms/API] Add Step Dependency',
  props<{ template: Template, dependentOrgId: string, dependentPathId: string, dependentRecipeId: string, dependentStepId: string}>()
);

export const addStepDependencySuccess = createAction(
  '[PathCms/API] Add Step Dependency Success',
  props<{ stepToUpdateTemplate: Template, dependencyStep: Template}>()
);

export const addStepDependencyError = createAction(
  '[PathCms/API] Add Step Dependency Error',
  props<{ error: any}>()
);

export const removeStepDependency = createAction(
  '[PathCms/API] Remove Step Dependency',
  props<{ template: Template, dependentRecipeId: string, dependentStepId: string}>()
);

export const removeStepDependencySuccess = createAction(
  '[PathCms/API] Remove Step Dependency Success',
  props<{ stepToUpdateTemplate: Template, template: Template}>()
);

export const removeStepDependencyError = createAction(
  '[PathCms/API] Remove Step Dependency Error',
  props<{ error: any}>()
);

export const activateActivity = createAction(
  '[PathCms/API] Activate Activity',
  props<{ activeActivity: string}>()
);

export const activateCodeMod = createAction(
  '[PathCms/API] Activate Code Mod',
  props<{ editFileStatus: boolean}>()
);

export const loadRecipe = createAction(
  '[PathCms/API] Load Recipe',
  props<{ step: Template}>()
);

export const loadRecipeSuccess = createAction(
  '[PathCms/API] Load Recipe Success',
  props<{ recipe: Template}>()
);

export const loadRecipeError = createAction(
  '[PathCms/API] Load Recipe Error',
  props<{ error: any}>()
);

export const updateRecipe = createAction(
  '[PathCms/API] Update Recipe',
  props<{ orgId: string, pathId: string, recipeId: string, recipeUpdateParams: RecipeUpdateParams}>()
);

export const updateRecipeSuccess = createAction(
  '[PathCms/API] Update Recipe Success',
  props<{ recipe: Template}>()
);

export const updateRecipeError = createAction(
  '[PathCms/API] Update Recipe Error',
  props<{ error: any}>()
);

export const loadScaffold = createAction(
  '[PathCms/API] Load Scaffold',
  props<{ scaffold: Step, folderPath: string, template: Template, userOrgId: string}>()
);

export const loadScaffoldSuccess = createAction(
  '[PathCms/API] Load Scaffold Success',
  props<{ scaffold: Template, folderPath: string, template: Template}>()
);

export const loadScaffoldError = createAction(
  '[PathCms/API] Load Scaffold Error',
  props<{ error: any}>()
);

export const injectScaffold = createAction(
  '[PathCms/API] Inject Scaffold',
  props<{ scaffold: Template, folderPath: string, template: Template, userOrgId: string}>()
);

export const injectScaffoldSuccess = createAction(
  '[PathCms/API] Inject Scaffold Success',
  props<{ scaffold: Template, folderPath: string, template: Template}>()
);

export const injectScaffoldError = createAction(
  '[PathCms/API] Inject Scaffold Error',
  props<{ error: any}>()
);

export const selectOpenFile = createAction(
  '[PathCms/API] Select Open File',
  props<{ openFile: FlatFolderFile}>()
);

export const saveDocumentation = createAction(
  '[PathCms/API] Save Documentation',
  props<{ pathOrgId: string, pathId: string, recipeId: string, stepId: string, markdown: string}>()
);

export const saveDocumentationSuccess = createAction(
  '[PathCms/API] Save Documentation Success',
  props<{ markdownConvertedToHtml: string }>()
);

export const saveDocumentationFailure = createAction(
  '[PathCms/API] Save Documentation Failure',
  props<{ error: any }>()
);

export const splitAreaDragEnd = createAction(
  '[PathCms/API] Split Area Drag End',
  props<{ percent: any }>()
);

export const portExistingCodeFileToNewStep = createAction(
  '[PathCms/API] Port Existing Code File To New Step',
  props<{
    userOrgId: string,
    fromPathOrgId: string,
    fromPathId: string,
    fromRecipeId: string,
    fromStepId: string,
    toPathOrgId: string,
    toPathId: string,
    toRecipeId: string,
    toStepId: string,
    staticFilePath: string,
    dynamicFilePath?: string,
    contentType?: string
  }>()
);

export const portExistingCodeFileToNewStepSuccess = createAction(
  '[PathCms/API] Port Existing Code File To New Step Success',
  props<{
    fileContent: string,
    contentType: string,
    parameters: TemplateInputParameter[],
    orgId: string,
    pathId: string,
    recipeId: string,
    stepId: string
  }>()
);

export const portExistingCodeFileToNewStepFailure = createAction(
  '[PathCms/API] Port Existing Code File To New Step Failure',
  props<{ error: any }>()
);


import { createAction, props } from '@ngrx/store';
import { Invoice } from '@razroo-zeta/data-models';

export const loadInvoices = createAction(
  '[Billing/API] Load Invoices',
  props<{ stripeCustomerId: string }>()
);

export const loadInvoicesSuccess = createAction(
  '[Billing/API] Load Invoices Success',
  props<{ invoices: Invoice[] }>()
);

export const loadInvoicesFailure = createAction(
  '[Billing/API] Load Invoices Failure',
  props<{ error: any }>()
);

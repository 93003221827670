import { Injectable, inject } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as RecipesActions from './recipes.actions';
import * as RecipesFeature from './recipes.reducer';
import * as RecipesSelectors from './recipes.selectors';
import { Recipe } from '@razroo-zeta/data-models';

@Injectable()
export class RecipesFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(RecipesSelectors.selectRecipesLoaded));
  recipes$ = this.store.pipe(select(RecipesSelectors.selectAllRecipes));
  recipesOrgId$ = this.store.pipe(select(RecipesSelectors.selectRecipesOrgId));
  recipesPathId$ = this.store.pipe(select(RecipesSelectors.selectRecipesPathId));
  recipesCorePathId$ = this.store.pipe(select(RecipesSelectors.selectRecipesCorePathId));
  recipesLatest$ = this.store.pipe(select(RecipesSelectors.selectRecipesLatest));
  selectedRecipe$ = this.store.pipe(select(RecipesSelectors.selectEntity));
  recipesToAddAsBatch$ = this.store.pipe(select(RecipesSelectors.selectRecipesToAddAsBatch));
  recipesToRemoveAsBatch$ = this.store.pipe(select(RecipesSelectors.selectRecipesToRemoveAsBatch));

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  loadRecipes(userOrgId: string, pathOrgId: string, pathId: string, search = '', start = 0, end = 10, analyticData?: {analyticData: boolean; userOrgId: string;}) {
    this.store.dispatch(RecipesActions.loadRecipes({userOrgId, pathOrgId, pathId, search, start, end, analyticData}));
  }

  toggleRecipe(recipe: Recipe) {
    this.store.dispatch(RecipesActions.toggleRecipe({recipe}));
  }

  setRecipesToAddAsBatch(recipes: Recipe[]) {
    this.store.dispatch(RecipesActions.setRecipesToAddAsBatch({recipes}));
  }

  addToRecipesToRemove(recipe: Recipe) {
    this.store.dispatch(RecipesActions.addToRecipesToRemove({recipe}));
  }

  updateRecipeSuccess(recipe: Recipe) {
    this.store.dispatch(RecipesActions.updateRecipeSuccess({recipe}));
  }

  reOrderRecipe(orgId: string, pathId: string, recipeId: string, previousRecipe: string,
    updateOrder: boolean, pageStart: number, pageSize: number, reOrderedRecipes: Recipe[]
    ){
      this.store.dispatch(RecipesActions.reOrderRecipe({orgId, pathId, recipeId, previousRecipe, updateOrder, pageStart, pageSize, reOrderedRecipes}))
  }

  createRecipe(orgId: string,
    userId: string,
    pathId: string,
    recipeName: string,
    description: string,
    previousRecipeId: string | null) {
    this.store.dispatch(RecipesActions.createRecipe({ orgId, userId, pathId, recipeName, description, previousRecipeId}));
  }

  deleteRecipe(orgId: string, pathId: string, recipeId: string){
    this.store.dispatch(RecipesActions.deleteRecipe({orgId, pathId, recipeId}))
  }
}
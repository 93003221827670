import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { GetStepperOfStepsQuery } from '@razroo-zeta/data-graphql';
import { pluck } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepperService {

  constructor(private apollo: Apollo) { }

  getStepperOfSteps(orgId: string, pathId: string, recipeIdStart?: string, stepIdStart?: string, amount?: number): Observable<any> {
    const query = GetStepperOfStepsQuery;
    const variables = {
      orgId,
      pathId,
      recipeIdStart,
      stepIdStart,
      amount
    };

    const template$ = this.apollo.query({ query, variables });
    return from(template$).pipe(pluck('data', 'getStepperOfSteps'));
  }
}

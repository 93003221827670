import gql from "graphql-tag";
import { TemplateFragment } from "../template/template.fragments";

export const AddStepDependency = gql`
  mutation AddStepDependency(
    $orgId: String!
    $pathId: String!
    $recipeId: String!
    $stepId: String!
    $dependentOrgId: String!
    $dependentPathId: String!
    $dependentRecipeId: String!
    $dependentStepId: String!
  ) {
    addStepDependency(orgId: $orgId, pathId: $pathId, recipeId: $recipeId, stepId: $stepId, dependentOrgId: $dependentOrgId, dependentPathId: $dependentPathId, dependentRecipeId: $dependentRecipeId, dependentStepId: $dependentStepId) {
      ...templateFields
    }
  }
  ${TemplateFragment}
`;

export const RemoveStepDependency = gql`
  mutation RemoveStepDependency(
    $orgId: String!
    $pathId: String!
    $recipeId: String!
    $stepId: String!
    $dependentRecipeId: String!
    $dependentStepId: String!
  ) {
    removeStepDependency(orgId: $orgId, pathId: $pathId, recipeId: $recipeId, stepId: $stepId, dependentRecipeId: $dependentRecipeId, dependentStepId: $dependentStepId) {
      ...templateFields
    }
  }
  ${TemplateFragment}
`;

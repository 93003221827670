import gql from 'graphql-tag';
import { TemplateFragment } from '../template/template.fragments';

export const MoveFileToNewPath = gql`
  mutation MoveFileToNewPath($userOrgId: String!, $pathOrgId: String!, $pathId: String!, $recipeId: String!, 
    $stepId: String!, $fullFilePath: String!, $newFullFilePath: String!, $contentType: String
  ) {
    moveFileToNewPath(userOrgId: $userOrgId, pathOrgId: $pathOrgId, pathId: $pathId, recipeId: $recipeId, 
      stepId: $stepId, fullFilePath: $fullFilePath, newFullFilePath: $newFullFilePath, contentType: $contentType
    ) {
      copyResults
      deleteResults
    }
  }
`

export const MoveFolderInStarter = gql`
  mutation MoveFolderInStarter($userOrgId: String!, $templateOrgId: String!, $pathId: String!, $recipeId: String!, $stepId: String!, $srcFolder: String!, $destinationFolder: String!){
    moveFolderInStarter(userOrgId: $userOrgId, templateOrgId: $templateOrgId, pathId: $pathId, recipeId: $recipeId, stepId: $stepId, srcFolder: $srcFolder, destinationFolder: $destinationFolder){
      ...templateFields
    }
  }
  ${TemplateFragment}
`

export const PortExistingCodeFileToNewStep = gql`
  mutation PortExistingCodeFileToNewStep(
    $userOrgId: String!,
    $fromPathOrgId: String!,
    $fromPathId: String!,
    $fromRecipeId: String!,
    $fromStepId: String!,
    $toPathOrgId: String!,
    $toPathId: String!,
    $toRecipeId: String!,
    $toStepId: String!,
    $staticFilePath: String!,
    $dynamicFilePath: String,
    $contentType: String
  ) {
    portExistingCodeFileToNewStep(
      userOrgId: $userOrgId,
      fromPathOrgId: $fromPathOrgId,
      fromPathId: $fromPathId,
      fromRecipeId: $fromRecipeId,
      fromStepId: $fromStepId,
      toPathOrgId: $toPathOrgId,
      toPathId: $toPathId,
      toRecipeId: $toRecipeId,
      toStepId: $toStepId,
      staticFilePath: $staticFilePath,
      dynamicFilePath: $dynamicFilePath,
      contentType: $contentType
    ) {
      fileContent
      contentType
      orgId
      pathId
      recipeId
      id
      fileTree
      parameters {
        defaultValue
        description
        inputType
        name
        paramType
        type
        optionalTypes {
          name
          selected
        }
      }
    }
  }
`;

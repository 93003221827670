import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CODE_GENERATION_HISTORY_FEATURE_KEY,
  CodeGenerationHistoryState,
  codeGenerationHistoryAdapter,
} from './code-generation-history.reducer';

// Lookup the 'CodeGenerationHistory' feature state managed by NgRx
export const selectCodeGenerationHistoryState =
  createFeatureSelector<CodeGenerationHistoryState>(
    CODE_GENERATION_HISTORY_FEATURE_KEY
  );

const { selectAll, selectEntities } =
  codeGenerationHistoryAdapter.getSelectors();

export const selectCodeGenerationHistoryLoaded = createSelector(
  selectCodeGenerationHistoryState,
  (state: CodeGenerationHistoryState) => state.loaded
);

export const selectCodeGenerationHistoryError = createSelector(
  selectCodeGenerationHistoryState,
  (state: CodeGenerationHistoryState) => state.error
);

export const selectAllCodeGenerationHistory = createSelector(
  selectCodeGenerationHistoryState,
  (state: CodeGenerationHistoryState) => selectAll(state)
);

export const selectCodeGenerationHistoryEntities = createSelector(
  selectCodeGenerationHistoryState,
  (state: CodeGenerationHistoryState) => selectEntities(state)
);

export const selectSelectedId = createSelector(
  selectCodeGenerationHistoryState,
  (state: CodeGenerationHistoryState) => state.selectedId
);

export const selectEntity = createSelector(
  selectCodeGenerationHistoryEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);


export const selectHistoryTimelinePage = createSelector(
  selectCodeGenerationHistoryState,
  state => state.page
);

export const selectHistoryTimelineSize = createSelector(
  selectCodeGenerationHistoryState,
  state => state.size
);
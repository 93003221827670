import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromGitInfo from './+state/git-info.reducer';
import { GitInfoEffects } from './+state/git-info.effects';
import { GitInfoFacade } from './+state/git-info.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromGitInfo.GIT_INFO_FEATURE_KEY,
      fromGitInfo.reducer
    ),
    EffectsModule.forFeature([GitInfoEffects]),
  ],
  providers: [GitInfoFacade],
})
export class DataAccessGitInfoModule {}

import gql from 'graphql-tag';

export const CreateOrganization = gql`
  mutation createOrganization($userId: String!, $displayName: String!, $isActive: Boolean, $isPersonal: Boolean, $orgType: OrgType) {
    createOrganization(userId: $userId, displayName: $displayName, isActive: $isActive, isPersonal: $isPersonal, orgType: $orgType) {
      createdBy
      dateCreated
      orgId
      paid
      planType
      stripeCustomerId
      orgType
    }
  }
`;
export const CreateOrganizationUser = gql`
  mutation createOrganizationUser(
    $orgId: ID!
    $email: String!
    $orgRole: String
    $isActive: Boolean
  ) {
    createOrganizationUser(
      orgId: $orgId
      email: $email
      orgRole: $orgRole
      isActive: $isActive
    ) {
      orgId
      email
      dateJoinedOrg
      orgRole
      pathPermissions {
        codeCommunityPaths
        codeOrgPaths
        contentCommunityPaths
        contentOrgPaths
      }
    }
  }
`;

export const UpdateOrganization = gql`
  mutation updateOrganization($displayName: String, $orgId: ID!, $gitHubOrg: String
) {
    updateOrganization(orgId: $orgId, displayName: $displayName, gitHubOrg: $gitHubOrg) {
      displayName
      orgId
      gitHubOrg
    }
  }
`;

export const UpdateGithubOrganization = gql`
  mutation updateOrganization($orgId: ID!, $gitHubOrg: String
) {
    updateOrganization(orgId: $orgId, gitHubOrg: $gitHubOrg) {
      orgId
      gitHubOrg
    }
  }
`;

export const DeactivateOrganization = gql`
  mutation deleteOrganization($orgId: ID! $userId: String) {
    deleteOrganization(orgId: $orgId, userId: $userId) {
      createdBy
      dateCreated
      displayName
      gitHubOrg
      isActive
      orgId
      owner
      paid
      planType
      slackAccessToken
      slackUserIds
      slackWorkspaceChannels
      slackWorkspaceId
      stripeCustomerId
      subscriptionId
      orgType
    }
  }
`;
export const UpdateSlackInfo = gql`
  mutation updateOrganization(
    $orgId: ID!,
    $slackAccessToken: String,
    $slackWorkspaceChannels: [String],
    $slackUserIds: [String]
    ) {

    updateOrganization(
      orgId: $orgId,
      slackAccessToken: $slackAccessToken,
      slackWorkspaceChannels: $slackWorkspaceChannels,
      slackUserIds: $slackUserIds
      ){

        orgId
        slackWorkspaceId
        slackAccessToken
        slackWorkspaceChannels
        slackUserIds

    }
  }
`;

export const AssignOwner = gql`
  mutation assignOwner($orgId: String!, $existingOwner: String!, $newOwner: String!){
    assignOwner(orgId: $orgId, existingOwner: $existingOwner, newOwner: $newOwner){
      createdBy
      dateCreated
      displayName
      gitHubOrg
      isActive
      orgId
      owner
      paid
      planType
      slackAccessToken
      slackUserIds
      slackWorkspaceChannels
      slackWorkspaceId
      stripeCustomerId
      orgType
    }
  }
`

export const CreateTeam = gql`
  mutation CreateTeam($orgId: String!, $userId: String!, $teamName: String!){
    createTeam(orgId: $orgId, userId: $userId, teamName: $teamName){
      createdBy
      dateCreated
      orgId
      pathPermissions {
        codeCommunityPaths
        codeOrgPaths
        contentCommunityPaths
        contentOrgPaths
      }
      teamId
      teamMembers
      teamName
    }
  }
`
export const AddTeamMember = gql`
  mutation AddTeamMember($orgId: String!, $userId: String!, $teamId: String!, $newUserId: String!){
    addTeamMember(orgId: $orgId, userId: $userId, teamId: $teamId, newUserId: $newUserId){
      createdBy
      dateCreated
      orgId
      pathPermissions {
        codeCommunityPaths
        codeOrgPaths
        contentCommunityPaths
        contentOrgPaths
      }
      teamId
      teamMembers
      teamName
    }
  }
`

export const AddTeamPermissions = gql`
  mutation AddTeamPermissions($orgId: String!, $userId: String!, $teamId: String!, $permissions: PermissionsInput){
    addTeamPermissions(orgId: $orgId, userId: $userId, teamId: $teamId, permissions: $permissions){
      createdBy
      dateCreated
      orgId
      pathPermissions {
        codeCommunityPaths
        codeOrgPaths
        contentCommunityPaths
        contentOrgPaths
      }
      teamId
      teamMembers
      teamName
    }
  }
`

export const RemoveTeamPermissions = gql`
  mutation RemoveTeamPermissions($orgId: String!, $userId: String!, $teamId: String!, $permissions: PermissionsInput){
    removeTeamPermissions(orgId: $orgId, userId: $userId, teamId: $teamId, permissions: $permissions){
      createdBy
      dateCreated
      orgId
      pathPermissions {
        codeCommunityPaths
        codeOrgPaths
        contentCommunityPaths
        contentOrgPaths
      }
      teamId
      teamMembers
      teamName
    }
  }
`;

export const DeleteStripeSubscription = gql`
  mutation deleteStripeSubscription($orgId: ID!){
    deleteStripeSubscription(orgId: $orgId){
      result
    }
  }
`;



import { environment } from '@razroo-zeta/common-environment';
import { pluck } from 'rxjs/operators';
import { from } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { GetVsCodeInstance, GetVsCodeAuthInfo, UpdateVSCodeAuthenticationSub } from '@razroo-zeta/data-graphql';
import { names, replaceCurlyBrace } from '@codemorph/devkit';
import { CodeTimelineItem, Template, TemplateInputParameter, VsCodeInstance } from '@razroo-zeta/data-models';
import { FileTreeService, FlatFolderFile, WindowRefService, LocalStorageService } from '@razroo-zeta/common-services';
import { AWSAppSyncClient } from 'aws-appsync';

@Injectable()
export class VscodeService {
  private redirectUri: string;
  replaceCurlyBraceCounter = 0;
  constructor(private apollo: Apollo, private localStorageService: LocalStorageService,
    private fileTreeService: FileTreeService, private windowRefService: WindowRefService) {
    this.redirectUri = `${this.windowRefService.nativeWindow.origin}/vscode-auth`;
  }

  vsCodeDirectoriesSubClient(accessToken: string) {
    return new AWSAppSyncClient({
      url: environment.graphQLURI,
      region: 'us-east-1',
      auth: {
        type: "OPENID_CONNECT",
        jwtToken: accessToken,
      },
      disableOffline: true
    });
  }

  getVsCodeAuthInfo(userId: string): any {
    const query = GetVsCodeAuthInfo;
    const variables = {
      userId
    }
    const getVsCodeAuthInfo$ = this.apollo.query({query, variables, fetchPolicy: "network-only"});
    return from(getVsCodeAuthInfo$).pipe(pluck('data', 'getVSCodeAuthInfo'));
  }

  replaceProjectNameIfExists(parameters: TemplateInputParameter[] | undefined, vsCodeInstance: VsCodeInstance): TemplateInputParameter[] | undefined {
    if(!parameters) {
      return;
    }
    return parameters.map(parameter => {
      if(parameter.name === 'projectName') {
        return {
          ...parameter,
          defaultValue: vsCodeInstance.packageJsonParams ? vsCodeInstance.packageJsonParams.name : parameter.defaultValue
        }
      }
      else {
        return parameter
      }
    });
  }

  getVsCodeInstance(vsCodeInstanceId: string, userId: string) {
    const query = GetVsCodeInstance;
    const variables = {
      vsCodeInstanceId,
      userId
    };

    const getVsCodeInstance$ = this.apollo.query({ query, variables, fetchPolicy: 'network-only' });

    return from(getVsCodeInstance$).pipe(pluck('data', 'getVsCodeInstance'));
  }

  updateVSCodeDirectoriesSub(userId: string, vsCodeInstanceId: string) {
    const query = UpdateVSCodeAuthenticationSub;
    const variables = {
      vsCodeInstanceId: vsCodeInstanceId,
      userId: userId,
    };
    const accessToken = this.localStorageService.getItem('access_token');
    return this.vsCodeDirectoriesSubClient(accessToken as string)
      .hydrated().then(appSyncClient => appSyncClient.subscribe({query, variables}).subscribe(res => {
          console.log('response in service from subscribe: ', res);
        })
      )
  }


  insertAndSortDirectoryString(privateDirectories: string, folderPath: string) {
    const newFolderPaths = folderPath.split('/').map((folder, index, arr) => {
      if (index !== 0) {
        return `${arr.slice(0, index).join('/')}/${folder}`;
      } else {
        return `${folder}`;
      }
    });

    const privateDirectoriesArr = privateDirectories.split(',');

    newFolderPaths.forEach((path) => {
      if (!privateDirectoriesArr.includes(path)) {
        privateDirectoriesArr.push(path);
      }
    });
    let sortedDirectories = privateDirectoriesArr
      .sort((a, b) => a.localeCompare(b))
      .join(',');
    return sortedDirectories;
  }

  insertFlatFolderFiles(
    originalfolderStructure: any[],
    folderToInsertIntoIndex: number,
    filesArray: any[]
  ) {
    let folderStructure = originalfolderStructure;
    folderStructure[folderToInsertIntoIndex].expandable = true;
    return [
      // part of the array before the specified index
      ...folderStructure.slice(0, folderToInsertIntoIndex + 1),
      // inserted item
      ...filesArray.map((item) => {
        return {
          type: 'file',
          name: item,
          path: folderStructure[folderToInsertIntoIndex].path + '/' + item,
          level: folderStructure[folderToInsertIntoIndex].level + 1,
          expandable: false,
        };
      }),
      // part of the array after the specified index
      ...folderStructure.slice(folderToInsertIntoIndex + 1),
    ];
  }

  replaceTagParameters = (
    parameters: any,
    toReplace: string
  ): string => {
    for (const [key, value] of Object.entries(parameters)) {
      const keyString = `<%= ${key} %>`;
      const keyStringRegex = new RegExp(keyString, 'g');
      toReplace = toReplace.replace(keyStringRegex, value as any);
    }
    return toReplace;
  };

  // replaceTageParameters for preview review
  // replaces name value with defaultValue value
  previewReplaceTagParameters = (
    parameters: any[],
    toReplace: string
  ): string => {
    const expandedParameters = this.expandGlobalVariables(parameters);
    for (let param of expandedParameters) {
      const keyString = `<%= ${param.name} %>`;
      const keyStringRegex = new RegExp(keyString, 'g');
      toReplace = toReplace.replace(keyStringRegex, param.defaultValue);
    }
    return toReplace;
  };

  createGenerateCodeFlatFolderFileTree(privateDirectories: string, parameters: TemplateInputParameter[], filesToGenerate: any): FlatFolderFile[] {
    // const filesToGenerateTree = this.fileTreeService.returnFilesToGenerateFlatFolderStructure(filesToGenerate, parameters);
    // const parsedFilesContent = privateDirectories.split(',');
    // const filesAsArr = this.fileTreeService.replaceStringPathsWithFlatFolderPaths(parsedFilesContent, filesToGenerateTree);
    // const combinedFiles = [...filesAsArr, ...filesToGenerateTree];
    return this.fileTreeService.returnFlatFolderStructure(privateDirectories, true);
  }

  // turns curly braces into static values 
  // that is what this function does that is unique
  createFilesToGenerateFlatFolderFileTree(fileTree: string[], parameters: TemplateInputParameter[]) {
    const fileTreeModdedValue = this.replaceCurlyBraceInFileTree(fileTree, parameters);
    return this.fileTreeService.returnStepFileTreeFilesToGenerateFlatFolderStructure(fileTreeModdedValue, parameters);
  }

  replaceCurlyBraceInFileTree(fileTree: string[], parameters: TemplateInputParameter[]) {
    const parametersObject = {};
    for(const parameter of parameters) {
      parametersObject[parameter.name] = parameter.defaultValue;
    }
    return fileTree.map(file => replaceCurlyBrace(parametersObject, file));
  }

  expandGlobalVariables(parameters: any[]) {
    const name = parameters.find(parameter => parameter.name === 'name');
    if(name && name.defaultValue) {
      const expandedNames = names(name.defaultValue);
      for (const [key, value] of Object.entries(expandedNames)) {
        if(key !== 'name') {
          parameters = [
            ...parameters,
            {
              name: key,
              defaultValue: value,
              extrapolated: true
            }
          ]
        }
      }
    }
    return parameters;
  }

  insertFilesIntoFlatFolderStructure(
    folderStructure: any[],
    folderPath: string,
    filesArray: string[]
  ) {
    const folderToInsertIntoIndex = folderStructure.findIndex(
      (folder) => folder.path === folderPath
    );
    const newlyCreatedFolder = this.insertFlatFolderFiles(
      folderStructure,
      folderToInsertIntoIndex,
      filesArray
    );

    return newlyCreatedFolder;
  }

  determineIfFilePathExists(folderStructure: any[], folderPath: string) {
    return folderStructure.some(function (folder) {
      return folder.path === folderPath;
    });
  }

  modifyDefaultValuesWithLocalStorage(template: Template, latestCodeGenHistoryItem: CodeTimelineItem | object, aggregatedParameters: any, vsCodeInstance: VsCodeInstance): {modifiedParameters: TemplateInputParameter[] | any, containsKeysOfPreviousStep: boolean} {    
    let containsKeysOfPreviousStep = true;
    const modifiedParameters = template.parameters && template.parameters.map(templateParameter => {
      // For performance reasons aggregated project name logic in this map
      if(templateParameter.name === 'projectName') {
        return {
          ...templateParameter,
          defaultValue: vsCodeInstance.packageJsonParams ? vsCodeInstance.packageJsonParams.name : templateParameter.defaultValue
        }
      }
      if(!latestCodeGenHistoryItem && (latestCodeGenHistoryItem && (!template.batchId && template.recipeId !== (latestCodeGenHistoryItem as CodeTimelineItem).recipeId) || (template.batchId && template.batchId !== (latestCodeGenHistoryItem as CodeTimelineItem).batchId)) || templateParameter.paramType === 'filePath') {
        // Skip over any elements with a different recipeId
        return templateParameter;
      }

      const codeGenHistoryItemValue = aggregatedParameters[templateParameter.name];
      if (codeGenHistoryItemValue) {
        return {
          ...templateParameter,
          defaultValue: codeGenHistoryItemValue
        };
      } else {
        containsKeysOfPreviousStep = false;
      }
      return templateParameter;
    });
    
    return {modifiedParameters, containsKeysOfPreviousStep};
  }

  /**
   * Counter added to make recursion full proof. If no respective params
   * but still curly braces, will end after 3 times
   * @param params
   * @param privateDirectories
   * @returns
   */
  replaceCurlyBrace(params: TemplateInputParameter[] | any, privateDirectories: string){
    let result = privateDirectories;
    params.forEach(el => {
      const regex = new RegExp(`{${el.name}}`, 'g');
      result = result.replace(regex, el.defaultValue)
    });
    // make sure that regex doesn't catch surrounded object curly braces
    const moddedString = result.slice(1, -1);
    const matchRegex = /\{[^}]+\}/;
    if(moddedString.match(matchRegex) && this.replaceCurlyBraceCounter < 4) {
      this.replaceCurlyBraceCounter++;
      return this.replaceCurlyBrace(params, result);
    }
    // reset counter
    this.replaceCurlyBraceCounter = 0;
    return result;
  }

  replaceCurlyBraceWithValue(params: TemplateInputParameter[], privateDirectories: string, valueToUse: string, filePath: string){
    let result = privateDirectories;
    if(params?.length){
      [...params].forEach(param => {
        result = result.replaceAll('{' + param.name + '}', `${filePath}/${valueToUse}`);
      })
    }
    return result;
  }
}

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { PathService, TemplateService } from '@razroo-zeta/data-services';
import { concatMap, map } from 'rxjs/operators';
import * as PathActions from './path.actions';
import { TitleCasePipe } from '@angular/common';
import * as RecipesActions from'../../../../recipes/src/lib/+state/recipes.actions';
import { SEOService } from '@razroo-zeta/common-services';

@Injectable()
export class PathEffects {
  loadAllPaths$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.loadAllPaths),
      fetch({
        run: (action) => {
          return this.pathService.getPathsForDropdown(action.orgId, 'All').pipe(
            map((paths: any) => {
              return PathActions.loadAllPathsSuccess({ paths: paths });
            })
          );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.loadAllPathsFailure({ error });
        },
      })
    )
  );
  loadProjectPaths$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.loadProjectPaths),
      fetch({
        run: (action) => {
          return this.pathService.getProjectPaths(action.orgId).pipe(
            map((projectPaths: any) => {
              return PathActions.loadProjectPathsSuccess({ paths: projectPaths });
            })
          );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.loadProjectPathsFailure({ error });
        },
      })
    )
  );

  loadPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.loadPath),
      fetch({
        run: (action) => {
          return this.pathService
            .searchPath(action.userOrgId, action.orgId, action.pathId, '', 0, 10)
            .pipe(
              map((path: any) => {
                // https://stackoverflow.com/questions/32589197/how-can-i-capitalize-the-first-letter-of-each-word-in-a-string-using-javascript
                const pathTitle = path.title.replace(/(^\w|\s\w)/g, (m) =>
                  m.toUpperCase()
                );
                this.pathService.replaceDnaPageUrl(path);
                
                const newTemplateUrl = `/${path.orgId}/${path.id}`;
                const canonicalUrl = `/${path.orgId}/${path.corePathId}`;
                this.seoService.setSEOTags(
                  `Finder - ${pathTitle}`,
                  path.description,
                  newTemplateUrl,
                  canonicalUrl
                );
                return PathActions.loadPathSuccess({ path: path });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.loadPathFailure({ error });
        },
      })
    )
  );

  loadPathTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.loadPathTemplate),
      fetch({
        run: (action) => {
          return this.pathService
            .loadPathTemplate(action.orgId, action.pathId)
            .pipe(
              map((path: any) => {
                return PathActions.loadPathSuccess({ path: path });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.loadPathTemplateFailure({ error });
        },
      })
    )
  );

  loadChatPathTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.loadChatPathTemplate),
      fetch({
        run: (action) => {
          return this.pathService
            .loadPathTemplate(action.orgId, action.pathId)
            .pipe(
              map((path: any) => {
                this.titleService.setTitle(
                  `Razroo Chat | ${this.titleCasePipe.transform(path.title)}`
                );
                return PathActions.loadPathSuccess({ path: path });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.loadPathTemplateFailure({ error });
        },
      })
    )
  );

  loadPathPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.loadPath),
      fetch({
        run: (action) => {
          return this.templateService
            .getTemplatePermissions(action.orgId, action.pathId)
            .pipe(
              map((templatePermissions: any) => {
                return PathActions.loadPathPermissionsSuccess({
                  pathPermissions: templatePermissions,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.loadPathFailure({ error });
        },
      })
    )
  );

  loadPathSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.loadPathSuccess),
      fetch({
        run: (action) => {
          return PathActions.getSecondaryPaths({
            orgId: action.path.orgId,
            pathId: action.path.id,
          });
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.loadPathFailure({ error });
        },
      })
    )
  );

  loadSecondaryPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.loadSecondaryPath),
      fetch({
        run: (action) => {
          return this.pathService
            .searchPath(action.userOrgId, action.orgId, action.pathId, '', 0, 10)
            .pipe(
              map((path: any) => {
                return PathActions.loadSecondaryPathSuccess({
                  path: path,
                  parentPath: action.parentPath,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.loadSecondaryPathFailure({ error });
        },
      })
    )
  );

  createPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.createPath),
      fetch({
        run: (action) => {
          return this.pathService
            .createPath(
              action.orgId,
              action.userId,
              action.path,
              action.description,
              action.baseCommunityPath,
              action.privacy,
              action.languageVersion,
              action.marketplace,
              action.minimumPrice
            )
            .pipe(
              concatMap((path: any) => {
                this.pathService.replaceDnaPageUrl(path);
                this.titleService.setTitle(`${path.title} - Razroo Finder`);
                return [
                  PathActions.createPathSuccess({ path: path }),
                  RecipesActions.loadRecipesSuccess({recipes: path.stepper, path})
                ];
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.createPathFailure({ error });
        },
      })
    )
  );

  searchPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.searchPath),
      fetch({
        run: (action) => {
          return this.pathService
            .searchPath(
              action.userOrgId,
              action.orgId,
              action.pathId,
              action.search,
              action.start,
              action.size
            )
            .pipe(
              map((path: any) => {
                return PathActions.searchPathSuccess({ path: path });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.searchPathFailure({ error });
        },
      })
    )
  );

  archiveRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.archiveRecipe),
      fetch({
        run: (action) => {
          return this.pathService
            .archiveTemplate(action.orgId, action.pathId, action.recipeId)
            .pipe(
              map((recipe: any) => {
                return PathActions.archiveRecipeSuccess({
                  recipe: recipe,
                  path: action.path,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.archiveRecipeFailure({ error });
        },
      })
    )
  );

  deleteRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.deleteRecipe),
      fetch({
        run: (action) => {
          return this.pathService
            .deleteTemplate(
              action.orgId,
              'Recipe',
              action.pathId,
              action.recipeId
            )
            .pipe(
              map((recipe: any) => {
                if (action.path) {
                  return PathActions.deleteRecipeSuccess({
                    recipe: recipe,
                    path: action.path,
                  });
                } else {
                  this.router.navigateByUrl('/finder');
                  return PathActions.noAction();
                }
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.deleteRecipeFailure({ error });
        },
      })
    )
  );

  archivePath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.archivePath),
      fetch({
        run: (action) => {
          return this.pathService
            .archiveTemplate(action.orgId, action.pathId)
            .pipe(
              map((path: any) => {
                return PathActions.archivePathSuccess({ path });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.archivePathFailure({ error });
        },
      })
    )
  );

  getSecondaryPaths$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.getSecondaryPaths),
      fetch({
        run: (action) => {
          return this.pathService
            .getSecondaryPaths(action.orgId, action.pathId)
            .pipe(
              map((paths: any) => {
                if (paths?.length) {
                  return PathActions.getSecondaryPathsSuccess({ paths });
                } else
                  return PathActions.getSecondaryPathsSuccess({ paths: [] });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.getSecondaryPathsFailure({ error });
        },
      })
    )
  );

  addSecondaryPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.addSecondaryPath),
      fetch({
        run: (action) => {
          return this.pathService
            .addSecondaryPath(
              action.orgId,
              action.userId,
              action.pathId,
              action.secondaryPathId,
              action.secondaryPathOrgId
            )
            .pipe(
              map((secondaryPaths: any) => {
                return PathActions.addSecondaryPathSuccess({ secondaryPaths });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.addSecondaryPathFailure({ error });
        },
      })
    )
  );

  removeSecondaryPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.removeSecondaryPath),
      fetch({
        run: (action) => {
          return this.pathService
            .removeSecondaryPath(
              action.orgId,
              action.userId,
              action.pathId,
              action.secondaryPathId,
              action.secondaryPathOrgId
            )
            .pipe(
              map((secondaryPaths: any) => {
                return PathActions.removeSecondaryPathSuccess({
                  secondaryPaths,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.removeSecondaryPathFailure({ error });
        },
      })
    )
  );

  updatePath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.updatePath),
      fetch({
        run: (action) => {
          return this.pathService
            .updatePath(action.orgId, action.pathId, action.pathUpdateParams)
            .pipe(
              map((path: any) => {
                return PathActions.updatePathSuccess({ path });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.updatePathFailure({ error });
        },
      })
    )
  );

  getPathVersions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathActions.getPathVersions),
      fetch({
        run: (action) => {
          return this.pathService
            .getPathVersions(action.orgId, action.pathId)
            .pipe(
              map((paths: any) => {
                return PathActions.getPathVersionsSuccess({ paths });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.getPathVersionsFailure({ error });
        },
      })
    )
  );

  addMarketplacePathToCommunity$ = createEffect(() => 
    this.actions$.pipe(
      ofType(PathActions.addMarketplacePathToCommunity),
      fetch({
        run: (action) => {
          return this.pathService
            .addMarketplacePathToCommunity(action.userOrgId, action.communityPathId, action.marketplaceOrgId, action.marketplacePathId, action.marketplacePathDescription)
            .pipe(
              map((path: any) => {
                return PathActions.addMarketplacePathToCommunitySuccess({path});
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.addMarketplacePathToCommunityFailure({error});
        }
      })
    )
  )

  removeMarketplacePathFromCommunityPath$ = createEffect(() => 
    this.actions$.pipe(
      ofType(PathActions.removeMarketplacePathFromCommunityPath),
      fetch({
        run: (action) => {
          return this.pathService
            .removeMarketplacePathFromCommunityPath(action.userOrgId, action.communityPathId, action.marketplaceOrgId, action.marketplacePathId)
            .pipe(
              map((path: any) => {
                return PathActions.removeMarketplacePathFromCommunityPathSuccess({path});
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathActions.removeMarketplacePathFromCommunityPathFailure({error});
        }
      })
    )
  )

  reorderCommunityMarketplacePaths$ = createEffect(() => 
      this.actions$.pipe(
        ofType(PathActions.reorderCommunityMarketplacePaths),
        fetch({
          run: (action) => {
            return this.pathService
              .reorderCommunityMarketplacePaths(action.userOrgId, action.communityPathId, action.marketplaceOrgId, action.marketplacePathId, action.previousMarketplaceOrgId, action.previousMarketplacePathId)
              .pipe(
                map((path: any) => {
                  return PathActions.reorderCommunityMarketplacePathsSuccess({path});
                })
              )
          },
          onError: (action, error ) => {
            console.error('Error', error);
            return PathActions.reorderCommunityMarketplacePathsFailure({error})
          }
        })
      )
  );

  constructor(
    private readonly actions$: Actions,
    private pathService: PathService,
    private templateService: TemplateService,
    private router: Router,
    private titleService: Title,
    private titleCasePipe: TitleCasePipe,
    private seoService: SEOService,
  ) {}
}

import { StepDependency } from './../../../../data-models/src/lib/template/template.interface';
import { TemplateInputParameter } from '@razroo-zeta/data-models';
import { Injectable } from '@angular/core';
import { NanoService } from '../nano/nano.service';

export interface FlatFolderFile {
  name: string;
  path: string;
  dynamicPath?: string;
  level: number;
  id: string;
  expandable: boolean;
  isExpanded?: boolean;
  fileText?: string;
  filesToGenerateFolderName?: string;
  isEdited: boolean;
  isActive: boolean;
  checked?: boolean;
  dependentStep?: StepDependency
  allFolders?: boolean;
}

export interface DynamicStaticFilePath {
  path: string;
  dynamicPath?: string;
  filesToGenerateFolderName?: string;
}

export const flatFolderFileMock = {
  name: 'test123',
  path: 'libs/test123',
  level: 1,
  id: 'test123',
  expandable: false,
  isExpanded: false,
  isEdited: false,
  isActive: false
}

@Injectable({
  providedIn: 'root'
})
export class FileTreeService {
  // idPathDictionary put in place to keep ids unique across different
  idPathDictionary: {[path: string]: {id?: string}} = {};

  constructor(private nanoService: NanoService) { }

  /**
   * Used to take a basic string of filePaths and convert to something html can use
   * @param filePaths
   * @param allFolders - option to make return result entirely folders
   * @returns FlatFolderFile[]
   */
  returnFlatFolderStructure(filePaths: string | (string | FlatFolderFile)[], allFolders?: boolean): FlatFolderFile[] {
    const arrayOfFilePaths = typeof filePaths === 'string' ? filePaths.split(',') : filePaths;
    const orderedFilePathsByRelativityArr = this.orderedFilePathsByRelativity(arrayOfFilePaths);

    const flatFolderArr: FlatFolderFile[] = [];
    orderedFilePathsByRelativityArr.forEach((filePath: string | FlatFolderFile, fileIndex) => {
      const expandable = orderedFilePathsByRelativityArr.findIndex((filePathItem) => (typeof filePathItem === 'string' ? filePathItem : filePathItem.path)
        .includes((typeof filePath === 'string' ? filePath : filePath.path) + '/')) > 0 ? true : false;

      const staticFilePath = typeof filePath === 'string' ? filePath : filePath.path;
      const folderName = typeof filePath === 'string' ? undefined : filePath.filesToGenerateFolderName;
        let builtFolder;
        // only push item if it doesn't exist yet i.e. filepath is just a string
        if(typeof filePath === 'string') {
          const fileObject = {
            path: staticFilePath,
            folderName
          }
          builtFolder = this.createFolderFileTreeFromPath(fileObject, expandable, '', folderName, undefined, undefined, allFolders);
        }
        else {
          builtFolder = filePath
        }

        //TODO START refactor logic to be it's own function
        if(flatFolderArr[flatFolderArr.length - 1] && flatFolderArr[flatFolderArr.length - 1].path === builtFolder.path) {
          if(flatFolderArr[flatFolderArr.length - 1] && !flatFolderArr[flatFolderArr.length - 1].filesToGenerateFolderName) {
            flatFolderArr[flatFolderArr.length - 1] = {
              ...builtFolder,
              filesToGenerateFolderName: folderName
            }
          }
        }
        else {
          flatFolderArr.push(builtFolder);
        }
    });

    return flatFolderArr;
  }

  /**
   * Used to create filesToGenerate flavoreds folder/file tree
   * @param filePaths
   * @returns FlatFolderFile[]
   */
   returnFilesToGenerateFlatFolderStructure(fileTree: string[], parameters: TemplateInputParameter[] | undefined, dependentStep?: StepDependency): FlatFolderFile[] {
    const arrayOfFilePaths = this.createGenerateFilesArrayFromFileTree(fileTree, parameters);
    const orderedFilePathsByRelativityArr = this.orderedFilePathsByRelativity(arrayOfFilePaths);
    //1. we want to create a files to generate folder
    //2. we also want to have the parent folders

    return orderedFilePathsByRelativityArr.map((dynamicStaticFilePath: DynamicStaticFilePath) => {
      const folderName = dynamicStaticFilePath.filesToGenerateFolderName;
      const filePath = dynamicStaticFilePath.path;
      const expandable = orderedFilePathsByRelativityArr.findIndex((filePathItem: DynamicStaticFilePath) => filePathItem.path.includes(filePath + '/')) > 0 ? true : false;
      return this.createFolderFileTreeFromPath(dynamicStaticFilePath, expandable, '', folderName, true, dependentStep);
    });
  }

  /**
   * Used to create filesToGenerate flavored folder/file tree
   * from razroo fileTree data
   * @param fileTree
   * @returns FlatFolderFile[]
   */
  returnStepFileTreeFilesToGenerateFlatFolderStructure(fileTree: string[] | DynamicStaticFilePath[], parameters: TemplateInputParameter[] | undefined, dependentStep?: StepDependency): FlatFolderFile[] {
    const arrayOfFilePaths = this.createGenerateFilesArrayFromFileTree(fileTree, parameters, dependentStep);
    const orderedFilePathsByRelativityArr = this.orderedFilePathsByRelativity(arrayOfFilePaths);
    //1. we want to create a files to generate folder
    //2. we also want to have the parent folders

    return orderedFilePathsByRelativityArr.map((dynamicStaticFilePath: DynamicStaticFilePath) => {
      const folderName = dynamicStaticFilePath.filesToGenerateFolderName;
      const filePath = dynamicStaticFilePath.path;
      const expandable = orderedFilePathsByRelativityArr.findIndex((filePathItem: DynamicStaticFilePath) => filePathItem.path.includes(filePath + '/')) > 0 ? true : false;
      return this.createFolderFileTreeFromPath(dynamicStaticFilePath, expandable, '', folderName, true, dependentStep);
    });
  }

  replaceStringPathsWithFlatFolderPaths(starterFiles: string[], filesToGenerateTree: FlatFolderFile[]) {
    return starterFiles.map(starterFile => {
      const index = filesToGenerateTree.findIndex(fileToGenerate => fileToGenerate.path === starterFile);
      if(index > -1) {
        return filesToGenerateTree[index];
      }
      else {
        return starterFile
      }
    })
  }

  transformFilesToGenerateFile(filePath: string, parameters: TemplateInputParameter[] | undefined): string {
    const folderName = filePath.split('/')[0];
    const paramName = this.extractNameFromFilePath(folderName);
    const param = parameters ? parameters.find(param => param.name === paramName) : undefined;
    if(param) {
      if(param.defaultValue === '') {
        return filePath.split('/').pop() as string;
      }
      else {
        return filePath.replace(`{${param?.name}}`, param?.defaultValue as string)
      }
    }
    else {
      return filePath;
    }
  }

  extractNameFromFilePath(fileName: string) {
    const keyString = `{[^<>]*}`;
    const keyStringRegex = new RegExp(keyString, 'g');
    const templateVariables = fileName?.match(keyStringRegex);
    if(templateVariables) {
      return templateVariables[0].replace('{', '').replace('}', '');
    }
    else {
      return fileName?.split('.')[0];
    }
  }

  createFolderFileTreeFromPath(dynamicStaticFilePath: DynamicStaticFilePath, expandable = false, fileText = '', folderName?: string, isExpanded = false, dependentStep?: StepDependency, allFolders?: boolean): FlatFolderFile {
    const {path, dynamicPath} = dynamicStaticFilePath;
    if(!this.idPathDictionary[path]) {
      this.idPathDictionary[path] = {id: this.nanoService.nanoid()};
    }
    return {
      name: path.split('/').pop() as string,
      path: path,
      dynamicPath: dynamicPath,
      level: path.split('/').length - 1,
      expandable: expandable,
      id: this.idPathDictionary[path].id as string,
      isEdited: false,
      isActive: false,
      fileText: fileText,
      isExpanded: isExpanded,
      filesToGenerateFolderName: folderName,
      checked: false,
      dependentStep: dependentStep ? dependentStep : undefined,
      allFolders: allFolders
    };
  }

  getChildFlatFilesOfFlatFolderAndUpdateForMove(flatFolderFileTree: FlatFolderFile[], flatFolderIndex: number, nodeMovingToIndex: number): {childItems: FlatFolderFile[], lastItemIndex: number} {
    const flatFolderItem = flatFolderFileTree[flatFolderIndex];
    // if a file use as is
    // BEGIN - if a folder moving to, get previous element
    let flatFolderItemMovingTo = flatFolderFileTree[nodeMovingToIndex];
    if(flatFolderItemMovingTo.expandable) {
      flatFolderItemMovingTo = flatFolderFileTree[nodeMovingToIndex - 1];
    }
    // END - if a folder moving to, get previous element
    const updatedFlatFolderItem = {
      ...flatFolderItem,
      path: flatFolderItemMovingTo ? flatFolderItemMovingTo.path + '/' + flatFolderItem.path : flatFolderItem.path,
      level: flatFolderItemMovingTo ? (flatFolderItemMovingTo.level + 1) : 0
    };
    const childItems: FlatFolderFile[] = [updatedFlatFolderItem];
    for(let i = flatFolderIndex; i++; i < flatFolderFileTree.length - 1) {
      if(flatFolderFileTree[i].path.includes(flatFolderItem.path)) {
        const pathMinusOriginalParentPath = flatFolderFileTree[i].path.replace(flatFolderItem.path + '/', '');
        const pathArr = pathMinusOriginalParentPath.split('/');
        const standaloneLevel = pathArr.length; 
        const modifiedChildItem = {
          ...flatFolderFileTree[i],
          path: updatedFlatFolderItem.path + '/' + pathMinusOriginalParentPath,
          level: (updatedFlatFolderItem.level + standaloneLevel)
        }
        childItems.push(modifiedChildItem);
      } else {
        return {childItems, lastItemIndex: (i - 1)};
      }
    }

    return {childItems, lastItemIndex: flatFolderIndex};
  }

  moveFlatFolderFileToNewLocation(flatFolderFileTree: FlatFolderFile[], nodeBeingMovedIndex: number, nodeMovingToIndex: number) {
    const clonedFlatFolderFileTree = [...flatFolderFileTree];
    const nodeMovingTo = clonedFlatFolderFileTree[(nodeMovingToIndex - 1)] ? clonedFlatFolderFileTree[(nodeMovingToIndex - 1)] : clonedFlatFolderFileTree[0];
    if(flatFolderFileTree[nodeBeingMovedIndex].expandable) {
      const {lastItemIndex, childItems} = this.getChildFlatFilesOfFlatFolderAndUpdateForMove(flatFolderFileTree, nodeBeingMovedIndex, nodeMovingToIndex);
      const itemsToRemove = lastItemIndex - nodeBeingMovedIndex;
      clonedFlatFolderFileTree.splice(nodeBeingMovedIndex, (1 + itemsToRemove));
      clonedFlatFolderFileTree.splice(nodeMovingToIndex, 0, ...childItems);
    } else {
      const [removedItem] = clonedFlatFolderFileTree.splice(nodeBeingMovedIndex, 1);
      // modified path depends on whether or not moving to folder
      const modifiedPath = nodeMovingTo.expandable ? `${nodeMovingTo.path}/${removedItem.name}` : `${nodeMovingTo.filesToGenerateFolderName}/${removedItem.name}`;
      const modifiedRemovedItem = {
        ...removedItem,
        filesToGenerateFolderName: nodeMovingTo.filesToGenerateFolderName,
        level: nodeMovingTo.expandable ? (nodeMovingTo.level + 1): nodeMovingTo.level,
        path: modifiedPath
      };
      clonedFlatFolderFileTree.splice(nodeMovingToIndex, 0, modifiedRemovedItem);
    }
    

    

    return clonedFlatFolderFileTree;
  }

  // 1. Find index of parent
  // 2. Find index of sibling that it should be sort against
  addTreeItemToFileTree(filePath: string, tree: FlatFolderFile[], expandable = false, folderName?: string, dynamicPath?: string): FlatFolderFile[] {
    const treeCopy = [...tree];
    const dynamicFileObject = {
      path: filePath,
      dynamicPath: `${folderName}`,
      filesToGenerateFolderName: folderName
    }
    const filePathToAdd = this.createFolderFileTreeFromPath(dynamicFileObject, expandable, undefined, folderName);
    treeCopy.push(filePathToAdd);

    const treeCopySorted = this.orderedFilePathsByRelativity(treeCopy);
    this.changeParentFolderFileTreeItemsToExpandable(treeCopySorted, filePath);

    return treeCopySorted;
  }

  changeParentFolderFileTreeItemsToExpandable(tree: FlatFolderFile[], filePath: string) {
    let path: string;
    filePath.split('/').forEach((subPath: string, index) => {
      path = index === 0 ? subPath : `${path}/${subPath}`;
      if(path === filePath) {
        return;
      }
      const parentIndex = tree.findIndex(treeItem => treeItem.path === path);

      if(parentIndex > -1) {
        tree[parentIndex] = {
          ...tree[parentIndex],
          isExpanded: true
        }
      }
    })
  }

  getParentNode(node: any, dataSource) {
    const nodeIndex = dataSource['_data'].indexOf(node);

    for (let i = nodeIndex - 1; i >= 0; i--) {
      if (dataSource['_data'][i].level === node.level - 1) {
        return dataSource['_data'][i];
      }
    }

    return null;
  }

  determineIfFolder(path: string, filePaths: DynamicStaticFilePath[] | (string | FlatFolderFile)[], flatFolderFile?: FlatFolderFile) {
    if(flatFolderFile && flatFolderFile.expandable === true) {
      return true
    }
    else {
      return filePaths.findIndex((filePath: any) => (filePath.path ? filePath.path : filePath).includes(path + '/')) > -1
    }
  };

  determineIfFile(path: string) {
    if(path.includes('.') && path.indexOf('.') > -1) {
      return true
    }
    else {
      return false;
    }
  }

  shouldRenderChildElement(node: any, dataSource) {
    let parent = this.getParentNode(node, dataSource);
    while (parent) {
      if (!parent.isExpanded) {
        return false;
      }
      parent = this.getParentNode(parent, dataSource);
    }
    return true;
  }

  orderedFilePathsByRelativity(filePaths: DynamicStaticFilePath[] | (string | FlatFolderFile)[]): any {
    return filePaths.sort((a, b) => {
      const aPath = a.path ? a.path : a;
      const bPath = b.path ? b.path : b;

      const bArray = bPath.split('/');
      const aArray = aPath.split('/');
      const aLen = aArray.length;
      const bLen = bArray.length;

      for (let i = 0; i < Math.min(aLen, bLen); i++) {
        const aComp = aArray[i];
        const bComp = bArray[i];

        const aCompIsFolder = this.determineIfFolder(aComp, filePaths, a);
        const bCompIsFolder = this.determineIfFolder(bComp, filePaths, b);

        if(aCompIsFolder && !bCompIsFolder) {
          if (aComp !== bComp) {
            return -1;
          }
        }

        if(!aCompIsFolder && bCompIsFolder) {
          if (aComp !== bComp) {
            return 1;
          }
        }

        if (aComp !== bComp) {
          return aComp < bComp ? -1 : 1;
        }
      }

      return aLen < bLen ? -1 : 1;
    });
  }

  createDefaultPathFoldersArray(defaultPath){
    const array: string[] = [];
    if(defaultPath.split('/').length){
      array.push(defaultPath.split('/')[0]);
      defaultPath.split('/').reduce(function(previousValue, currentValue){
        array.push(previousValue + '/' + currentValue)
        return previousValue + '/' + currentValue
      })
    } else {
      array.push(defaultPath);
    }
    return array
  }

  getFirstFile(flatFolderFiles: FlatFolderFile[]) {
    return flatFolderFiles.find(node => !node.expandable);
  }

  /**
   * createParentPaths
   * Used primarily for filesToGenerate
   * @param param
   */
  createParentPaths(dynamicStaticFilePaths: DynamicStaticFilePath[]): DynamicStaticFilePath[] {
    const filePathsArr: DynamicStaticFilePath[] = [];
    dynamicStaticFilePaths.forEach((dynamicStaticFilePath: DynamicStaticFilePath) => {
      let dynamicStaticFilePathSection;
      dynamicStaticFilePath.path.split('/').forEach((filePathSection: string, index) => {
        dynamicStaticFilePathSection = index === 0
        ? {path: filePathSection, dynamicPath: dynamicStaticFilePath.dynamicPath, filesToGenerateFolderName: dynamicStaticFilePath.filesToGenerateFolderName}
        : {path: `${dynamicStaticFilePathSection.path}/${filePathSection}`, dynamicPath: dynamicStaticFilePath.dynamicPath, filesToGenerateFolderName: dynamicStaticFilePath.filesToGenerateFolderName}
        if(filePathsArr.findIndex(filePathsArr => filePathsArr.path === dynamicStaticFilePathSection.path) === -1) {
          filePathsArr.push(dynamicStaticFilePathSection)
        }
      });
    });
    return [...new Set([...filePathsArr])]
  }

  /**
   * createParentPaths
   * Used primarily for filesToGenerate
   * @param param
   */
  fanOutFilePaths(filePaths: string[]): any[] {
    const result: string[] = [];

    filePaths.forEach(filePath => {
      const parts = filePath.split('/');
      let currentPath = '';

      parts.forEach(part => {
        currentPath = currentPath ? `${currentPath}/${part}` : part;
        if (!result.includes(currentPath)) {
          result.push(currentPath);
        }
      });
    });

    return result;
  }

  createGenerateFilesArrayFromFileTree(fileTree: string[] | DynamicStaticFilePath[], parameters?: any, dependentStep?: StepDependency): DynamicStaticFilePath[] {
    let files: DynamicStaticFilePath[] = [];
    // if dependentStep preserve fileTree as is as already DynamicStaticFilePath
    !dependentStep ? (fileTree as string[]).forEach((fullFilePath: string) => {
      const splitFullFilePath = fullFilePath.split('/');
      const folderFilePath = splitFullFilePath[0];
      if(parameters) {
        const transformedFilePath = this.transformFilesToGenerateFile(fullFilePath, parameters);
        files.push({
          path: transformedFilePath, 
          dynamicPath: fullFilePath,
          filesToGenerateFolderName: folderFilePath ? folderFilePath : fullFilePath});
      } else {
        files.push({
          path: fullFilePath, 
          dynamicPath: fullFilePath,
          filesToGenerateFolderName: folderFilePath ? folderFilePath : fullFilePath});
      }
    }) : files = fileTree as DynamicStaticFilePath[];
    const filledOutTreeFiles = this.createParentPaths(files);
    return filledOutTreeFiles;
  }
}

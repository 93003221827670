import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { concatMap, map } from 'rxjs/operators';
import { Observable, EMPTY } from 'rxjs';
import * as VscodeActions from '../actions/vscode.actions';
import { fetch } from '@ngrx/router-store/data-persistence';
import { VscodeService } from '@razroo-zeta/data-services';
import {
  TemplateInputParameter,
  VsCodeAuthInfo,
  VsCodeInstance,
} from '@razroo-zeta/data-models';

@Injectable()
export class VscodeEffects {
  loadVscodes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VscodeActions.loadVscodes),
      /** An EMPTY observable only emits completion. Replace with your own observable API request */
      concatMap(() => EMPTY as Observable<{ type: string }>)
    );
  });

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VscodeActions.loadVscodeInstances),
      fetch({
        run: (action) => {
          // Your custom service 'load' logic goes here. For now just return a success action...
          return this.vscodeService.getVsCodeAuthInfo(action.userId).pipe(
            map((vsCodeAuthInfo: VsCodeAuthInfo) => {
              return VscodeActions.loadVscodeInstancesSuccess({
                vsCodeInstances: vsCodeAuthInfo?.vsCodeInstances,
              });
            })
          );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return VscodeActions.loadVscodeInstancesError({ error });
        },
      })
    )
  );

  loadVSCodeInstance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VscodeActions.loadVscodeInstance),
      fetch({
        run: (action) => {
          // Your custom service 'load' logic goes here. For now just return a success action...
          return this.vscodeService
            .getVsCodeInstance(action.vsCodeInstanceId, action.userId)
            .pipe(
              map((vsCodeInstance: any) => {
                return VscodeActions.loadVscodeInstanceSuccess({
                  vsCodeInstance,
                  template: action.template,
                });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return VscodeActions.loadVscodeInstanceError({ error });
        },
      })
    )
  );

  selectVscodeInstance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VscodeActions.loadVscodeInstanceSuccess),
      fetch({
        run: (action) => {
          return VscodeActions.loadVscodeFolderTree({
            privateDirectories: action.vsCodeInstance
              .privateDirectories as string,
            parameters: action.template.parameters as TemplateInputParameter[],
            fileTree: action.template.fileTree,
          });
        },

        onError: (action, error) => {
          console.error('Error', error);
          return VscodeActions.loadVscodeInstancesError({ error });
        },
      })
    )
  );

  loadVscodeFolderTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VscodeActions.loadVscodeFolderTree),
      fetch({
        run: (action) => {
          const generateCodeFlatFolderFileTree =
            this.vscodeService.createGenerateCodeFlatFolderFileTree(
              action.privateDirectories,
              action.parameters,
              action.fileTree
            );
          return VscodeActions.loadVscodeFolderTreeSuccess({
            generateCodeFlatFolderFileTree,
          });
        },

        onError: (action, error) => {
          console.error('Error', error);
          return VscodeActions.loadVscodeInstancesError({ error });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private vscodeService: VscodeService
  ) {}
}

import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { Invoice } from '@razroo-zeta/data-models';

import * as BillingActions from './billing.actions';
import { InvoicesEntity } from './billing.models';

export const BILLING_FEATURE_KEY = 'billing';

export interface State {
  invoices: InvoicesState;
}

export interface InvoicesState extends EntityState<InvoicesEntity> {
  selectedId?: string | number; // which Organizations record has been selected
  loaded?: boolean; // has the Organizations list been loaded
  error?: string | null; // last known error (if any)
}

export interface BillingPartialState {
  readonly [BILLING_FEATURE_KEY]: State;
}

export function selectInvoiceId(invoice: Invoice): string {
  //path will be used as the key for each file type
  return invoice.id;
}

export const billingAdapter: EntityAdapter<any> =
  createEntityAdapter<any>();

export const invoicesAdapter: EntityAdapter<InvoicesEntity> =
  createEntityAdapter<InvoicesEntity>({
    selectId: selectInvoiceId
  });

export const invoicesInitialState: InvoicesState = invoicesAdapter.getInitialState({
  loaded: false,
  selectedId: '',
  error: null
});

export const initialState: State = billingAdapter.getInitialState({
  invoices: invoicesInitialState
});

const billingReducer = createReducer(
  initialState,
  on(BillingActions.loadInvoicesSuccess, (state, { invoices }) =>
    ({
      ...state,
      invoices: invoicesAdapter.setAll(invoices, {
        ...state.invoices,
        loaded: true
      })
    })
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return billingReducer(state, action);
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GENERATECODE_FEATURE_KEY,
  State,
  GenerateCodePartialState,
  generateCodeAdapter,
} from './generate-code.reducer';

// Lookup the 'GenerateCode' feature state managed by NgRx
export const getGenerateCodeState = createFeatureSelector<
  GenerateCodePartialState,
  State
>(GENERATECODE_FEATURE_KEY);

const { selectAll, selectEntities } = generateCodeAdapter.getSelectors();

export const getGenerateCodeLoaded = createSelector(
  getGenerateCodeState,
  (state: State) => state.loaded
);

export const getRunUnitTestsLoaded = createSelector(
  getGenerateCodeState,
  (state: State) => state.runUnitTestsLoaded
);

export const getRunIntegrationTestsLoaded = createSelector(
  getGenerateCodeState,
  (state: State) => state.runIntegrationTestsLoaded
);

export const getGenerateCodeError = createSelector(
  getGenerateCodeState,
  (state: State) => state.error
);

export const getAllGenerateCode = createSelector(
  getGenerateCodeState,
  (state: State) => selectAll(state)
);

export const getGenerateCodeEntities = createSelector(
  getGenerateCodeState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getGenerateCodeState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getGenerateCodeEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

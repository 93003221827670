import { Injectable } from '@angular/core';
import { AddCommentResponse, CreateComment, DeleteComment, DeleteCommentResponse, DownvoteComment, GetAllCommentsOnPage, UpdateComment, UpdateCommentResponse, UpvoteComment } from '@razroo-zeta/data-graphql';
import { Apollo } from 'apollo-angular';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { CreateCommentParams } from 'libs/data-access/comments-store/src/lib/+state/comments.models';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(private apollo: Apollo) { }
  getAllComments(
      pageUrl: string,
      componentName: string,
      currentUserOrgId: string,
      fileName?: string,
      bugsOnly = false
    ){
      const query = GetAllCommentsOnPage;
      const variables = {
        pageUrl,
        componentName,
        currentUserOrgId,
        fileName,
        bugsOnly
      };
      const getAllCommentsOnPage$ = this.apollo.query({query,variables, fetchPolicy: 'no-cache'});
      return from(getAllCommentsOnPage$).pipe(pluck('data', 'getAllCommentsOnPage'));
  }

  createComment(createCommentParams: CreateCommentParams){
      const mutation = CreateComment;
      const variables = {
        createCommentParams
      }
      const createComment$ = this.apollo.mutate({mutation, variables})
      return from(createComment$).pipe(pluck('data', 'createComment'))

  }

  addResponse(addCommentResponseParams){
    const mutation = AddCommentResponse;
    const variables = {
      addCommentResponseParams
    }
    const addCommentResponse$ = this.apollo.mutate({mutation, variables});
    return from(addCommentResponse$).pipe(pluck('data', 'addCommentResponse'))
  }

  upvoteComment(
    commentId: string,
    userId: string,
    userOrgId: string
    ){
    const mutation = UpvoteComment;
    const variables = {
      commentId,
      userId,
      userOrgId
    }
    const upvoteComment$ = this.apollo.mutate({mutation, variables});
    return from(upvoteComment$).pipe(pluck('data', 'upvoteComment'))
  }

  downvoteComment(
    commentId: string,
    userId: string,
    userOrgId: string
    ){
    const mutation = DownvoteComment;
    const variables = {
      commentId,
      userId,
      userOrgId
    }
    const downvoteComment$ = this.apollo.mutate({mutation, variables});
    return from(downvoteComment$).pipe(pluck('data', 'downvoteComment'))
  }
  deleteComment(
    userId: string,
    userOrgId: string,
    commentId: string){
    const mutation = DeleteComment;
    const variables = {
      commentId,
      userId,
      userOrgId
    }
    const deleteComment$ = this.apollo.mutate({mutation, variables});
    return from(deleteComment$).pipe(pluck('data', 'deleteComment'))
  }

  deleteCommentResponse(
    userId: string,
    userOrgId: string,
    commentId: string,
    responseIndex: number
    ){
    const mutation = DeleteCommentResponse;
    const variables = {
      commentId,
      userId,
      userOrgId,
      responseIndex
    }
    const deleteCommentResponse$ = this.apollo.mutate({mutation, variables});
    return from(deleteCommentResponse$).pipe(pluck('data', 'deleteCommentResponse'))
  }

  updateComment(
    userId: string,
    userOrgId: string,
    commentId: string,
    newComment?: string,
    resolved?: boolean,
    pathId?: string
    ){
    const mutation = UpdateComment;
    const variables = {
      userId,
      userOrgId,
      commentId,
      newComment,
      resolved,
      pathId
    }
    const updateComment$ = this.apollo.mutate({mutation, variables, fetchPolicy: 'no-cache'});
    return from(updateComment$).pipe(pluck('data', 'updateComment'))
  }

  updateCommentResponse(
    userId: string,
    userOrgId: string,
    commentId: string,
    responseIndex: number,
    newComment: string
    ){
    const mutation = UpdateCommentResponse;
    const variables = {
      userId,
      userOrgId,
      commentId,
      responseIndex,
      newComment
    }
    const updateCommentResponse$ = this.apollo.mutate({mutation, variables});
    return from(updateCommentResponse$).pipe(pluck('data', 'updateCommentResponse'))
  }
}

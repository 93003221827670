<ng-container *ngrxLet="currentUser$ as currentUser">
<div class="chat-container">
  <mat-toolbar class="chat-header">
    <img class="WifejakChat__logo" src="https://cdn.razroo.com/oppwiz/razroo-letter-o.svg" alt="Oppwiz Charachter">
    <h3 class="WifejakChat__title" matTooltip="Press ⌘+L to pop out AI assistant">Oppwiz</h3>

    <div class="WifejakChat__actions">
      <button mat-icon-button (click)="startWalkthrough(currentUser)" matTooltip="Start Walkthrough" class="WifejakChat__help-button" aria-label="Start Walkthrough">
        <i class="fa-solid fa-question-circle"></i>
      </button>
      <button mat-icon-button (click)="newChat()" matTooltip="New Chat" class="WifejakChat__plus-button" aria-label="New Chat">
        <i class="fa-solid fa-plus"></i>
      </button>
      <button mat-icon-button (click)="closeChat()" matTooltip="Close Chat" class="WifejakChat__close-button" aria-label="Close chat">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    
  </mat-toolbar>
  <div class="chat-messages">
    @if (chatMessages().length === 0) {
      <mat-card class="CodeChat__chat-block mat-card-no-padding">
        <mat-card-header>
          <ng-container>
            <img mat-card-avatar class="WifejakChat__user-avatar" 
              [ngSrc]="'https://cdn.razroo.com/oppwiz/oppwiz-ai-assistant-robot.svg'" 
              width="32" height="32" 
              alt="User profile photo"/>
            <mat-card-title class="WifejakChat__title">
              AI Assistant
            </mat-card-title>
          </ng-container>
        </mat-card-header>
        <mat-card-content class="CodeChat__chat-message">
          Hello! How can I assist you?
        </mat-card-content>
      </mat-card>
    } @else {
      @for (wifejak of chatMessages(); track wifejak) {
        <mat-card class="CodeChat__chat-block mat-card-no-padding">
          <mat-card-header>
            <ng-container>
              <img mat-card-avatar class="WifejakChat__user-avatar" [class.generating]="wifejak?.message.generating"
                [ngSrc]="wifejak?.message.role === 'assistant' ? 'https://cdn.razroo.com/oppwiz/oppwiz-ai-assistant-robot.svg' : (currentUser?.picture || 'https://razroo.b-cdn.net/characters/placeholder-avatar.png')"
                width="32" height="32" 
                alt="User profile photo"/>
              <mat-card-title class="WifejakChat__title">
                {{wifejak?.message.role === 'assistant' ? 'AI Assistant' : 'You'}}
              </mat-card-title>
            </ng-container>
          </mat-card-header>
          <mat-card-content class="CodeChat__chat-message">
            @if (wifejak?.message.role === 'assistant' && wifejak?.message.content) {
              @if(wifejak | wifejakSelector) {
                <button mat-button class="WifejakChat__quick-action-button" 
                  (click)="quickAction(wifejak)"
                  (mouseenter)="quickActionMouseEnter(wifejak)"
                  (mouseleave)="quickActionMouseLeave(wifejak)">Quick Action</button>
              }
              <p [innerHTML]="wifejak?.message.content"></p>
            } @else {
              <p [innerHTML]="wifejak?.message.content"></p>
            }
          </mat-card-content>
        </mat-card>
      }
    }
  </div>
  
  <div class="WifejakChat__actionable-items-container">
    @if (wifejakAiActionableItems()?.actionableItems?.length) {
      <div class="WifejakChat__actionable-items-header">
        <i class="fa-regular fa-lightbulb"></i>
        <span>Try asking about:</span>
      </div>
      <div class="WifejakChat__horizontal-cards">
        @for (item of wifejakAiActionableItems()?.actionableItems?.slice(0, 3); track $index) {
          <button class="WifejakChat__horizontal-card" (click)="handleActionableItem(item)">
            <div class="WifejakChat__horizontal-icon">
              @if ($index === 0) {
                <i class="fa-regular fa-file"></i>
              } @else if ($index === 1) {
                <i class="fa-regular fa-circle-question"></i>
              } @else {
                <i class="fa-solid fa-bars"></i>
              }
            </div>
            <div class="WifejakChat__horizontal-content">
              <div class="WifejakChat__horizontal-title">{{ item.title }}</div>
              <div class="WifejakChat__horizontal-subtitle">
                @if (item.title.toLowerCase().includes('project')) {
                  Create new
                } @else if (item.title.toLowerCase().includes('ticket')) {
                  Add ticket
                } @else {
                  Explore options
                }
              </div>
            </div>
          </button>
        }
      </div>
      <div class="WifejakChat__more-suggestions">
        <button class="WifejakChat__more-button">
          <span>More suggestions</span>
          <i class="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    }
  </div>

  <div class="chat-input" [formGroup]="wifejakChatForm">
    <mat-form-field appearance="outline" class="wifejakChat__form-field">
      <div class="Wifejack__actions-container">
        <textarea matInput cdkTextareaAutosize rows="1" draggable="false" class="CodeChat__textarea" #textareaElement formControlName="message"
          #searchInput class="gtm-search-bar-class" matInput type="search" autofocus="true" [autofocus]="true"
            (keydown.enter)="submitChat(currentUser, $event, wifejakChatForm.value.message, chatMessages())" razrooZetaAutofocus
        ></textarea>
        <div class="Wifejack__actions">
          <div class="Wifejack__actions-inner">
            <button mat-icon-button matTooltip="Go" [disabled]="!wifejakChatForm.value.message"
              (click)="submitChat(currentUser, $event, wifejakChatForm.value.message, chatMessages())">
              <i class="fa-solid fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
    </mat-form-field>
  </div>
</div>

@if (isFloatingInputVisible()) {
  <div class="floating-input-container" 
       [class.walkthrough-active]="isWalkthroughActive()"
       [style.left.px]="floatingInputPosition().x"
       [style.top.px]="floatingInputPosition().y"
       [style.width.px]="floatingInputSize().width"
       [style.height.px]="floatingInputSize().height"
       [attr.data-walkthrough-ignore]="true"
       #floatingInput>
    <div class="floating-input-header"
         (mousedown)="onMouseDown($event, floatingInput)">
      <div class="floating-input-header-left">
        <i class="fa-solid fa-grip-lines"></i>
        <img class="floating-input-logo" src="https://cdn.razroo.com/oppwiz/oppwiz-ai-assistant-robot.svg" alt="Wifejak logo">
        <i class="fa-solid fa-keyboard" matTooltip="Press ⌘ + L to close"></i>
      </div>
      <div class="floating-input-header-right">
        <button mat-icon-button (click)="startWalkthrough(currentUser)" matTooltip="Start Walkthrough" class="WifejakChat__help-button" aria-label="Start Walkthrough">
          <i class="fa-solid fa-question-circle"></i>
        </button>
        <button mat-icon-button (click)="toggleFloatingInput($event)" matTooltip="Close Chat" class="WifejakChat__close-button" aria-label="Close chat">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
    
    <!-- Walkthrough content area -->
    @if (isWalkthroughActive()) {
      <div class="floating-walkthrough-content">
        @if (assistantWalkthrough()) {
          <ng-container *ngIf="assistantWalkthrough() as walkthrough">
            <ng-container *ngIf="walkthrough.groupings && walkthrough.groupings.length > currentGroupingIndex() && walkthrough.groupings[currentGroupingIndex()] as currentGroup">
              <div class="walkthrough-header">
                <h3>{{ currentGroup.title }}</h3>
                <p>{{ currentGroup.description }}</p>
              </div>
              
              <ng-container *ngIf="currentGroup.items && currentGroup.items.length > currentItemIndex() && currentGroup.items[currentItemIndex()] as currentItem">
                <div class="walkthrough-item">
                  <h4>Step {{ currentItemIndex() + 1 }} of {{ currentGroup.items.length }}</h4>
                  <div class="walkthrough-item-title">
                    <i class="fa-solid fa-circle-info"></i>
                    <h5>{{ currentItem?.title }}</h5>
                  </div>
                  <div class="walkthrough-item-description">
                    {{ currentItem?.description }}
                  </div>
                </div>
                
                <div class="walkthrough-navigation">
                  @if (currentItemIndex() > 0 || currentGroupingIndex() > 0) {
                    <button class="walkthrough-prev-btn" (click)="previousWalkthroughStep()">Previous</button>
                  }
                  <button class="walkthrough-next-btn" (click)="nextWalkthroughStep()">
                    {{ (currentItemIndex() < currentGroup.items.length - 1 || currentGroupingIndex() < walkthrough.groupings.length - 1) ? 'Next' : 'Finish' }}
                  </button>
                  <button class="walkthrough-exit-btn" (click)="exitWalkthrough()">Exit</button>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        }
      </div>
    } @else {
      <mat-form-field appearance="outline" class="floating-input-field">
        <textarea matInput
                  cdkTextareaAutosize
                  razrooZetaAutofocus
                  [formControl]="wifejakChatForm.controls['message']"
                  (keydown.enter)="submitChat(currentUser, $event, wifejakChatForm.value.message, chatMessages())"
                  placeholder="Type your message..."></textarea>
      </mat-form-field>
    }
    
    <div class="resize-handle resize-e" (mousedown)="onResizeStart($event, 'e', floatingInput)"></div>
    <div class="resize-handle resize-s" (mousedown)="onResizeStart($event, 's', floatingInput)"></div>
    <div class="resize-handle resize-se" (mousedown)="onResizeStart($event, 'se', floatingInput)"></div>
  </div>
}
</ng-container>
import gql from 'graphql-tag';

export const UpdateUserProfile = gql`
  mutation UpdateUserProfile(
    $userId: String!
    $email: String
    $firstName: String
    $lastName: String
    $nickname: String
    $timezone: String
    $certification: String
    $analyticType: AnalyticType
  ) {
    updateUserProfile(
      userId: $userId
      email: $email
      firstName: $firstName
      lastName: $lastName
      nickname: $nickname
      timezone: $timezone
      certification: $certification
      analyticType: $analyticType
    ) {
      userId
      email
      firstName
      lastName
      nickname
      timezone
      picture
      superuser
      analytics {
        events {
          date
        }
        type
      }
    }
  }
`;

export const CreateUserProfile = gql`
  mutation CreateUserProfile($currentUserEmail: String!, $firstName: String!, $lastName: String!) {
    createUserProfile(currentUserEmail: $currentUserEmail, firstName: $firstName, lastName: $lastName) {
      activeOrgId
      dateCreated
      dateCreatedFirstOrg
      email
      firstName
      grantedBy
      superuser
      picture
      lastName
      nickname
      timezone
      userId
    }
  }
`;

export const UpdateActiveOrgId = gql`
  mutation UpdateActiveOrg($userId: String!, $activeOrgId: String) {
    updateUserProfile(userId: $userId, activeOrgId: $activeOrgId) {
      userId
      email
      activeOrgId
    }
  }
`;
export const GrantCertification = gql`
  mutation GrantCertification($userId: String!, $certification: String, $activeOrgId: String, $grantedBy: String) {
    updateUserProfile(userId: $userId, certification: $certification, activeOrgId: $activeOrgId, grantedBy: $grantedBy) {
      certifications {
        name,
        grantedBy,
        orgId,
        dateGranted
      }
    }
  }
`;

import { gql } from 'graphql-tag';
import { CodeGenerationFragment } from '../generateCode/generateCode.fragments';

export const PathQuery = gql`
	query template($orgId: String!, $pathId: String!, $search: String, $start: Int, $size: Int, $stepper: Boolean, $analyticData: StepperAnalyticData){
    	template(orgId: $orgId, pathId: $pathId, stepper: $stepper, analyticData: $analyticData){
        ai
        author
        userId
        id
        count
        recipeId
        baseCommunityPath
        description
        discordUrl
        latest
        title
        pathId
        corePathId
        orgId
        languageVersion
        marketplacePaths {
          pathId
          pathOrgId
          description
        }
        beta
        privacy
        minimumPrice
        marketplace
        scaffold
        pathType
        stepper(search: $search, start: $start, size: $size, path: $pathId){
          analyticData {
            ...codeGenerationFields
          }
          title
          id
          pathId
          scaffold
          recipeId
          description
          iconName
          batchId
          executionId
          stepper {
            analyticData {
              ...codeGenerationFields
            }
            id
            pathId
            recipeId
            title
            scaffold
            qaStatus {
              qa
              users {
                userId
                firstName
                lastName
                email
              }
            }
          }
          count
        }
      }
	}
  ${CodeGenerationFragment}
`

export const GetPathQuery = gql`
	query getPath($userOrgId: String, $templateOrgId: String!, $pathId: String! $search: String, $start: Int, $size: Int, $analyticData: StepperAnalyticData) {
    	getPath(userOrgId: $userOrgId, templateOrgId: $templateOrgId, pathId: $pathId, analyticData: $analyticData){
        ai
        author
        userId
        id
        count
        recipeId
        baseCommunityPath
        description
        discordUrl
        latest
        title
        minimumPrice
        pathId
        corePathId
        orgId
        paid
        languageVersion
        marketplacePaths {
          pathId
          pathOrgId
          description
        }
        beta
        privacy
        marketplace
        scaffold
        pathType
        stepper(search: $search, start: $start, size: $size, path: $pathId){
          analyticData {
            ...codeGenerationFields
          }
          title
          id
          pathId
          scaffold
          recipeId
          description
          iconName
          batchId
          executionId
          stepper {
            analyticData {
              ...codeGenerationFields
            }
            id
            pathId
            recipeId
            scaffold
            title
            qaStatus {
              qa
              users {
                userId
                firstName
                lastName
                email
              }
            }
          }
          count
        }
      }
	}
  ${CodeGenerationFragment}
`

export const GetPaths = gql`
  query GetPaths($orgId: String!, $pathsType: PathsType){
    getPaths(orgId: $orgId, pathsType: $pathsType){
      userId
      codePath
      description
      id
      corePathId
      latest
      baseCommunityPath
      language
      project
      discordUrl
      languageVersion
      lastUpdated
      pathType
      orgId
      parameters
      stepper{
        id
        next
        orgId
        pathId
        iconName
        recipeId
        scaffold
        prev
        timestamp
        title
      }
      stepperSearch
      timestamp
      title
      type
      marketplace
      beta
    }
  }

`

export const GetPathsForDropdown = gql`
  query GetPaths($orgId: String!, $pathsType: PathsType, $beta: Boolean){
    getPaths(orgId: $orgId, pathsType: $pathsType, beta: $beta){
      userId
      id
      corePathId
      latest
      orgId
      languageVersion
      pathType
      project
      title
      baseCommunityPath
      marketplace
      description
      beta
    }
  }

`

export const GetProjectPaths = gql`
  query GetProjectPaths($orgId: String){
    getProjectPaths(orgId: $orgId){
      author
      baseCommunityPath
      beta
      codePath
      corePathId
      count
      description
      discordUrl
      id
      language
      languageVersion
      lastUpdated
      latest
      marketplace
      marketplacePaths {
        description
        pathId
        pathOrgId
      }
      orgId
      parameters
      parentPath {
        id
        orgId
        title
      }
      pathType
      project
      schema
      stepperSearch
      timestamp
      title
      type
      userId
    }
  }
`

export const GetRazrooStarterRepoFileTree = gql`
    query GetRazrooStarterRepoFileTree($pathId: String!, $branch: String) {
        getRazrooStarterRepoFileTree(pathId: $pathId, branch: $branch) {
            content
        }
    }
`

export const GetSecondaryPaths = gql`
    query GetSecondaryPaths($orgId: String!, $pathId: String!){
      getSecondaryPaths(orgId: $orgId, pathId: $pathId){
        author
        baseCommunityPath
        codePath
        count
        description
        id
        language
        corePathId
        parentPath {
          id
          orgId
          title
        }
        lastUpdated
        orgId
        parameters
        stepperSearch
        timestamp
        title
        type
        userId
        marketplace
      }
    }
`

export const GetPathVersions = gql`
    query GetPathVersions($orgId: String!, $pathId: String!){
      getPathVersions(orgId: $orgId, pathId: $pathId){
        userId
        id
        orgId
        title
        baseCommunityPath
        marketplace
        languageVersion
        description
      }
    }
`
export const GetPathScaffolds = gql`
    query GetPathScaffolds($pathOrgId: String!, $pathId: String!){
      getPathScaffolds(pathOrgId: $pathOrgId, pathId: $pathId){
        count
        description
        firstPathStep
        firstStep
        iconName
        id
        isPublished
        pathId
        recipeId
        title
      }
    }
`

export const GetCodeMetadata = gql`
  query GetCodeMetadata($pathId: String!, $recipeId: String!, $stepId: String!, $orgId: String){
    getCodeMetadata(
      pathId: $pathId
      recipeId: $recipeId
      stepId: $stepId
      orgId: $orgId
    ){
      dependencies
      devDependencies
    }
  }
`



import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { WifejakAiAssistant, GetWifejakAiActionableItems, GetAssistantWalkthrough } from '@razroo-zeta/data-graphql';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WifejakAiService {
  constructor(private apollo: Apollo) {}

  wifejakAiAssistant(orgId: string, userPrompt: string, pageUrl: string, messages?: Array<{role: string, content: string}>) {
    const mutation = WifejakAiAssistant;
    const variables = {
      orgId,
      userPrompt,
      pageUrl,
      messages
    };
    const wifejakAiAssistant$ = this.apollo.mutate({ mutation, variables });
    return from(wifejakAiAssistant$).pipe(map((response: any) => response.data?.wifejakAiAssistant));
  }

  getWifejakAiActionableItems(orgId: string, pageUrl: string, userStatus: string) {
    const mutation = GetWifejakAiActionableItems;
    const variables = {
      orgId,
      pageUrl,
      userStatus
    };
    const getWifejakAiActionableItems$ = this.apollo.mutate({ mutation, variables });
    return from(getWifejakAiActionableItems$).pipe(map((response: any) => response.data?.getWifejakAiActionableItems));
  }

  getAssistantWalkthrough(orgId: string, pageUrl: string) {
    const mutation = GetAssistantWalkthrough;
    const variables = {
      orgId,
      pageUrl
    };
    const getAssistantWalkthrough$ = this.apollo.mutate({ mutation, variables });
    return from(getAssistantWalkthrough$).pipe(map((response: any) => response.data?.assistantWalkthrough));
  }
}

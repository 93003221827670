import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PATH_CMS_FEATURE_KEY,
  State,
  filesToGenerateAdapter,
  starterWithFilesToGenerateAdapter,
  openFilesAdapter,
  terminalOpenFilesAdapter,
} from './path-cms.reducer';

// Lookup the 'PathCms' feature state managed by NgRx
export const getPathCmsState =
  createFeatureSelector<State>(PATH_CMS_FEATURE_KEY);

const { selectAll, selectEntities } = filesToGenerateAdapter.getSelectors();
const starterWithFilesToGenerateAdapterSelect = starterWithFilesToGenerateAdapter.getSelectors();
const starterWithFilesToGenerateAdapterSelectAll = starterWithFilesToGenerateAdapterSelect.selectAll;
const starterWithFilesToGenerateAdapterSelectEntities = starterWithFilesToGenerateAdapterSelect.selectEntities;

const openFilesAdapterSelect = openFilesAdapter.getSelectors();
const openFilesAdapterSelectAll = openFilesAdapterSelect.selectAll;
const openFilesAdapterSelectEntities = openFilesAdapterSelect.selectEntities;

const terminalOpenFilesAdapterSelect = terminalOpenFilesAdapter.getSelectors();
const terminalOpenFilesAdapterSelectAll = terminalOpenFilesAdapterSelect.selectAll;
const terminalOpenFilesAdapterSelectEntities = terminalOpenFilesAdapterSelect.selectEntities;

export const getStarterWithFilesToGenerateLoaded = createSelector(
  getPathCmsState,
  (state: State) => state.starterWithFilesToGenerate.loaded
);

export const getSplitAreaPercent = createSelector(
  getPathCmsState,
  (state: State) => state.splitAreaPercent
);

export const getDocumentationSaving = createSelector(
  getPathCmsState,
  (state: State) => state.documentationSaving
);

export const getAllStarterWithFilesToGenerate = createSelector(
  getPathCmsState,
  (state: any) => starterWithFilesToGenerateAdapterSelectAll(state.starterWithFilesToGenerate)
);

export const getStarterWithFilesToGenerateEntities = createSelector(
  getPathCmsState,
  (state: any) => starterWithFilesToGenerateAdapterSelectEntities(state.starterWithFilesToGenerate)
);

export const getStarterWithFilesToGenerate = createSelector(
  getStarterWithFilesToGenerateEntities,
  (entities: any) => Object.values(entities) ? Object.values(entities) : []
);

export const getStarterWithFilesToGenerateSelectedId = createSelector(
  getPathCmsState,
  (state: State) => state.filesToGenerate.selectedId
);
export const getStarterTemplate = createSelector(
  getPathCmsState,
  (state: State) => state.starterTemplate
);

export const getStarterWithFilesToGenerateSelected = createSelector(
  getStarterWithFilesToGenerateEntities,
  getStarterWithFilesToGenerateSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getOpenFilesLoaded = createSelector(
  getPathCmsState,
  (state: State) => state.openFiles.loaded
);

export const getCodeModLoaded = createSelector(
  getPathCmsState,
  (state: State) => state.codeModLoaded
);

export const getAllOpenFiles = createSelector(
  getPathCmsState,
  (state: any) => openFilesAdapterSelectAll(state.openFiles)
);

export const getOpenFilesEntities = createSelector(
  getPathCmsState,
  (state: any) => openFilesAdapterSelectEntities(state.openFiles)
);

export const getOpenFiles = createSelector(
  getOpenFilesEntities,
  (entities: any) => Object.values(entities) ? Object.values(entities) : []
);

export const getOpenFilesSelectedId = createSelector(
  getPathCmsState,
  (state: State) => state.openFiles.selectedId
);

export const getOpenFilesSelected = createSelector(
  getOpenFilesEntities,
  getOpenFilesSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getOpenFilesSelectedIsReadOnly = createSelector(
  getOpenFilesSelected,
  (selected) => selected && selected.filesToGenerateFolderName && !selected.dependentStep ? false : true
);

export const getOpenFilesSelectedFileText = createSelector(
  getOpenFilesSelected,
  (selected) => selected && selected.fileText ? selected.fileText : ''
);

export const getOpenFilesSelectedPath = createSelector(
  getOpenFilesSelected,
  (selected) => selected && selected.path ? selected.path : ''
);

export const getAllTerminalOpenFiles = createSelector(
  getPathCmsState,
  (state: any) => terminalOpenFilesAdapterSelectAll(state.terminalOpenFiles)
);

export const getTerminalOpenFilesEntities = createSelector(
  getPathCmsState,
  (state: any) => terminalOpenFilesAdapterSelectEntities(state.terminalOpenFiles)
);

export const getTerminalOpenFiles = createSelector(
  getTerminalOpenFilesEntities,
  (entities: any) => Object.values(entities) ? Object.values(entities) : []
);

export const getTerminalOpenFilesSelectedId = createSelector(
  getPathCmsState,
  (state: State) => state.terminalOpenFiles.selectedId
);

export const getTerminalOpenFilesSelected = createSelector(
  getTerminalOpenFilesEntities,
  getTerminalOpenFilesSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getTerminalOpenFilesSelectedFileText = createSelector(
  getTerminalOpenFilesSelected,
  (terminalFile: any) => terminalFile ? terminalFile.fileText : undefined
);

export const getActivityBar = createSelector(
  getPathCmsState,
  (state: State) => state.activityBar
);

export const getCodeMod = createSelector(
  getPathCmsState,
  (state: State) => state.codeMod
);

export const getRecipe = createSelector(
  getPathCmsState,
  (state: State) => state.recipe
);

// need to select specific text to make sure selector isn't triggered by
// other items in openFile
export const getOpenCodeModel = createSelector(
  getOpenFilesSelectedFileText,
  getOpenFilesSelectedPath,
  (openFileFileText, openFilePath) => {
    let language;
    switch(openFilePath.split('.').pop()){
      case 'ts':
        language = 'typescript'
        break;
      case 'js': 
        language = 'javascript'
        break;
      case 'json': 
        language = 'json'
        break;
      case 'scss':
        language = 'scss'
        break;
      case 'html': 
        language = 'html'
        break;
      default: 
        language = 'typescript'
        break;
    }

    return ({
      language,
      value: openFileFileText,
      uri: openFilePath,
      dependencies: ['@types/node']
    })
  }
);

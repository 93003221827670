<h2 mat-dialog-title>Generate Executable <i
  aria-label="What Is A Razroo Starter Repo" class="fa-light fa-circle-question"
  (mouseover)="executeInformationOpen = !executeInformationOpen"
  (mouseout)="executeInformationOpen = !executeInformationOpen"
  cdkOverlayOrigin #executeRepoTrigger="cdkOverlayOrigin"
></i>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="executeRepoTrigger"
  [cdkConnectedOverlayOpen]="executeInformationOpen"
  >
  <mat-card class="child-mat-card">
    <h3 class="ExecuteCode__information-header">What Is An Executable?</h3>
    <p>An executable allows you to generate multiple features at once.</p>
  </mat-card>
</ng-template>
</h2>
<ng-container *ngrxLet="user$ as user">
  <div mat-dialog-content>
    @if (!(data.template?.starter)) {
      <mat-form-field class='github-field'>
        <mat-label>GitHub Repo</mat-label>
        <input type="text"
          matInput
          (keyup)="filterGithubRepos($event)"
          [value]="githubRepo?.name || githubRepoName"
          [matAutocomplete]="githubAuto"
          >
          <mat-autocomplete #githubAuto="matAutocomplete">
            @if ((user?.githubOrg || (user?.activeOrgId === user?.userId))) {
              @for (repo of githubRepos; track repo) {
                <mat-option
                  (click)="selectGithubRepo(repo)"
                  [value]="repo.name"
                  >
                  {{ repo.name }}
                </mat-option>
              }
              <mat-option class="github-link" (click)="getGithubRepos(data.user)">
                <i class="refresh-icon fa-regular fa-refresh"></i>Get Repos
              </mat-option>
            } @else {
              <mat-option (click)="connectGithub()">
                <i class="fa-2x fa-brands fa-github"></i> Choose organization
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      }
      <razroo-zeta-stepper-scroller
        class="ExecuteCodeDialog__scroller"
        [template]="data.template">
      </razroo-zeta-stepper-scroller>
      <div class="ExecuteCodeDialog__tagline-text">
        <p>
          <i>You will receive an email once execution complete</i>
        </p>
      </div>
    </div>
    <div mat-dialog-actions align="end" class="ExecuteCodeDialog__action-buttons">
      <button mat-flat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="accent" (click)="executeCode(githubRepo)">Execute</button>
    </div>
  </ng-container>
  
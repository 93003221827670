import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromVscode from './reducers/vscode.reducer';
import { EffectsModule } from '@ngrx/effects';
import { VscodeEffects } from './effects/vscode.effects';
import { VscodeFacade } from './facade/vscode.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromVscode.vscodeFeatureKey, fromVscode.reducer),
    EffectsModule.forFeature([VscodeEffects])
  ],
  providers: [VscodeFacade],
})
export class DataAccessVscodeModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromBilling from './+state/billing.reducer';
import { BillingEffects } from './+state/billing.effects';
import { BillingFacade } from './+state/billing.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromBilling.BILLING_FEATURE_KEY,
      fromBilling.reducer
    ),
    EffectsModule.forFeature([BillingEffects]),
  ],
  providers: [BillingFacade],
})
export class DataAccessBillingModule {}

import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import * as CommentsActions from './comments.actions';
import { CommentsEntity } from './comments.models';

export const COMMENTS_FEATURE_KEY = 'comments';

export interface State extends EntityState<CommentsEntity> {
  selectedId?: string | number; // which Comments record has been selected
  loaded: boolean; // has the Comments list been loaded
  error?: string | null; // last known error (if any)
}

export interface CommentsPartialState {
  readonly [COMMENTS_FEATURE_KEY]: State;
}

export const commentsAdapter: EntityAdapter<CommentsEntity> =
  createEntityAdapter<CommentsEntity>({
    selectId: (e) => e.commentId,
    sortComparer: sortByUpvotesOrTime
  });

export const initialState: State = commentsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const commentsReducer = createReducer(
  initialState,
  on(CommentsActions.loadComments, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(CommentsActions.loadCommentsSuccess, (state, { comments }) =>
    commentsAdapter.setAll(comments, { ...state, selectedId: comments[0]?.commentId, loaded: true })
  ),
  on(CommentsActions.loadCommentsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(CommentsActions.createCommentSuccess, (state, { comment }) =>
    commentsAdapter.addOne(comment, { ...state, loaded: true })
  ),
  on(CommentsActions.addResponseSuccess, (state, { comment, response }) => {
    if(comment.responses?.length){
      return commentsAdapter.updateOne({id: comment.commentId, changes: {responses: [...comment.responses!, response]}}, { ...state, loaded: true })
    } else {
      return commentsAdapter.updateOne({id: comment.commentId, changes: {responses: [response]}}, { ...state, loaded: true })
    }
  }),
  on(CommentsActions.voteSuccess, (state, {comment}) => 
    commentsAdapter.updateOne({id: comment.commentId, changes: comment}, {...state, loaded: true})
  ),
  on(CommentsActions.deleteCommentSuccess, (state, {comment}) => 
    commentsAdapter.removeOne(comment.commentId, { ...state, loaded: true })
  ),
  on(CommentsActions.deleteCommentResponseSuccess, (state, {response, commentId}) => {
    const filteredResponses = state.entities[commentId]?.responses?.filter(reply => !(reply.index == response.index))!;
    return commentsAdapter.updateOne({id: commentId, changes: {responses: [...filteredResponses]}}, { ...state, loaded: true }) 
  }),
  on(CommentsActions.updateCommentSuccess, (state, {comment}) => 
    commentsAdapter.upsertOne(comment, { ...state, loaded: true })
  ),
  on(CommentsActions.updateCommentResponseSuccess, (state, {response, commentId}) => {
    const filteredResponses = state.entities[commentId]?.responses?.map(reply => reply.index === response.index? response : reply)
    return commentsAdapter.updateOne({id: commentId, changes: {responses: filteredResponses}}, { ...state, loaded: true }) 
  })
);

export function reducer(state: State | undefined, action: Action) {
  return commentsReducer(state, action);
}

export function sortByUpvotesOrTime(a: CommentsEntity, b: CommentsEntity): number {
  if(a.upvotes?.length !== undefined && b.upvotes?.length !== undefined){
    if(a.upvotes?.length! > b.upvotes?.length!){
      return -1
    } else if (a.upvotes?.length! < b.upvotes?.length!){
      return 1
    } else {
      let diff = new Date(a.timestamp!).getTime() - new Date(b.timestamp!).getTime()
      if(diff > 0){
        return -1
      } else if (diff < 0) {
        return 1
      } else return 0
    }
  } else if(a.upvotes?.length !== undefined && b.upvotes?.length === undefined){
    return -1
  } else if(a.upvotes?.length === undefined && b.upvotes?.length !== undefined){
    return 1
  } else{
    let diff = new Date(a.timestamp!).getTime() - new Date(b.timestamp!).getTime()
    if(diff > 0){
      return -1
    } else if (diff < 0) {
      return 1
    } else return 0
  }
}
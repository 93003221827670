export const OrganizationUserFragment = `
fragment organizationUserFields on OrganizationUser {
  orgId
  orgType
  activeOrgId
  email
  userId
  firstName
  lastName
  likedTemplates {
    orgId
    pathId
    recipeId
    stepId
    timestamp
  }
  payoutId
  picture
  orgPicture
  superuser
  githubUserId
  githubOrg
  jiraOrgId
  orgRole
  hasEncryptedJiraApiKey
  paid
  projectsPaid
  codePaid
  stripeCustomerId
  marketplacePaths {
    pathId
    pathOrgId
    description
  }
  timezone
  slackTeamName
  slackTeamId
  pathPermissions {
    codeCommunityPaths
    codeOrgPaths
    contentCommunityPaths
    contentOrgPaths
      }
  orgNotifications {
    notification
    read
    templateCommentParams {
      commentType
      fileName
      languageVersion
      pageUrl
      pathId
    }
    timestamp
  }
  githubRepos {
    id
    name
    html_url
    owner {
      login
    }
    default_branch
    description
    created_at
    updated_at
  }
  activeProject {
    orgId
    id
    baseCommunityPath
    languageVersion
    project
    title
  }
  activeStep {
    orgId
    pathId
    recipeId
    id
    start
    end
  }
  activeTicketWorkspace {
    workspaceName
    workspaceId
    activeBoard
  }
}
`;

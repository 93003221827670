import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ORGANIZATIONS_FEATURE_KEY,
  State,
  organizationsAdapter,
  organizationUsersAdapter,
  organizationTeamsAdapter
} from './organizations.reducer';

// Lookup the 'Organizations' feature state managed by NgRx
export const getOrganizationsState = createFeatureSelector<State>(
  ORGANIZATIONS_FEATURE_KEY
);

const { selectAll, selectEntities } = organizationsAdapter.getSelectors();
const organizationUsersAdapterSelect = organizationUsersAdapter.getSelectors();
const organizationUsersSelectAll = organizationUsersAdapterSelect.selectAll;
const organizationUsersSelectEntities = organizationUsersAdapterSelect.selectEntities;
const organizationTeamsAdapterSelect = organizationTeamsAdapter.getSelectors();
const organizationTeamsSelectAll = organizationTeamsAdapterSelect.selectAll;
const organizationTeamsSelectEntities = organizationTeamsAdapterSelect.selectEntities;

export const getOrganizationsLoaded = createSelector(
  getOrganizationsState,
  (state: State) => state.organizations.loaded
);

export const getOrganizationsError = createSelector(
  getOrganizationsState,
  (state: State) => state.organizations.error
);

export const getAllOrganizations = createSelector(
  getOrganizationsState,
  (state: any) => selectAll(state.organizations)
);

export const getOrganizationsEntities = createSelector(
  getOrganizationsState,
  (state: any) => state.organizations ? Object.values(selectEntities(state.organizations)) : undefined
);

export const getOrganizationsEntitiesRaw = createSelector(
  getOrganizationsState,
  (state: any) => state.organizations ? selectEntities(state.organizations) : undefined
);

export const getSelectedId = createSelector(
  getOrganizationsState,
  (state: State) => state.organizations.selectedId
);

export const getSelected = createSelector(
  getOrganizationsEntitiesRaw,
  getSelectedId,
  (entities, selectedId) => (entities && selectedId ? entities[selectedId] : undefined)
);

export const getSelectedIsPaid = createSelector(
  getSelected,
  (organization) => (organization ? organization.paid : undefined)
);

export const getInvitedUsers = createSelector(
  getOrganizationsState,
  (state: State) => state.invitedUsers ? state.invitedUsers : []
);

export const getOrganizationUsers = createSelector(
  getOrganizationsState,
  (state: State) => Object.values(organizationUsersSelectEntities(state.organizationUsers))
);

export const getOrganizationUsersLoaded = createSelector(
  getOrganizationsState,
  (state: State) => state.organizationUsers.loaded
);

export const getOrganizationUsersError = createSelector(
  getOrganizationsState,
  (state: State) => state.organizationUsers.error
);

export const getAllOrganizationUsers = createSelector(
  getOrganizationsState,
  (state: any) => organizationUsersSelectAll(state.organizationUsers)
);
export const getAllOrganizationTeams = createSelector(
  getOrganizationsState,
  (state: any) => organizationTeamsSelectAll(state.organizationTeams)
);

export const getOrganizationUsersEntities = createSelector(
  getOrganizationsState,
  (state: any) => organizationUsersSelectEntities(state.organizationUsers)
);

export const getSelectedOrganizationUsersId = createSelector(
  getOrganizationsState,
  (state: State) => state.organizationUsers.selectedId
);

export const getSelectedOrganizationUsers = createSelector(
  getOrganizationUsersEntities,
  getSelectedOrganizationUsersId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getOrganizationUserNotifications = createSelector(
  getSelectedOrganizationUsers,
  (organizationUser) => (organizationUser ? (organizationUser as any)?.orgNotifications : undefined)
);

export const getActiveStep = createSelector(
  getSelectedOrganizationUsers,
  (selected) => (selected ? {...selected.activeStep} : undefined)
);

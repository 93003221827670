@if (!noVSCodeConnected) {
  <h1 mat-dialog-title>Confirm Code Generation</h1>
  <div mat-dialog-content>
    Confirm that all variables are correct before proceeding:
    @for (variable of data.variables | keyvalue; track variable) {
      <li>
        <b>{{ variable.key }}: </b>{{ variable.value }}
      </li>
    }
    <form [formGroup]="vsCodeForm">
      <mat-form-field appearance="outline">
        <mat-label>Select a VSCode instance</mat-label>
        <mat-select formControlName="vsCodeSelect">
          @for (instance of vsCodeInstances; track instance) {
            <mat-option [value]="instance">{{instance?.packageJsonParams?.name}}</mat-option>
          }
        </mat-select>
        @if (vsCodeForm.get('vsCodeSelect').invalid && vsCodeForm.get('vsCodeSelect').touched) {
          <mat-error>
            Please select a VSCode instance.
          </mat-error>
        }
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="accent" (click)="go()">Go!</button>
  </div>
} @else {
  <div mat-dialog-content>
    <i>Connect to the Razroo VSCode extension to run this command in your local code editor</i>
  </div>
}

import gql from 'graphql-tag';

export const DeleteProfilePicture = gql`
    mutation DeleteProfilePicture(
        $userPictureParams: userPictureInputParams,
        $orgPictureParams: orgPictureInputParams
    ){
        deleteProfilePicture(
            userPictureParams: $userPictureParams,
            orgPictureParams: $orgPictureParams
        ){
            orgId
            userId
        }
    }
`
<div class="outer-container" [class.home-page]="!(componentName === 'Ticket')">
  @if(!(componentName === 'Ticket')){
    <div class="CommentsSideNav__header">
      <h2>Leave Feedback</h2>
      <button mat-icon-button class="x-out" (click)="closeNav()">
        <i class="fa-solid fa-x"></i>
      </button>
    </div>
  }
  <div class="container">
    <div>
      <div class="comment-header">
        <div class="image-wrapper">
          <div class="comment-image" [ngStyle]="{ 'background-image': 'url(' + currentUser?.picture + ')' }"></div>
          <div class="user-initials">
            {{currentUser | razrooName | initials}}
          </div>
        </div>
        <div class="header-text">
          <div><b>{{currentUser | razrooName}}</b></div>
        </div>
      </div>
    </div>
    @if (textCommentedOn) {
      <div class="text-commented-on">
        @if (componentName === 'Instructional Content') {
          <mark>{{textCommentedOn}}</mark>
        } 
        @if (componentName === 'Ticket'){
          <mark class="ticket-comment">{{textCommentedOn}}</mark>
        }
        @if (componentName === 'Code Sandbox') {
          <pre><code [highlight]="textCommentedOn"></code></pre>
        }
      </div>
    }
    <div class="inputs-div">
      <form [formGroup]="addCommentForm">
        <mat-form-field  class="comment-input" appearance="outline">
          <textarea rows="6" matInput formControlName="commentText" type="text" placeholder="Write a comment...">
          </textarea >
        </mat-form-field>
        <!-- <div class="toggle-div">
          <mat-button-toggle-group formControlName="commentType" aria-label="Font Style">
            <mat-button-toggle value="Bug" class="bug-btn">Bug</mat-button-toggle>
            <mat-button-toggle value="Feature" class="feature-btn">Feature</mat-button-toggle>
            <mat-button-toggle value="Cool" class="cool-btn">Cool</mat-button-toggle>
            <mat-button-toggle value="Edit" class="edit-btn">Edit</mat-button-toggle>
            <mat-button-toggle value="Question" class="edit-btn">Question</mat-button-toggle>
          </mat-button-toggle-group>
        </div> -->
      </form>
      <div class="buttons" >
        <button mat-flat-button color="accent" (click)="postComment(currentUser)">COMMENT</button>
      </div>
    </div>
    @if ((comments$ | async)?.length) {
      <hr class="divider">
    }
    <div>
      @for (comment of (comments$ | async); track comment) {
        <div class="existing-comment">
          <razroo-zeta-comment [comment]="comment" [currentUserId]="currentUser?.userId" (edit)="editComment(comment, $event)" (upvote)="upvote($event)" (downvote)="downvote($event)" (delete)="deleteCommentOrReply(comment, orgId)"></razroo-zeta-comment>
          @if (comment.responses) {
            <div class="replies">
              @for (reply of comment.responses; track reply) {
                <div>
                  <razroo-zeta-comment [comment]="reply" [currentUserId]="currentUser?.userId"  (edit)="editComment(reply, $event, comment.commentId)" (delete)="deleteCommentOrReply(reply, orgId, comment.commentId)"></razroo-zeta-comment>
                </div>
              }
            </div>
          }
          <razroo-zeta-comment-reply (postReply)="postReply($event, comment, orgId)"></razroo-zeta-comment-reply>
        </div>
      }
    </div>
  </div>
</div>
  
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  USER_FEATURE_KEY,
  State,
  UserPartialState,
  userAdapter,
} from './user.reducer';

// Lookup the 'User' feature state managed by NgRx
export const getUserState = createFeatureSelector<UserPartialState, State>(
  USER_FEATURE_KEY
);

const { selectAll, selectEntities } = userAdapter.getSelectors();

export const getUserLoaded = createSelector(
  getUserState,
  (state: State) => state.loaded
);

export const getUserError = createSelector(
  getUserState,
  (state: State) => state.error
);

export const getAllUser = createSelector(getUserState, (state: State) =>
  selectAll(state)
);

export const getUserEntities = createSelector(getUserState, (state: State) =>
  selectEntities(state)
);

export const getSelectedId = createSelector(
  getUserState,
  (state: State) => state.selectedId
);

export const getUserProfile = createSelector(
  getUserEntities,
  (entities) => entities as any
);

export const getSelected = createSelector(
  getUserEntities,
  getSelectedId,
  (entities, selectedId) => selectedId ? entities[selectedId] : undefined
);

export const getSelectedActiveOrgId = createSelector(
  getSelected,
  (selected) => selected ? selected.activeOrgId : undefined
);

export const getSelectedUserPaid = createSelector(
  getSelected,
  (selected) => selected ? selected.paid : undefined
);

export const getActiveStep = createSelector(
  getSelected,
  (selected) => selected ? selected.activeStep : undefined
);

export const getActiveProject = createSelector(
  getSelected,
  (selected) => selected ? selected.activeProject : undefined
);

export const getSuperUserRole = createSelector(
  getSelected,
  (selected) => selected ? selected.roles?.filter((role:any) => role.name === 'Super User')[0] : undefined
);

export const getCommunityArchitectRole = createSelector(
  getSelected,
  (selected) => selected ? selected.roles?.filter((role:any) => role.name === "community-architect")[0] : undefined
);

export const getCertifications = createSelector(
  getSelected,
  (selected) => selected ? selected.certifications : undefined
);

export const getCurrentUserRoles = createSelector(
  getSelected,
  (selected) => selected ? selected.roles?.map((role) => role.name) : undefined
);

export const isDeveloperOrBetaUser = createSelector(
  getCurrentUserRoles,
  (roles) => roles ? roles.includes("BetaUser") || roles.includes("Developer") : undefined
);

export const getCurrentUserOrganizations = createSelector(
  getSelected,
  (selected) => selected? selected.userOrganizations : undefined
);

import { createAction, props } from '@ngrx/store';
import { Template, ParentPath, TemplatePermission } from '@razroo-zeta/data-models';
import { PathEntity } from './path.models';
import { Recipe } from '@razroo-zeta/data-models';


export const loadAllPaths = createAction(
  '[Path Page] Load All Paths',
  props<{orgId: string}>()
);

export const loadAllPathsSuccess = createAction(
  '[Path/API] Load All Paths Success',
  props<{ paths: any }>()
);

export const loadAllPathsFailure = createAction(
  '[Path/API] Load All Paths Failure',
  props<{ error: any }>()
);
export const loadProjectPaths = createAction(
  '[Path Page] Load Project Paths',
  props<{orgId: string}>()
);

export const loadProjectPathsSuccess = createAction(
  '[Path/API] Load Project Paths Success',
  props<{ paths: any }>()
);

export const loadProjectPathsFailure = createAction(
  '[Path/API] Load Project Paths Failure',
  props<{ error: any }>()
);

export const loadPath = createAction(
  '[Path Page] Load Path',
  props<{userOrgId: string, pathId: string, orgId: string}>()
);

export const loadPathSuccess = createAction(
  '[Path/API] Load Path Success',
  props<{ path: PathEntity }>()
);

export const loadPathFailure = createAction(
  '[Path/API] Load Path Failure',
  props<{ error: any }>()
);

export const loadPathTemplate = createAction(
  '[Path/API] Load Path Template',
  props<{orgId: string, pathId: string}>()
);

export const loadChatPathTemplate = createAction(
  '[Path/API] Load Chat Path Template',
  props<{orgId: string, pathId: string}>()
);

export const loadPathTemplateSuccess = createAction(
  '[Path/API] Load Path Template Success',
  props<{ path: Template }>()
);

export const loadPathTemplateFailure = createAction(
  '[Path/API] Load Path Template Failure',
  props<{ error: any }>() 
);

export const loadSecondaryPath = createAction(
  '[Path Page] Load Secondary Path',
  props<{userOrgId: string, pathId: string, orgId: string, parentPath: ParentPath}>()
);

export const loadSecondaryPathSuccess = createAction(
  '[Path/API] Load Secondary Path Success',
  props<{ path: PathEntity, parentPath: ParentPath }>()
);

export const loadSecondaryPathFailure = createAction(
  '[Path/API] Load Secondary Path Failure',
  props<{ error: any }>()
);

export const createPath = createAction(
  '[Path Page] Create Path',
  props<{orgId: string, userId: string, path: string, description: string, baseCommunityPath: string | null, privacy: string, languageVersion: string, marketplace: boolean, minimumPrice: string}>()
);

export const createPathSuccess = createAction(
  '[Path/API] Create Path Success',
  props<{ path: PathEntity }>()
);

export const createPathFailure = createAction(
  '[Path/API] Create Path Failure',
  props<{ error: any }>()
);

export const createProjectSuccess = createAction(
  '[Path/API] Create Project Success',
  props<{ project: PathEntity }>()
);
export const searchPath = createAction(
  '[Path Page] Search Path',
  props<{userOrgId: string, orgId: string, pathId: string, search: string, start: number, size: number}>()
);

export const searchPathSuccess = createAction(
  '[Path/API] Search Path Success',
  props<{ path: PathEntity }>()
);

export const searchPathFailure = createAction(
  '[Path/API] Search Path Failure',
  props<{ error: any }>()
);

export const archiveRecipe = createAction(
  '[Path Page] Archive Recipe',
  props<{orgId: string, pathId: string, recipeId: string, path: PathEntity}>()
);

export const archiveRecipeSuccess = createAction(
  '[Path/API] Archive Recipe Success',
  props<{ recipe: Template, path: PathEntity}>()
);

export const archiveRecipeFailure = createAction(
  '[Path/API] Archive Recipe Failure',
  props<{ error: any }>()
);

export const deleteRecipe = createAction(
  '[Path Page] Delete Recipe',
  props<{orgId: string, pathId: string, recipeId: string, path?: PathEntity}>()
);

export const deleteRecipeSuccess = createAction(
  '[Path/API] Delete Recipe Success',
  props<{ recipe: Template, path: PathEntity}>()
);

export const deleteRecipeFailure = createAction(
  '[Path/API] Delete Recipe Failure',
  props<{ error: any }>()
);

export const archivePath = createAction(
  '[Path Page] Archive Path',
  props<{orgId: string, pathId: string}>()
);

export const archivePathSuccess = createAction(
  '[Path/API] Archive Path Success',
  props<{ path: PathEntity}>()
);

export const archivePathFailure = createAction(
  '[Path/API] Archive Path Failure',
  props<{ error: any }>()
);

export const addSecondaryPath = createAction(
  '[Path Page] Add Secondary Path',
  props<{orgId: string,
    userId: string,
    pathId: string,
    secondaryPathId: string,
    secondaryPathOrgId: string
  }>()
);

export const addSecondaryPathSuccess = createAction(
  '[Path Page] Add Secondary Path Success',
  props<{ secondaryPaths: any}>()
)
export const addSecondaryPathFailure = createAction(
  '[Path Page] Add Secondary Path Failure',
  props<{error: any}>()
)

export const getSecondaryPaths = createAction(
  '[Path Page] Get Secondary Paths',
  props<{ orgId: string, pathId: string }>()
)

export const getSecondaryPathsSuccess = createAction(
  '[Path Page] Get Secondary Paths Success',
  props<{ paths: any }>()
)

export const getSecondaryPathsFailure = createAction(
  '[Path Page] Get Secondary Paths Failure',
  props<{ error: any }>()
)

export const removeSecondaryPath = createAction(
  '[Path Page] Remove Secondary Path',
  props<{orgId: string,
    userId: string,
    pathId: string,
    secondaryPathId: string,
    secondaryPathOrgId: string }>()
)

export const removeSecondaryPathSuccess = createAction(
  '[Path Page] Remove Secondary Path Success',
  props<{ secondaryPaths: any}>()
)
export const removeSecondaryPathFailure = createAction(
  '[Path Page] Remove Secondary Path Failure',
  props<{error: any}>()
)

export const updatePath = createAction(
  '[Path Page] Update Path',
  props<{orgId: string, pathId: string, pathUpdateParams: any}>()
)
export const updatePathSuccess = createAction(
  '[Path Page] Update Path Success',
  props<{path: PathEntity}>()
)
export const updatePathFailure = createAction(
  '[Path Page] Update Path Failure',
  props<{error: any}>()
)
export const getPathVersions = createAction(
  '[Path Page] Get Path Versions',
  props<{orgId: string, pathId: string}>()
)
export const getPathVersionsSuccess = createAction(
  '[Path Page] Get Path Versions Success',
  props<{paths: [PathEntity]}>()
)
export const getPathVersionsFailure = createAction(
  '[Path Page] Get Path Versions Failure',
  props<{error: any}>()
)

export const addPathToDropdown = createAction(
  '[Path Page] Add Path To Dropdown',
  props<{ path: any}>()
);

export const noAction = createAction(
  '[Path Page] No Action'
)

export const toggleRecipeAsBatch = createAction(
  '[Path Page] Toggle Recipe As Batch',
  props<{ recipe: Recipe}>()
)

export const loadPathPermissionsSuccess = createAction(
  '[Path Page] Load Path Permissions Success',
  props<{ pathPermissions: TemplatePermission[]}>()
)

export const addMarketplacePathToCommunity = createAction(
  '[Path Page] AddMarketplacePathToCommunity',
  props<{userOrgId: string, communityPathId: string, marketplaceOrgId: string, marketplacePathId: string, marketplacePathDescription: string}>()
)
export const addMarketplacePathToCommunitySuccess = createAction(
  '[Path Page] AddMarketplacePathToCommunity Success',
  props<{path: PathEntity}>()
)
export const addMarketplacePathToCommunityFailure = createAction(
  '[Path Page] AddMarketplacePathToCommunity Failure',
  props<{error: any}>()
)

export const removeMarketplacePathFromCommunityPath = createAction(
  '[Path Page] Remove Marketplace Path From Community Path',
  props<{userOrgId: string, communityPathId: string, marketplaceOrgId: string, marketplacePathId: string}>()
)

export const removeMarketplacePathFromCommunityPathSuccess = createAction(
  '[Path Page] Remove Marketplace Path From Community Path Success',
  props<{path: any}>()
)

export const removeMarketplacePathFromCommunityPathFailure = createAction(
  '[Path Page] Remove Marketplace Path From Community Path Failure',
  props<{error: any}>()
)

export const reorderCommunityMarketplacePaths = createAction(
  '[Path Page] ReorderCommunityMarketplacePaths',
  props<{userOrgId: string,
    communityPathId: string,
    marketplaceOrgId: string,
    marketplacePathId: string,
    previousMarketplaceOrgId?: string,
    previousMarketplacePathId?: string}>()
)
export const reorderCommunityMarketplacePathsSuccess = createAction(
  '[Path Page] ReorderCommunityMarketplacePathsSuccess',
  props<{path: PathEntity}>()
)
export const reorderCommunityMarketplacePathsFailure = createAction(
  '[Path Page] ReorderCommunityMarketplacePathsFailure',
  props<{error: any}>()
)
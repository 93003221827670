import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TICKET_WORKSPACES_FEATURE_KEY,
  TicketWorkspacesState,
  ticketWorkspaceBoardsAdapter,
  ticketWorkspacesAdapter,
} from './ticket-workspaces.reducer';

// Lookup the 'TicketWorkspaces' feature state managed by NgRx
export const selectTicketWorkspacesState =
  createFeatureSelector<TicketWorkspacesState>(TICKET_WORKSPACES_FEATURE_KEY);

const { selectAll, selectEntities } = ticketWorkspacesAdapter.getSelectors();
const ticketWorkspaceBoardsAdapterSelector = ticketWorkspaceBoardsAdapter.getSelectors();
const boardsSelectAll = ticketWorkspaceBoardsAdapterSelector.selectAll;
const boardsSelectEntities = ticketWorkspaceBoardsAdapterSelector.selectEntities;
const boardsSelectIds = ticketWorkspaceBoardsAdapterSelector.selectIds;

export const selectTicketWorkspacesLoaded = createSelector(
  selectTicketWorkspacesState,
  (state: TicketWorkspacesState) => state.loaded
);

export const selectTicketWorkspaceBoardsLoaded = createSelector(
  selectTicketWorkspacesState,
  (state: TicketWorkspacesState) => state.ticketWorkspaceBoards.loaded
);

export const selectTicketWorkspaceBoardId = createSelector(
  selectTicketWorkspacesState,
  (state: TicketWorkspacesState) => state.selectedBoardId
);
export const selectTicketWorkspaceBoard = createSelector(
  selectTicketWorkspacesState,
  (state: TicketWorkspacesState) => state.ticketWorkspaceBoards?.entities[state.selectedBoardId!]
);

export const selectTicketWorkspacesError = createSelector(
  selectTicketWorkspacesState,
  (state: TicketWorkspacesState) => state.error
);

export const selectAllTicketWorkspaces = createSelector(
  selectTicketWorkspacesState,
  (state: TicketWorkspacesState) => selectAll(state)
);

export const selectTicketWorkspacesEntities = createSelector(
  selectTicketWorkspacesState,
  (state: TicketWorkspacesState) => selectEntities(state)
);

export const selectTicketWorkspaces = createSelector(
  selectTicketWorkspacesEntities,
  (entities: any) => entities ? Object.values(entities) : undefined
);

export const selectSelectedId = createSelector(
  selectTicketWorkspacesState,
  (state: TicketWorkspacesState) => state.selectedId
);

export const selectEntity = createSelector(
  selectTicketWorkspacesEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const selectTicketWorkspaceBoardEntities = createSelector(
  selectTicketWorkspacesState,
  (state: TicketWorkspacesState) => boardsSelectEntities(state.ticketWorkspaceBoards)
);

export const selectTicketWorkspaceBoardIds = createSelector(
  selectTicketWorkspacesState,
  (state: TicketWorkspacesState) => boardsSelectIds(state.ticketWorkspaceBoards)
);

export const selectTicketWorkspaceBoards = createSelector(
  selectTicketWorkspaceBoardEntities,
  selectTicketWorkspaceBoardIds,
  (entities: any, ids: any[]) => (entities && ids) ? ids.map(id => entities[id]) : undefined
);

import { gql } from 'graphql-tag';
import { RazrooProductFragment } from './products.fragments';

export const GetRazrooProducts = gql`
  query GetRazrooProducts($orgId: String!) {
    razrooProducts(orgId: $orgId) {
      activeProducts {
        ...razrooProductFields
      }
      nonActiveProducts {
        ...razrooProductFields
      }
    }
  }
  ${RazrooProductFragment}
`;

export const GetPaidStripeProductsForUser = gql`
  query GetPaidStripeProductsForUser($orgId: String!, $userId: String!) {
    getPaidStripeProductsForUser(orgId: $orgId, userId: $userId)
  }
`;

export const GetStripeProduct = gql`
  query GetStripeProduct($productName: String!) {
    stripeProduct(productName: $productName) {
      title
      id
      monthly {
        id
        priceId
        name
        description
      }
      yearly {
        id
        priceId
        name
        description
      }
    }
  }
`;



import gql from 'graphql-tag';

export const GetDownloadUrl = gql`
  query GetDownloadUrl($downloadFileType: DownloadFileType!, $id: String, $s3Key: String) {
    getDownloadUrl(downloadFileType: $downloadFileType, id: $id, s3Key: $s3Key) {
      url
    }
  }
`;

export const GetUploadUrl = gql`
  query GetUploadUrl($userProfilePic: UserProfilePicInput, $organizationProfilePic: OrganizationProfilePicInput, $uploadTemplate: UploadTemplateInput) {
    getUploadUrl(userProfilePic: $userProfilePic, organizationProfilePic: $organizationProfilePic, uploadTemplate: $uploadTemplate) {
      url
    }
  }
`;

export const GetUserPictureUploadUrl = gql`
  query GetUserPictureUploadUrl{
    getUserPictureUploadUrl{
      contentType
      url
    }
  }
`

export const GetOrgPictureUploadUrl = gql`
  query GetOrgPictureUploadUrl($orgId: String!){
    getOrgPictureUploadUrl(orgId: $orgId){
      contentType
      url
    }
  }
`

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import * as SearchActions from './search.actions';
import { debounceTime, map } from 'rxjs/operators';
import { TemplatesService } from '@razroo-zeta/data-services';

@Injectable()
export class SearchEffects {
  loadSearch$ = createEffect(() =>
    this.actions$.pipe(
      debounceTime(300),
      ofType(SearchActions.loadSearch),
      fetch({
        run: (action) => {
          return this.templatesService
            .getTemplatesSearchResults(action.search, action.path, action.orgId)
            .pipe(
              map((search: any) =>
                SearchActions.loadSearchSuccess({ search: search })
              )
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return SearchActions.loadSearchFailure({ error });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private templatesService: TemplatesService
  ) {}
}

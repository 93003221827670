import gql from 'graphql-tag';

export const GetAllBooks = gql`
  query Books($orgId: String!, $userId: String!, $search: String) {
    books(orgId: $orgId, userId: $userId, search: $search) {
      category
      author
      id
      score
      title
    }
  }
`;

import gql from 'graphql-tag';
import { GenerateVsCodeDownloadCodeFragment } from './generateCode.fragments';

export const GenerateVsCodeDownloadCode = gql`
  mutation GenerateVsCodeDownloadCode(
    $generateVsCodeDownloadCodeParameters: GenerateCodeDownloadInput
  ) {
    generateVsCodeDownloadCode(
      generateVsCodeDownloadCodeParameters: $generateVsCodeDownloadCodeParameters
    ) {
      ...generateVsCodeDownloadCodeFields
    }
  }
  ${GenerateVsCodeDownloadCodeFragment}
`;

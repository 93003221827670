import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TICKETS_FEATURE_KEY,
  ticketsAdapter,
  State,
  titleTicketsAdapter,
  epicSuggestionsAdapter,
  epicsAdapter
} from './tickets.reducer';

// Lookup the 'Tickets' feature state managed by NgRx
export const selectTicketsState = createFeatureSelector<State>(TICKETS_FEATURE_KEY);

const { selectAll, selectEntities } = ticketsAdapter.getSelectors();
const { selectAll: selectAllTitleTickets, selectEntities:  selectAllTitleTicketsEntities } = titleTicketsAdapter.getSelectors();
const { selectAll: selectAllEpicSuggestions, selectEntities:  selectAllEpicSuggestionsEntities } = epicSuggestionsAdapter.getSelectors();
const { selectAll: selectAllEpics, selectEntities:  selectAllEpicsEntities } = epicsAdapter.getSelectors();

export const selectTicketsLoaded = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.loaded
);

export const selectGeneratingAI = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.generatingAI
);

export const selectGeneratingTicketAI = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.generatingTicketAI
);

export const selectGeneratingTicketsAI = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.generatingTicketsAI
);

export const selectTicketsError = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.error
);

export const selectCommunityTicketWorkspaces = createSelector(
  selectTicketsState,
  (state: State) => state.communityTicketWorkspaces
);

export const selectTotalHits = createSelector(
  selectTicketsState,
  (state: State) => state.totalHits
);

export const selectAllTickets = createSelector(
  selectTicketsState,
  (state: State) => selectAll(state.tickets)
);

export const selectEpicsEntities = createSelector(
  selectTicketsState,
  (state: State) => selectAllEpicsEntities(state.epics)
);

export const selectEpics = createSelector(
  selectEpicsEntities,
  (entities: any) => entities ? Object.values(entities) : undefined
);

export const selectSelectedEpicId = createSelector(
  selectTicketsState,
  (state: State) => state.selectedEpicId
);

export const selectSelectedEpic = createSelector(
  selectEpics,
  selectSelectedEpicId,
  (epics: any, selectedEpicId) => selectedEpicId ? epics.find((epic: any) => epic?.ticketNumber === selectedEpicId) : {}
);


export const selectEpicSuggestionsEntities = createSelector(
  selectTicketsState,
  (state: State) => selectAllEpicSuggestionsEntities(state.epicSuggestions)
);

export const selectEpicSuggestions = createSelector(
  selectEpicSuggestionsEntities,
  (entities: any) => entities ? Object.values(entities) : undefined
);

export const selectTicketsEntities = createSelector(
  selectTicketsState,
  (state: State) => selectEntities(state.tickets)
);

export const selectTickets = createSelector(
  selectTicketsEntities,
  (entities: any) => entities ? Object.values(entities) : undefined
);

export const selectSelectedId = createSelector(
  selectTicketsState,
  (state: State) => state.tickets.selectedId
);

export const selectEntity = createSelector(
  selectTicketsEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
export const selectAllTicketHistory = createSelector(
  selectTicketsState,
  (state: State) => selectAll(state.ticketHistory)
);
export const selectHistorySelectedId = createSelector(
  selectTicketsState,
  (state: State) => state.ticketHistory.selectedId
);
export const selectHistoryLoaded = createSelector(
  selectTicketsState,
  (state: State) => state.ticketHistory.loaded
);
export const selectHistoryEdited = createSelector(
  selectTicketsState,
  (state: State) => state.ticketHistory.edited
);

export const selectColumnsLoaded = createSelector(
  selectTicketsState,
  (state: State) => state.columnsLoaded
);

export const ticketUpdating = createSelector(
  selectTicketsState,
  (state: State) => state.ticketUpdating
);

export const selectTicketUpdatingStatus = createSelector(
  selectTicketsState,
  (state: State) => state.ticketUpdatingStatus
);

export const selectAllColumns = createSelector(
  selectTicketsState,
  (state: State) => state.columns
);

export const selectBoardId = createSelector(
  selectTicketsState,
  (state: State) => state.boardId
);

export const selectEpicGenerating = createSelector(
  selectTicketsState,
  (state: State) => state.epicGenerating
);

export const selectGeneratingTitleTickets = createSelector(
  selectTicketsState,
  (state: State) => state.generatingTitleTickets
);

export const selectTitleTicketsEntities = createSelector(
  selectTicketsState,
  (state: State) => selectAllTitleTicketsEntities(state.titleTickets)
);

export const selectTitleTickets = createSelector(
  selectTitleTicketsEntities,
  (entities: any) => entities ? Object.values(entities) : undefined
);

import { Injectable } from '@angular/core';
import { FileTreeService } from '@razroo-zeta/common-services';
import { Template, TemplateInputParameter } from '@razroo-zeta/data-models';
import { unionBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PathCmsScaffoldService {

  constructor(private fileTreeService: FileTreeService) { }

  /**
   * updateParametersIfRelevant - add existing parameters from scaffold to current parameters
   * preference being given to scaffolded values
   */
  updateParametersIfRelevant(parameters: TemplateInputParameter[], scaffoldParameters: TemplateInputParameter[], folderPath: string): TemplateInputParameter[] | undefined {
    const updatedScaffoldParameters = this.replaceFilePathParameterWithCurrentFolderPath(scaffoldParameters, folderPath);
    if(parameters) {
      return unionBy([
        ...updatedScaffoldParameters,
        ...parameters,
      ], 'name');
    } else {
      return unionBy([
        ...updatedScaffoldParameters,
      ], 'name');
    }
  }

  replaceFilePathParameterWithCurrentFolderPath(scaffoldParameters: TemplateInputParameter[], folderPath: string): TemplateInputParameter[] {
    return scaffoldParameters ? scaffoldParameters.map(scaffoldParameter => {
      const dynamicFolderName = this.extractDynamicFolderIfRelevant(scaffoldParameter.defaultValue);
      return {
        ...scaffoldParameter,
        defaultValue: scaffoldParameter.paramType === 'filePath' ? (dynamicFolderName ? `${folderPath}/${dynamicFolderName}` : folderPath) : scaffoldParameter.defaultValue
      }
    }): []
  }

  /**
   * if e.g. libs/ui/{name}-dialog will extract {name}-dialog
   */
  extractDynamicFolderIfRelevant(folderPath: string): string | undefined {
    const filePathArray = folderPath.split('/');
    const lastArrItemOfPath = filePathArray[filePathArray.length - 1];
    const curlyBraceRegex = /{(.*?)}/g;
    if(curlyBraceRegex.test(lastArrItemOfPath)) {
      const folderPath = filePathArray.pop();
      return folderPath
    }
    else {
      return undefined
    }
  }

  createScaffoldFolderKeys(scaffold: Template, template: Template): {sourceFolderKey: string, destinationFolderKey: string} {
    const firstFile = scaffold.fileTree && scaffold.fileTree[0];
    const dynamicFolderName = firstFile.split('/')[0];
    const sourceFolderKey = `community/${scaffold.pathId}/${scaffold.recipeId}/${scaffold.id}/code/${dynamicFolderName}`;
    const destinationFolderKey = `${template.orgId}/${template.pathId}/${template.recipeId}/${template.id}/code/${dynamicFolderName}`;
    return {
      sourceFolderKey,
      destinationFolderKey
    }
  }
}

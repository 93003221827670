import gql from 'graphql-tag';

export const SendJoinOrgEmail = gql`
  mutation sendJoinOrgEmail($orgId: ID!, $userEmail: String!, $link: String!, $currentUserEmail: String) {
    sendJoinOrgEmail(orgId: $orgId, userEmail: $userEmail, link: $link, currentUserEmail: $currentUserEmail) {
      body
      statusCode
    }
  }
`;
export const processJoinOrgEmailLink = gql`
  mutation processJoinOrgEmailLink($token: String!) {
    processJoinOrgEmailLink(token: $token) {
      dateJoinedOrg
      email
      firstName
      isActive
      lastName
      orgId
      orgRole
      seniority
      userId
    }
  }
`;

export const RemoveInvitedUser= gql`
  mutation removeInvitedUser($orgId: String!, $userEmail: String!) {
    removeInvitedUser(orgId: $orgId, userEmail: $userEmail) {
      userEmail
    }
  }
`;



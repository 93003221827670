import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, vscodeFeatureKey, vscodeAdapter, vsCodeDirectoriesAdapter } from '../reducers/vscode.reducer';

export const selectVscodeState = createFeatureSelector<State>(
  vscodeFeatureKey
);

const { selectAll, selectEntities } = vscodeAdapter.getSelectors();

const vsCodeDirectoriesAdapterSelect = vsCodeDirectoriesAdapter.getSelectors();
const vsCodeDirectoriesAdapterSelectAll = vsCodeDirectoriesAdapterSelect.selectAll;
const vsCodeDirectoriesAdapterSelectEntities = vsCodeDirectoriesAdapterSelect.selectEntities;

export const getVscodeLoaded = createSelector(
  selectVscodeState,
  (state: State) => state.loaded
);

export const getVscodeError = createSelector(
  selectVscodeState,
  (state: State) => state.error
);

export const getAllVscode = createSelector(selectVscodeState, (state: State) =>
  selectAll(state)
);

export const getVscodeEntities = createSelector(selectVscodeState, (state: State) =>
  selectEntities(state)
);

export const getVscodeInstances = createSelector(
  getVscodeEntities,
  (entities) => Object.values(entities) ? Object.values(entities) : []
);

export const getSelectedId = createSelector(
  selectVscodeState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getVscodeEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getVsCodeParameters = createSelector(
  selectVscodeState,
  (state: State) => state.parameters ? state.parameters : undefined
);

export const getVsCodeFilesToGenerate = createSelector(
  selectVscodeState,
  (state: State) => state.filesToGenerate ? state.filesToGenerate : undefined
);

export const getVsCodeDirectoriesLoaded = createSelector(
  selectVscodeState,
  (state: State) => state.vsCodeDirectories.loaded
);

export const getAllVsCodeDirectories = createSelector(
  selectVscodeState,
  (state: any) => vsCodeDirectoriesAdapterSelectAll(state.vsCodeDirectories)
);

export const getVsCodeDirectoriesEntities = createSelector(
  selectVscodeState,
  (state: any) => vsCodeDirectoriesAdapterSelectEntities(state.vsCodeDirectories)
);

export const getVsCodeDirectories = createSelector(
  getVsCodeDirectoriesEntities,
  (entities: any) => Object.values(entities) ? Object.values(entities) : []
);

export const getVsCodeDirectoriesSelectedId = createSelector(
  selectVscodeState,
  (state: State) => state.vsCodeDirectories.selectedId
);

export const getVsCodeDirectoriesSelected = createSelector(
  getVsCodeDirectoriesEntities,
  getVsCodeDirectoriesSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  addStepDependencySuccess,
  removeStepDependencySuccess,
} from './../../../../path-cms/src/lib/+state/path-cms.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as PathCmsActions from '../../../../path-cms/src/lib/+state/path-cms.actions';
import * as TemplatesActions from './templates.actions';
import { TemplatesEntity } from './templates.models';
import {
  loadGenerateCode,
  loadGenerateCodeSuccess,
} from '../../../../organizations/src/lib/+state/generate-code/generate-code.actions';

import { updateParameterValue } from 'libs/data-access/vscode/src/lib/actions/vscode.actions';

export const TEMPLATES_FEATURE_KEY = 'templates';

export interface State extends EntityState<TemplatesEntity> {
  selectedId?: string; // which Templates record has been selected
  loaded: boolean; // has the Templates list been loaded
  error?: string | null; // last known error (if any)
  recipeIdHoveredOver: string | null;
  instructionalContent?: string;
  relevantQuestionsAiLoaded: boolean;
  instructionalContentAiLoading: boolean;
  commandText?: string;
  aggregate: boolean;
  stepper?: any;
}

export interface TemplatesPartialState {
  readonly [TEMPLATES_FEATURE_KEY]: State;
}

export const templatesAdapter: EntityAdapter<TemplatesEntity> =
  createEntityAdapter<TemplatesEntity>();

export const initialState: State = templatesAdapter.getInitialState({
  // set initial required properties
  recipeIdHoveredOver: null,
  loaded: false,
  relevantQuestionsAiLoaded: true,
  instructionalContentAiLoading: false,
  aggregate: false,
  commandText: undefined,
  stepper: { steps: [], start: 0, end: 0 },
});

const templatesReducer = createReducer(
  initialState,
  on(TemplatesActions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(TemplatesActions.loadTemplate, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(loadGenerateCode, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(loadGenerateCodeSuccess, (state) => ({
    ...state,
    loaded: true,
    error: null,
  })),
  on(TemplatesActions.loadPathBatchTemplate, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(TemplatesActions.updateActivePathOrgId, (state, { activePathOrgId }) => ({
    ...state,
    activePathOrgId: activePathOrgId,
  })),
  on(TemplatesActions.setCommandText, (state, { commandText }) => ({
    ...state,
    commandText: commandText,
  })),
  on(TemplatesActions.loadTemplateSuccess, (state, { template }) =>
    templatesAdapter.upsertOne(template, {
      ...state,
      loaded: true,
      selectedId: template.id,
    })
  ),
  on(TemplatesActions.addDocumentationViaAiToStep, (state, action) => ({
    ...state,
    instructionalContentAiLoading: true,
    error: null,
  })),
  on(
    TemplatesActions.addDocumentationViaAiToStepSuccess,
    (state, { stepId, markdown }) =>
      templatesAdapter.updateOne(
        {
          id: stepId,
          changes: {
            instructionalContent: markdown
          },
        }, {
        ...state,
        instructionalContentAiLoading: false,
        selectedId: stepId,
      })
  ),
  on(TemplatesActions.addRelevantQuestionsViaAiToStep, (state) => ({
    ...state,
    relevantQuestionsAiLoaded: false,
    error: null,
  })),
  on(
    TemplatesActions.addRelevantQuestionsViaAiToStepSuccess,
    (state, { template }) =>
      templatesAdapter.upsertOne(template, {
        ...state,
        relevantQuestionsAiLoaded: true,
        selectedId: template.id,
      })
  ),
  on(TemplatesActions.createStepSuccess, (state, { template, stepper }) =>
    templatesAdapter.upsertOne(
      { ...template, stepper: [...stepper, template] },
      {
        ...state,
        loaded: true,
        selectedId: template.id,
      }
    )
  ),
  on(TemplatesActions.loadStepperSuccess, (state, { stepper, amount }) => ({
    ...state,
    stepper: { steps: stepper, amount },
  })),
  on(
    TemplatesActions.loadStepperSupplementSuccess,
    (state, { stepper, amount }) => ({
      ...state,
      stepper: { steps: [...state.stepper.steps, ...stepper], amount },
    })
  ),
  on(TemplatesActions.updateStep, (state, { id, stepUpdateParams }) =>
    templatesAdapter.updateOne(
      {
        id: id,
        changes: { ...(stepUpdateParams as any) },
      },
      {
        ...state,
        selectedId: id,
      }
    )
  ),
  on(TemplatesActions.updateStepSuccess, (state, { template }) =>
    templatesAdapter.upsertOne(template, {
      ...state,
      selectedId:
        template && template.stepper && template.type === 'Recipe'
          ? template.stepper[0].id
          : template.id,
      loaded: true,
    })
  ),
  on(
    TemplatesActions.likeTemplateSuccess,
    TemplatesActions.downvoteTemplateSuccess,
    (state, { template }) =>
      templatesAdapter.updateOne(
        {
          id: template.id,
          changes: {
            likeCount: template.likeCount,
          },
        },
        state
      )
  ),
  on(addStepDependencySuccess, (state, { stepToUpdateTemplate, dependencyStep }) =>
    templatesAdapter.updateOne(
      {
        id: stepToUpdateTemplate.id,
        changes: {
          dependentSteps: state.entities[stepToUpdateTemplate.id]
            ?.dependentSteps
            ? [
                ...(state.entities[stepToUpdateTemplate.id]
                  ?.dependentSteps as any),
                { orgId: dependencyStep.orgId, pathId: dependencyStep.pathId, recipeId: dependencyStep.recipeId, stepId: dependencyStep.id },
              ]
            : [{ orgId: dependencyStep.orgId, pathId: dependencyStep.pathId, recipeId: dependencyStep.recipeId, stepId: dependencyStep.id }],
        },
      },
      state
    )
  ),
  on(removeStepDependencySuccess, (state, { stepToUpdateTemplate, template }) =>
    templatesAdapter.updateOne(
      {
        id: stepToUpdateTemplate.id,
        changes: {
          dependentSteps: stepToUpdateTemplate.dependentSteps
            ? [
                ...stepToUpdateTemplate.dependentSteps.filter(
                  (dependentStep) =>
                    dependentStep.recipeId !== template.recipeId &&
                    dependentStep.stepId !== template.id
                ),
              ]
            : [],
        },
      },
      state
    )
  ),
  on(TemplatesActions.loadActiveStateSuccess, (state, { template }) =>
    templatesAdapter.upsertOne(template, {
      ...state,
      loaded: true,
      selectedId:
        template && template.stepper[0] && template.type === 'Recipe'
          ? template.stepper[0].id
          : template.id,
    })
  ),
  on(TemplatesActions.loadPathBatchTemplateSuccess, (state, { template }) =>
    templatesAdapter.upsertOne(template, {
      ...state,
      loaded: true,
      selectedId: template.id,
    })
  ),
  on(TemplatesActions.loadTemplateFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(TemplatesActions.loadTemplatesSuccess, (state, { templates }) =>
    templatesAdapter.setAll(templates, { ...state, loaded: true })
  ),
  on(TemplatesActions.loadTemplatesFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(TemplatesActions.toggleAggregate, (state) => ({
    ...state,
    aggregate: !state.aggregate,
  })),
  on(TemplatesActions.stepHoveredOver, (state, { step }) => ({
    ...state,
    recipeIdHoveredOver: step.recipeId,
  })),
  on(TemplatesActions.stepUnhoveredOver, (state, { template }) => ({
    ...state,
    recipeIdHoveredOver: template.recipeId,
  })),
  on(TemplatesActions.resetStore, () => ({
    ...initialState,
  })),

  on(updateParameterValue, (state, { parameterName, newValue }) =>
  templatesAdapter.updateOne(
    {
      id: state.selectedId!,
      changes: {
        parameters: state.entities[state.selectedId!]?.parameters?.map(param => {
          if(param.name === parameterName){
            return {...param, defaultValue: newValue}
          } else return param
        })
      },
    },
    state
  )),
  on(PathCmsActions.portExistingCodeFileToNewStepSuccess, (state, { fileContent, contentType, parameters }) => 
    templatesAdapter.updateOne(
      {
        id: state.selectedId!,
        changes: {
          parameters: parameters ? 
            [...(state.entities[state.selectedId!]?.parameters || []), ...parameters] 
            : state.entities[state.selectedId!]?.parameters,
        },
      },
      state
    )
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return templatesReducer(state, action);
}

import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as GenerateCodeActions from './generate-code.actions';
import { GenerateCodeEntity } from './generate-code.models';

export const GENERATECODE_FEATURE_KEY = 'generateCode';

export interface State extends EntityState<GenerateCodeEntity> {
  selectedId?: string | number; // which GenerateCode record has been selected
  loaded: boolean; // has the GenerateCode list been loaded
  runUnitTestsLoaded: boolean;
  runIntegrationTestsLoaded: boolean;
  error?: string | null; // last none error (if any)
}

export interface GenerateCodePartialState {
  readonly [GENERATECODE_FEATURE_KEY]: State;
}

export const generateCodeAdapter: EntityAdapter<GenerateCodeEntity> =
  createEntityAdapter<GenerateCodeEntity>();

export const initialState: State = generateCodeAdapter.getInitialState({
  // set initial required properties
  runUnitTestsLoaded: true,
  runIntegrationTestsLoaded: true,
  loaded: false,
});

const generateCodeReducer = createReducer(
  initialState,
  on(GenerateCodeActions.loadGenerateCode, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(GenerateCodeActions.runUnitTests, (state) => ({
    ...state,
    runUnitTestsLoaded: false
  })),
  on(GenerateCodeActions.runUnitTestsSuccess, (state) => ({
    ...state,
    runUnitTestsLoaded: true
  })),
  on(GenerateCodeActions.runIntegrationTests, (state) => ({
    ...state,
    runIntegrationTestsLoaded: false
  })),
  on(GenerateCodeActions.runIntegrationTestsSuccess, (state) => ({
    ...state,
    runIntegrationTestsLoaded: true
  })),
  on(GenerateCodeActions.loadGenerateCodeSuccess, (state, { generateCode }) =>
    generateCodeAdapter.setAll(generateCode, { ...state, loaded: true })
  ),
  on(GenerateCodeActions.loadGenerateCodeFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return generateCodeReducer(state, action);
}

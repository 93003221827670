import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DeveloperOrBetaUserGuard, UserProfileLoadedGuard } from '@razroo-zeta/data-guards'
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canLoad: [UserProfileLoadedGuard],
    canActivate: [UserProfileLoadedGuard],
    loadChildren: () => import('@razroo-zeta/landing-page').then((m) => m.LandingPageModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('@razroo-zeta/settings').then((m) => m.SettingsModule),
    pathMatch: 'prefix',
    canLoad: [UserProfileLoadedGuard, AuthGuard]
  },
  {
    path: 'sessions',
    loadChildren: () =>
      import('@razroo-zeta/team-sessions').then((m) => m.TeamSessionsModule),
    canLoad: [AuthGuard, DeveloperOrBetaUserGuard]
  },
  {
    path: 'library',
    loadChildren: () =>
      import('@razroo-zeta/library-module').then((m) => m.LibraryModuleModule)
  },
  {
    path: 'book/:id',
    loadChildren: () => import('@razroo-zeta/book').then((m) => m.BookModule)
  },
  {
    path: 'book/:id/:chapter',
    loadChildren: () => import('@razroo-zeta/book').then((m) => m.BookModule)
  },
  {
    path: 'book/:id/:chapter/:searchText',
    loadChildren: () => import('@razroo-zeta/book').then((m) => m.BookModule)
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('@razroo-zeta/pricing').then((m) => m.PricingModule),
      canLoad: [AuthGuard]
  },
  {
    path: 'accept-invitation',
    canLoad: [UserProfileLoadedGuard],
    loadChildren: () =>
      import('@razroo-zeta/accept-invite').then((m) => m.AcceptInviteModule)
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('@razroo-zeta/legal').then((m) => m.LegalModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('@razroo-zeta/not-found-page').then((m) => m.NotFoundPageModule),
    canLoad: [UserProfileLoadedGuard],
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

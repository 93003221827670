import { Injectable } from '@angular/core';
import { AbridgedTicket, Ticket } from '@razroo-zeta/data-models';

@Injectable({
  providedIn: 'root'
})
export class GenerateTicketsService {

  constructor() { }

  combineEpicTickets(existingTickets: AbridgedTicket[] | undefined, generatedTickets: AbridgedTicket[] | undefined): AbridgedTicket[] {
    const combinedTickets = [
      ...(Array.isArray(existingTickets) ? existingTickets : []), 
      ...(Array.isArray(generatedTickets) ? generatedTickets : [])
    ].map(ticket => ({
      labels: ticket.labels,
      type: ticket.type,
      epic: ticket.epic,
      title: ticket.title,
      description: ticket.description
    }));
    return combinedTickets;
  }

  shortenChildTickets(tickets: Ticket[]): AbridgedTicket[] {
    return tickets.map(ticket => ({
      labels: ticket.labels,
      type: ticket.type,
      epic: ticket.epic,
      title: ticket.title,
      description: this.getFirstParagraphText(ticket.description)
    }));
  }

  getFirstParagraphText(html: string): string {
    const match = html.match(/<p>(.*?)<\/p>/);
    return match ? match[1].replace(/<\/?[^>]+(>|$)/g, "") : "";
  }

  extractEpicText(epicContent: string): string {
    const epicRegex = /<b>Epic:<\/b>(.*?)(?=<p><b>Scenario:|$)/;
    const match = epicContent.match(epicRegex);
    if (match && match[1]) {
      return match[1].trim().replace(/<\/?p>/g, '');
    }
    return '';
  }
}
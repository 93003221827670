export const VsCodeAuthInfoFragment = `
  fragment vsCodeAuthInfoFields on VsCodeAuthInfo {
    vsCodeInstances {
      privateDirectories
      vsCodeInstanceId
      packageJsonParams {
        name
        languages
        nx {
          defaultProject
        }
      }
      absoluteFolderRoot
      versionControlsParams {
        gitOrigin
        gitBranch
      }
      updatedAt
      userId
      orgId
    }
  }
`;

export const VsCodeInstanceFragment = `
  fragment vsCodeInstanceFields on VsCodeInstance {
    privateDirectories
    vsCodeInstanceId
    packageJsonParams {
      name
      languages
      nx {
        defaultProject
      }
    }
    absoluteFolderRoot
    versionControlsParams {
      gitOrigin
      gitBranch
    }
    updatedAt
    userId
    orgId
  }
`;

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromCodeChat from './+state/code-chat.reducer';
import { CodeChatEffects } from './+state/code-chat.effects';
import { CodeChatFacade } from './+state/code-chat.facade';

@NgModule({
  imports: [CommonModule,
    StoreModule.forFeature(fromCodeChat.CODE_CHAT_FEATURE_KEY, fromCodeChat.codeChatReducer),
    EffectsModule.forFeature([CodeChatEffects]),
  ],
  providers: [CodeChatFacade]
})
export class DataAccessCodeChatModule {}

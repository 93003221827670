<h1 mat-dialog-title>Create User</h1>
<mat-dialog-content class="content">
  <form [formGroup]="form">
    <mat-form-field class="form-input">
      <mat-label>First Name</mat-label>
      <input
        matInput
        type="text"
        formControlName="firstName"
        />
      @if (form.controls.firstName.hasError('pattern')) {
        <mat-error>
          Invalid first name
        </mat-error>
      }
      @if (form.controls.firstName.hasError('required')) {
        <mat-error>
          This field is required
        </mat-error>
      }
    </mat-form-field>
    <mat-form-field class="form-input CreateUser__last-name-form-field">
      <mat-label>Last Name</mat-label>
      <input
        matInput
        type="text"
        formControlName="lastName"
        />
      @if (form.controls.lastName.hasError('pattern')) {
        <mat-error>
          Invalid last name
        </mat-error>
      }
      @if (form.controls.lastName.hasError('required')) {
        <mat-error>
          This field is required
        </mat-error>
      }
    </mat-form-field>
  </form>
  <div>
    <i>Your first and last name will be used to create your username.</i>
  </div>
</mat-dialog-content>
<div class="action-buttons" align="end">
  <button mat-flat-button color="accent" (click)="submitName()">Create</button>
</div>

import gql from 'graphql-tag';
import { BranchesOutputFragment, GithubRepoOutputFragment } from './github.fragments';

export const SendGithubReposDataSub = gql`
  subscription sendGithubReposDataSub($userId: String!) {
    sendGithubReposDataSub(userId: $userId) {
      ...githubRepoOutputFields
    }
  }
  ${GithubRepoOutputFragment}
`;

export const SendGithubBranchesDataSub = gql`
  subscription sendGithubBranchesDataSub($userId: String!) {
    sendGithubBranchesDataSub(userId: $userId) {
      ...branchesOutputFields
    }
  }
  ${BranchesOutputFragment}
`;

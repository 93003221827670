import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GIT_INFO_FEATURE_KEY,
  State,
  gitInfoAdapter,
} from './git-info.reducer';

// Lookup the 'GitInfo' feature state managed by NgRx
export const getGitInfoState =
  createFeatureSelector<State>(GIT_INFO_FEATURE_KEY);

const { selectAll, selectEntities } = gitInfoAdapter.getSelectors();

export const getGitInfoLoaded = createSelector(
  getGitInfoState,
  (state: State) => state.loaded
);

export const getGitInfoError = createSelector(
  getGitInfoState,
  (state: State) => state.error
);

export const getAllGitInfo = createSelector(getGitInfoState, (state: State) =>
  selectAll(state)
);

export const getGitInfoEntities = createSelector(
  getGitInfoState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getGitInfoState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getGitInfoEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

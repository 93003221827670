import { CreatePath, PathQuery, GetPaths, GetPathsForDropdown, CreateRecipe, CreateStep,
  UpdateStep, ArchiveTemplate, DeleteTemplate, GetRazrooStarterRepoFileTree, 
  UpdateRecipe, AddSecondaryPath, GetSecondaryPaths, RemoveSecondaryPath, UpdatePath, 
  ClonePathWithNewVersionId, GetPathVersions, GetPathScaffolds, GetTemplate, 
  GetCodeMetadata, CreateCodeMetadata, AddMarketplacePathToCommunity, 
  ReorderCommunityMarketplacePaths, GetPathQuery, RemoveMarketplacePathFromCommunityPath, GetProjectPaths } from '@razroo-zeta/data-graphql';
import { pluck } from 'rxjs/operators';
import { from, map } from 'rxjs';
import { Apollo, MutationResult } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { StepUpdateParams } from '@razroo-zeta/data-models';
import { fromFetch } from 'rxjs/fetch';
import { VersioningService } from '@razroo-zeta/common-services';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PathService {

  constructor(private apollo: Apollo, private versioningService: VersioningService,
    private route: ActivatedRoute, private router: Router, private location: Location) { }

  getPaths(orgId: string, pathsType: string){
    const query = GetPaths;
    const variables = {
      orgId,
      pathsType
    };
    const paths$ = this.apollo.query({ query, variables });
    return from(paths$).pipe(pluck('data', 'getPaths'));
  }

  getPathsForDropdown(orgId: string, pathsType: string, beta: boolean = true){
    const query = GetPathsForDropdown;
    const variables = {
      orgId,
      pathsType,
      beta
    };
    const paths$ = this.apollo.query({ query, variables });
    return from(paths$).pipe(pluck('data', 'getPaths'));
  }

  getProjectPaths(orgId: string){
    const query = GetProjectPaths;
    const variables = {orgId};
    const projectPaths$ = this.apollo.query({query, variables});
    return from(projectPaths$).pipe(pluck('data', 'getProjectPaths'));
  }

  loadPathTemplate(orgId: string, pathId: string) {
    const query = GetTemplate;
    const variables = {
      orgId,
      pathId
    };
    const paths$ = this.apollo.query({ query, variables });
    return from(paths$).pipe(pluck('data', 'template'));
  }

  searchPath(userOrgId: string, templateOrgId: string, pathId: string, search: string | undefined, start: number, size: number, analyticData?: {analyticData: boolean;
    userOrgId: string}) {
    const query = GetPathQuery;
    const variables = {
      userOrgId,
      templateOrgId,
      pathId,
      search,
      start,
      size,
      stepper: true,
      analyticData
    };
    const getPath$ = this.apollo.query({ query, variables, fetchPolicy: 'network-only' });
    return from(getPath$).pipe(pluck('data', 'getPath'));
  }

  createPath(orgId: string, userId: string, path: string, description: string, baseCommunityPath: string | null = null, privacy: string, languageVersion: string, marketplace: boolean, minimumPrice?: string) {
    const mutation = CreatePath;
    const variables = {
      orgId,
      userId,
      path,
      description,
      baseCommunityPath,
      privacy,
      languageVersion,
      marketplace,
      minimumPrice
    }
    const defaultFilePath$ = this.apollo.mutate({ mutation, variables });
    return from(defaultFilePath$).pipe(pluck('data', 'createPath'));
  }

  createRecipe(
    orgId: string,
    userId: string,
    pathId: string,
    recipeName: string,
    description: string,
    previousRecipeId: string | null) {
    const mutation = CreateRecipe;
    const variables = {
      orgId,
      userId,
      pathId,
      recipeName,
      description,
      previousRecipeId
    }
    const newRecipe$ = this.apollo.mutate({ mutation, variables });
    return from(newRecipe$).pipe(pluck('data', 'createRecipe'));
  }

  updateRecipe(
    orgId: string,
    pathId: string,
    recipeId: string,
    recipeUpdateParams: any){
      const mutation = UpdateRecipe;
      const variables = {
        orgId,
        pathId,
        recipeId,
        recipeUpdateParams
      }
      const updateRecipe$ = this.apollo.mutate({mutation, variables});
      return from(updateRecipe$).pipe(pluck('data', 'updateRecipe'));
  }

  createStep(
    orgId: string,
    userId: string,
    pathId: string,
    recipeId: string,
    stepName: string,
    stepType?: string,
    description?: string | null,
    parameters?: string | null
    ) {
    const mutation = CreateStep;
    const variables = {
      orgId,
      userId,
      pathId,
      recipeId,
      stepName,
      stepType,
      description,
      parameters
    }
    const newStep$ = this.apollo.mutate({ mutation, variables });
    return from(newStep$).pipe(pluck('data', 'createStep'));
  }

  replaceDnaPageUrl(path: any) {
    //Build URL Tree
    const params = {};
    const urlTree = this.router.createUrlTree([`/finder/${path.orgId}/${path.id}`],{
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge'
    });

    //Update the URL
    this.location.replaceState(urlTree.toString());
  }

  updateStep(
    orgId: string,
    pathId: string,
    recipeId: string,
    stepId: string,
    stepUpdateParams: StepUpdateParams
    ) {
    const mutation = UpdateStep;
    const variables = {
      orgId,
      pathId,
      recipeId,
      stepId,
      stepUpdateParams
    }
    const updateStep$ = this.apollo.mutate({ mutation, variables });
    return from(updateStep$).pipe(pluck('data', 'updateStep'));
  }

  archiveTemplate(
    orgId: string,
    pathId: string,
    recipeId?: string,
    stepId?: string
  ){
    const mutation = ArchiveTemplate;
    const variables = {
      orgId,
      pathId,
      recipeId,
      stepId
    }
    const archiveTemplate$ = this.apollo.mutate({mutation, variables});
    return from(archiveTemplate$).pipe(pluck('data', 'archiveTemplate'));
  }

  deleteTemplate(
    orgId: string,
    templateType: string,
    pathId: string,
    recipeId?: string,
    stepId?: string
  ){
    const mutation = DeleteTemplate;
    const variables = {
      orgId,
      templateType,
      pathId,
      recipeId,
      stepId
    }
    const deleteTemplate$ = this.apollo.mutate({mutation, variables});
    return from(deleteTemplate$).pipe(map((res: MutationResult<any>) => res?.data?.deleteTemplate));
  }

  getRazrooStarterRepoFileTree(pathId: string, branch: string = 'main'){
    let nonAiPathId = pathId.replace('-ai-', '-');
    const query = GetRazrooStarterRepoFileTree;
    const variables = {
      pathId: nonAiPathId,
      branch
    }
    const fileTree$ = this.apollo.query({query, variables});
    return from(fileTree$).pipe(pluck('data', 'getRazrooStarterRepoFileTree'))
  }

  getSecondaryPaths(orgId: string, pathId: string ){
    const query = GetSecondaryPaths;
    const variables = {
      orgId,
      pathId
    }
    const getSecondaryPaths$ = this.apollo.query({query, variables});
    return from(getSecondaryPaths$).pipe(pluck('data', 'getSecondaryPaths'))
  }

  addSecondaryPath(
    orgId: string,
    userId: string,
    pathId: string,
    secondaryPathId: string,
    secondaryPathOrgId: string
  ){
    const mutation = AddSecondaryPath;
    const variables = {
      orgId,
      userId,
      pathId,
      secondaryPathId,
      secondaryPathOrgId
    }
    const addSecondaryPath$ = this.apollo.mutate({mutation, variables});
    return from(addSecondaryPath$).pipe(pluck('data', 'addSecondaryPath'));
  }

  removeSecondaryPath(
    orgId: string,
    userId: string,
    pathId: string,
    secondaryPathId: string,
    secondaryPathOrgId: string
  ){
    const mutation = RemoveSecondaryPath;
    const variables = {
      orgId,
      userId,
      pathId,
      secondaryPathId,
      secondaryPathOrgId
    }
    const removeSecondaryPath$ = this.apollo.mutate({mutation, variables});
    return from(removeSecondaryPath$).pipe(pluck('data', 'removeSecondaryPath'));
  }

  reorderRecipe(orgId: string,
    pathId: string,
    recipeId: string,
    previousRecipe: string,
    updateOrder: boolean
    ){
    const mutation = UpdateRecipe;
    const variables = {
      orgId,
      pathId,
      recipeId,
      recipeUpdateParams: {
        updateOrder: {
          previousRecipe,
          updateOrder
        }
      }
    }
    const updateRecipe$ = this.apollo.mutate({mutation, variables});
    return from(updateRecipe$).pipe(pluck('data', 'updateRecipe'))
  }

  updatePath(orgId: string, pathId: string, pathUpdateParams: any){
    const mutation = UpdatePath;
    const variables = {
      orgId,
      pathId,
      pathUpdateParams
    }
    const updatePath$ = this.apollo.mutate({mutation, variables});
    return from(updatePath$).pipe(pluck('data', 'updatePath'))
  }

  clonePathWithNewVersionId(orgId: string,
    userId: string,
    pathId: string,
    newLanguageVersion: string
  ){
    const mutation = ClonePathWithNewVersionId;
    const variables = {
      orgId,
      userId,
      pathId,
      newLanguageVersion
    }
    const clonePath$ = this.apollo.mutate({mutation, variables});
    return from(clonePath$).pipe(pluck('data', 'clonePathWithNewVersionId'))
  }


  getPathVersions(orgId: string, pathId: string ){
    const query = GetPathVersions;
    const variables = {
      orgId,
      pathId
    }
    const getPathVersions$ = this.apollo.query({query, variables});
    return from(getPathVersions$).pipe(pluck('data', 'getPathVersions'))
  }

  getPathScaffolds(pathOrgId: string, pathId: string ){
    const query = GetPathScaffolds;
    const variables = {
      pathOrgId,
      pathId
    }
    const getPathScaffolds$ = this.apollo.query({query, variables});
    return from(getPathScaffolds$).pipe(pluck('data', 'getPathScaffolds'))
  }

  createCodeMetadata(
    userOrgId: string,
    pathId: string,
    recipeId: string,
    stepId: string,
    dependencies?: any,
    devDependencies?: any,
    orgId?: string){
    const mutation = CreateCodeMetadata;
    const variables = {
      userOrgId,
      pathId,
      recipeId,
      stepId,
      dependencies,
      devDependencies,
      orgId
    }
    const createCodeMetadata$ = this.apollo.mutate({mutation, variables});
    return from(createCodeMetadata$).pipe(pluck('data', 'createCodeMetadata'))
  }

  getCodeMetadata(
    pathId: string,
    recipeId: string,
    stepId: string,
    orgId: string
  ){
    const query = GetCodeMetadata;
    const variables = {
      pathId, 
      recipeId, 
      stepId,
      orgId
    }
    const getCodeMetadata$ = this.apollo.query({query, variables});
    return from(getCodeMetadata$).pipe(pluck('data', 'getCodeMetadata'));
  }

  addMarketplacePathToCommunity(userOrgId: string, communityPathId: string, 
    marketplaceOrgId: string, marketplacePathId: string, marketplacePathDescription: string){
      const mutation = AddMarketplacePathToCommunity;
      const variables = {
        userOrgId, 
        communityPathId, 
        marketplaceOrgId, 
        marketplacePathId,
        marketplacePathDescription
      }
      const addMarketplacePathToCommunity$ = this.apollo.mutate({mutation, variables});
      return from(addMarketplacePathToCommunity$).pipe(pluck('data', 'addMarketplacePathToCommunity'));
  }

  removeMarketplacePathFromCommunityPath(userOrgId: string, communityPathId: string, 
    marketplaceOrgId: string, marketplacePathId: string){
      const mutation = RemoveMarketplacePathFromCommunityPath;
      const variables = {
        userOrgId, 
        communityPathId, 
        marketplaceOrgId, 
        marketplacePathId,
      }
      const removeMarketplacePathFromCommunityPath$ = this.apollo.mutate({mutation, variables});
      return from(removeMarketplacePathFromCommunityPath$).pipe(pluck('data', 'removeMarketplacePathFromCommunityPath'));
  }

  reorderCommunityMarketplacePaths(
    userOrgId: string,
    communityPathId: string,
    marketplaceOrgId: string,
    marketplacePathId: string,
    previousMarketplaceOrgId?: string,
    previousMarketplacePathId?: string
  ){
    const mutation = ReorderCommunityMarketplacePaths;
    const variables = {
      userOrgId,
      communityPathId,
      marketplaceOrgId,
      marketplacePathId,
      previousMarketplaceOrgId,
      previousMarketplacePathId
    }

    const reorderCommunityMarketplacePaths$ = this.apollo.mutate({mutation, variables});
    return from(reorderCommunityMarketplacePaths$).pipe(pluck('data', 'reorderCommunityMarketplacePaths'));
  }
}

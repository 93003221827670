import gql from 'graphql-tag';

export const GetUserOrganizations = gql`
  query GetUserOrganizations($userId: ID!) {
    userOrganizations(userId: $userId) {
      activeStep{
        end
        id
        orgId
        pathId
        recipeId
        start
      }
      displayName
      isActive
      orgId
      orgRole
      picture
      userId
    }
  }
`;

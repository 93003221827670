import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { GetStep } from '@razroo-zeta/data-graphql';

@Injectable({
  providedIn: 'root'
})
export class GetStepService {
  getStep(userOrgId: string, templateOrgId: string, pathId: string, recipeId: string, stepId: string, displayDocumentationHtml?: boolean){
    const query = GetStep;
    const variables = {
      userOrgId,
      templateOrgId,
      pathId,
      recipeId,
      stepId,
      displayDocumentationHtml
    };
    const getStep$ = this.apollo.query({query, variables});
    return from(getStep$).pipe(pluck('data', 'getStep'))
  }
  constructor(private apollo: Apollo) { }
}
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { DeleteProfilePicture } from '@razroo-zeta/data-graphql';
@Injectable({
  providedIn: 'root'
})
export class DeletePictureService {

  constructor(private apollo: Apollo) { }

  deletePicture(userId: string, orgId?: string){
    const mutation = DeleteProfilePicture;
    let variables:any;
    if(orgId){
      variables = {
        orgPictureParams: {
          orgId,
          userId
        }
      }
    } else {
      variables = {
        userPictureParams: {
          userId
        }
      }
    }
    const deleteProfilePicture$ = this.apollo.mutate({ mutation, variables });
    return from(deleteProfilePicture$).pipe(pluck('data', 'deleteProfilePicture'));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsDialogComponent } from './comments-dialog/comments-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { CommentsSideNavComponent } from './comments-side-nav/comments-side-nav.component';
import { CommentComponent } from './comment/comment.component';
import { CommentsFacade } from '@razroo-zeta/data-access/comments-store';
import { CommentReplyComponent } from './comment-reply/comment-reply.component';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { MatMenuModule } from '@angular/material/menu';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {CommonPipesModule} from '@razroo-zeta/common/pipes'
import { SocialMediaShareComponentModule } from 'libs/common/ui/src/lib/social-media-share/social-media-share.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatSelectModule,
    HighlightModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatChipsModule,
    CommonPipesModule,
    SocialMediaShareComponentModule
  ],
  declarations: [
    CommentsDialogComponent,
    CommentsSideNavComponent,
    CommentComponent,
    CommentReplyComponent,

  ],
  providers: [
    CommentsFacade,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          javascript: () => import('highlight.js/lib/languages/javascript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          xml: () => import('highlight.js/lib/languages/xml'),
          css: () => import('highlight.js/lib/languages/css'),
        },
      },
    },
  ],
  exports: [CommentsDialogComponent, CommentsSideNavComponent, CommentComponent],
})
export class CommentsModule {}

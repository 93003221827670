import gql from 'graphql-tag';
import { VsCodeInstanceFragment } from './vscode.fragments';

export const UpdateVSCodeAuthenticationSub = gql`
  subscription UpdateVSCodeAuthenticationSub($userId: String!, $orgId: String!) {
    updateVSCodeAuthenticationSub(userId: $userId, orgId: $orgId) {
      ...vsCodeInstanceFields
    }
  }
  ${VsCodeInstanceFragment}
`;

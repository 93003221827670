import gql from "graphql-tag";
import { InvoiceFragment } from "./invoices.fragments";

export const GetStripeInvoices = gql`
  query StripeInvoices($stripeCustomerId: ID!) {
    stripeInvoices(stripeCustomerId: $stripeCustomerId) {
      ...invoiceFields
    }
  }
  ${InvoiceFragment}
`;

import gql from "graphql-tag";
import { TemplateFragment } from "../template/template.fragments";

export const GetPathBatchTemplate = gql`
  query GetPathBatchTemplate(
      $pathOrgId: String!,
      $pathId: String!,
      $batchId: String!,
      $recipeId: String!,
      $stepId: String,
      $idType: String,
      $published: Boolean, 
      $stepper: Boolean, 
      $analyticData: StepperAnalyticData, 
      $displayDocumentationHtml: Boolean
    ) {
    getPathBatchTemplate(
      pathOrgId: $pathOrgId,
      pathId: $pathId,
      batchId: $batchId,
      recipeId: $recipeId,
      stepId: $stepId,
      idType: $idType,
      published: $published, 
      stepper: $stepper, 
      analyticData: $analyticData, 
      displayDocumentationHtml: $displayDocumentationHtml
    ) {
      ...templateFields
    }
  }
  ${TemplateFragment}
`;

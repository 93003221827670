import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserProfilePicInput, OrganizationProfilePicInput, UploadTemplateInput, Template } from '@razroo-zeta/data-models';
import { map, pluck } from 'rxjs/operators';
import { from } from 'rxjs';
import { Apollo } from 'apollo-angular';
import {
  GetDownloadUrl,
  GetOrgPictureUploadUrl,
  GetUploadUrl,
  GetUserPictureUploadUrl
} from './../../../../data-graphql/src/lib/downloadUrl/downloadUrl.queries';
import { Injectable } from '@angular/core';
import { DownloadFileType } from '@razroo-zeta/data-models';
import { CopyStepS3Folder, DeleteS3File, DeleteS3Folder, RenameS3File, RenameS3Folder } from '@razroo-zeta/data-graphql';

@Injectable({
  providedIn: 'root',
})
export class DownloadUrlService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  getDownloadUrl(id: string, downloadFileType: DownloadFileType, s3Key?: string) {
    const query = GetDownloadUrl;
    const variables = {
      downloadFileType,
      id,
      s3Key
    };
    const getDownloadUrl$ = this.apollo.query({ query, variables });
    return from(getDownloadUrl$).pipe(pluck('data', 'getDownloadUrl'));
  }

  getUploadUrl(uploadInput: {userProfilePic: UserProfilePicInput} | {organizationProfilePic: OrganizationProfilePicInput} | {uploadTemplate: UploadTemplateInput}) {
    const query = GetUploadUrl;
    const variables = {
      ...uploadInput
    };
    const getUploadUrl$ = this.apollo.query({ query, variables });
    return from(getUploadUrl$).pipe(pluck('data', 'getUploadUrl'));
  }

  getUserPictureUploadUrl(){
    const query = GetUserPictureUploadUrl;
    const getUserPictureUploadUrl$ = this.apollo.query({ query });
    return from(getUserPictureUploadUrl$).pipe(pluck('data', 'getUserPictureUploadUrl'));
  }
  
  getOrgPictureUploadUrl(orgId: string){
    const query = GetOrgPictureUploadUrl;
    const variables = {
      orgId
    }
    const getOrgPictureUploadUrl$ = this.apollo.query({ query, variables });
    return from(getOrgPictureUploadUrl$).pipe(pluck('data', 'getOrgPictureUploadUrl'));
  }

  getFileFromUrl(url: string) {
    const fileFromUrl$ = this.http.get(url, {responseType: 'text'});
    return from(fileFromUrl$);
  }

  uploadFileFromUrl(url: string, fileContent: string | File, contentType?: string) {
    const config = {
      headers: {
        'Content-Type': contentType ? contentType : 'application/json',
      }
    }
    return this.http.put(url, fileContent, config);
  }

  deleteS3File(s3Key: string){
    const mutation = DeleteS3File;
    const variables = {
      deleteTemplateFiles: {
        s3Key
      }
    }
    const deleteS3File$ = this.apollo.mutate({ mutation, variables });
    return from(deleteS3File$).pipe(pluck('data', 'deleteS3File'));
  }

  deleteS3Folder(s3Key: string){
    const mutation = DeleteS3Folder;
    const variables = {
      deleteTemplateFiles: {
        s3Key
      }
    }
    const deleteS3Folder$ = this.apollo.mutate({ mutation, variables });
    return from(deleteS3Folder$).pipe(pluck('data', 'deleteS3Folder'));
  }

  renameS3File(key: string, newKey: string){
    const mutation = RenameS3File;
    const variables = {
      key,
      newKey
    }
    const renameS3File = this.apollo.mutate({mutation, variables});
    return from(renameS3File).pipe(pluck('data', 'renameS3File'))
  }

  renameS3Folder(key: string, newFolderName: string){
    const mutation = RenameS3Folder;
    const variables = {
      key,
      newFolderName
    }
    const renameS3Folder = this.apollo.mutate({mutation, variables});
    return from(renameS3Folder).pipe(map((x: any) => x?.data?.renameS3Folder))
  }

  copyStepS3Folder(userOrgId: string, pathOrgId: string, 
    pathId: string, recipeId: string, stepId: string, 
    destinationPathOrgId: string, destinationPathId: string, 
    destinationRecipeId: string, destinationStepId: string){
    const mutation = CopyStepS3Folder;
    const variables = {
      userOrgId, pathOrgId,
      pathId, recipeId, stepId,
      destinationPathOrgId, destinationPathId, 
      destinationRecipeId, destinationStepId
    }
    const copyStepS3Folder = this.apollo.mutate({mutation, variables});
    return from(copyStepS3Folder).pipe(map(data => (data as any)?.data.copyStepS3Folder));
  }
}

import { gql } from '@apollo/client/core';

export const WifejakAiAssistant = gql`
  mutation WifejakAiAssistant(
    $orgId: String!
    $userPrompt: String!
    $pageUrl: String!
    $messages: [ChatCompletionRequestMessageInput]
  ) {
    wifejakAiAssistant(
      orgId: $orgId
      userPrompt: $userPrompt
      pageUrl: $pageUrl
      messages: $messages
    ) {
      message {
        role
        content
        name
      }
      textToType
      stepsWithSelectors {
        title
        selector
        inputType
        textToType
      }
      questionOrCommand
      url
    }
  }
`;

export const GetWifejakAiActionableItems = gql`
  mutation GetWifejakAiActionableItems($orgId: String!, $pageUrl: String!, $userStatus: String!) {
    getWifejakAiActionableItems(orgId: $orgId, pageUrl: $pageUrl, userStatus: $userStatus) {
      actionableItems {
        message {
        role
        content
        name
      }
      textToType
      title
      description
      stepsWithSelectors {
        title
        selector
        inputType
        textToType
      }
      questionOrCommand
      url
      }
      oppwizCredits
    }
  }
`;

export const GetAssistantWalkthrough = gql`
  mutation GetAssistantWalkthrough($orgId: String!, $pageUrl: String!) {
    assistantWalkthrough(orgId: $orgId, pageUrl: $pageUrl) {
      baseUrl
      description
      url
      groupings {
        title
        description
        containerSelector
        items {
          title
          description
          selector
        }
      }
    }
  }
`;

import { BillingUpgradeDialogComponent } from '../../../../billing/src/lib/billing-upgrade-dialog/billing-upgrade-dialog.component';
import {
  NotificationsService,
  OrgAuthService,
} from '@razroo-zeta/data-services';
import { UserFacade } from '@razroo-zeta/data-access';
import { OrganizationsFacade } from '@razroo-zeta/data-access/organizations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  input,
} from '@angular/core';
import { UserService } from '@razroo-zeta/data-services';
import { take, first, catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CorePathIdPipe } from '@razroo-zeta/data-pipes';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  LocalStorageService,
  WindowRefService,
} from '@razroo-zeta/common-services';
import { EMPTY, Subject } from 'rxjs';
import { User as Auth0User } from '@auth0/auth0-angular';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationUser, User } from '@razroo-zeta/data-models';
import { environment } from '@razroo-zeta/common-environment';

@Component({
    selector: 'razroo-zeta-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit, OnDestroy {
  orgOptions = [];
  notifications: any = [];
  unsubscribe$ = new Subject();
  freeTrialMessage: any = null;
  environment = environment;
  currentUser$ = this.userFacade.currentUser$;
  selectedOrganization$ = this.organizationsFacade.selectedOrganizations$;
  organizations$ = this.userFacade.currentUserOrgs$;
  communityOrganizations$ = this.organizations$.pipe(
    map(orgs => orgs?.filter(org => org.orgType === 'Community') || [])
  );
  nonCommunityOrganizations$ = this.organizations$.pipe(
    map(orgs => orgs?.filter(org => org.orgType !== 'Community') || [])
  );
  appName = input<'Projects' | 'Code'>();
  @ViewChild('bellTrigger') notificationMenuTrigger: MatMenuTrigger;
  @Output() themeToggle: EventEmitter<any> = new EventEmitter();
  toggleDarkTheme() {
    this.themeToggle.emit();
  }
  isAuthenticated = input<any>();
  @Input() set setCurrentUser(user: any) {
    if (user) {
      this.notifications = user.orgNotifications;
    }
  }
  @Output() login: EventEmitter<any> = new EventEmitter();
  @Output() logout: EventEmitter<any> = new EventEmitter();
  @Output() signUp: EventEmitter<any> = new EventEmitter();
  @Output() sideNavToggle: EventEmitter<any> = new EventEmitter();
  @Output() wifejackAi: EventEmitter<any> = new EventEmitter();

  constructor(
    private userFacade: UserFacade,
    private notificationsService: NotificationsService,
    private organizationsFacade: OrganizationsFacade,
    private userService: UserService,
    public router: Router,
    private corePathPipe: CorePathIdPipe,
    private windowRefService: WindowRefService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  orgSelected(orgId: any, currentUser: OrganizationUser) {
    this.localStorageService.removeItem('finder-state');
    this.userService
      .updateActiveOrgId(orgId, currentUser.userId)
      .pipe(take(1))
      .subscribe((_) => {
        this.windowRefService.nativeWindow.open(
          this.windowRefService.nativeWindow.location.href
        );
      });
  }

  markRead(notificationObj: any, currentUser: OrganizationUser) {
    if (!notificationObj.read) {
      let newNotifications = [...this.notifications];
      newNotifications[
        this.notifications.findIndex(
          (n) =>
            n.notification === notificationObj.notification &&
            n.timestamp === notificationObj.timestamp
        )
      ] = { ...notificationObj, read: true };
      this.notifications = newNotifications;
      this.notificationsService
        .markNotificationAsRead(
          currentUser.activeOrgId,
          currentUser.userId,
          notificationObj.notification,
          notificationObj.timestamp
        )
        .subscribe();
    }
  }

  wifejakAiClick() {
    this.wifejackAi.emit();
  }

  emitLogin(): void {
    this.login.emit();
  }

  emitSignUp(): void {
    this.signUp.emit();
  }

  emitLogout(): void {
    this.logout.emit();
  }

  emitSideNavToggle() {
    this.sideNavToggle.emit();
  }

  upgrade(user: Auth0User, appName: 'Projects' | 'Code' | undefined) {
    // Ensure appName is defined
    const safeAppName: 'Projects' | 'Code' = appName || 'Code';
    
    const dialog = this.dialog.open(BillingUpgradeDialogComponent, {
      data: {
        user,
        appName: safeAppName
      },
    });

    dialog
      .afterClosed()
      .pipe(
        take(1),
        catchError(() => EMPTY)
      )
      .subscribe((res) => {
        if (res) {
          console.log('res');
        }
      });
  }

  // Helper method to safely cast currentUser to OrganizationUser
  getOrganizationUser(user: any): OrganizationUser {
    if (!user) {
      throw new Error('User is required');
    }
    return user as OrganizationUser;
  }

  // Helper method to safely cast currentUser to User
  getUser(user: any): User {
    if (!user) {
      throw new Error('User is required');
    }
    return user as User;
  }

  notificationClick(notification) {
    /*
      the following code makes sure that if the user is already on the finder path, that it still loads
      the new step, unless they are already on the path of the bug they clicked on
    */
    if (notification?.templateCommentParams?.commentType === 'Bug') {
      this.notificationMenuTrigger.closeMenu();
      if (
        this.windowRefService.nativeWindow.location.href.includes(
          this.windowRefService.nativeWindow.location.origin + '/finder/'
        )
      ) {
        let pathParams = this.windowRefService.nativeWindow.location.pathname
          .slice(5)
          .split('/');
        pathParams[1] = this.corePathPipe.transform(pathParams[1]);
        let routeCorePath = '/' + pathParams.join('/');
        if (routeCorePath !== notification?.templateCommentParams?.pageUrl) {
          this.router
            .navigateByUrl('/redirect', { skipLocationChange: true })
            .then(() =>
              this.router.navigate([
                'finder/' + notification?.templateCommentParams?.pageUrl,
              ])
            );
        }
      } else {
        this.router.navigate([
          'finder/' + notification?.templateCommentParams?.pageUrl,
        ]);
      }
    }
  }

  deleteNotification(notification, currentUser: OrganizationUser) {
    this.notificationsService
      .deleteNotification(
        currentUser.activeOrgId,
        currentUser.userId,
        notification.notification,
        notification.timestamp
      )
      .pipe(first())
      .subscribe((notifications) => {
        this.notifications = notifications;
      });
  }

  loadImageError(event) {
    event.target.src = 'https://cdn.razroo.com/icons/less.svg';
  }

  loadUserOrgs(currentUser: OrganizationUser) {
    this.userFacade.loadUserOrganizations(currentUser.userId);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}

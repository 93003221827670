import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, signal, input, ViewChild, ElementRef } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UserFacade } from '@razroo-zeta/data-access';
import { LetDirective } from '@ngrx/component';
import { NgOptimizedImage } from '@angular/common'
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutofocusDirectiveModule, WifejakAiService } from '@razroo-zeta/data-services';
import { take, fromEvent } from 'rxjs';
import { AssistantWalkthrough, CodeChatMessageRoleEnum, OrganizationUser, StepWithSelector, WifejakChat } from '@razroo-zeta/data-models';
import { WifejakSelectorPipe } from '../pipes/wifejak-selector.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { findElement } from './utils/wifejak-utils';

@Component({
  standalone: true,
  selector: 'razroo-zeta-wifejak-chat',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatCardModule, MatInputModule, MatButtonModule, 
    MatToolbarModule, LetDirective, NgOptimizedImage, CdkTextareaAutosize, 
    MatFormFieldModule, MatDividerModule, ReactiveFormsModule, MatTooltipModule, WifejakSelectorPipe,
    AutofocusDirectiveModule, MatChipsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './wifejak-chat.component.html',
  styleUrls: ['./wifejak-chat.component.scss']
})
export class WifejakChatComponent implements OnInit {
  wifejakChatForm = this.fb.group({
    message: ['', [Validators.required]]
  });
  currentUser$ = this.userFacade.currentUser$;
  chatMessages = signal<any[]>([]);
  wifejakAiActionableItems = input<any[]>();
  assistantWalkthrough = signal<AssistantWalkthrough | null>(null);
  isWalkthroughActive = signal(false);
  currentGroupingIndex = signal<number>(0);
  currentItemIndex = signal<number>(0);
  @ViewChild('searchInput') searchInput: ElementRef;
  @Output() chatClosed = new EventEmitter<void>();
  isFloatingInputVisible = signal(false);
  floatingInputPosition = signal({ x: 100, y: 100 });
  floatingInputSize = signal({ width: 300, height: 200 });

  constructor(private userFacade: UserFacade, private fb: FormBuilder, private wifejakAiService: WifejakAiService) { }

  ngOnInit(): void {
    // Add keyboard shortcut listener
    fromEvent<KeyboardEvent>(document, 'keydown').subscribe((event) => {
      if (event.metaKey && event.key.toLowerCase() === 'l') {
        event.preventDefault();
        // For keyboard events, get the current mouse position from the window object
        this.toggleFloatingInput({
          clientX: window.mouseX || window.innerWidth / 2,
          clientY: window.mouseY || window.innerHeight / 2
        } as MouseEvent);
      }
    });

    // Track mouse position
    fromEvent<MouseEvent>(document, 'mousemove').subscribe((event) => {
      window.mouseX = event.clientX;
      window.mouseY = event.clientY;
    });
  }

  closeChat(): void {
    this.chatClosed.emit();
  }

  handleActionableItem(wifejakAiActionableItem: any) {
    const titleLowercased = wifejakAiActionableItem.title.charAt(0).toUpperCase() + wifejakAiActionableItem.title.slice(1).toLowerCase();
    const message = `${titleLowercased} for `;
    this.wifejakChatForm.patchValue({
      message
    });
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
      this.searchInput.nativeElement.setSelectionRange(message.length + 1, message.length + 1);
      this.searchInput.nativeElement.dispatchEvent(new KeyboardEvent('keydown', { key: message }));
    });
  }

  quickAction(wifejakChat: WifejakChat): void {
    const selector = wifejakChat.stepsWithSelectors.find(step => step.selector)?.selector;
    const generateTicketsButton = findElement(selector as string, document) as HTMLElement;
    if (generateTicketsButton) {
      generateTicketsButton.click();
    }
  }

  quickActionMouseEnter(wifejakChat: WifejakChat) {
    const selector = wifejakChat.stepsWithSelectors.find(step => step.selector)?.selector;
    const generateTicketsButton = findElement(selector as string, document) as HTMLElement;
    if (generateTicketsButton) {
      generateTicketsButton.classList.add('wifejak-animation');
    }
  }

  quickActionMouseLeave(wifejakChat: WifejakChat) {
    const selector = wifejakChat.stepsWithSelectors.find(step => step.selector)?.selector;
    const generateTicketsButton = findElement(selector as string, document) as HTMLElement;
    if (generateTicketsButton) {
      generateTicketsButton.classList.remove('wifejak-animation');
    }
  }

  submitChat(user: OrganizationUser, event: any, userMessage: string | null | undefined, messages: any[]): void {
    // stop enter from going to next line
    event.preventDefault();
    
    // Return early if message is empty, null, or undefined
    if (!userMessage) return;
    
    const userMessageChat = {message:{role: CodeChatMessageRoleEnum.User, content: userMessage}};
    const assistantChat = {message:{role: CodeChatMessageRoleEnum.Assistant, generating: true}};

    this.chatMessages.set([...messages, userMessageChat, assistantChat]);
    const prunedMessages = messages.map(message => ({
      role: message.message.role,
      content: message.message.content
    }));
    
    this.wifejakAiService.wifejakAiAssistant(user?.orgId, userMessage, window.location.href, prunedMessages)
      .pipe(take(1)).subscribe((wifejakChat: WifejakChat) => {
        const latestMessage = wifejakChat.message;
        const latestWifejakChat = {message: latestMessage, stepsWithSelectors: wifejakChat.stepsWithSelectors};
        const updatedMessages = [...this.chatMessages().slice(0, -1), latestWifejakChat];
        this.chatMessages.set(updatedMessages);
        if(wifejakChat.questionOrCommand === 'command') {
          this.actOutCommand(wifejakChat.stepsWithSelectors, wifejakChat.textToType);
        }
    });
    this.wifejakChatForm.controls['message'].reset();
  }

  dispatchAngularEvents(input: any, text: string, i: number) {
    input.dispatchEvent(new Event('input', { bubbles: true }));
    input.dispatchEvent(new Event('change', { bubbles: true }));
    // Force Angular to detect changes on the input
    input.dispatchEvent(new KeyboardEvent('keydown', { key: text[i] }));
    input.dispatchEvent(new KeyboardEvent('keypress', { key: text[i] }));
    input.dispatchEvent(new KeyboardEvent('keyup', { key: text[i] }));
  }

  async actOutCommand(stepsWithSelectors: StepWithSelector[], textToType: string) {
    for (const step of stepsWithSelectors) {
      const selector = step.selector;
      // Wait for element to be visible before proceeding
      const commandElement = await this.waitForElement(selector as string);

      if(step.inputType !== 'button') {
        // only require focus if input
        await new Promise(resolve => setTimeout(resolve, 300));
        commandElement.focus();
        commandElement.click();
        const input = commandElement as HTMLInputElement;
        const text = textToType;
        input.value = '';
        for(let i = 0; i < text.length; i++) {
          await new Promise(resolve => setTimeout(resolve, 50));
          input.value += text[i];
          this.dispatchAngularEvents(input, text, i);
        }
      } else {
        commandElement.click();
      }
    }
  }

  private waitForElement(selector: string): Promise<HTMLElement> {
    return new Promise((resolve) => {
      const checkElement = () => {
        const element = findElement(selector, document) as HTMLElement;
        if (element && this.isElementVisible(element)) {
          // Add a small delay to ensure element is fully rendered and interactive
          setTimeout(() => resolve(element), 100);
          return;
        }
        setTimeout(checkElement, 100); // Use setTimeout instead of requestAnimationFrame
      };
      checkElement();
    });
  }

  private isElementVisible(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) &&
      window.getComputedStyle(element).visibility !== 'hidden' &&
      window.getComputedStyle(element).display !== 'none'
    );
  }

  newChat() {
    this.wifejakChatForm.controls['message'].reset();
    this.chatMessages.set([]);
  }

  toggleFloatingInput(event?: KeyboardEvent | MouseEvent): void {
    if (!this.isFloatingInputVisible()) {
      const padding = 10;
      
      // Get current mouse position either from event or tracked position
      const mouseX = event instanceof MouseEvent ? event.clientX : window.mouseX;
      const mouseY = event instanceof MouseEvent ? event.clientY : window.mouseY;

      if (mouseX !== undefined && mouseY !== undefined) {
        // Position near mouse cursor
        this.floatingInputPosition.set({
          x: Math.min(
            Math.max(0, mouseX - padding),
            window.innerWidth - this.floatingInputSize().width
          ),
          y: Math.min(
            Math.max(0, mouseY - padding),
            window.innerHeight - this.floatingInputSize().height
          )
        });
      } else {
        // Fallback to center only if no mouse position available
        this.floatingInputPosition.set({
          x: Math.max(0, (window.innerWidth - this.floatingInputSize().width) / 2),
          y: Math.max(0, (window.innerHeight - this.floatingInputSize().height) / 2)
        });
      }
    }
    this.isFloatingInputVisible.update(value => !value);
  }

  onDragStart(event: DragEvent, element: HTMLElement) {
    // Create an empty, transparent image
    const img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    event.dataTransfer?.setDragImage(img, 0, 0);
    
    // Your existing dragstart logic here
  }

  onDrag(event: DragEvent, dragHandle: HTMLElement): void {
    if (event.clientX && event.clientY) {
      const offsetX = Number(dragHandle.getAttribute('data-offset-x')) || 0;
      const offsetY = Number(dragHandle.getAttribute('data-offset-y')) || 0;
      
      this.floatingInputPosition.set({
        x: event.clientX - offsetX,
        y: event.clientY - offsetY
      });
    }
  }

  onResizeStart(event: MouseEvent, direction: string, element: HTMLElement) {
    event.preventDefault();
    const startX = event.pageX;
    const startY = event.pageY;
    const startWidth = element.offsetWidth;
    const startHeight = element.offsetHeight;

    const onMouseMove = (e: MouseEvent) => {
      if (direction.includes('e')) {
        const width = startWidth + (e.pageX - startX);
        this.floatingInputSize.update(size => ({ ...size, width: Math.max(200, width) }));
      }
      if (direction.includes('s')) {
        const height = startHeight + (e.pageY - startY);
        this.floatingInputSize.update(size => ({ ...size, height: Math.max(150, height) }));
      }
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  onMouseDown(event: MouseEvent, element: HTMLElement) {
    event.preventDefault();
    
    const startX = event.clientX - this.floatingInputPosition().x;
    const startY = event.clientY - this.floatingInputPosition().y;

    const onMouseMove = (e: MouseEvent) => {
      this.floatingInputPosition.set({
        x: e.clientX - startX,
        y: e.clientY - startY
      });
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  startWalkthrough(user: OrganizationUser): void {
    console.log('startWalkthrough user', user);
    // Show the floating input if it's not already visible
    if (!this.isFloatingInputVisible()) {
      // Set a larger size for the walkthrough
      this.floatingInputSize.set({ width: 400, height: 300 });
      // Position it in the center of the screen initially
      this.floatingInputPosition.set({
        x: Math.max(0, (window.innerWidth - this.floatingInputSize().width) / 2),
        y: Math.max(0, (window.innerHeight - this.floatingInputSize().height) / 2)
      });
      this.toggleFloatingInput();
    } else {
      // If already visible, adjust the size for better walkthrough display
      this.floatingInputSize.set({ width: 400, height: 300 });
    }
    
    this.wifejakAiService.getAssistantWalkthrough(user?.orgId, window.location.href)
      .pipe(take(1))
      .subscribe((walkthrough: AssistantWalkthrough) => {
        if (walkthrough && walkthrough.groupings && walkthrough.groupings.length > 0) {
          this.assistantWalkthrough.set(walkthrough);
          this.isWalkthroughActive.set(true);
          this.currentGroupingIndex.set(0);
          this.currentItemIndex.set(0);
          
          // Begin the walkthrough
          this.processCurrentWalkthroughStep();
        } else {
          // Handle case where no walkthrough is available - don't add to chat
          console.warn('No walkthrough is available for this page.');
        }
      });
  }
  
  processCurrentWalkthroughStep(): void {
    const walkthrough = this.assistantWalkthrough();
    if (!walkthrough || !this.isWalkthroughActive()) return;
    
    const groupings = walkthrough.groupings;
    if (!groupings || groupings.length === 0) {
      this.exitWalkthrough();
      return;
    }
    
    const currentGroupingIndex = this.currentGroupingIndex();
    const currentItemIndex = this.currentItemIndex();
    
    // Check if we've reached the end of the walkthrough
    if (currentGroupingIndex >= groupings.length) {
      this.completeWalkthrough();
      return;
    }
    
    const currentGrouping = groupings[currentGroupingIndex];
    if (!currentGrouping) {
      this.exitWalkthrough();
      return;
    }
    
    // Remove any existing highlights
    this.removeAllHighlights();
    
    // Process the current item
    const items = currentGrouping.items || [];
    
    // If we're starting a new grouping and there are items, prioritize highlighting the first item
    if (currentItemIndex === 0 && items.length > 0) {
      const firstItem = items[0];
      if (firstItem && firstItem.selector) {
        this.highlightElement(firstItem.selector);
      }
    }
    // If we're starting a new grouping but there are no items, highlight the container
    else if (currentItemIndex === 0 && items.length === 0 && currentGrouping.containerSelector) {
      this.highlightElement(currentGrouping.containerSelector);
    }
    // Otherwise, highlight the current item
    else if (currentItemIndex < items.length) {
      const currentItem = items[currentItemIndex];
      if (currentItem && currentItem.selector) {
        this.highlightElement(currentItem.selector);
      }
    } else if (items.length === 0) {
      // If there are no items in this grouping, move to the next grouping
      this.currentGroupingIndex.update(index => index + 1);
      this.currentItemIndex.set(0);
      this.processCurrentWalkthroughStep();
    } else {
      // We've reached the end of the current grouping, move to the next one
      this.currentGroupingIndex.update(index => index + 1);
      this.currentItemIndex.set(0);
      this.processCurrentWalkthroughStep();
    }
  }
  
  nextWalkthroughStep(): void {
    const walkthrough = this.assistantWalkthrough();
    if (!walkthrough || !this.isWalkthroughActive()) return;
    
    const groupings = walkthrough.groupings;
    const currentGroupingIndex = this.currentGroupingIndex();
    const currentItemIndex = this.currentItemIndex();
    
    if (currentGroupingIndex < groupings.length) {
      const currentGrouping = groupings[currentGroupingIndex];
      
      if (currentItemIndex < currentGrouping.items.length - 1) {
        // Move to the next item in the current grouping
        this.currentItemIndex.update(index => index + 1);
      } else if (currentGroupingIndex < groupings.length - 1) {
        // Move to the next grouping
        this.currentGroupingIndex.update(index => index + 1);
        this.currentItemIndex.set(0);
      } else {
        // We've reached the end of the walkthrough
        this.completeWalkthrough();
        return;
      }
      
      this.processCurrentWalkthroughStep();
    }
  }
  
  previousWalkthroughStep(): void {
    const walkthrough = this.assistantWalkthrough();
    if (!walkthrough || !this.isWalkthroughActive()) return;
    
    const currentGroupingIndex = this.currentGroupingIndex();
    const currentItemIndex = this.currentItemIndex();
    
    if (currentItemIndex > 0) {
      // Move to the previous item in the current grouping
      this.currentItemIndex.update(index => index - 1);
    } else if (currentGroupingIndex > 0) {
      // Move to the last item of the previous grouping
      this.currentGroupingIndex.update(index => index - 1);
      const previousGrouping = walkthrough.groupings[currentGroupingIndex - 1];
      this.currentItemIndex.set(previousGrouping.items.length - 1);
    }
    
    this.processCurrentWalkthroughStep();
  }
  
  completeWalkthrough(): void {
    // Just clean up without adding a message to the chat
    this.isWalkthroughActive.set(false);
    this.removeAllHighlights();
  }
  
  exitWalkthrough(): void {
    // Just clean up without adding a message to the chat
    this.isWalkthroughActive.set(false);
    this.removeAllHighlights();
  }
  
  highlightElement(selector: string): void {
    this.removeAllHighlights();
    
    try {
      const element = findElement(selector, document);
      if (!element) {
        console.warn(`Element with selector "${selector}" not found`);
        return;
      }
      
      // Don't highlight if this is our own floating container or has the ignore attribute
      if (element.classList.contains('floating-input-container') || 
          element.getAttribute('data-walkthrough-ignore') === 'true') {
        console.warn('Attempted to highlight an element that should be ignored, skipping');
        return;
      }
      
      element.classList.add('wifejak-walkthrough-highlight');
      
      // Scroll element into view if needed
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      
      // Position the floating input container near the highlighted element
      setTimeout(() => {
        const rect = element.getBoundingClientRect();
        const padding = 20; // Space between element and floating container
        
        // Calculate optimal position for the floating container
        // Try to position it to the right of the element first
        let x = rect.right + padding;
        let y = rect.top;
        
        // If positioning to the right would make it go off-screen, try left side
        if (x + this.floatingInputSize().width > window.innerWidth) {
          x = Math.max(0, rect.left - this.floatingInputSize().width - padding);
        }
        
        // If still off-screen horizontally, center it
        if (x < 0 || x + this.floatingInputSize().width > window.innerWidth) {
          x = Math.max(0, Math.min(
            window.innerWidth - this.floatingInputSize().width,
            rect.left + (rect.width - this.floatingInputSize().width) / 2
          ));
        }
        
        // If it would go off-screen vertically, adjust y position
        if (y + this.floatingInputSize().height > window.innerHeight) {
          y = Math.max(0, window.innerHeight - this.floatingInputSize().height - padding);
        }
        
        // Update the position of the floating input container
        this.floatingInputPosition.set({ x, y });
      }, 300); // Small delay to allow scrolling to complete
    } catch (error) {
      console.error('Error highlighting element:', error);
    }
  }
  
  removeAllHighlights(): void {
    try {
      const highlightedElements = document.querySelectorAll('.wifejak-walkthrough-highlight');
      highlightedElements.forEach(element => {
        element.classList.remove('wifejak-walkthrough-highlight');
      });
    } catch (error) {
      console.error('Error removing highlights:', error);
    }
  }
}

// Add this to make TypeScript happy
declare global {
  interface Window {
    mouseX?: number;
    mouseY?: number;
  }
}

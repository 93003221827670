import { FlatFolderFile } from './../../../../../common-services/src/lib/file-tree/file-tree.service';
import { TemplateInputParameter } from './../../../../../data-models/src/lib/template/template.interface';
import { createAction, props } from '@ngrx/store';
import { Template, VsCodeInstance } from '@razroo-zeta/data-models';


export const directoriesLoading = createAction(
  '[Vscode] directoriesLoading'
)

export const loadParameters = createAction(
  '[Vscode] Load Parameters',
  props<{parameters:any}>()
);

export const updateParameterValue = createAction(
  '[Vscode] Update Parameter Value', 
  props<{parameterName: string, newValue: string}>()
);

export const loadVscodes = createAction(
  '[Vscode] Load Vscodes'
);

export const loadVscodeInstances = createAction(
  '[Vscode] Load Vscode Instances',
  props<{userId: string;}>()
);

export const loadVscodeInstancesSuccess = createAction(
  '[Vscode] Load Vscode Instances Success',
  props<{vsCodeInstances: VsCodeInstance[]}>()
);

export const loadVscodeInstancesError = createAction(
  '[Vscode] Load Vscode Instances Error',
  props<{error: any}>()
);

export const loadVscodeInstance = createAction(
  '[Vscode] Load Vscode Instance',
  props<{vsCodeInstanceId: string, template: Template, userId: string}>()
);

export const loadVscodeInstanceSuccess = createAction(
  '[Vscode] Load Vscode Instance Success',
  props<{vsCodeInstance: VsCodeInstance, template: Template}>()
);

export const loadVscodeInstanceError = createAction(
  '[Vscode] Load Vscode Instance Error',
  props<{error: any}>()
);

export const selectVscodeInstance = createAction(
  '[Vscode] Select Vscode Instance',
  props<{vsCodeInstance: VsCodeInstance, template: Template}>()
);

export const updateVscodeInstanceParameter = createAction(
  '[Vscode] Update Vscode Instance Parameter',
  props<{parameter: TemplateInputParameter, newInputValue: string}>()
);

export const updateVscodeDirectories = createAction(
  '[Vscode] Update Vscode Directories',
  props<{directories: FlatFolderFile[]}>()
);

export const loadVscodeFolderTree = createAction(
  '[Vscode] Load Vscode Folder Tree',
  props<{privateDirectories: string, parameters: TemplateInputParameter[], fileTree: any}>()
);

export const loadVscodeFolderTreeSuccess = createAction(
  '[Vscode] Load Vscode Folder Tree Success',
  props<{generateCodeFlatFolderFileTree: FlatFolderFile[]}>()
);

export const loadFilesToGenerateFolderFileTree = createAction(
  '[Vscode] Load Files To Generate Folder File Tree',
  props<{filesToGenerate: FlatFolderFile[]}>()
);

export const toggleVsCodeFolder = createAction(
  '[Vscode] Toggle VsCode Folder',
  props<{folder: FlatFolderFile}>()
);



import { APP_ID, NgModule, isDevMode, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { AuthService, CookiesService } from '@razroo-zeta/data-services';
import { AuthCoreModule } from './auth-core.module';
import { SharedModule } from './shared.module';
import { MarkdownModule } from 'ngx-markdown';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { RazrooAuthOrNoAuthHttpInterceptor } from './http-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { Overlay } from '@angular/cdk/overlay';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    MarkdownModule.forRoot(),
    AuthCoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    CookiesService, 
    AuthService,
    AuthHttpInterceptor,
    { 
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, 
      useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.block(), 
      deps: [Overlay] 
    },
    { provide: HTTP_INTERCEPTORS, useClass: RazrooAuthOrNoAuthHttpInterceptor, multi: true },
    { provide: APP_ID, useValue: 'server-app' },
    provideClientHydration(withEventReplay()),
    provideExperimentalZonelessChangeDetection()
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}

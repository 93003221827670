import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as BillingActions from './billing.actions';
import * as BillingSelectors from './billing.selectors';

@Injectable()
export class BillingFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  invoicesLoaded$ = this.store.pipe(select(BillingSelectors.getInvoicesLoaded));
  allInvoices$ = this.store.pipe(select(BillingSelectors.getAllInvoices));
  selectedInvoices$ = this.store.pipe(select(BillingSelectors.getSelected));
  invoices$ = this.store.pipe(select(BillingSelectors.getInvoices));
  futureInvoice$ = this.store.pipe(select(BillingSelectors.getFutureInvoice));

  constructor(private readonly store: Store) {}

  loadInvoices(stripeCustomerId: string) {
    this.store.dispatch(BillingActions.loadInvoices({stripeCustomerId}));
  }
}

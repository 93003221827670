export interface CodeChatMessage {
  content: string;
  role: CodeChatMessageRoleEnum;
  name?: string;
}

// chatId created via Razroo
// id created via AI API
export interface CodeChat {
  dateCreated?: string;
  id: string;
  lastUpdated?: string;
  messages: [any];
  init?: boolean;
  pathOrgId?: string;
  generationStatus: GenerationStatus;
  technologies?: [string];
  title?: string;
  userId?: string;
  userOrgId?: string;
  chatId?: string;
}

export enum GenerationStatus {
  Generating = 'Generating',
  Complete = 'Complete',
  GenerationsFailed = 'GenerationsFailed'
}

export enum CodeChatMessageRoleEnum {
  User = 'user',
  Assistant = 'assistant'
}

export interface CodeChatHistory extends CodeChat {
  orgId: string;
  pathId: string;
  recipeId?: string;
  batchId?: string;
  dateCreated: string;
}
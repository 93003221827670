import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NgModule, Component, Inject, PLATFORM_ID, Renderer2, Input, input } from '@angular/core';
// replace this with your environment path
// import { environment } from '../../environments/environment';

@Component({
  selector: 'razroo-zeta-google-analytics-gtag',
  templateUrl: './google-analytics-gtag.component.html',
  styleUrls: ['./google-analytics-gtag.component.scss'],
  standalone: true
})
export class GoogleAnalyticsGtagComponent {
  trackingCode = input<string>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    @Inject(DOCUMENT) private _document: Document,
    private readonly renderer: Renderer2
  ) {
    // BROWSER
    if (isPlatformBrowser(this.platformId)) {
      const script = this.renderer.createElement('script') as HTMLScriptElement;
      script.src = `//www.googletagmanager.com/gtag/js?id=${this.trackingCode()}`;
      script.async = true;
      this.renderer.appendChild(this._document.body, script);

      const script2 = this.renderer.createElement('script') as HTMLScriptElement;
      const scriptBody = this.renderer.createText(`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', '${this.trackingCode()}');
      `);
      this.renderer.appendChild(script2, scriptBody);
      this.renderer.appendChild(this._document.body, script2);
    }
  }
}

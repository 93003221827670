export const GithubRepoOutputFragment = `
  fragment githubRepoOutputFields on GithubRepoOutput{
    userId
    githubUserId
    githubRepos {
        id
        name
        html_url
        owner {
          login
        }
        description
        created_at
        updated_at
        default_branch
    }
}`;

export const BranchesOutputFragment = `
  fragment branchesOutputFields on BranchesOutput{
    userId
    githubUserId
    githubBranches {
      name
      protected
      commit {
        sha
        url
      } 
    }
}`;
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SEARCH_FEATURE_KEY,
  State,
  SearchPartialState,
  searchAdapter,
} from './search.reducer';

// Lookup the 'Search' feature state managed by NgRx
export const getSearchState = createFeatureSelector<SearchPartialState, State>(
  SEARCH_FEATURE_KEY
);

const { selectAll, selectEntities, selectIds } = searchAdapter.getSelectors();

export const getSearchLoaded = createSelector(
  getSearchState,
  (state: State) => state.loaded
);

export const getSearchPath = createSelector(
  getSearchState,
  (state: State) => state.path ? state.path : undefined
);

export const getSearchError = createSelector(
  getSearchState,
  (state: State) => state.error
);

export const getSearchText = createSelector(
  getSearchState,
  (state: State) => state.text
);

export const getAllSearch = createSelector(getSearchState, (state: State) =>
  selectAll(state)
);

export const getSearchEntities = createSelector(
  getSearchState,
  (state: State) => selectEntities(state)
);

const getAllSearchIds = createSelector(getSearchState, selectIds);

export const getSearchCollection = createSelector(
  getSearchEntities,
  getAllSearchIds,
  (entities: any, ids: any) => ids && ids.map((id:any) => entities[id])
);

export const getSelectedId = createSelector(
  getSearchState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getSearchEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

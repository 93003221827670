import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Notification } from '@razroo-zeta/data-models';

@Component({
  selector: 'razroo-zeta-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent implements OnInit {
  @Input() notification: Notification;
  @Output() notificationClick = new EventEmitter<Notification>();
  @Output() deleteNotificationClick = new EventEmitter<Notification>();
  @Output() markReadClick = new EventEmitter<Notification>();

  constructor() {}

  ngOnInit(): void {}

  notificationClickEmit(notification: Notification) {
    this.notificationClick.emit(notification);
  }

  deleteNotificationClickEmit(notification: Notification) {
    this.deleteNotificationClick.emit(notification);
  }

  markReadClickEmit(notification: Notification) {
    this.markReadClick.emit(notification);
  }
}

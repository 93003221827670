import { TemplateInputParameter } from '../../../../../data-models/src/lib/template/template.interface';
import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { FlatFolderFile, TerminalFile } from '@razroo-zeta/common-services';
import { OrganizationUser, RecipeUpdateParams, Step, Template } from '@razroo-zeta/data-models';

import * as PathCmsActions from './path-cms.actions';
import { PathCmsTreeName } from './path-cms.models';
import * as PathCmsSelectors from './path-cms.selectors';

@Injectable()
export class PathCmsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */

  getStarterWithFilesToGenerateLoaded$ = this.store.pipe(
    select(PathCmsSelectors.getStarterWithFilesToGenerateLoaded)
  );
  
  getStarterWithFilesToGenerate$ = this.store.pipe(
    select(PathCmsSelectors.getStarterWithFilesToGenerate)
  );
  openFiles$ = this.store.pipe(
    select(PathCmsSelectors.getOpenFiles)
  );
  openFilesLoaded$ = this.store.pipe(
    select(PathCmsSelectors.getOpenFilesLoaded)
  );
  selectedOpenFile$ = this.store.pipe(
    select(PathCmsSelectors.getOpenFilesSelected)
  );
  terminalOpenFiles$ = this.store.pipe(
    select(PathCmsSelectors.getTerminalOpenFiles)
  );
  terminalOpenFile$ = this.store.pipe(
    select(PathCmsSelectors.getTerminalOpenFilesSelected)
  );
  terminalOpenFileText$ = this.store.pipe(
    select(PathCmsSelectors.getTerminalOpenFilesSelectedFileText)
  );
  getOpenCodeModel$ = this.store.pipe(
    select(PathCmsSelectors.getOpenCodeModel)
  );
  isReadOnly$ = this.store.pipe(
    select(PathCmsSelectors.getOpenFilesSelectedIsReadOnly)
  );
  activityBar$ = this.store.pipe(
    select(PathCmsSelectors.getActivityBar)
  );
  codeModStatus$ = this.store.pipe(
    select(PathCmsSelectors.getCodeMod)
  );
  recipe$ = this.store.pipe(
    select(PathCmsSelectors.getRecipe)
  );
  codeModLoaded$ = this.store.pipe(
    select(PathCmsSelectors.getCodeModLoaded)
  );

  splitAreaPercent$ = this.store.pipe(
    select(PathCmsSelectors.getSplitAreaPercent)
  );

  documentationSaving$ = this.store.pipe(
    select(PathCmsSelectors.getDocumentationSaving)
  );

  starterTemplate$ = this.store.pipe(select(PathCmsSelectors.getStarterTemplate))

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */

  loadFilesToGenerate(orgId: string, pathId: string, recipeId: string, id: string) {
    this.store.dispatch(PathCmsActions.loadFilesToGenerate({orgId, pathId, recipeId, id}));
  }

  toggleFolder(folder: FlatFolderFile, treeName: PathCmsTreeName) {
    this.store.dispatch(PathCmsActions.toggleFolder({folder, treeName}));
  }

  loadFile(flatFolderFile: FlatFolderFile, template: Template, starterTemplate?: any) {
    this.store.dispatch(PathCmsActions.loadFile({flatFolderFile, template, starterTemplate}));
  }

  loadDependentStepFile(flatFolderFile: FlatFolderFile, template: Template) {
    this.store.dispatch(PathCmsActions.loadDependentStepFile({flatFolderFile, template}));
  }

  loadTerminalFile(terminalFile: TerminalFile, template: Template) {
    this.store.dispatch(PathCmsActions.loadTerminalFile({terminalFile, template}));
  }

  closeFile(flatFolderFile: FlatFolderFile) {
    this.store.dispatch(PathCmsActions.closeFile({flatFolderFile}));
  }

  resetStore() {
    this.store.dispatch(PathCmsActions.resetStore());
  }

  editFile(codeChanged: string, openFile: FlatFolderFile) {
    this.store.dispatch(PathCmsActions.editFile({codeChanged, openFile}));
  }

  editTerminalFile(terminalTextChanged: string, terminalFile: TerminalFile) {
    this.store.dispatch(PathCmsActions.editTerminalFile({terminalTextChanged, terminalFile}));
  }

  saveFile(codeChanged: string, openFile: FlatFolderFile, template: Template, userOrgId: string) {
    this.store.dispatch(PathCmsActions.saveFile({codeChanged, openFile, template, userOrgId}));
  }

  saveTerminalFile(fileText: string, terminalFile: TerminalFile, template: Template, userOrgId: string) {
    this.store.dispatch(PathCmsActions.saveTerminalFile({ fileText, terminalFile, template, userOrgId}));
  }

  changeDynamicFilePath(openFile: FlatFolderFile, template: Template, source: string, destination: string) {
    this.store.dispatch(PathCmsActions.changeDynamicFilePath({ openFile, template, source, destination}));
  }

  renameFile(openFile: FlatFolderFile, template: Template, newName: string, source: string, destination: string) {
    this.store.dispatch(PathCmsActions.renameFile({openFile, template, newName, source, destination}));
  }

  renameTerminalFile(terminalFile: TerminalFile, template: Template, newName: string) {
    this.store.dispatch(PathCmsActions.renameTerminalFile({terminalFile, template, newName}));
  }

  deleteFile(openFile: FlatFolderFile, template: Template) {
    this.store.dispatch(PathCmsActions.deleteFile({openFile, template}));
  }
  deleteFolder(folderName: string, template: Template, fileIdsToDelete: string[]) {
    this.store.dispatch(PathCmsActions.deleteFolder({folderName, template, fileIdsToDelete}));
  }

  deleteTerminalFile(terminalFile: TerminalFile, template: Template, terminalFiles: Array<any>) {
    this.store.dispatch(PathCmsActions.deleteTerminalFile({terminalFile, template, terminalFiles}));
  }

  newFile(openFile: FlatFolderFile, template: Template, newFileName: string, filePath: string, 
    starterFilesToGenerate: FlatFolderFile[], fileText = '', userOrgId: string, dynamicPath: string) {
    this.store.dispatch(PathCmsActions.newFile({openFile, template, newFileName, filePath, starterFilesToGenerate, fileText, userOrgId, dynamicPath}));
  }

  newStarterFile(openFile: FlatFolderFile, template: Template, newFileName: string, filePath: string, starterFilesToGenerate: FlatFolderFile[], fileText = '', userOrgId: string) {
    this.store.dispatch(PathCmsActions.newStarterFile({openFile, template, newFileName, filePath, starterFilesToGenerate, fileText, userOrgId}));
  }

  updateCodemod(user: OrganizationUser, openFile: FlatFolderFile, template: Template, codeMod: string) {
    this.store.dispatch(PathCmsActions.updateCodemod({user, openFile, template, codeMod}));
  }

  newTerminalFile(terminalFile: TerminalFile, template: Template, userOrgId: string) {
    this.store.dispatch(PathCmsActions.newTerminalFile({terminalFile, template, userOrgId}));
  }

  setFilesToGenerate(filesToGenerate: FlatFolderFile[]) {
    this.store.dispatch(PathCmsActions.loadFilesToGenerateSuccess({filesToGenerate: filesToGenerate}));
  }

  setStarterWithFilesToGenerate(starterWithFilesToGenerate: FlatFolderFile[]) {
    this.store.dispatch(PathCmsActions.loadStarterWithFilesToGenerateSuccess({starterWithFilesToGenerate: starterWithFilesToGenerate}));
  }

  activateActivity(activeActivity: string) {
    this.store.dispatch(PathCmsActions.activateActivity({activeActivity}))
  }

  activateCodeMod(editFileStatus: boolean) {
    this.store.dispatch(PathCmsActions.activateCodeMod({editFileStatus}))
  }

  renameFolder(openFolder: FlatFolderFile, template: Template, newFolderName: string) {
    this.store.dispatch(PathCmsActions.renameFolder({openFolder, template, newFolderName}));
  }

  renameStaticFolder(openFolder: FlatFolderFile, template: Template, newFolderName: string) {
    this.store.dispatch(PathCmsActions.renameStaticFolder({openFolder, template, newFolderName}));
  }

  addStepDependency(template: Template, dependentOrgId: string, dependentPathId: string, dependentRecipeId: string, dependentStepId: string) {
    this.store.dispatch(PathCmsActions.addStepDependency({template, dependentOrgId, dependentPathId, dependentRecipeId, dependentStepId}));
  }

  removeStepDependency(template: Template, dependentRecipeId: string, dependentStepId: string) {
    this.store.dispatch(PathCmsActions.removeStepDependency({template, dependentRecipeId, dependentStepId}));
  }

  loadRecipe(step: Template) {
    this.store.dispatch(PathCmsActions.loadRecipe({step}));
  }

  updateRecipe(orgId: string, pathId: string, recipeId: string, recipeUpdateParams: RecipeUpdateParams) {
    this.store.dispatch(PathCmsActions.updateRecipe({orgId, pathId, recipeId, recipeUpdateParams}));
  }

  loadScaffold(scaffold: Step, folderPath: string, template: Template, userOrgId: string) {
    this.store.dispatch(PathCmsActions.loadScaffold({scaffold, folderPath, template, userOrgId}));
  }

  selectOpenFile(openFile: FlatFolderFile) {
    this.store.dispatch(PathCmsActions.selectOpenFile({openFile}));
  }

  addUploadedFilesToTree(uploadedFiles: Array<any>){
    this.store.dispatch(PathCmsActions.addUploadedFilesToTree({ uploadedFiles }))
  }

  saveDocumentation(pathOrgId: string, pathId: string, recipeId: string, stepId: string, markdown: string) {
    this.store.dispatch(PathCmsActions.saveDocumentation({pathOrgId, pathId, recipeId, stepId, markdown}));
  }

  splitAreaDragEnd(percent: any) {
    this.store.dispatch(PathCmsActions.splitAreaDragEnd({percent}));
  }

  portExistingCodeFileToNewStep(
    userOrgId: string,
    fromPathOrgId: string,
    fromPathId: string,
    fromRecipeId: string,
    fromStepId: string,
    toPathOrgId: string,
    toPathId: string,
    toRecipeId: string,
    toStepId: string,
    staticFilePath: string,
    dynamicFilePath?: string,
    contentType?: string
  ) {
    this.store.dispatch(
      PathCmsActions.portExistingCodeFileToNewStep({
        userOrgId,
        fromPathOrgId,
        fromPathId,
        fromRecipeId,
        fromStepId,
        toPathOrgId,
        toPathId,
        toRecipeId,
        toStepId,
        staticFilePath,
        dynamicFilePath,
        contentType
      })
    );
  }

}

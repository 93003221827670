import gql from "graphql-tag";

export const CreateComment = gql`
    mutation CreateComment( $createCommentParams: CreateCommentParams){
        createComment(createCommentParams: $createCommentParams){
            comment
            commentId
            componentName
            commentType
            firstName
            lastName
            downvotes
            fileName
            lineNumberEnd
            lineNumberStart
            pageUrl
            resolved
            responses{
                comment
                timestamp
                firstName
                lastName
                userId
                userOrgId
                index
            }
            textCommentedOn
            timestamp
            upvotes
            userId
            userOrgId
            voteCount
            languageVersion
            picture
        }
    }
`

export const AddCommentResponse = gql`
    mutation AddCommentResponse($addCommentResponseParams: AddCommentResponseParams){
        addCommentResponse(addCommentResponseParams: $addCommentResponseParams){
            comment
            timestamp
            firstName
            lastName
            userId
            userOrgId
            index
            picture
        }
    }
`

export const UpvoteComment = gql`
    mutation UpvoteComment(
        $commentId: String!
        $userId: String!
        $userOrgId: String!
    ){
        upvoteComment(
            commentId: $commentId
            userId: $userId
            userOrgId: $userOrgId
        ){
            comment
            commentId
            componentName
            commentType
            downvotes
            fileName
            firstName
            lastName
            lineNumberEnd
            lineNumberStart
            pageUrl
            resolved
            responses{
                comment
                timestamp
                firstName
                lastName
                userId
                userOrgId
                index
            }
            textCommentedOn
            timestamp
            upvotes
            userId
            userOrgId
            voteCount
            picture
        }
    }

`

export const DownvoteComment = gql`
    mutation DownvoteComment(
        $commentId: String!
        $userId: String!
        $userOrgId: String!
    ){
        downvoteComment(
            commentId: $commentId
            userId: $userId
            userOrgId: $userOrgId
        ){
            comment
            commentId
            componentName
            commentType
            downvotes
            fileName
            firstName
            lastName
            lineNumberEnd
            lineNumberStart
            pageUrl
            resolved
            responses{
                comment
                timestamp
                firstName
                lastName
                userId
                userOrgId
                index
            }
            textCommentedOn
            timestamp
            upvotes
            userId
            userOrgId
            voteCount
            picture
        }
    }

`

export const DeleteComment = gql`
    mutation deleteComment(
        $userId: String!
        $userOrgId: String!
        $commentId: String!
        ){
        deleteComment(userId: $userId, userOrgId: $userOrgId, commentId: $commentId){
            comment
            commentId
            componentName
            commentType
            downvotes
            fileName
            firstName
            lastName
            lineNumberEnd
            lineNumberStart
            pageUrl
            resolved
            responses{
                comment
                timestamp
                firstName
                lastName
                userId
                userOrgId
                index
            }
            textCommentedOn
            timestamp
            upvotes
            userId
            userOrgId
            voteCount
            picture
        }
    }
`
export const DeleteCommentResponse = gql`
    mutation deleteCommentResponse(
        $userId: String!
        $userOrgId: String!
        $commentId: String!
        $responseIndex: Int!
        ){
        deleteCommentResponse(userId: $userId, userOrgId: $userOrgId, commentId: $commentId, responseIndex: $responseIndex){
            comment
            timestamp
            firstName
            lastName
            userId
            userOrgId
            index
            picture
        }
    }
`
export const UpdateComment = gql`
    mutation updateComment(
        $userId: String!
        $userOrgId: String!
        $commentId: String!
        $newComment: String
        $resolved: Boolean
        $commentType: CommentType,
        $pathId: String
        ){
        updateComment(userId: $userId, userOrgId: $userOrgId, commentId: $commentId, newComment: $newComment, commentType: $commentType, resolved: $resolved, pathId: $pathId){
            comment
            commentId
            componentName
            commentType
            downvotes
            fileName
            firstName
            lastName
            lineNumberEnd
            lineNumberStart
            pageUrl
            resolved
            responses{
                comment
                timestamp
                firstName
                lastName
                userId
                userOrgId
                index
            }
            textCommentedOn
            timestamp
            upvotes
            userId
            userOrgId
            voteCount
            picture
        }
    }
`
export const UpdateCommentResponse = gql`
    mutation updateCommentResponse(
        $userId: String!
        $userOrgId: String!
        $commentId: String!
        $responseIndex: Int!
        $newComment: String!
        ){
        updateCommentResponse(userId: $userId, userOrgId: $userOrgId, commentId: $commentId, responseIndex: $responseIndex, newComment: $newComment){
            comment
            timestamp
            firstName
            lastName
            userId
            userOrgId
            index
            picture
        }
    }
`

import { Injectable } from '@angular/core';
import { GetStripeInvoices } from '@razroo-zeta/data-graphql';
import { Apollo } from 'apollo-angular';
import { from, pluck } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private apollo: Apollo) { }

  getInvoices(stripeCustomerId: string) {
    const query = GetStripeInvoices;
    const variables = {
      stripeCustomerId,
    };

    const stripeInvoices$ = this.apollo.query({ query, variables });
    return from(stripeInvoices$).pipe(pluck('data', 'stripeInvoices'));
  }
}

import { GetBook, GetToc } from '@razroo-zeta/data-graphql';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class BookService {
  constructor(private apollo: Apollo) {}

  getBook(id: string, chapter: string) {
    const query = GetBook;
    const variables = {
      id: id,
      chapter: chapter,
    };
    const book$ = this.apollo.query({ query, variables });
    return from(book$).pipe(pluck('data', 'book'));
  }

  getToc(id: string) {
    const query = GetToc;
    const variables = {
      id: id,
    };
    const toc$ = this.apollo.query({ query, variables });
    return from(toc$).pipe(pluck('data', 'book'));
  }
}

import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import {
  GetOrganization,
  GetOrganizationUsers,
  CreateOrganization,
  CreateOrganizationUser,
  SendJoinOrgEmail,
  processJoinOrgEmailLink,
  UpdateOrgRole,
  UpdateOrgUserSeniority,
  UpdateOrganization,
  DeactivateOrgUser,
  AddOrgUserNotification,
  DeactivateOrganization,
  UpdateSlackInfo,
  UpdateGithubOrganization,
  GetInvitedUsers,
  OrganizationUser,
  RemoveInvitedUser,
  CreateTeam,
  AssignOwner,
  GetOrgTeams,
  AddTeamMember,
  AddTeamPermissions,
  RemoveTeamPermissions,
  UpdateUserPermission,
  RevertToDefaultPermission,
  UserAndOrganizationUser,
  UpdateOrganizationUser,
  DeleteStripeSubscription
} from '@razroo-zeta/data-graphql';
import { ActiveProject, ActiveStep, GithubRepo, Template } from '@razroo-zeta/data-models';
import { SearchEntity } from '@razroo-zeta/data-access/search';
import { UpdateActiveStep } from 'libs/data-graphql/src/lib/organizationUsers/organization-users.mutations';
import { Apollo } from 'apollo-angular';
import { ActiveTicketWorkspace } from 'libs/data-models/src/lib/organizations/organizations.interface';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  constructor(private apollo: Apollo) { }

  getOrganization(orgId: string): Observable<any> {
    const query = GetOrganization;
    const variables = {
      orgId,
    };
    // const organization$ = this.apollo.query({query, variables});
    // const organization$ = Auth.currentUserInfo();
    const organization$ = this.apollo.query({ query, variables, fetchPolicy: 'no-cache' });
    return from(organization$).pipe(pluck('data', 'organization'));
  }

  getOrganizationUsers(orgId: string): Observable<any> {
    const query = GetOrganizationUsers;
    const variables = {
      orgId,
    };
    const organizationUsers$ = this.apollo.query({ query, variables });
    return from(organizationUsers$).pipe(pluck('data', 'organizationUsers'));
  }

  getOrganizationUser(userId: string, orgId: string): Observable<any> {
    const query = OrganizationUser;
    const variables = {
      userId,
      orgId
    };
    const organizationUser$ = this.apollo.query({ query, variables });
    return from(organizationUser$).pipe(pluck('data', 'organizationUser'));
  }

  getUserAndOrganizationUser(orgId?: string): Observable<any> {
    const query = UserAndOrganizationUser;
    const variables = {
      orgId
    };
    const organizationUser$ = this.apollo.query({ query, variables, fetchPolicy: 'no-cache' });
    return from(organizationUser$).pipe(pluck('data', 'userAndOrganizationUser'));
  }

  createOrganization(userId: string, isPersonal: boolean, isActive = true, displayName = '', orgType?: string) {
    const mutation = CreateOrganization;
    const variables = {
      userId,
      isPersonal,
      isActive,
      displayName,
      orgType
    };
    const newOrganization$ = this.apollo.mutate({ mutation, variables });
    return from(newOrganization$).pipe(pluck('data', 'createOrganization'));
  }

  createOrganizationUser(orgId: string, email: string, orgRole: string) {
    const mutation = CreateOrganizationUser;
    const variables = {
      orgId,
      email,
      orgRole,
      isActive: true,
    };
    const newOrganizationUser$ = this.apollo.mutate({ mutation, variables });
    return from(newOrganizationUser$).pipe(
      pluck('data', 'createOrganizationUser')
    );
  }

  sendJoinOrgEmail(orgId: string, userEmail: string, link: string) {
    const mutation = SendJoinOrgEmail;
    const variables = {
      orgId,
      userEmail,
      link
    };
    const sentEmailResponse$ = this.apollo.mutate({ mutation, variables });
    return from(sentEmailResponse$).pipe(pluck('data', 'sendJoinOrgEmail'));
  }

  processUserInvite(token: string) {
    const mutation = processJoinOrgEmailLink;
    const variables = {
      token,
    };
    const processJoinOrgEmailLink$ = this.apollo.mutate({
      mutation,
      variables,
    });
    return from(processJoinOrgEmailLink$).pipe(pluck('data', 'processJoinOrgEmailLink'));
  }

  removeUserInvite(orgId: string, userEmail: string){
    const mutation = RemoveInvitedUser;
    const variables = {
      orgId,
      userEmail
    };
    const removeInvitedUser$ = this.apollo.mutate({ mutation, variables });
    return from(removeInvitedUser$).pipe(pluck('data', 'removeInvitedUser'));
  }

  updateOrgRole(orgId: string, userId: string, orgRole: string) {
    const mutation = UpdateOrgRole;
    const variables = {
      orgId,
      userId,
      orgRole,
    };
    const updatedOrgUser$ = this.apollo.mutate({ mutation, variables });
    return from(updatedOrgUser$).pipe(pluck('data', 'updateOrganizationUser'));
  }

  updateOrganizationUser(orgId: string, userId: string, 
    githubRepos?: GithubRepo[], githubUserId?: string, 
    orgRole?: string, activeProject?: ActiveProject, activeStep?: ActiveStep, activeTicketWorkspace?: ActiveTicketWorkspace): any {
    const mutation = UpdateOrganizationUser;
    const variables = {
      orgId,
      userId,
      orgRole,
      githubRepos,
      githubUserId,
      activeProject,
      activeStep,
      activeTicketWorkspace
    };
    const updatedOrgUser$ = this.apollo.mutate({ mutation, variables });
    return from(updatedOrgUser$).pipe(map((user: any) => user?.data?.updateOrganizationUser));
  }

  // 1. figure out recipe based on search store
  // 2. + 3. step + programming language based on template store
  createActiveStepObject(currentSearch: SearchEntity, template: Template) {
    let recipe;
    if (currentSearch && currentSearch.type === "recipe") {
      recipe = currentSearch.id;
    }
    else {
      recipe = undefined;
    }

    return {
      recipe: template.recipeId as string,
      step: template.id as string,
      language: template.pathId as string
    }
  }

  setActiveStep(orgId: string, userId: string, activeStep: ActiveStep) {
    const mutation = UpdateActiveStep;
    const variables = {
      orgId,
      userId,
      activeStep,
    };
    const updatedOrgUser$ = this.apollo.mutate({ mutation, variables });
    return from(updatedOrgUser$).pipe(pluck('data', 'updateOrganizationUser'));
  }

  updateOrgUserSeniority(orgId: string, userId: string, seniority: string) {
    const mutation = UpdateOrgUserSeniority;
    const variables = {
      orgId,
      userId,
      seniority,
    };
    const updatedOrgUser$ = this.apollo.mutate({ mutation, variables });
    return from(updatedOrgUser$).pipe(pluck('data', 'updateOrganizationUser'));
  }

  updateOrganization(displayName: string, orgId: string) {
    const mutation = UpdateOrganization;
    const variables = {
      displayName,
      orgId,
    };
    const updatedOrg$ = this.apollo.mutate({ mutation, variables });
    return from(updatedOrg$).pipe(pluck('data', 'updateOrganization'));
  }

  updateGithubOrganization(orgId: string, gitHubOrg: string) {
    const mutation = UpdateGithubOrganization;
    const variables = {
      gitHubOrg,
      orgId,
    };
    const updatedOrg$ = this.apollo.mutate({ mutation, variables });
    return from(updatedOrg$).pipe(pluck('data', 'updateOrganization'));
  }

  removeMemberFromOrg(orgId: string, userId: string) {
    const mutation = DeactivateOrgUser;
    const variables = {
      orgId,
      userId,
      isActive: false
    };
    const updatedOrgUser$ = this.apollo.mutate({ mutation, variables });
    return from(updatedOrgUser$).pipe(pluck('data', 'updateOrganizationUser'));
  }

  addOrgUserNotification(orgId: string, userId: string, orgNotification: string) {
    const mutation = AddOrgUserNotification;
    const variables = {
      orgId,
      userId,
      orgNotification
    };
    const updatedOrgUser$ = this.apollo.mutate({ mutation, variables });
    return from(updatedOrgUser$).pipe(pluck('data', 'updateOrganizationUser'));
  }

  deactivateOrganization(orgId: string, userId) {
    const mutation = DeactivateOrganization;
    const variables = {
      orgId,
      userId
    };
    const updatedOrg$ = this.apollo.mutate({ mutation, variables });
    return from(updatedOrg$).pipe(pluck('data', 'deleteOrganization'));
  }

  updateSlackInfo(orgId: string, slackAccessToken: string, slackWorkspaceChannels: any, slackUserIds: any) {
    const mutation = UpdateSlackInfo;
    const variables = {
      orgId,
      slackAccessToken,
      slackWorkspaceChannels,
      slackUserIds
    };
    const updatedOrg$ = this.apollo.mutate({ mutation, variables });
    return from(updatedOrg$).pipe(pluck('data', 'updateOrganization'));
  }

  getInvitedUsers(orgId: string, userId: string) {
    const query = GetInvitedUsers;
    const variables = {
      orgId,
      userId
    }
    const invitedUsers$ = this.apollo.query({ query, variables });
    return from(invitedUsers$).pipe(pluck('data', 'getInvitedUsers'));
  }

  assignOwner(orgId: string, existingOwner: string, newOwner: string) {
    const mutation = AssignOwner;
    const variables = {
      orgId,
      existingOwner,
      newOwner
    };
    const assignOwner$ = this.apollo.mutate({ mutation, variables });
    return from(assignOwner$).pipe(pluck('data', 'assignOwner'));
  }

  getOrgTeams(orgId: string, userId: string){
    const query = GetOrgTeams;
    const variables = {
      orgId, 
      userId
    }
    const getOrgTeams$ = this.apollo.query({ query, variables});
    return from(getOrgTeams$).pipe(pluck('data', 'getOrgTeams'))
  }
  
  createTeam(orgId: string, userId: string, teamName: string){
    const mutation = CreateTeam;
    const variables = {
      orgId,
      userId,
      teamName
    }
    const createTeam$ = this.apollo.mutate({mutation, variables});
    return from(createTeam$).pipe(pluck('data', 'createTeam'));
  }


  addTeamMember(orgId: string,
    userId: string, 
    teamId: string,
    newUserId: string){
      const mutation = AddTeamMember;
      const variables = {
        orgId,
        userId, 
        teamId, 
        newUserId
      }
      const addTeamMember$ = this.apollo.mutate({mutation, variables});
      return from(addTeamMember$).pipe(pluck('data', 'addTeamMember'));
  }

  addTeamPermissions(orgId: string,
    userId: string, 
    teamId: string,
    permissions: any){
      const mutation = AddTeamPermissions;
      const variables = {
        orgId,
        userId, 
        teamId, 
        permissions
      }
      const addTeamPermissions$ = this.apollo.mutate({mutation, variables});
      return from(addTeamPermissions$).pipe(pluck('data', 'addTeamPermissions'));
  }

  removeTeamPermissions(orgId: string,
    userId: string, 
    teamId: string,
    permissions: any){
      const mutation = RemoveTeamPermissions;
      const variables = {
        orgId,
        userId, 
        teamId, 
        permissions
      }
      const removeTeamPermissions$ = this.apollo.mutate({mutation, variables});
      return from(removeTeamPermissions$).pipe(pluck('data', 'removeTeamPermissions'));
  }

  updateUserPermission(
    orgId: string,
    userId: string,
    gettersUserId: string,
    permission: string,
    pathOrgId: string,
    pathId: string,
    recipeId?: string,
    stepId?: string){
      const mutation = UpdateUserPermission;
      const variables = {
        orgId, 
        userId,
        gettersUserId,
        permission,
        pathOrgId,
        pathId,
        recipeId,
        stepId
      }
      const updateUserPermission$ = this.apollo.mutate({ mutation, variables});
      return from(updateUserPermission$).pipe(pluck('data', 'updateUserPermission'))
  }

  revertToDefaultPermission(
    orgId: string,
    userId: string,
    gettersUserId: string,
    pathOrgId: string,
    pathId: string,
    recipeId?: string,
    stepId?: string
  ){
    const mutation = RevertToDefaultPermission;
    const variables = {
      orgId, 
      userId,
      gettersUserId,
      pathOrgId,
      pathId,
      recipeId,
      stepId
    }
    const revertToDefaultPermission$ = this.apollo.mutate({ mutation, variables});
    return from(revertToDefaultPermission$).pipe(pluck('data', 'revertToDefaultPermission'))
  }

  deleteStripeSubscription(orgId: string){
    const mutation = DeleteStripeSubscription;
    const variables = {
      orgId
    };
    const deleteStripeSubscription$ = this.apollo.mutate({ mutation, variables});
    return from(deleteStripeSubscription$).pipe(map((result: any) => result?.data?.deleteStripeSubscription))
  }
}

import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { UserFacade } from '@razroo-zeta/data-access';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeveloperOrBetaUserGuard  {
  constructor(private userFacade: UserFacade, private router: Router){}

  canLoad(): any {
    return this.userFacade.isDeveloperOrBetaUser$.pipe(
      tap((res: any) => {
        if(res === false){
          this.router.navigate(['/home'])
        }
      }),
      first(res => res !== undefined)
    );
  }

}

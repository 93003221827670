import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as UserActions from './user.actions';
import * as OrganizationsActions from '@razroo-zeta/data-access/organizations';
import { UserEntity } from './user.models';
import { updateActiveProject } from '@razroo-zeta/data-access/organizations';
export const USER_FEATURE_KEY = 'user';

export interface State extends EntityState<UserEntity> {
  selectedId?: string; // which User record has been selected
  loaded: boolean; // has the User list been loaded
  error?: string | null; // last none error (if any)
}

export interface UserPartialState {
  readonly [USER_FEATURE_KEY]: State;
}

export const userAdapter: EntityAdapter<UserEntity> =
  createEntityAdapter<UserEntity>();

export const initialState: State = userAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const userReducer = createReducer(
  initialState,
  on(UserActions.loadUser, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(UserActions.loadUserSuccess, (state, { user }) =>
    userAdapter.upsertOne(
      { 
        ...user, 
        id: user.userId,
        orgId: user.orgId ? user.orgId : user.userId
      },
      { ...state, selectedId: user.userId, loaded: true }
    )
  ),
  on(UserActions.loadUserFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.loadUserProfile, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(UserActions.loadUserProfileSuccess, (state, { update }) =>
    userAdapter.updateOne(update, { ...state, loaded: true })
  ),
  on(UserActions.loadUserProfileFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UserActions.loadUserProfileFromScratch, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(UserActions.loadUserProfileFromScratchSuccess, (state, { userProfile, userId }) =>
    userAdapter.addOne(
      { ...userProfile, id: userId },
      { ...state, selectedId: userId, loaded: true }
    )
  ),
  on(UserActions.loadUserOrganizationsSuccess, (state, { update }) =>
    userAdapter.updateOne(update, { ...state, loaded: true })
  ),
  on(OrganizationsActions.updateOrganizationUser, (state, { userId, orgId, activeStep, activeProject }) => 
    userAdapter.updateOne({ 
      id: userId,
      changes: {
        ...(orgId && {orgId}),
        ...(activeStep && {activeStep}),
        ...(activeProject && {activeProject}),
      }
    }, {...state})
  ),
  on(UserActions.loadUserRolesSuccess, (state, { user, roles }) =>
    userAdapter.upsertOne({ ...user, id: user.userId, roles: roles }, {
      ...state,
      loaded: true
    })
  ),
  on(UserActions.likeTemplate, (state, { likedTemplate }) =>
    userAdapter.updateOne({
      id: state.selectedId!,
      changes: {
        likedTemplates: state.entities[state.selectedId!]?.likedTemplates?.length ? [...state.entities[state.selectedId!]?.likedTemplates!, likedTemplate] : [likedTemplate]
      }
    }, state)
  ),
  on(UserActions.downvoteTemplate, (state, { downvotedTemplate }) =>
    userAdapter.updateOne({
      id: state.selectedId!,
      changes: {
        likedTemplates: state.entities[state.selectedId!]?.likedTemplates?.filter(temp => !(temp.stepId === downvotedTemplate.stepId && temp.pathId === downvotedTemplate.pathId && temp.recipeId === downvotedTemplate.recipeId))
      }
    }, state)
  ),
  on(UserActions.updateProfilePic, (state, { newUrl }) =>
    userAdapter.updateOne({
      id: state.selectedId!,
      changes: {
        picture: newUrl
      }
    }, state) 
  ),
  on(UserActions.deleteProfilePicSuccess, (state, { userId }) =>
    userAdapter.updateOne({
      id: state.selectedId!,
      changes: {
        picture: undefined
      }
    }, state) 
  ),
  on(updateActiveProject, (state, { activeProject, githubRepos}) =>
    userAdapter.updateOne({
      id: state.selectedId!,
      changes: {
        activeProject,
        ...(githubRepos && { githubRepos }),
      }
    }, state) 
  ),
  on(UserActions.updateOrgUserJiraOrgId, (state, { jiraOrgId }) =>
    userAdapter.updateOne({
      id: state.selectedId!,
      changes: {
        jiraOrgId
      }
    }, state) 
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

import { environment } from '@razroo-zeta/common-environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthService, VscodeService } from '@razroo-zeta/data-services'
import { authConfig } from './auth.config';
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { RazrooAuthOrNoAuthHttpInterceptor } from './http-interceptor';

@NgModule({ imports: [AuthModule.forRoot({
            ...authConfig,
            httpInterceptor: {
                allowedList: [
                    {
                        uri: `${environment.graphQLURI}`
                    }
                ]
            }
        })], providers: [
        AuthService,
        AuthHttpInterceptor,
        VscodeService,
        { provide: HTTP_INTERCEPTORS, useClass: RazrooAuthOrNoAuthHttpInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AuthCoreModule {}
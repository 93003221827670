import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UrlTree } from '@angular/router';
import { UserFacade } from '@razroo-zeta/data-access';
import { catchError, filter, EMPTY, from, Observable, of, switchMap, take, tap } from 'rxjs';
import { loadUser } from '../../../../data-access/src/lib/+state/user/user.actions';
import {isPlatformBrowser} from '@angular/common';
import { AuthService } from '@razroo-zeta/data-services';

@Injectable({
  providedIn: 'root'
})
export class UserProfileLoadedGuard  {
  constructor(
    private userFacade: UserFacade, @Inject(PLATFORM_ID) private platformId: any, 
    private authService: AuthService) {}
    
    getUser(): Observable<any> {
      return this.authService.isAuthenticated$.pipe(
        switchMap((isAuthenticated: any) => {
          if (isAuthenticated) {
            return this.userFacade.currentUser$.pipe(
              tap((user: any) => {
                if (!user?.userId) {
                  this.userFacade.dispatch(loadUser({ calledByGuard: true}));
                }
              }),
              filter((user: any) => !!user),
              take(1),
              catchError((error) => {
                console.error('Error in getUser():', error);
                return EMPTY;
              })
            );
          } else {
            return from([true]);
          }
        })
      );
    }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(isPlatformBrowser(this.platformId)) {
      return this.getUser().pipe(
        switchMap(res => of(!!res)),
        catchError(() => of(false))
      )
    }
    else {
      return of(true);
    }
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(isPlatformBrowser(this.platformId)) {
      return this.getUser().pipe(
        switchMap(res => of(!!res)),
        catchError(() => of(false))
      )
    }
    else {
      return of(true);
    }
  }
}

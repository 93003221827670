<a class="reply-toggle" (click)="showForm = !showForm">Reply</a>
@if (showForm) {
  <div>
    <form class="reply-form" [formGroup]="addReplyForm" >
      <div>
        <i class="fa-2x fa-solid fa-user-circle"></i>
      </div>
      <mat-form-field  class="reply-field" appearance="outline">
        <textarea rows="5" class="reply-input" matInput formControlName="replyText" type="text" placeholder="Write a reply..."></textarea>
      </mat-form-field>
    </form>
    <div class="buttons">
      <button mat-flat-button (click)="cancel()" >Cancel</button>
      <button (click)="post()" mat-flat-button color="accent">Reply</button>
    </div>
  </div>
}
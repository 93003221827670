import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { InvoiceService } from '@razroo-zeta/data-services';
import { map } from 'rxjs';

import * as BillingActions from './billing.actions';

@Injectable()
export class BillingEffects {
  loadInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.loadInvoices),
      fetch({
        run: (action) => {
          return this.invoiceService.getInvoices(action.stripeCustomerId).pipe(
            map((invoices: any) => {
              return BillingActions.loadInvoicesSuccess({ invoices });
            })
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return BillingActions.loadInvoicesFailure({ error });
        },
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private invoiceService: InvoiceService
  ) {}
}

import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser } from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';


class LocalStorage implements Storage {
  [name: string]: any;
  readonly length: number;
  clear(): void {}
  getItem(key: string): string | null {return null;}
  key(index: number): string | null {return null;}
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements Storage {
  private storage: Storage;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId) && window?.localStorage) {
        this.storage = localStorage;
    } else {
      this.storage = new LocalStorage();
    };
  }

  [name: string]: any;

  length: number;

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    return this.storage.setItem(key, value);
  }

  updateHistorySub$ = new Subject();

  setCodeGenHistory(userId, orgId, action){
    let dateTime = new Date();
    let date = dateTime.toJSON().slice(0,10)
    let historyKey = `${userId}/${orgId}/home/history`;
    let history:any = this.getItem(historyKey);
    let newHistoryItem = {
      time: moment().format('LT'),
      pathOrgId: action.generateCodeParameters.orgId,
      pathId: action.generateCodeParameters.pathId,
      recipeId: action.generateCodeParameters.recipeId,
      stepId: action.generateCodeParameters.stepId,
      vsCodeInstanceId: action.generateCodeParameters.vsCodeInstanceId,
      parameters: action.generateCodeParameters.parameters
    }
    if(history){
      history = JSON.parse(history)
      if(history[date]){
        history[date].unshift(newHistoryItem)
      } else {
        history[date] = [newHistoryItem]
      }
    } else {
      history = {}
      history[date] = [newHistoryItem];
    }
    this.setItem(historyKey, JSON.stringify(history))
    this.updateHistorySub$.next(true);
  }
}
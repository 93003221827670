import { Pipe, PipeTransform, NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersioningService } from '@razroo-zeta/common-services';

@Pipe({
  name: 'corePathId',
})
export class CorePathIdPipe implements PipeTransform {
  constructor(private versioningService: VersioningService) {}

  transform(pathId: string): string {
    if(!pathId) {
      return '';
    }
    const { name } = this.versioningService.getVersionAndNameString(pathId);

    return name;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [CorePathIdPipe],
  exports: [CorePathIdPipe],
})
export class CorePathIdPipeModule {}

import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { GetAllBooks } from '@razroo-zeta/data-graphql';

@Injectable({
  providedIn: 'root',
})
export class AllBooksService {
  constructor(private apollo: Apollo) {}

  getAllBooks(orgId: string, userId: string, search = '') {
    const query = GetAllBooks;
    const variables = {
      orgId,
      userId,
      search
    }
    const allBooks$ = this.apollo.query({ query, variables });
    return from(allBooks$).pipe(pluck('data', 'books'));
  }
}

<h2 mat-dialog-title color="warn">Hit Daily Ticket Limit!</h2>

<div mat-dialog-content>
   <p>You have hit your daily ticket generation limit.</p>
   <p>Upgrade to a Monthly or Annual Subscription for unlimited AI ticket generations!</p>
</div>

<div mat-dialog-actions align="end">
   <button mat-flat-button color="accent" (click)="upgrade($event, data?.user)">Upgrade</button>
</div>

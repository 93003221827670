<div class="container">
  <h3>Are you sure you want to delete @if (data.itemName) {
    <span>the</span>
    } @if (!data.itemName) {
    <span>this</span>
  } <span class="itemName"> {{ data.itemName}}</span> {{data.itemType}}?</h3>
  <div mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close >CANCEL</button>
    <button mat-flat-button color="warn" mat-dialog-close="delete">DELETE</button>
  </div>
</div>

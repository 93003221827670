import { BookService } from '@razroo-zeta/data-services';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map } from 'rxjs/operators';
import { AllBooksService } from '@razroo-zeta/data-services';
import * as BooksActions from './books.actions';

@Injectable()
export class BooksEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BooksActions.init),
      fetch({
        run: (action) => {
          // Your custom service 'load' logic goes here. For now just return a success action...
          return this.allBooksService
            .getAllBooks(action.orgId, action.userId)
            .pipe(
              map((books: any) =>
                BooksActions.loadBooksSuccess({ books: books })
              )
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return BooksActions.loadBooksFailure({ error });
        },
      })
    )
  );

  loadBook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BooksActions.loadBook),
      fetch({
        run: (action) => {
          return this.bookService
            .getBook(action.selectedID, action.chapter)
            .pipe(
              map((book: any) => BooksActions.loadBookSuccess({ book: book }))
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return BooksActions.loadBookFailure({ error });
        },
      })
    )
  );

  loadToc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BooksActions.loadToc),
      fetch({
        run: (action) => {
          return this.bookService
            .getToc(action.selectedID)
            .pipe(
              map((book: any) => BooksActions.loadTocSuccess({ book: book }))
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return BooksActions.loadTocFailure({ error });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private bookService: BookService,
    private allBooksService: AllBooksService
  ) {}
}

import gql from "graphql-tag";
import { TicketFragment, TicketUpdateFragment, TicketWorkspaceFragment } from "./tickets.fragments";

export const CreateTicket = gql`
    mutation CreateTicket(
        $orgId: String!
        $workspaceId: String!
        $ticketTitle: String!
        $ticketType: TicketType
        $content: String){
            createTicket(
                orgId :$orgId
                workspaceId :$workspaceId
                ticketTitle :$ticketTitle
                ticketType :$ticketType
                content :$content
            ){
                ...ticketFields
            }
        }

    ${TicketFragment}
`;

export const CreateTicketWorkspace = gql`
    mutation CreateTicketWorkspace(
        $orgId: String!
        $workspaceName: String!
        $jiraProject: String
        $description: String
    ) {
        createTicketWorkspace(
            orgId: $orgId
            workspaceName: $workspaceName
            jiraProject: $jiraProject
            description: $description
        ){
            ...ticketWorkspaceFields
        }
    }
    ${TicketWorkspaceFragment}
`;

export const CreateCommunityTicketWorkspace = gql`
    mutation CreateCommunityTicketWorkspace(
        $workspaceName: String!
        $description: String
    ) {
        createCommunityTicketWorkspace(
            workspaceName: $workspaceName
            description: $description
        ){
            ...ticketWorkspaceFields
        }
    }
    ${TicketWorkspaceFragment}
`;

export const PortCommunityDatasetToTicketWorkspace = gql`
    mutation PortCommunityDatasetToTicketWorkspace(
        $communityWorkspaceId: String!
        $orgIdToPortTo: String!
        $workspaceIdToPortTo: String!
    ) {
        portCommunityDatasetToTicketWorkspace(
            communityWorkspaceId: $communityWorkspaceId
            orgIdToPortTo: $orgIdToPortTo
            workspaceIdToPortTo: $workspaceIdToPortTo
        ){
          taskCompleted
        }
    }
`;



export const UpdateTicketWorkspace = gql`
    mutation updateTicketWorkspace($orgId: String!, $workspaceId: String!, $ticketWorkspaceUpdateParams: TicketWorkspaceUpdateParams) {
        updateTicketWorkspace(
            orgId: $orgId,
            workspaceId: $workspaceId,
            ticketWorkspaceUpdateParams: $ticketWorkspaceUpdateParams
        ){
            ...ticketWorkspaceFields
        }
    }
    ${TicketWorkspaceFragment}
`;

export const DeleteTicketWorkspace = gql`
    mutation DeleteTicketWorkspace($orgId: String!, $workspaceId: String!){
        deleteTicketWorkspace(orgId: $orgId, workspaceId: $workspaceId){
            ...ticketWorkspaceFields
        }
    }
    ${TicketWorkspaceFragment}
`;

export const DeleteTicketWorkspaceTicket = gql`
    mutation DeleteTicketWorkspaceTicket(
        $orgId: String!
        $workspaceId: String!
        $ticketNumber: Int
    ){
        deleteTicketWorkspaceTicket(
            orgId: $orgId,
            workspaceId: $workspaceId,
            ticketNumber: $ticketNumber
        ){
            ...ticketFields
        }
    }
    ${TicketFragment}
`;

export const GenerateProductTicketTitlesAndUpload = gql`
    mutation GenerateProductTicketTitlesAndUpload(
        $orgId: String!, $workspaceId: String! ,$userProductMessage: String!
        $currentEpicTickets: String, $parentTicketNumber: Int, $epic: String, $startingTicketNumber: Int,
        $extraDetails: String, $numberOfTickets: Int
    ) {
        generateProductTicketTitlesAndUpload(
            orgId: $orgId,
            workspaceId: $workspaceId,
            userProductMessage: $userProductMessage,
            currentEpicTickets: $currentEpicTickets,
            parentTicketNumber: $parentTicketNumber,
            epic: $epic,
            startingTicketNumber: $startingTicketNumber,
            extraDetails: $extraDetails,
            numberOfTickets: $numberOfTickets
        ){
          ...ticketFields
        }
    }
    ${TicketFragment}
`;

export const ManuallyCreateTicket = gql`
  mutation ManuallyCreateTicket(
    $orgId: String!,
    $workspaceId: String!,
    $boardId: Int!,
    $title: String!,
    $parentTicketNumber: Int
  ) {
    manuallyCreateTicket(
      orgId: $orgId,
      workspaceId: $workspaceId,
      boardId: $boardId,
      title: $title,
      parentTicketNumber: $parentTicketNumber
    ) {
      ...ticketFields
    }
  }
  ${TicketFragment}
`;

export const GenerateEntireProductTicketUsingAiAndUpdate = gql`
  mutation GenerateEntireProductTicketUsingAiAndUpdate(
    $orgId: String!,
    $workspaceId: String!,
    $ticketNumber: Int!
  ) {
    generateEntireProductTicketUsingAiAndUpdate(
      orgId: $orgId,
      workspaceId: $workspaceId,
      ticketNumber: $ticketNumber
    ) {
      ...ticketFields
    }
  }
  ${TicketFragment}
`;

export const GenerateProductTicketTitles = gql`
    mutation GenerateProductTicketTitles(
        $orgId: String!,
        $workspaceId: String!,
        $userProductMessage: String!,
        $currentEpicTickets: String,
        $parentTicketNumber: Int,
        $epic: String,
        $startingTicketNumber: Int
    ) {
        generateProductTicketTitles(
          orgId: $orgId,
          workspaceId: $workspaceId,
          userProductMessage: $userProductMessage,
          currentEpicTickets: $currentEpicTickets,
          parentTicketNumber: $parentTicketNumber
          epic: $epic,
          startingTicketNumber: $startingTicketNumber
        ){
          ...ticketFields
        }
    }
    ${TicketFragment}
`;

export const GenerateFollowUpProductTicketTitles = gql`
    mutation GenerateFollowUpProductTicketTitles(
        $ticketsAlreadyGenerated: String!,
        $startingTicketNumber: Int!
    ) {
        generateFollowUpProductTicketTitles(
          ticketsAlreadyGenerated: $ticketsAlreadyGenerated,
          startingTicketNumber: $startingTicketNumber
        ){
          ...ticketFields
        }
    }
    ${TicketFragment}
`;

export const GenerateEntireProductTicketUsingAiAndUpload = gql`
  mutation generateEntireProductTicketUsingAiAndUpload(
        $orgId: String!, $workspaceId: String!, $userTicketPrompt: String!, 
        $jiraTicketKey: String, $epic: String, $boardId: Int, $followUpResponses: [FollowUpResponseInput], $parentTicketNumber: Int
    ){
        generateEntireProductTicketUsingAiAndUpload(
            orgId: $orgId, workspaceId: $workspaceId, userTicketPrompt: $userTicketPrompt, 
            jiraTicketKey: $jiraTicketKey, epic: $epic, boardId: $boardId, followUpResponses: $followUpResponses, parentTicketNumber: $parentTicketNumber
        ){
            ...ticketFields
        }
  }
  ${TicketFragment}
`;

export const CreateEpicProductTicketUsingAi = gql`
  mutation createEpicProductTicketUsingAi($orgId: String!, $workspaceId: String!, $userTicketPrompt: String!) {
    createEpicProductTicketUsingAi(orgId: $orgId, workspaceId: $workspaceId, userTicketPrompt: $userTicketPrompt){
      ...ticketFields
    }
  }
  ${TicketFragment}
`;

export const ModifyTicketBasedOnTitleUsingAi = gql`
  mutation modifyTicketBasedOnTitleUsingAi($orgId: String!, $workspaceId: String!, $ticketNumber: Int!, 
    $existingDescription: String!, $existingTitle: String!, $updatedTitle: String!) {
      modifyTicketBasedOnTitleUsingAi(
          orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, 
          existingDescription: $existingDescription, existingTitle: $existingTitle, updatedTitle: $updatedTitle
      ){
          ...ticketUpdateFields
      }
  }
  ${TicketUpdateFragment}
`;

export const CreateOnDemandEpic = gql`
  mutation createOnDemandEpic($orgId: String!, $workspaceId: String!, $ticketNumber: Int!, 
    $epicName: String!) {
        createOnDemandEpic(
          orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, 
          epicName: $epicName
      ){
          ...ticketFields
      }
  }
  ${TicketFragment}
`;

export const UnlinkParentTicketFromTicket = gql`
  mutation unlinkParentTicketFromTicket($orgId: String!, $workspaceId: String!, $ticketNumber: Int!) {
      unlinkParentTicketFromTicket(
        orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber
      ){
        ...ticketFields
      }
  }
  ${TicketFragment}
`;

export const GenerateDescriptionForProductTicketAndUpdate = gql`
    mutation GenerateDescriptionForProductTicketAndUpdate(
        $orgId: String!, $workspaceId: String!, $ticketNumber: Int!, 
        $title: String!, $description: String!, $type: String!, $labels: [String!]
    ){
        generateDescriptionForProductTicketAndUpdate(
            orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, 
            title: $title, description: $description, type: $type, labels: $labels
        ){
            description
            ticketNumber
        }
    }
`;

export const EditPartOfTicketDescriptionBasedOnPrompt = gql`
    mutation EditPartOfTicketDescriptionBasedOnPrompt(
        $orgId: String!, $workspaceId: String!, $ticketNumber: Int!, 
        $descriptionToModify: String!, $textToUpdate: String!, $userPrompt: String!
    ){
        editPartOfTicketDescriptionBasedOnPrompt(
            orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, 
            descriptionToModify: $descriptionToModify, textToUpdate: $textToUpdate, userPrompt: $userPrompt
        ){
            condensations {
              text
              condensation
            }
            description
            ticketNumber
        }
    }
`;

export const UpdateTicketWorkspaceTicket = gql`
    mutation UpdateTicketWorkspaceTicket(
        $orgId: String!, $workspaceId: String!, $ticketNumber: Int!, $ticketUpdateParams: TicketWorkspaceTicketUpdateParams
    ){
        updateTicketWorkspaceTicket(
            orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, 
            ticketUpdateParams: $ticketUpdateParams
        ){
          ...ticketFields
        }
    }
    ${TicketFragment}
`;

export const UpdateWorkspaceTicketColumn = gql`
    mutation UpdateWorkspaceTicketColumn(
        $orgId: String!, $workspaceId: String!, $ticketNumber: Int!, $updateTicketColumnParams: UpdateTicketColumnParams
    ){
        updateWorkspaceTicketColumn(
            orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, 
            updateTicketColumnParams: $updateTicketColumnParams
        ){
            description
            ticketNumber
        }
    }
`;

export const UploadJiraApiKeyAndJiraOrg = gql`
  mutation UploadJiraApiKeyAndJiraOrg(
    $orgId: String!, $jiraOrgId: String!, $jiraApiKey: String!
  ){
    uploadJiraApiKeyAndJiraOrg(
      orgId: $orgId, jiraOrgId: $jiraOrgId, jiraApiKey: $jiraApiKey, 
    ){
      hasEncryptedJiraApiKey
      jiraOrgId
    }
  }
`;

export const UploadTicketToJira = gql`
  mutation uploadTicketToJira(
    $orgId: String!
    $workspaceId: String!,
    $ticketNumber: Int!,
    $jiraInstance: String!
    $projectKey: String!
    $issueType: String!
    $summary: String!
    $description: String!
    $labels: [String]
    $estimatedStoryPoints: Int
  ){
    uploadTicketToJira(
        orgId: $orgId,
        workspaceId: $workspaceId,
        ticketNumber: $ticketNumber,
        jiraInstance: $jiraInstance,
        projectKey: $projectKey,
        issueType: $issueType,
        summary: $summary,
        description: $description,
        labels: $labels,
        estimatedStoryPoints: $estimatedStoryPoints
    ){
        id
        key
        self
    }
  }
`;

export const AddTicketDescriptionBackground = gql`
    mutation addTicketDescriptionBackground(
        $orgId: String!
        $workspaceId: String!
        $ticketNumber: Int!
        $description: String!
    ){
        addTicketDescriptionBackground(
            orgId: $orgId
            workspaceId: $workspaceId
            ticketNumber: $ticketNumber
            description: $description
        ){
            condensations {
              text
              condensation
            }
            description
            ticketNumber   
        }
    }

`;

export const AddTicketTechnicalNotes = gql`
    mutation addTicketTechnicalNotes(
        $orgId: String!
        $workspaceId: String!
        $ticketNumber: Int!
        $description: String!
    ){
        addTicketTechnicalNotes(
            orgId: $orgId
            workspaceId: $workspaceId
            ticketNumber: $ticketNumber
            description: $description
        ){
            condensations {
              text
              condensation
            }
            description
            ticketNumber   
        }
    }

`;
export const AddTicketCodeSnippet = gql`
    mutation addTicketCodeSnippet(
        $orgId: String!
        $workspaceId: String!
        $ticketNumber: Int!
        $description: String!
    ){
        addTicketCodeSnippet(
            orgId: $orgId
            workspaceId: $workspaceId
            ticketNumber: $ticketNumber
            description: $description
        ){
          codeSnippet
          ticketNumber   
        }
    }

`;

export const DeleteTitleTicket = gql`
    mutation DeleteTitleTicket(
        $orgId: String!,
        $workspaceId: String!,
        $parentTicketNumber: Int!,
        $ticketNumber: Int!
    ) {
        deleteTitleTicket(
            orgId: $orgId,
            workspaceId: $workspaceId,
            parentTicketNumber: $parentTicketNumber,
            ticketNumber: $ticketNumber
        ) {
            ...ticketFields
        }
    }
    ${TicketFragment}
`;


export const AddTicketScenario = gql`
  mutation addTicketScenario($orgId: String!, $workspaceId: String!, $ticketNumber: Int!, $description: String!, $userPrompt: String!){
    addTicketScenario(orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, description: $description, userPrompt: $userPrompt){
      description
      ticketNumber
      condensations {
        text
        condensation
      }
    }
  }
`;

export const GenerateUserJourneyForProductTicket = gql`
    mutation generateUserJourneyForProductTicket(
        $orgId: String!, 
        $workspaceId: String!, 
        $ticketNumber: Int!, 
        $description: String!
    ){
        generateUserJourneyForProductTicket(
            orgId: $orgId, workspaceId: $workspaceId, 
            ticketNumber: $ticketNumber, description: $description
        ){
            userJourney {
                discovery
                learning
                consideration
                decision
                evaluation
            }
            ticketNumber
        }
    }
`;

export const GenerateAcceptanceCriteriaForProductTicket = gql`
    mutation GenerateAcceptanceCriteriaForProductTicket($orgId: String!, $workspaceId: String!, $ticketNumber: Int!, $description: String!){
        generateAcceptanceCriteriaForProductTicket(orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, description: $description){
            acceptanceCriteria
            ticketNumber
        }
    }

`;

export const GenerateBusinessValueForProductTicket = gql`
    mutation GenerateBusinessValueForProductTicket($orgId: String!, $workspaceId: String!, $ticketNumber: Int!, $description: String!){
        generateBusinessValueForProductTicket(orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, description: $description){
            businessValue {
                strategicAlignment
                customerImpact
                revenueGeneration
                riskManagement
                efficiencyAndProductivity
                competitiveAdvantage
            }
            ticketNumber
        }
    }
`;

export const GenerateEstimatedStoryPointsReasonForProductTicket = gql`
  mutation GenerateEstimatedStoryPointsReasonForProductTicket(
    $orgId: String!, $workspaceId: String!, $ticketNumber: Int!, $description: String!, $estimatedStoryPoints: Int!
  ){
    generateEstimatedStoryPointsReasonForProductTicket(
      orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, description: $description, estimatedStoryPoints: $estimatedStoryPoints
    ){
        estimatedStoryPointsReason {
            complexity {reason, points}
            effort {reason, points}
            uncertainty {reason, points}
        }
        ticketNumber
    }
  }
`;

export const GenerateEstimatedStoryPointsCotReasoning = gql`
  mutation GenerateEstimatedStoryPointsCotReasoning(
    $orgId: String!, 
    $workspaceId: String!, 
    $ticketNumber: Int!, 
    $technologies: [String]
  ) {
    generateEstimatedStoryPointsCotReasoning(
      orgId: $orgId, 
      workspaceId: $workspaceId, 
      ticketNumber: $ticketNumber, 
      technologies: $technologies
    ) {
      ...ticketFields
    }
  }
  ${TicketFragment}
`;

export const UploadProductDocumentToS3 = gql`
  mutation UploadProductDocumentToS3($orgId: String!, $workspaceId: String!, $fileName: String!) {
    uploadProductDocumentToS3(orgId: $orgId, workspaceId: $workspaceId, fileName: $fileName) {
      url
      contentType
    }
  }
`;

export const ExtractTextFromDocumentForEpics = gql`
  mutation ExtractTextFromDocumentForEpics($orgId: String!, $workspaceId: String!, $fileName: String!) {
    extractTextFromDocumentForEpics(orgId: $orgId, workspaceId: $workspaceId, fileName: $fileName) {
      text
      jobId
    }
  }
`;

export const GenerateEpicsFromDocumentsUsingAi = gql`
  mutation GenerateEpicsFromDocumentsUsingAi(
    $orgId: String!,
    $workspaceId: String!,
    $documentText: String!,
    $businessDescription: String!,
    $targetAudience: String!
  ) {
    generateEpicsFromDocumentsUsingAi(
      orgId: $orgId,
      workspaceId: $workspaceId,
      documentText: $documentText,
      businessDescription: $businessDescription,
      targetAudience: $targetAudience
    ) {
        id
        title
        description
        whyMakesSense
        productMarketFitScore
    }
  }
`;

export const CreateTicketWorkspaceBoard = gql`
    mutation CreateTicketWorkspaceBoard($orgId: String!, $workspaceId: String!, $boardName: String!, $startDate: String, $endDate: String, $description: String){
        createTicketWorkspaceBoard(orgId: $orgId, workspaceId: $workspaceId, boardName: $boardName, startDate: $startDate, endDate: $endDate, description: $description){
            boardId
            boardName
            workspaceId
            description
            columns {
                columnId
                title 
                tickets {
                    ...ticketFields
                }
            }
            startDate
            endDate
            dateCreated
            lastUpdated
            userId
            orgId
            board
        }
    }
    ${TicketFragment}
`;

export const UpdateTicketWorkspaceBoard = gql`
    mutation updateTicketWorkspaceBoard($orgId: String!, $workspaceId: String!, $boardId: Int!, $boardUpdateParams: TicketWorkspaceBoardUpdateParams){
        updateTicketWorkspaceBoard(
            orgId: $orgId, workspaceId: $workspaceId, boardId: $boardId, boardUpdateParams: $boardUpdateParams
        ){
            boardId
            boardName
            workspaceId
            description
            columns {
                columnId
                title 
                tickets {
                    ...ticketFields
                }
            }
            startDate
            endDate
            dateCreated
            lastUpdated
            userId
            orgId
            board
        }
    }
    ${TicketFragment}
`;

export const DeleteTicketWorkspaceBoard = gql`
  mutation deleteTicketWorkspaceBoard($orgId: String!, $workspaceId: String!, $boardId: Int!, $newBoardId: Int){
    deleteTicketWorkspaceBoard(orgId: $orgId, workspaceId: $workspaceId, boardId: $boardId, newBoardId: $newBoardId){
        boardId
        boardName
        workspaceId
        description
        columns {
            columnId
            title 
            tickets {
                ...ticketFields
            }
        }
        startDate
        endDate
        dateCreated
        lastUpdated
        userId
        orgId
        board
    }
  }
  ${TicketFragment}
`;

export const GenerateFollowUpQuestionsForProductTicket = gql`
  mutation GenerateFollowUpQuestionsForProductTicket($orgId: String!, $workspaceId: String!, $userPrompt: String!){
    generateFollowUpQuestionsForProductTicket(orgId: $orgId, workspaceId: $workspaceId, userPrompt: $userPrompt) {
      clarificationQuestions {
        question
        score
      }
    }
  }
`;

export const AddUserToTicketWorkspace = gql`
    mutation addUserToTicketWorkspace($orgId: String!, $workspaceId: String!, $newUserId: String!){
        addUserToTicketWorkspace(orgId: $orgId, workspaceId: $workspaceId, newUserId: $newUserId){
            ...ticketWorkspaceFields
        }
    }
    ${TicketWorkspaceFragment}
`;

export const RemoveTicketMedia = gql`
  mutation RemoveTicketMedia($orgId: String!, $workspaceId: String!, $ticketNumber: Int!, $fileName: String!){
    removeTicketMedia(orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, fileName: $fileName){
      ...ticketFields
    }
  }
  ${TicketFragment}
`;

export const MoveCardInKanbanColumn = gql`
  mutation MoveCardInKanbanColumn($orgId: String!, $workspaceId: String!, $boardId: Int!, $moveKanbanCardParams: MoveKanbanCardParamsInputAlt!){
    moveCardInKanbanColumn(orgId: $orgId, workspaceId: $workspaceId, boardId: $boardId, moveKanbanCardParams: $moveKanbanCardParams){
        orgId
        workspaceId
        boardId
    }
  }
`;
export const UploadTicketWorkspaceGraphqlSchema = gql`
  mutation uploadTicketWorkspaceGraphqlSchema($orgId: String!, $workspaceId: String!, $endpointUrl: String!, $accessToken: String){
    uploadTicketWorkspaceGraphqlSchema(orgId: $orgId,  workspaceId: $workspaceId, endpointUrl: $endpointUrl, accessToken: $accessToken){
        result
    }
  }
`;

export const UploadTicketWorkspaceZeplinImages = gql`
  mutation uploadTicketWorkspaceZeplinImages($orgId: String!, $workspaceId: String!, $zeplinProjectId: String!, $accessToken: String){
    uploadTicketWorkspaceZeplinImages(orgId: $orgId, workspaceId: $workspaceId, zeplinProjectId: $zeplinProjectId, accessToken: $accessToken){
      result
    }
  }
`;

export const RemoveUserFromTicketWorkspace = gql`
    mutation removeUserFromTicketWorkspace($orgId: String!, $workspaceId: String!, $userId: String!) {
        removeUserFromTicketWorkspace(orgId: $orgId, workspaceId: $workspaceId, userId: $userId){
            ...ticketWorkspaceFields
        }
    }
    ${TicketWorkspaceFragment}
`;

export const SubscribeTicketWorkspaceToSlackChannel = gql`
    mutation subscribeTicketWorkspaceToSlackChannel($orgId: String!, $workspaceId: String!, $slackChannelId: String!, $slackChannelName: String!){
        subscribeTicketWorkspaceToSlackChannel(orgId: $orgId, workspaceId: $workspaceId, slackChannelId:$slackChannelId, slackChannelName: $slackChannelName){
            ...ticketWorkspaceFields
        }
    }
    ${TicketWorkspaceFragment}
`;

export const ImportJiraTickets = gql`
    mutation importJiraTickets($orgId: String!, $workspaceId: String!, $jiraOrgId: String!, $boardId: Int!){
        importJiraTickets(orgId: $orgId, workspaceId: $workspaceId, jiraOrgId: $jiraOrgId, boardId: $boardId){
            result
        }
    }
`;

export const SubscribeToTicket = gql`
    mutation subscribeToTicket($orgId: String!, $workspaceId: String!, $ticketNumber: Int!){
        subscribeToTicket(orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber){
            ...ticketFields
        }
    }
    ${TicketFragment}
`;

export const UnsubscribeFromTicket = gql`
    mutation unsubscribeFromTicket($orgId: String!, $workspaceId: String!, $ticketNumber: Int!){
        unsubscribeFromTicket(orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber){
            ...ticketFields
        }
    }
    ${TicketFragment}
`;
export const RenameKanbanColumn = gql`
    mutation renameKanbanColumn($orgId: String!, $workspaceId: String!, $columnId: String!, $newColumnName: String!, $boardId: Int){
        renameKanbanColumn(orgId: $orgId, workspaceId: $workspaceId, columnId: $columnId, newColumnName: $newColumnName, boardId: $boardId) {
            orgId
            workspaceId
            boardId
            columns {
                columnId
                title
                ticketOrder
            }
        }
    }
`;

export const CreateKanbanColumn = gql`
    mutation createKanbanColumn($orgId: String!, $workspaceId: String!, $columnName: String!, $boardId: Int){
        createKanbanColumn(orgId: $orgId, workspaceId: $workspaceId, columnName: $columnName, boardId: $boardId){
            orgId
            workspaceId
            boardId
            columns {
                columnId
                title
                ticketOrder
            }
        }
    }
`;

export const DeleteKanbanColumn = gql`
  mutation deleteKanbanColumn($orgId: String!, $workspaceId: String!, $columnId: String!, $newColumnId: String, $boardId: Int) {
    deleteKanbanColumn(orgId: $orgId, workspaceId: $workspaceId, columnId: $columnId, newColumnId: $newColumnId, boardId: $boardId) {
            boardId
            boardName
            workspaceId
            description
            columns {
                columnId
                title 
                tickets {
                    ...ticketFields
                }
            }
            startDate
            endDate
            dateCreated
            lastUpdated
            userId
            orgId
            board
        }
    }
    ${TicketFragment}
`;

export const ReorderKanbanColumns = gql`
    mutation reorderKanbanColumns($orgId: String!, $workspaceId: String!, $boardColumns: [String!]!, $boardId: Int!) {
        reorderKanbanColumns(orgId: $orgId, workspaceId: $workspaceId, boardColumns: $boardColumns, boardId: $boardId) {
            orgId
            workspaceId
            boardId
            columns {
                columnId
                title
                ticketOrder
            }
        }
    }
`;

export const UpdateTicketOrder = gql`
  mutation updateTicketOrder($orgId: String!, $workspaceId: String!, $ticketsOrder: [Int]!, $boardId: Int) {
    updateTicketOrder(orgId: $orgId, workspaceId: $workspaceId, ticketsOrder: $ticketsOrder, boardId: $boardId) {
      orgId
      workspaceId
      boardId
      columns {
        columnId
        title
        ticketOrder
      }
    }
  }
`;

export const SuggestNewEpics = gql`
  mutation suggestNewEpics($orgId: String!, $workspaceId: String!, $epicTitles: [String!]!, $businessDescription: String!, $targetAudience: String!) {
    suggestNewEpics(orgId: $orgId, workspaceId: $workspaceId, epicTitles: $epicTitles, businessDescription: $businessDescription, targetAudience: $targetAudience) {
      title
      description
      whyMakesSense
      productMarketFitScore
      id
    }
  }
`;

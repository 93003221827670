import { ActiveProject, GenerateCodeParameters, GithubRepo, OrganizationUser, ProjectParams, Template, VsCodeInstance } from '@razroo-zeta/data-models';
import { createAction, props } from '@ngrx/store';
import { GenerateCodeEntity } from './generate-code.models';

export const loadGenerateCode = createAction(
  '[GenerateCode] Load GenerateCode',
  props<{ generateCodeParameters: GenerateCodeParameters, stepper: Template[], template: Template, vsCodeInstances?: VsCodeInstance[], projectParams?: ProjectParams}>()
);

export const loadGenerateCodeSuccess = createAction(
  '[GenerateCode] Load GenerateCode Success',
  props<{ generateCode: GenerateCodeEntity[], stepper: Template[], template: Template, userId?: string, orgId?: string, vsCodeInstances?: VsCodeInstance[], 
    codeGenPlatform?: 'github' | 'vscode' | undefined, projectParams?: ProjectParams, parameters?: any, user?: OrganizationUser, githubRepo?: GithubRepo, newWorkspace?: boolean}>()
);

export const loadGenerateCodeFailure = createAction(
  '[GenerateCode] Load GenerateCode Failure',
  props<{ error: any }>()
);

export const updateActiveProject = createAction(
  '[Generate Code] updateActiveProject',
  props<{ activeProject: ActiveProject, githubRepos?: GithubRepo[] }>()
)

export const runUnitTests = createAction(
  '[GenerateCode] Run Unit Tests',
  props<{ template: Template, userId: string, orgId: string}>()
);

export const runUnitTestsSuccess = createAction(
  '[GenerateCode] Run Unit Tests Success',
  props<{ template: Template}>()
);

export const runUnitTestsFailure = createAction(
  '[GenerateCode] Run Unit Tests Failure',
  props<{ error: any}>()
);

export const runIntegrationTests = createAction(
  '[GenerateCode] Run Integration Tests',
  props<{ template: Template, userId: string, orgId: string}>()
);

export const runIntegrationTestsSuccess = createAction(
  '[GenerateCode] Run Integration Tests Success',
  props<{ template: Template}>()
);

export const runIntegrationTestsFailure = createAction(
  '[GenerateCode] Run Integration Tests Failure',
  props<{ error: any}>()
);

export const runPreviewGeneration = createAction(
  '[GenerateCode/API] Run Preview Generation',
  props<{template: Template, userId: string, orgId: string}>()
);

export const runPreviewGenerationSuccess = createAction(
  '[GenerateCode/API] Run Preview Generation Success',
  props<{ template: Template }>()
);

export const runPreviewGenerationFailure = createAction(
  '[GenerateCode/API] Run Preview Generation Failure',
  props<{ error: any }>() 
);


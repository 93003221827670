import { FlatFolderFile } from './../../../../../common-services/src/lib/file-tree/file-tree.service';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TemplateInputParameter, VsCodeAuthInfo, VsCodeInstance } from '@razroo-zeta/data-models';
import * as VscodeActions from '../actions/vscode.actions';

export const vscodeFeatureKey = 'vscode';

export interface State extends EntityState<VsCodeInstance> {
  selectedId?: string | number;
  loaded: boolean;
  error?: string | null;
  parameters?: TemplateInputParameter[];
  filesToGenerate?: any,
  fileTree?: string[],
  directories?: FlatFolderFile[],
  directoriesLoaded: boolean,
  vsCodeDirectories: VsCodeDirectoriesState
}

export function selectVscodeId(vscodeInstance: VsCodeInstance): string {
  return vscodeInstance.vsCodeInstanceId;
}

export const vscodeAdapter: EntityAdapter<any> =
  createEntityAdapter<any>({
    selectId: selectVscodeId
  });

export interface VsCodeDirectoriesEntity extends FlatFolderFile {}

export function selectFileId(flatFolderFile: FlatFolderFile): string {
  //path will be used as the key for each file type
  return flatFolderFile.id;
}

export const vsCodeDirectoriesAdapter: EntityAdapter<VsCodeDirectoriesEntity> =
  createEntityAdapter<VsCodeDirectoriesEntity>({
    selectId: selectFileId
  });

export interface VsCodeDirectoriesState extends EntityState<VsCodeDirectoriesEntity> {
  selectedId?: string | number; // which Organizations record has been selected
  loaded?: boolean; // has the Organizations list been loaded
  error?: string | null; // last known error (if any)
}

export const vsCodeDirectoriesInitialState: VsCodeDirectoriesState = vsCodeDirectoriesAdapter.getInitialState({
  loaded: false,
  selectedId: '',
  error: null
});

export const initialState: State = vscodeAdapter.getInitialState({
  // set initial required properties
  selectedId: '',
  loaded: false,
  parameters: [],
  filesToGenerate: [],
  folderFileTree: [],
  vsCodeDirectories: vsCodeDirectoriesInitialState,
  directoriesLoaded: false
});

export const reducer = createReducer(
  initialState,
  on(VscodeActions.directoriesLoading, (state) => ({
    ...state,
    vsCodeDirectories: {
      ...state.vsCodeDirectories,
      loaded: false
    }
  })),
  on(VscodeActions.loadParameters, (state, {parameters}) => ({
    ...state,
    loaded: true,
    error: null,
    parameters
  })),
  on(VscodeActions.updateParameterValue, (state, {parameterName, newValue}) => ({
    ...state, 
    loaded: true,
    error: null,
    parameters: state.parameters?.map(param => {
      if(param.name === parameterName){
        return {...param, defaultValue: newValue}
      } else return param
    })
  })),
  on(VscodeActions.loadVscodeInstances, (state) => ({
     ...state,
     loaded: false,
     error: null
  })),
  on(VscodeActions.loadFilesToGenerateFolderFileTree, (state, {filesToGenerate}) => ({
    ...state,
    filesToGenerate: filesToGenerate
  })),
  on(VscodeActions.updateVscodeInstanceParameter, (state, {parameter, newInputValue}) => ({
    ...state,
    parameters: state.parameters && state.parameters.map(param => param.name === parameter.name ? {...parameter, defaultValue: newInputValue} : param)
  })),
  on(VscodeActions.loadVscodeFolderTreeSuccess, (state, {generateCodeFlatFolderFileTree}) => ({
    ...state,
    vsCodeDirectories: vsCodeDirectoriesAdapter.setAll(generateCodeFlatFolderFileTree, {
      ...state.vsCodeDirectories,
      selectedId: generateCodeFlatFolderFileTree ? generateCodeFlatFolderFileTree[0].id : '',
      loaded: true
    })
  })),
  on(VscodeActions.updateVscodeDirectories, (state, {directories}) => ({
    ...state,
    directories: directories
  })),
  on(VscodeActions.toggleVsCodeFolder, (state, {folder}) => ({
    ...state,
    vsCodeDirectories: vsCodeDirectoriesAdapter.upsertOne({...folder, isExpanded: !folder.isExpanded}, {
      ...state.vsCodeDirectories,
    })
  })),
  on(VscodeActions.loadVscodeInstancesSuccess, (state, { vsCodeInstances }) =>
    vscodeAdapter.setAll(vsCodeInstances, {
      ...state,
      loaded: true,
      selectedId: vsCodeInstances[0]?.vsCodeInstanceId
    })
  ),
  on(VscodeActions.loadVscodeInstanceSuccess, (state, { vsCodeInstance }) =>
    vscodeAdapter.upsertOne(vsCodeInstance, {
      ...state,
      loaded: true,
      selectedId: vsCodeInstance.vsCodeInstanceId
    })
  ),

);

import { gql } from 'graphql-tag';

export const RazrooProductFragment = gql`
  fragment razrooProductFields on RazrooProduct {
    id
    name
    priceId
    planName
    nextBillDate
    billingCycle
    subscriberCount
  }
  
`;

import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { ParentPath, Recipe } from '@razroo-zeta/data-models';

import * as PathActions from './path.actions';
import { PathEntity } from './path.models';
import * as PathFeature from './path.reducer';
import * as PathSelectors from './path.selectors';

@Injectable()
export class PathFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(PathSelectors.getPathLoaded));
  projectPathsLoaded$ = this.store.pipe(select(PathSelectors.getProjectPathsLoaded));
  allPaths$ = this.store.pipe(select(PathSelectors.getAllPaths));
  projectPaths$ = this.store.pipe(select(PathSelectors.getProjectPaths));
  selectedPath$ = this.store.pipe(select(PathSelectors.getSelected));
  secondaryPaths$ = this.store.pipe(select(PathSelectors.getAllSecondaryPaths));
  pathVersions$ = this.store.pipe(select(PathSelectors.getAllPathVersions));
  pathVersionsLoaded$ = this.store.pipe(select(PathSelectors.getPathVersionsLoaded));
  pathPermissions$ = this.store.pipe(select(PathSelectors.getPathPermissions));

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */

  loadAllPaths(orgId: string){
    this.store.dispatch(PathActions.loadAllPaths({orgId}));
  }

  loadProjectPaths(orgId: string){
    this.store.dispatch(PathActions.loadProjectPaths({orgId}));
  }

  loadPath(userOrgId: string, pathId: string, orgId: string) {
    this.store.dispatch(PathActions.loadPath({ userOrgId, pathId, orgId }));
  }

  loadPathTemplate(orgId: string, pathId: string) {
    this.store.dispatch(PathActions.loadPathTemplate({ pathId, orgId }));
  }

  loadChatPathTemplate(orgId: string, pathId: string) {
    this.store.dispatch(PathActions.loadChatPathTemplate({ pathId, orgId }));
  }

  loadSecondaryPath(userOrgId: string, pathId: string, orgId: string, parentPath: ParentPath) {
    this.store.dispatch(PathActions.loadSecondaryPath({ userOrgId, pathId, orgId, parentPath }));
  }

  createPath(orgId: string, userId: string, path: string, description: string, baseCommunityPath: string | null = null, privacy: string, languageVersion: string, marketplace: boolean, minimumPrice: string){
    this.store.dispatch(PathActions.createPath({ orgId, userId, path, description, baseCommunityPath, privacy, languageVersion, marketplace, minimumPrice}));
  }
  createProjectSuccess(project: PathEntity){
    this.store.dispatch(PathActions.createProjectSuccess({project}))
  }
  searchPath(userOrgId: string, orgId: string, pathId: string, search: string, start: number, size: number){
    this.store.dispatch(PathActions.searchPath({ userOrgId, orgId, pathId, search, start, size}));
  }
  
  deleteRecipe(orgId: string, pathId: string, recipeId: string, path?: PathEntity){
    this.store.dispatch(PathActions.deleteRecipe({orgId, pathId, recipeId, path}))
  }
  archiveRecipe(orgId: string, pathId: string, recipeId: string, path: PathEntity){
    this.store.dispatch(PathActions.archiveRecipe({orgId, pathId, recipeId, path}))
  }

  archivePath(orgId: string, pathId: string){
    this.store.dispatch(PathActions.archivePath({orgId, pathId}))
  }

  getSecondaryPaths(orgId: string, pathId: string){
    this.store.dispatch(PathActions.getSecondaryPaths({orgId, pathId}))
  }

  addSecondaryPath(orgId: string,
    userId: string,
    pathId: string,
    secondaryPathId: string,
    secondaryPathOrgId: string,
  ){
      this.store.dispatch(PathActions.addSecondaryPath({orgId,
        userId,
        pathId,
        secondaryPathId,
        secondaryPathOrgId
      }))

  }

  removeSecondaryPath(
    orgId: string,
    userId: string,
    pathId: string,
    secondaryPathId: string,
    secondaryPathOrgId: string
    ){
      this.store.dispatch(PathActions.removeSecondaryPath({orgId, userId, pathId, secondaryPathId, secondaryPathOrgId}))

  }

  updatePath(orgId: string, pathId: string, pathUpdateParams: any){
      this.store.dispatch(PathActions.updatePath({orgId, pathId, pathUpdateParams}))
  }
  updatePathSuccess(path: PathEntity){
    this.store.dispatch(PathActions.updatePathSuccess({path}))
  }

  getPathVersions(orgId: string, pathId: string){
    this.store.dispatch(PathActions.getPathVersions({orgId, pathId}))
  }

  addPathToDropdown(path: any){
    this.store.dispatch(PathActions.addPathToDropdown({path}))
  }

  toggleRecipeAsBatch(recipe: Recipe) {
    this.store.dispatch(PathActions.toggleRecipeAsBatch({recipe}))
  }

  addMarketplacePathToCommunity(userOrgId: string, communityPathId: string, 
    marketplaceOrgId: string, marketplacePathId: string, marketplacePathDescription: string){
      this.store.dispatch(PathActions.addMarketplacePathToCommunity({userOrgId, communityPathId, 
        marketplaceOrgId, marketplacePathId, marketplacePathDescription}))
  }

  removeMarketplacePathFromCommunityPath(userOrgId: string, communityPathId: string, 
    marketplaceOrgId: string, marketplacePathId: string){
      this.store.dispatch(PathActions.removeMarketplacePathFromCommunityPath({userOrgId, communityPathId, 
        marketplaceOrgId, marketplacePathId}))
  }
  
  reorderCommunityMarketplacePaths(userOrgId: string, communityPathId: string,
    marketplaceOrgId: string, marketplacePathId: string, previousMarketplaceOrgId?: string, previousMarketplacePathId?: string){
      this.store.dispatch(PathActions.reorderCommunityMarketplacePaths({userOrgId, communityPathId,
        marketplaceOrgId, marketplacePathId, previousMarketplaceOrgId, previousMarketplacePathId}));
  }
}

import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, OnInit, NgModule, Input, ViewChild } from '@angular/core';
import { Template } from '@razroo-zeta/data-models';
import { StepIndexModule } from './pipes/step-index/step-index.pipe';

@Component({
  selector: 'razroo-zeta-stepper-scroller',
  templateUrl: './stepper-scroller.component.html',
  styleUrls: ['./stepper-scroller.component.scss'],
})
export class StepperScrollerComponent implements OnInit {
  @ViewChild('viewport') viewport: CdkVirtualScrollViewport;
  @Input() template: Template;

  constructor() { } 
  ngOnInit(): void {}

  trackByFn(index, item) {
    return item.id; // or item.id
  }
}
@NgModule({
  imports: [CommonModule, ScrollingModule, StepIndexModule],
  declarations: [StepperScrollerComponent],
  exports: [StepperScrollerComponent],
})
export class StepperScrollerComponentModule {}
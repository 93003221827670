import { GenerateCodeParameters, GithubRepo, OrganizationUser, ProjectParams, Template, VsCodeInstance } from '@razroo-zeta/data-models';
import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromGenerateCode from './generate-code.reducer';
import * as GenerateCodeSelectors from './generate-code.selectors';
import { loadGenerateCode, runIntegrationTests, runUnitTests, runPreviewGeneration, loadGenerateCodeSuccess } from './generate-code.actions';
import { GenerateCodeEntity } from './generate-code.models';

@Injectable()
export class GenerateCodeFacade {
  loaded$ = this.store.pipe(
    select(GenerateCodeSelectors.getGenerateCodeLoaded)
  );
  runUnitTestsLoaded$ = this.store.pipe(
    select(GenerateCodeSelectors.getRunUnitTestsLoaded)
  );
  runIntegrationTestsLoaded$ = this.store.pipe(
    select(GenerateCodeSelectors.getRunIntegrationTestsLoaded)
  );
  allGenerateCode$ = this.store.pipe(
    select(GenerateCodeSelectors.getAllGenerateCode)
  );
  selectedGenerateCode$ = this.store.pipe(
    select(GenerateCodeSelectors.getSelected)
  );

  generateCodeEntities$ = this.store.pipe(
    select(GenerateCodeSelectors.getGenerateCodeEntities)
  );

  constructor(
    private store: Store<fromGenerateCode.GenerateCodePartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  generateCode(generateCodeParameters: GenerateCodeParameters, stepper: Template[], template: Template, vsCodeInstances?: VsCodeInstance[], projectParams?: ProjectParams) {
    this.store.dispatch(loadGenerateCode({ generateCodeParameters, stepper, template, vsCodeInstances, projectParams }));
  }
  
  loadGenerateCodeSuccess(generateCode: GenerateCodeEntity[], stepper: Template[], template: Template, userId?: string, orgId?: string, vsCodeInstances?: VsCodeInstance[], codeGenPlatform?: 'github' | 'vscode' | undefined, projectParams?: ProjectParams, parameters?: any, user?: OrganizationUser, githubRepo?: GithubRepo, newWorkspace?: boolean) {
    this.store.dispatch(loadGenerateCodeSuccess({ generateCode, stepper, template, userId, orgId, vsCodeInstances, codeGenPlatform, projectParams, parameters, user, githubRepo, newWorkspace }));
  }

  runUnitTests(template: Template, userId: string, orgId: string) {
    this.store.dispatch(runUnitTests({ template, userId, orgId }));
  }

  runIntegrationTests(template: Template, userId: string, orgId: string) {
    this.store.dispatch(runIntegrationTests({ template, userId, orgId }));
  }

  runPreviewGeneration(template: Template, userId: string, orgId: string) {
    this.store.dispatch(runPreviewGeneration({ template, userId, orgId }));
  }
}

import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of, first, map } from 'rxjs';
import * as CodeGenerationHistoryActions from './code-generation-history.actions';
import { GenerateCodeService } from '@razroo-zeta/data-services';
import { fetch } from '@ngrx/router-store/data-persistence';

@Injectable()
export class CodeGenerationHistoryEffects {
  private actions$ = inject(Actions);
  constructor(private generateCodeService: GenerateCodeService){}
  init$ = createEffect(() => 
    this.actions$.pipe(
      ofType(CodeGenerationHistoryActions.initCodeGenerationHistory),
      fetch({
        run: (action)=> {
          return this.generateCodeService.getCodeGenerationHistory(action.orgId, action.page, action.size).pipe(first(), map((res:any) => 
            CodeGenerationHistoryActions.loadCodeGenerationHistorySuccess({
              codeGenerationHistory: res,
              page: action.page,
              size: action.size
            })
          ))
        },
        onError: (action, error) => {
          console.error('Error', error);
          return of(
            CodeGenerationHistoryActions.loadCodeGenerationHistoryFailure({
              error,
            })
          );
        }
      })
    )
  )
}
            
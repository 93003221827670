import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { GetTemplatesSearchResults } from '@razroo-zeta/data-graphql';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  constructor(private apollo: Apollo) { }

  getTemplatesSearchResults(search: string, path: string, orgId?: string) {
    const query = GetTemplatesSearchResults;
    const variables = {
      search: search,
      path: path,
      orgId: orgId
    };

    const templates$ = this.apollo.query({ query, variables });
    return from(templates$).pipe(pluck('data', 'templates'));
  }
}

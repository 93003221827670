import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'razroo-zeta-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss'],
})
export class CreateUserDialogComponent implements OnInit {
  form = this.fb.group({
    firstName: ['', [Validators.required, Validators.pattern("^([ \u00c0-\u01ffa-zA-Z'\-]){2,}$")]],
    lastName: ['', [Validators.required, Validators.pattern("^([ \u00c0-\u01ffa-zA-Z'\-]){2,}$")]],
  });

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateUserDialogComponent>,
  ) {}

  ngOnInit(): void {}

  submitName() {
    if (this.form.valid) {
      this.dialogRef.close({
        firstName: this.form.value.firstName.trim(),
        lastName: this.form.value.lastName.trim(),
      });
    } else {
      console.log('invalid');
    }
  }
}

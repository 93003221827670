import { Injectable, inject } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as CodeGenerationHistoryActions from './code-generation-history.actions';
import * as CodeGenerationHistorySelectors from './code-generation-history.selectors';

@Injectable({
  providedIn: 'root'
})
export class CodeGenerationHistoryFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(
    select(CodeGenerationHistorySelectors.selectCodeGenerationHistoryLoaded)
  );
  allCodeGenerationHistory$ = this.store.pipe(
    select(CodeGenerationHistorySelectors.selectAllCodeGenerationHistory)
  );
  selectedCodeGenerationHistory$ = this.store.pipe(
    select(CodeGenerationHistorySelectors.selectEntity)
  );
  
  historyTimelinePage$ = this.store.pipe
    (select(CodeGenerationHistorySelectors.selectHistoryTimelinePage)
  );
  
  historyTimelineSize$ = this.store.pipe
    (select(CodeGenerationHistorySelectors.selectHistoryTimelineSize)
  );

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init(orgId: string, page = 1, size = 20) {
    this.store.dispatch(
      CodeGenerationHistoryActions.initCodeGenerationHistory({orgId, page, size})
    );
  }

  addGenerationToHistory(codeGeneration:any){
    this.store.dispatch(CodeGenerationHistoryActions.addGenerationToHistory({codeGeneration}))
  }

}

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { CommentsService } from '@razroo-zeta/data-services';
import { map } from 'rxjs/operators';
import * as CommentsActions from './comments.actions';
import { CommentsEntity } from './comments.models';

@Injectable()
export class CommentsEffects {
  loadComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.loadComments),
      fetch({
        run: (action) => {
          return this.commentsService
            .getAllComments(
              action.pageUrl,
              action.componentName,
              action.currentUserOrgId,
              action.fileName
            )
            .pipe(
              map((comments: any) => {
                return CommentsActions.loadCommentsSuccess({
                  comments: comments,
                });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return CommentsActions.loadCommentsFailure({ error });
        },
      })
    )
  );

  createComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.createComment),
      fetch({
        run: (action) => {
          return this.commentsService
            .createComment(action.createCommentParams)
            .pipe(
              map((comment: any) =>
                CommentsActions.createCommentSuccess({
                  comment: { ...comment, picture: action.picture },
                })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
        },
      })
    )
  );

  addResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.addResponse),
      fetch({
        run: (action) => {
          return this.commentsService.addResponse(action.response).pipe(
            map((response: any) =>
              CommentsActions.addResponseSuccess({
                comment: action.comment,
                response: response,
              })
            )
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
        },
      })
    )
  );

  upvoteComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.upvoteComment),
      fetch({
        run: (action) => {
          return this.commentsService
            .upvoteComment(action.commentId, action.userId, action.userOrgId)
            .pipe(
              map((comment: any) =>
                CommentsActions.voteSuccess({ comment: comment })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
        },
      })
    )
  );

  downvoteComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.downvoteComment),
      fetch({
        run: (action) => {
          return this.commentsService
            .downvoteComment(action.commentId, action.userId, action.userOrgId)
            .pipe(
              map((comment: any) =>
                CommentsActions.voteSuccess({ comment: comment })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
        },
      })
    )
  );

  deleteComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.deleteComment),
      fetch({
        run: (action) => {
          return this.commentsService
            .deleteComment(action.userId, action.userOrgId, action.commentId)
            .pipe(
              map((comment: any) =>
                CommentsActions.deleteCommentSuccess({ comment: comment })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
        },
      })
    )
  );

  deleteCommentResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.deleteCommentResponse),
      fetch({
        run: (action) => {
          return this.commentsService
            .deleteCommentResponse(
              action.userId,
              action.userOrgId,
              action.commentId,
              action.responseIndex
            )
            .pipe(
              map((response: any) =>
                CommentsActions.deleteCommentResponseSuccess({
                  response: response,
                  commentId: action.commentId,
                })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
        },
      })
    )
  );
  updateComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.updateComment),
      fetch({
        run: (action) => {
          return this.commentsService
            .updateComment(
              action.userId,
              action.userOrgId,
              action.commentId,
              action.newComment
            )
            .pipe(
              map((comment: any) =>
                CommentsActions.updateCommentSuccess({ comment: comment })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
        },
      })
    )
  );
  updateCommentResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.updateCommentResponse),
      fetch({
        run: (action) => {
          return this.commentsService
            .updateCommentResponse(
              action.userId,
              action.userOrgId,
              action.commentId,
              action.responseIndex,
              action.newComment
            )
            .pipe(
              map((response: any) =>
                CommentsActions.updateCommentResponseSuccess({
                  response: response,
                  commentId: action.commentId,
                })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
        },
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private commentsService: CommentsService
  ) {}
}

import gql from 'graphql-tag';
import { GenerateCodeFragment } from './generateCode.fragments';

export const GetGenerateCode = gql`
  query currentUser($generateCodeParameters: GenerateCodeInput) {
    generateCode(generateCodeParameters: $generateCodeParameters) {
      ...generateCodeFields
    }
  }
  ${GenerateCodeFragment}
`;

export const GetCodeGenerationHistory = gql`
  query getCodeGenerationHistory(
    $orgId: String!
    $page: Int
    $size: Int
  ){
    getCodeGenerationHistory(
      orgId: $orgId
      page: $page
      size: $size
    ){
      generateId
      analyticType
      parameters
      pathId
      pathOrgId
      projectName
      recipeId
      stepId
      template
      timestamp
      title
      type
      userId
      userOrgId
    }
  }
`

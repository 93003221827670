import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GenericDeleteDialogComponent } from 'libs/common/ui/src/lib/generic-delete-dialog/generic-delete-dialog.component';
import { CommentsFacade } from '@razroo-zeta/data-access/comments-store';
import { first } from 'rxjs/operators';
import { TemplatesFacade } from '@razroo-zeta/data-access';
import { OrganizationUser } from '@razroo-zeta/data-models';
import { AuthService } from '@razroo-zeta/data-services';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'razroo-zeta-comments-side-nav',
  templateUrl: './comments-side-nav.component.html',
  styleUrls: ['./comments-side-nav.component.scss'],
})
export class CommentsSideNavComponent implements OnDestroy {
  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private commentsFacade: CommentsFacade,
    private dialog: MatDialog,
    private templatesFacade: TemplatesFacade,
    private router: Router,
    private location: Location
  ) {}
  pageUrl;
  currentUser;
  componentName;
  textCommentedOn;
  ticketParams;
  templateOrgId;
  pageTitle;
  fileName: string | undefined;
  @Output() close = new EventEmitter();
  @Input() set commentsParams(params){
    if(params){
      this.pageUrl = params.pageUrl;
      this.ticketParams = params.ticketParams;
      this.templateOrgId = params?.orgId ? params.orgId : this.pageUrl?.split('/')[1];
      this.currentUser = params.currentUser;
      this.componentName = params.componentName;
      this.textCommentedOn = params.textCommentedOn;
      this.fileName = params.fileName;
      this.pageTitle = params.pageTitle;
      if(!params?.commentsLoaded){
        this.commentsFacade.loadComments(params.pageUrl, params.componentName, this.templateOrgId)
      }
    }
  }
  @Input() orgId: string;
  @Input() set commentsOpen(value: boolean){
    this.toggleUrl(value);
  }
  
  toggleUrl(commentsView: boolean){
    if(commentsView){
      const url = this.router.createUrlTree([], {queryParams: {commentsView: true}}).toString();
      this.location.replaceState(url);
    } else {
      const url = this.router.createUrlTree([], {queryParams: {}}).toString();
      this.location.replaceState(url);
    }
  }

  ngOnDestroy(): void {
    this.toggleUrl(false);
  }
  
  closeNav(){
    this.close.emit()
  }

  comments$ = this.commentsFacade.allComments$

  addCommentForm = this.fb.group({
    commentText: ['', Validators.required]
  });

  // addCommentForm = this.fb.group({
  //   commentText: ['', Validators.required],
  //   commentType: ['Question', Validators.required]
  // });

  upvote(comment){
    this.commentsFacade.upvoteComment(comment.commentId, this.currentUser.userId, comment.userOrgId)
  }
  downvote(comment){
    this.commentsFacade.downvoteComment(comment.commentId, this.currentUser.userId, comment.userOrgId)
  }
  postComment(user: OrganizationUser) {
    if(!user) {
      this.authService.signUpWithRedirect();
    }
    if(this.addCommentForm.valid){
      if(this.componentName === 'Ticket'){
        this.commentsFacade.createComment({
          comment: this.addCommentForm.value.commentText,
          commentType: 'Question',
          componentName: this.componentName,
          firstName: this.currentUser.firstName,
          lastName: this.currentUser.lastName,
          pageUrl: this.pageUrl,
          ticketParams: this.ticketParams,
          pageTitle: this.pageTitle,
          textCommentedOn: this.textCommentedOn,
          userOrgId: this.currentUser.activeOrgId,
          templateOrgId: this.templateOrgId,
          userId: this.currentUser.userId
        }, this.currentUser.picture)
        this.addCommentForm.reset();
        this.textCommentedOn = undefined;
      } else {
        this.templatesFacade.selectedTemplate$.pipe(first(res => !!res)).subscribe(res => {
          const languageVersion = res?.pathId.split('-').pop()
          this.commentsFacade.createComment({
            comment: this.addCommentForm.value.commentText,
            commentType: 'Question',
            componentName: this.componentName,
            firstName: this.currentUser.firstName,
            lastName: this.currentUser.lastName,
            pageUrl: this.pageUrl,
            textCommentedOn: this.textCommentedOn,
            userOrgId: this.currentUser.activeOrgId,
            templateOrgId: this.templateOrgId,
            userId: this.currentUser.userId,
            languageVersion: languageVersion,
            fileName: this.textCommentedOn ? this.fileName : undefined
          }, this.currentUser.picture)
          this.addCommentForm.reset();
          this.textCommentedOn = undefined;
        })
      }
    }
  }
  postReply(replyText, comment, userOrgId: string){
    this.commentsFacade.addResponse(comment, {
      comment: replyText,
      commentId: comment.commentId,
      firstName: this.currentUser.firstName,
      lastName: this.currentUser.lastName,
      userId: this.currentUser.userId,
      userOrgId: userOrgId
    })
  }

  deleteCommentOrReply(item: any, userOrgId: string, commentId?: string){
    if(!commentId){
      const dialogRef = this.dialog.open(GenericDeleteDialogComponent, {
        data: {
          itemName: '',
          itemType: 'comment'
        }
      })

      dialogRef.afterClosed().pipe(first()).subscribe(res => {
        if(res){
          this.commentsFacade.deleteComment(item.commentId, this.currentUser.userId, userOrgId);
        }
      })
    } else {
      const dialogRef = this.dialog.open(GenericDeleteDialogComponent, {
        data: {
          itemName: '',
          itemType: 'reply'
        }
      })

      dialogRef.afterClosed().pipe(first()).subscribe(res => {
        if(res){
          this.commentsFacade.deleteCommentResponse(commentId, this.currentUser.userId, userOrgId, item.index)
        }
      })
    }
  }

  editComment(comment, event, commentId?: string){
    if(commentId){
      this.commentsFacade.updateCommentResponse(comment.userId, comment.userOrgId, commentId, comment.index, event)
    } else {
      this.commentsFacade.updateComment(comment.userId, comment.userOrgId, comment.commentId, event)
    }
  }

}

import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromUser from './user.reducer';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';

@Injectable()
export class UserFacade {
  loaded$ = this.store.pipe(select(UserSelectors.getUserLoaded));
  allUser$ = this.store.pipe(select(UserSelectors.getAllUser));
  selectedUser$ = this.store.pipe(select(UserSelectors.getSelected));
  currentUser$ = this.store.pipe(select(UserSelectors.getSelected));
  currentUserID$ = this.store.pipe(select(UserSelectors.getSelectedId));
  currentActiveStep$ = this.store.pipe(select(UserSelectors.getActiveStep));
  activeProject$ = this.store.pipe(select(UserSelectors.getActiveProject));
  // uses activeOrgId to get the current org id
  currentOrgId$ = this.store.pipe(select(UserSelectors.getSelectedActiveOrgId));
  currentUserRoles$ = this.store.pipe(
    select(UserSelectors.getCurrentUserRoles)
  );
  userPaid$ = this.store.pipe(
    select(UserSelectors.getSelectedUserPaid)
  );
  isDeveloperOrBetaUser$ = this.store.pipe(
    select(UserSelectors.isDeveloperOrBetaUser)
  );
  superUserRole$ = this.store.pipe(select(UserSelectors.getSuperUserRole));
  communityArchitectRole$ = this.store.pipe(select(UserSelectors.getCommunityArchitectRole))
  currentUserOrgs$ = this.store.pipe(
    select(UserSelectors.getCurrentUserOrganizations)
  );
  currentUserCertifcations$ = this.store.pipe(select(UserSelectors.getCertifications));

  constructor(private store: Store<fromUser.UserPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  loadUserOrganizations(userId: string) {
    this.store.dispatch(UserActions.loadUserOrganizations({ userId }));
  }

  updateProfilePic(newUrl: string){
    this.store.dispatch(UserActions.updateProfilePic({newUrl}))
  }
  deleteProfilePic(userId:string){
    this.store.dispatch(UserActions.deleteProfilePic({userId}))
  }
}

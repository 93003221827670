export const GenerateCodeFragment = `
  fragment generateCodeFields on ScaffoldCode {
    downloadUrl
    template {
      id
      title
      type
    }
  }
`;

// this fragment will determine which data is passed to the vscode
// generate code sub
export const GenerateVsCodeDownloadCodeFragment = `
  fragment generateVsCodeDownloadCodeFields on GenerateCodeDownloadOutput {
    downloadUrl
    generateId
    template {
      id
      title
      type
      recipeId
      pathId
      orgId
      updates
      fileTree
      parameters {
        defaultValue
        description
        inputType
        name
        paramType
        type
        optionalTypes {
          name
          selected
        }
      }
      baseCommunityPath
    }
    runUnitTests
    runIntegrationTests
    runPreviewGeneration
    vsCodeInstanceId
    parameters
  }
`;

export const CodeGenerationFragment = `
  fragment codeGenerationFields on CodeGeneration {
    analyticType
    generateId
    orgGenerated
    parameters
    pathId
    pathOrgId
    projectName
    recipeId
    stepId
    template
    timestamp
    title
    type
    userId
    userOrgId
  }
`;
import { CodeChatMessage } from '@razroo-zeta/data-models';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { DeleteCodeChat, GenerateCodeChat, GetCodeChatById } from '@razroo-zeta/data-graphql';
import { from, map, pluck, Subject, takeUntil } from 'rxjs';
import { GetCodeChatHistory, SearchCodeChat } from 'libs/data-graphql/src/lib/code-chat/code-chat.queries';

@Injectable({
  providedIn: 'root'
})
export class CodeChatService {
  private cancel$ = new Subject<void>();

  constructor(private apollo: Apollo) { }

  generateCodeChat(orgId: string, pathOrgId: string, pathId: string, technologies: string[], 
    messages: CodeChatMessage[], chatId?: string) {
    const mutation = GenerateCodeChat;
    const variables = {
      orgId,
      technologies,
      messages,
      chatId,
      pathOrgId,
      pathId
    };
    const generateCodeChat$ = this.apollo.mutate({ mutation, variables });
    return from(generateCodeChat$).pipe(
      takeUntil(this.cancel$),
      map((generateCodeChat: any) => generateCodeChat.data.generateCodeChat)
    );
  }

  getCodeChatById(chatId: string){
    const query = GetCodeChatById;
    const variables = { chatId };
    const getCodeChatById$ = this.apollo.query({ query, variables, fetchPolicy: 'network-only'});
    return from(getCodeChatById$).pipe(pluck('data', 'getCodeChatById'));
  }

  searchCodeChat(
      orgId: string, 
      search?: string,
      pathId?: string,
      size?: number,
      page?: number
    ){
      const query = SearchCodeChat;
      const variables = {
        orgId,
        search,
        pathId,
        size,
        page
      }
      const searchCodeChat$ = this.apollo.query({query, variables});
      return from(searchCodeChat$).pipe(pluck('data', 'searchCodeChat'));
  }

  getCodeChatHistory(
      orgId: string,
      amountOfItems?: number,
      lastEvaluatedKey?: any,
      pathId?: string
    ){
    const query = GetCodeChatHistory;
    const variables = {
      orgId,
      amountOfItems,
      lastEvaluatedKey,
      pathId
    }
    const getCodeChatHistory$ = this.apollo.query({query, variables});
    return from(getCodeChatHistory$).pipe(pluck('data', 'getCodeChatHistory'));
  }
  cancelCodeChat() {
    this.cancel$.next();
    this.cancel$.complete();
  }

  deleteCodeChat(chatId: string){
    const mutation = DeleteCodeChat;
    const variables = {
      chatId
    }
    const deletCodeChat$ = this.apollo.mutate({ mutation, variables });
    return from(deletCodeChat$).pipe(pluck('data', 'deleteCodeChat'));
  }
}

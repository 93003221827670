import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Link } from './link-tag/link-tag';
import { Template } from '@razroo-zeta/data-models';
import { VersioningService } from '../versioning/versioning.service';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(
    private title: Title,
    private meta: Meta,
    private link: Link,
    private versioningService: VersioningService
  ) { }

  // adding canonicalUrl is optional
  setSEOTags(title: string, desc: string, hostUrl: string, url: string, canonicalUrl?: string){
    this.updateTitle(title);
    this.updateOgTitle(title);
    this.updateDescription(desc);
    this.updateOgUrl(url, hostUrl);
    this.updateRobots();
    this.updateOgImage();
    if(canonicalUrl) {
      this.updateCanonicalUrl(canonicalUrl, hostUrl);
    }
  }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgTitle(title: string) {
    this.meta.updateTag({ name: 'og:title', content: title }, "name='og:title'");
    this.meta.updateTag({ name: 'twitter:title', content: title }, "name='twitter:title'");
  }

  updateDescription(desc: string | undefined) {
    const trimmedDescription = desc ? desc.trim() : '';
    this.meta.updateTag({ name: 'description', content: trimmedDescription }, "name='description'");
    this.meta.updateTag({ name: 'og:description', content: trimmedDescription }, "name='og:description'");
    this.meta.updateTag({ name: 'twitter:description', content: trimmedDescription }, "name='twitter:description'");
  }

  updateOgUrl(url: string, hostUrl: string){
    this.meta.updateTag({ name: 'og:url', content: hostUrl + url}, "name='og:url'")
  }

  updateCanonicalUrl(canonicalUrl: string, hostUrl: string){
    const canonicalTag = { rel: 'canonical', href: hostUrl + canonicalUrl };
    this.link.updateLink(canonicalTag, 'rel="canonical"');
  }

  updateRobots(){
    this.meta.updateTag({ name: 'robots', content: 'all' }, "name='robots'")
  }

  updateOgImage() {
    // this.meta.updateTag({ name: 'og:image', content: '' })
  }

  createCanonicalLinkFromStep(step: Template): string {
    if(step.symlinkStep) {
      const symlinkStep = step.symlinkStep;
      const { name } = this.versioningService.getVersionAndNameString(symlinkStep.pathId);
      return `/${symlinkStep.orgId}/${name}/${symlinkStep.recipeId}${symlinkStep.id}`;
    } else {
      return `/${step.orgId}/${step.corePathId}/${step.recipeId}/${step.id}`;
    } 
  }

  createDescription(title: string, instructionalContent: string): string {
    const innerText = instructionalContent ? instructionalContent.replace(/<[^>]*>/g, '') : '';
    return title + ' ' + innerText.trim();
  }
  
  extractAllInnerText(element: Node): string {
      let textContent = '';
      if (element.nodeType === Node.TEXT_NODE && element.textContent?.trim() !== '') {
          textContent += element.textContent?.trim() + ' ';
      }
      if (element.hasChildNodes()) {
        const children = Array.from(element.childNodes);
        for (const child of children) {
            textContent += this.extractAllInnerText(child);
        }
      }
      return textContent;
  }
}

import { gql } from 'graphql-tag';

export const MarkNotificationAsRead = gql`
  mutation markNotificationAsRead(
    $orgId: String!
    $userId: String!
    $text: String!
    $timestamp: String!
  ) {
    markNotificationAsRead(
      orgId: $orgId
      userId: $userId
      text: $text
      timestamp: $timestamp
    ) {
      notification
      read
      templateCommentParams{
        commentType
        componentName
        fileName
        languageVersion
        pageUrl
      }
      timestamp
    }
  }
`;

export const DeleteNotification =  gql`
  mutation DeleteNotification(
    $orgId: String!
    $userId: String!
    $text: String!
    $timestamp: String!){
    deleteNotification(
      orgId: $orgId,
      userId: $userId,
      text: $text,
      timestamp: $timestamp
    ){
      notification
      read
      templateCommentParams{
        commentType
        componentName
        fileName
        languageVersion
        pageUrl
      }
      timestamp
    }
  }
`

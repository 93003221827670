export const GetStepperOfStepsFragment = `
  fragment getStepperOfStepsFields on Stepper {
    firstPathStep
    firstStep
		id
    description
		pathId
    batchId
    scaffold
    executionId
    iconName
		recipeId
		title
  }
`;

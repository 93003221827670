import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import * as OrganizationsActions from './organizations.actions';
import { OrganizationsEntity } from './organizations.models';
import * as OrganizationsSelectors from './organizations.selectors';
import { ActiveProject, GithubRepo } from '@razroo-zeta/data-models';
import { ActiveTicketWorkspace } from 'libs/data-models/src/lib/organizations/organizations.interface';

@Injectable()
export class OrganizationsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(
    select(OrganizationsSelectors.getOrganizationsLoaded)
  );
  allOrganizations$ = this.store.pipe(
    select(OrganizationsSelectors.getAllOrganizations)
  );
  selectedOrganizations$ = this.store.pipe(
    select(OrganizationsSelectors.getSelected)
  );
  selectedOrganization$ = this.store.pipe(
    select(OrganizationsSelectors.getSelected)
  );
  organizations$ = this.store.pipe(
    select(OrganizationsSelectors.getOrganizationsEntities)
  );
  invitedUsers$ = this.store.pipe(
    select(OrganizationsSelectors.getInvitedUsers)
  );

  selectedOrganizationIsPaid$ = this.store.pipe(
    select(OrganizationsSelectors.getSelectedIsPaid)
  );

  organizationsUsers$ = this.store.pipe(
    select(OrganizationsSelectors.getOrganizationUsers)
  );

  organizationsUser$ = this.store.pipe(
    select(OrganizationsSelectors.getSelectedOrganizationUsers)
  );

  selectedOrgId$ = this.store.pipe(
    select(OrganizationsSelectors.getSelectedId)
  );

  organizationUser$ = this.store.pipe(
    select(OrganizationsSelectors.getSelectedOrganizationUsers)
  );

  organizationUserNotifications$ = this.store.pipe(
    select(OrganizationsSelectors.getOrganizationUserNotifications)
  );

  activeStep$ = this.store.pipe(
    select(OrganizationsSelectors.getActiveStep)
  );

  organizationTeams$ = this.store.pipe(
    select(OrganizationsSelectors.getAllOrganizationTeams)
  )

  constructor(private store: Store) {}

  loadOrganization(orgId: string) {
    this.store.dispatch(OrganizationsActions.loadOrganization({ orgId }));
  }

  loadOrganizationUsers(orgId: string) {
    this.store.dispatch(OrganizationsActions.loadOrganizationUsers({ orgId }));
  }

  loadOrganizationUser(userId: string, orgId: string) {
    this.store.dispatch(OrganizationsActions.loadOrganizationUser({ orgId, userId}));
  }

  sendJoinOrgEmail(orgId: string, userEmail: string, link: string) {
    this.store.dispatch(OrganizationsActions.sendJoinOrgEmail({ orgId, userEmail, link}));
  }

  loadInvitedUsers(userId: string, orgId: string) {
    this.store.dispatch(OrganizationsActions.loadInvitedUsers({ userId, orgId}));
  }

  undoInviteUser(orgId: string, userEmail: string) {
    this.store.dispatch(OrganizationsActions.undoInviteUser({ orgId, userEmail }));
  }

  removeUserFromOrg(orgId: string, userEmail: string) {
    this.store.dispatch(OrganizationsActions.removeUserFromOrg({ orgId, userEmail }));
  }

  getOrgTeams(orgId: string, userId: string){
    this.store.dispatch(OrganizationsActions.getOrgTeams({orgId, userId}))
  }

  createTeam(orgId: string, userId: string, teamName: string){
    this.store.dispatch(OrganizationsActions.createTeam({orgId, userId, teamName}))
  }

  addTeamMember(orgId: string, userId: string, teamId: string, newUserId: string){
    this.store.dispatch(OrganizationsActions.addTeamMember({orgId, userId, teamId, newUserId}))
  }

  addTeamPermissions(orgId: string, userId: string, teamId: string, permissions: any){
    this.store.dispatch(OrganizationsActions.addTeamPermissions({orgId, userId, teamId, permissions}))
  }
  removeTeamPermissions(orgId: string, userId: string, teamId: string, permissions: any){
    this.store.dispatch(OrganizationsActions.removeTeamPermissions({orgId, userId, teamId, permissions}))
  }
  updateOrganization(organization: OrganizationsEntity){
    this.store.dispatch(OrganizationsActions.updateOrganization({organization}))
  }
  updateOrgAvatar(newUrl: string){
    this.store.dispatch(OrganizationsActions.updateOrgAvatar({newUrl}))
  }
  deletePicture(orgId:string, userId: string){
    this.store.dispatch(OrganizationsActions.deleteOrgPicture({orgId, userId}))
  }

  updateOrganizationUserActiveProject(orgId: string, userId: string, activeProject: ActiveProject) {
    this.store.dispatch(OrganizationsActions.updateOrganizationUser({orgId, userId, activeProject}))
  }

  updateOrganizationUserActiveTicketWorkspace(orgId: string, userId: string, activeTicketWorkspace: ActiveTicketWorkspace) {
    this.store.dispatch(OrganizationsActions.updateOrganizationUser({orgId, userId, activeTicketWorkspace}))
  }

  updateOrganizationUser(orgId: string, userId: string, githubRepos?: GithubRepo[], githubUserId?: string) {
    this.store.dispatch(OrganizationsActions.updateOrganizationUser({orgId, userId, githubRepos, githubUserId}))
  }

  deleteStripeSubscription(orgId: string){
    this.store.dispatch(OrganizationsActions.deleteStripeSubscription({orgId}))
  }
}

import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as GitInfoActions from './git-info.actions';
import * as GitInfoFeature from './git-info.reducer';
import * as GitInfoSelectors from './git-info.selectors';

@Injectable()
export class GitInfoFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(GitInfoSelectors.getGitInfoLoaded));
  allGitInfo$ = this.store.pipe(select(GitInfoSelectors.getAllGitInfo));
  selectedGitInfo$ = this.store.pipe(select(GitInfoSelectors.getSelected));

  constructor(private store: Store) {}

  loadGitInfo(org: string) {
    this.store.dispatch(GitInfoActions.loadOrgGitInfo({ org }));
  }
}

import { CodeChat, CodeChatMessage } from '@razroo-zeta/data-models';
import { createAction, props } from '@ngrx/store';

export const initChatHistory = createAction(
  '[ChatHistory Page] Init',
  props<{ orgId: string }>()
);

export const loadChatHistorySuccess = createAction(
  '[ChatHistory/API] Load ChatHistory Success',
  props<{ chatHistory: CodeChat[], lastEvaluatedKey?: {
    gsiKey: string;
    pk: string;
    sk: string;
  } }>()
);
export const initCodeChat = createAction('[Code Chat/API] Init',
  props<{ userOrgId: string, pathOrgId: string, pathId: string, 
    technologies: [string], messages: [any], newMessageText: string, chatId?: string}>()
);

export const loadCodeChat = createAction(
  '[Code Chat/API] Load Code Chat',
  props<{ chatId: string }>()
)

export const loadCodeChatSuccess = createAction(
  '[Code Chat/API] Load Code Chat Success',
  props<{ codeChat: CodeChat }>()
);

export const loadCodeChatPolling = createAction(
  '[Code Chat/API] Load Code Chat Polling',
  props<{ codeChat: CodeChat }>()
);

export const loadCodeChatFailure = createAction(
  '[Code Chat/API] Load Code Chat Failure',
  props<{ error: any, codeChat: CodeChat }>()
);

export const resetChat = createAction(
  '[Code Chat/API] Reset Chat'
);

export const cancelChat = createAction(
  '[Code Chat/API] Cancel Chat'
);

export const cancelChatSuccess = createAction(
  '[Code Chat/API] Cancel Chat Success',
  props<{ codeChat: CodeChat }>()
);

export const deleteCodeChat = createAction(
  '[Code Chat/API] Delete Code Chat',
  props<{chatId: string}>()
)
export const deleteCodeChatSuccess = createAction(
  '[Code Chat/API] Delete Code Chat Success',
  props<{codeChat: CodeChat}>()
)
export const deleteCodeChatFailure = createAction(
  '[Code Chat/API] Delete Code Chat Failure',
  props<{ error: any }>()
)

export const loadMoreHistory = createAction(
  '[Code Chat/API] Load More History', 
  props<{orgId: string}>()
);

export const loadMoreHistorySuccess = createAction(
  '[ChatHistory/API] Load More History Success',
  props<{ chatHistory: CodeChat[], lastEvaluatedKey: {
    gsiKey: string;
    pk: string;
    sk: string;
  } }>()
);
export const searchChat = createAction(
  '[CodeChat/API] Search Chat',
  props<{orgId: string, search: string}>()
);

export const searchChatSuccess = createAction(
  '[CodeChat/API] Search Chat Success',
  props<{ id: string }>()
);

export const searchChatFailure = createAction(
  '[CodeChat/API] Search Chat Failure',
  props<{ error: any }>() 
);


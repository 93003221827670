import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as GitInfoActions from './git-info.actions';
import { GitInfoEntity } from './git-info.models';

export const GIT_INFO_FEATURE_KEY = 'gitInfo';

export interface State extends EntityState<GitInfoEntity> {
  selectedId?: string | number; // which GitInfo record has been selected
  loaded: boolean; // has the GitInfo list been loaded
  error?: string | null; // last known error (if any)
}

export interface GitInfoPartialState {
  readonly [GIT_INFO_FEATURE_KEY]: State;
}

export const gitInfoAdapter: EntityAdapter<GitInfoEntity> =
  createEntityAdapter<GitInfoEntity>();

export const initialState: State = gitInfoAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const gitInfoReducer = createReducer(
  initialState,
  on(GitInfoActions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(GitInfoActions.loadOrgGitInfo, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(GitInfoActions.loadGitInfoSuccess, (state, { gitInfo }) =>
    gitInfoAdapter.setAll(gitInfo, { ...state, loaded: true })
  ),
  on(GitInfoActions.loadGitInfoFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GitInfoActions.loadOrgGitInfoSuccess, (state, { gitInfo }) =>
    gitInfoAdapter.upsertOne(gitInfo, { ...state, loaded: true })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return gitInfoReducer(state, action);
}

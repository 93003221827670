import gql from 'graphql-tag';

export const GetTemplatesSearchResults = gql`
  query Templates($search: String, $path: String, $orgId: String, $searchField: String) {
    templates(search: $search, orgId: $orgId, path: $path, searchField: $searchField) {
      ai
      author
      userId
      title
      id
      score
      recipeId
      description
      baseCommunityPath
      orgId
      pathId
      beta
      next
      prev
      relevantQuestions
      instructionalContent
      updates
      lastUpdated
      scaffold
      parameters {
        defaultValue
        description
        inputType
        name
        type
        optionalTypes {
          name
          selected
        }
        paramType
      }
    }
  }
`;
export const GetTemplatesRecipeSearchResults = gql`
  query Templates($search: String, $path: String, $searchField: String) {
    templates(search: $search, path: $path, searchField: $searchField) {
      ai
      title
      score
      index
      id
      author
      userId
      recipeId
      baseCommunityPath
      description
      pathId
      orgId
      beta
      position
      next
      prev
      relevantQuestions
      instructionalContent
      updates
      lastUpdated
      scaffold
      parameters {
        defaultValue
        description
        inputType
        name
        type
        optionalTypes {
          name
          selected
        }
        paramType
      }
    }
  }
`;

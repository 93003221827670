import { Injectable } from '@angular/core';
import { GetPathBatchTemplate } from '@razroo-zeta/data-graphql';
import { Apollo } from 'apollo-angular';
import { from, pluck } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PathBatchService {

  constructor(private apollo: Apollo) { }

  getPathBatchTemplate(
    pathOrgId: string,
    pathId: string,
    batchId: string,
    recipeId: string,
    stepId: string,
    executable?: boolean
  ) {
  const query = GetPathBatchTemplate;
  const variables = {
    pathOrgId,
    pathId,
    batchId,
    recipeId,
    stepId,
    idType: executable ? 'executionId' : 'batchId',
    published: true, 
    stepper: true, 
    analyticData: undefined, 
    displayDocumentationHtml: true
  };
  const getPathBatchTemplate$ = this.apollo.query({ query, variables });
  return from(getPathBatchTemplate$).pipe(pluck('data', 'getPathBatchTemplate'));
}

}

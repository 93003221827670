import { Role } from 'libs/data-models/src/lib/role/role.interface';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { UserEntity } from './user.models';
import { LikedTemplate } from 'libs/data-models/src/lib/user/user.interface';
import { ActiveStep, OrganizationUser } from '@razroo-zeta/data-models';

export const loadUser = createAction(
  '[User] Load User',
  props<{ orgId?: string, calledByGuard?: boolean}>()
);

export const loadUserSuccess = createAction(
  '[User] Load User Success',
  props<{ user: UserEntity }>()
);

export const loadUserFailure = createAction(
  '[User] Load User Failure',
  props<{ error: any }>()
);

export const loadUserProfile = createAction(
  '[User] Load User Profile',
  props<{ userId: string }>()
);

export const loadUserProfileFromScratch = createAction(
  '[User] Load User Profile From Scratch',
  props<{ userId: string }>()
);

export const loadUserProfileFromScratchSuccess = createAction(
  '[User] Load User Profile From Scratch Success',
  props<{ userProfile: UserEntity; userId: string }>()
);

export const loadUserRoles = createAction(
  '[User] Load User Roles',
  props<{ user: UserEntity }>()
);

export const loadUserRolesSuccess = createAction(
  '[User] Load User Roles Success',
  props<{ user: UserEntity, roles: Role[] }>()
);

export const loadUserProfileSuccess = createAction(
  '[User] Load User Profile Success',
  props<{ update: Update<UserEntity>; userId: string }>()
);

export const loadUserProfileFailure = createAction(
  '[User] Load User Profile Failure',
  props<{ error: any }>()
);

export const loadUserOrganizations = createAction(
  '[User] Load User Organizations',
  props<{ userId: string }>()
);

export const loadUserOrganizationsSuccess = createAction(
  '[User] Load User Organizations Success',
  props<{ update: Update<UserEntity> }>()
);

export const loadUserOrganizationsFailure = createAction(
  '[User] Load User Organizations Failure',
  props<{ error: any }>()
);

export const likeTemplate = createAction(
  '[User] Like Template',
  props<{likedTemplate: LikedTemplate}>()
)
export const downvoteTemplate = createAction(
  '[User] Downvote Template',
  props<{downvotedTemplate: LikedTemplate}>()
)

export const updateProfilePic = createAction(
  '[User] Update Profile Pic',
  props<{newUrl: string}>()
)
export const deleteProfilePic = createAction(
  '[User] Delete Profile Pic',
  props<{userId: string}>()
)
export const deleteProfilePicSuccess = createAction(
  '[User] Delete Profile Pic Success',
  props<{userId: string}>()
)
export const deleteProfilePicFailure = createAction(
  '[User] Delete Profile Pic Failure',
  props<{ error: any }>()
);

export const updateOrgUserActiveStep = createAction(
  '[User] Update Org User Active Step',
  props<{orgId: string, userId: string, activeStep: ActiveStep}>()
)

export const updateOrgUserActiveStepSuccess = createAction(
  '[User] Update Org User Active Step Success',
  props<{user: OrganizationUser}>()
)

export const updateOrgUserActiveStepFailure = createAction(
  '[User] Update Org User Active Step Failure',
  props<{error: any}>()
)

export const updateOrgUserJiraOrgId = createAction(
  '[User] Update Org User Jira Org Id',
  props<{jiraOrgId: string}>()
)

import gql from "graphql-tag";

export const GetAllCommentsOnPage = gql`
    query GetAllCommentsOnPage($pageUrl: String!
        $componentName: Component!
        $currentUserOrgId: String!
        $fileName: String
        ){
            getAllCommentsOnPage(
                pageUrl: $pageUrl,
                componentName: $componentName,
                currentUserOrgId: $currentUserOrgId,
                fileName: $fileName
            ){
                comment
                commentId
                componentName
                commentType
                firstName
                lastName
                downvotes
                fileName
                lineNumberEnd
                lineNumberStart
                resolved
                pageUrl
                responses{
                    comment
                    timestamp
                    firstName
                    lastName
                    userId
                    userOrgId
                    index
                }
                textCommentedOn
                timestamp
                upvotes
                userId
                userOrgId
                templateOrgId
                voteCount
                languageVersion
                picture
        }
    }
`

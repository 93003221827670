import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Template } from '@razroo-zeta/data-models';

@Pipe({
  name: 'stepIndex'
})
export class StepIndexPipe implements PipeTransform {

  transform(template: Template): unknown {
    if(template && template.stepper) {
      return template.stepper.findIndex(stepperStep => stepperStep.id === template.id);
    }
    else {
      return 0;
    }
  }

}

@NgModule({
  imports: [CommonModule],
  declarations: [StepIndexPipe],
  exports: [StepIndexPipe],
})
export class StepIndexModule {}

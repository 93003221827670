import { Injectable } from '@angular/core';
import { WindowRefService } from '@razroo-zeta/common-services';
import { Ticket } from '@razroo-zeta/data-models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentsToggleService {
  sideNavToggle = new Subject()
  openSideNav(pageUrl: string,
    componentName: string,
    currentUser: any,
    fileName?: string,
    textCommentedOn?: string,
    ticket?: Ticket
    ){
    this.sideNavToggle.next({pageUrl, componentName, currentUser, fileName, textCommentedOn, ticket})
  }
  closeSideNav(){
    this.sideNavToggle.next(false)
  }

  highlightComment(user, type, fileName?, stripVersion: boolean = true){
    const selection = this.windowRefService.nativeWindow.getSelection()
    if(selection?.toString()){
      const oRange = selection.getRangeAt(0);
      const oRect = oRange.getBoundingClientRect();
      const offsetTop = oRect.top + document.documentElement.scrollTop;
      const offsetLeft = oRect.left + oRect.width/2;
      const toolTip = document.createElement("div");
      toolTip.innerHTML = `<svg width="20px" fill="currentColor" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.86 122.88"><title>comment</title><path d="M30.28,110.09,49.37,91.78A3.84,3.84,0,0,1,52,90.72h60a2.15,2.15,0,0,0,2.16-2.16V9.82a2.16,2.16,0,0,0-.64-1.52A2.19,2.19,0,0,0,112,7.66H9.82A2.24,2.24,0,0,0,7.65,9.82V88.55a2.19,2.19,0,0,0,2.17,2.16H26.46a3.83,3.83,0,0,1,3.82,3.83v15.55ZM28.45,63.56a3.83,3.83,0,1,1,0-7.66h53a3.83,3.83,0,0,1,0,7.66Zm0-24.86a3.83,3.83,0,1,1,0-7.65h65a3.83,3.83,0,0,1,0,7.65ZM53.54,98.36,29.27,121.64a3.82,3.82,0,0,1-6.64-2.59V98.36H9.82A9.87,9.87,0,0,1,0,88.55V9.82A9.9,9.9,0,0,1,9.82,0H112a9.87,9.87,0,0,1,9.82,9.82V88.55A9.85,9.85,0,0,1,112,98.36Z"/></svg>`;
      toolTip.setAttribute('style', `
        background-color: black;
        position: absolute; top: ${(offsetTop - 35) + 'px'}; left: ${(offsetLeft - 20)+ 'px'};
        z-index: 1000; width: 40px; height: 28px;
        border-radius: 6px; text-align: center;
        padding: 5px; color: white; cursor: pointer;
        box-shadow: 1px 1px 2px grey;
      `);
      const text = selection.toString();

      const pageUrl = stripVersion ? this.removeVersionFromPageUrl(this.windowRefService.nativeWindow.location.pathname) : this.windowRefService.nativeWindow.location.pathname;
      toolTip.addEventListener('click', () => this.openSideNav(pageUrl, type, user, fileName, text))
      document.body.appendChild(toolTip);
      //TODO - BEGIN: look into this more
      this.windowRefService.nativeWindow.setTimeout(()=> {
        document.body.addEventListener('click', () => {
          toolTip.remove()
        })
      }, 0)
      //TODO - END: look into this more
    }
  }

  /**
   * sample url - '/UxuBbeDEVqhXQfVRrgch_/new-org-path-13.0.0./test-recipe/new-step'
   * @param pageUrl
   * @returns
   */
  removeVersionFromPageUrl(pageUrl: string): string {
    const pageUrlArr = pageUrl.split('/');
    // pathId will always be 3 item in url due to leading slash
    const splitPathId = pageUrlArr[2].split('-');
    if(this.hasSemver(pageUrlArr[2])) {
      splitPathId.pop();
    }
    const updatedPathId = splitPathId.join('-');
    pageUrlArr[2] = updatedPathId;
    const updatedPageUrl = pageUrlArr.join('/');
    return updatedPageUrl;
  }

  hasSemver(pathId: string) {
    return /\d+\.\d+\.\d+/.test(pathId);
  }

  constructor(private windowRefService: WindowRefService) { }
}

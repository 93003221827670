import { addStepDependencySuccess, removeStepDependencySuccess } from './../../../../path-cms/src/lib/+state/path-cms.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as SearchActions from './search.actions';
import { SearchEntity } from './search.models';
import { TemplatesEntity } from '@razroo-zeta/data-access';
import { Template } from '@razroo-zeta/data-models';

export const SEARCH_FEATURE_KEY = 'search';

export interface State extends EntityState<SearchEntity> {
  selectedId?: string | number; // which Search record has been selected
  text?: string;
  loaded: boolean; // has the Search list been loaded
  error?: string | null; // last none error (if any)
  path?: Template;
}

export interface SearchPartialState {
  readonly [SEARCH_FEATURE_KEY]: State;
}

export function selectTemplateId(template: TemplatesEntity): string | number {
  //In this case this would be optional since primary key is id
  return template.id;
}

export const searchAdapter: EntityAdapter<SearchEntity> =
  createEntityAdapter<SearchEntity>();

export const initialState: State = searchAdapter.getInitialState({
  // set initial required properties
  loaded: true,
  path: undefined
});

const searchReducer = createReducer(
  initialState,
  on(SearchActions.loadSearch, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(SearchActions.loadSearchSuccess, (state, { search }) =>
    searchAdapter.setAll(search, { ...state, loaded: true })
  ),
  on(addStepDependencySuccess, (state, { dependencyStep }) =>
    searchAdapter.removeOne(dependencyStep.id, state),
  ),
  on(removeStepDependencySuccess, (state, { template }) =>
    searchAdapter.upsertOne(template as any, {...state})
  ),
  on(SearchActions.updateSearchPath, (state, {path}) => ({
    ...state,
    loaded: true,
    path
  })),
  on(SearchActions.loadSearchFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return searchReducer(state, action);
}

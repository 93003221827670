// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  amplify: 'test',
  issuer: 'https://dev.id.razroo.com',
  staticWebsiteUrl: 'https://dev-static.razroo.com',
  slackRedirectUrl: 'https://dev.razroo.com/add-slack-app',
  clientId: 'Ar7y21NCpC8KhP9bHN1iBVTctXRYgNTY',
  apiIdentifier: 'https://razroo-dev.us.auth0.com/api/v2/',
  githubPortalUrl: 'https://github-auth.dev.razroo.com',
  graphQLURI: 'https://vuerbsj4cjffvfzx7cph4iy7se.appsync-api.us-east-1.amazonaws.com/graphql',
  stripePricesToken:
    'Bearer rk_test_51JMFp9Ch3AeG1GD0YJW9hJC8B3rvrXCftz7MN7vmKtQLR6bErI97dylOzDLr7sBRCiczHQlbvGkWN7rkQ6OS2l2u00Zqe44hPG',
  awsApiKey: "da2-mjxxlblgx5f6xgdfsvvxcnhv6e",
  landingPageUrl: 'https://razroo.com',
  projectsHostUrl: 'https://dev.projects.razroo.com',
  codeHostUrl: 'https://dev.code.razroo.com',
  googleMeasurementId: 'G-3L9Z47YNLR',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

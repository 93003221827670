import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromSearch from './search.reducer';
import * as SearchSelectors from './search.selectors';
import * as SearchActions from './search.actions';
import { PathEntity } from '@razroo-zeta/data-access/path';
import { Template } from '@razroo-zeta/data-models';

@Injectable()
export class SearchFacade {
  loaded$ = this.store.pipe(select(SearchSelectors.getSearchLoaded));
  allSearch$ = this.store.pipe(select(SearchSelectors.getAllSearch));
  selectedSearch$ = this.store.pipe(select(SearchSelectors.getSelected));
  searchPath$ = this.store.pipe(select(SearchSelectors.getSearchPath));
  currentSearchText$ = this.store.pipe(select(SearchSelectors.getSearchText));
  getSearchCollection$ = this.store.pipe(
    select(SearchSelectors.getSearchCollection)
  );
  getSearchLoaded$ = this.store.pipe(select(SearchSelectors.getSearchLoaded));

  constructor(private store: Store<fromSearch.SearchPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  updateSearchPath(path: Template){
    this.store.dispatch(SearchActions.updateSearchPath({path}))
  }
  loadSearch(search: string, path: string, orgId?: string){
    this.store.dispatch(SearchActions.loadSearch({search, path, orgId}))
  }
}

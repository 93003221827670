import gql from "graphql-tag";

export const CodeMorph = gql`
    mutation CodeMorph(
        $userOrgId: String!
        $templateOrgId: String!
        $pathId: String!
        $recipeId: String!
        $stepId: String!
        $fileType: String!
        $filePath: String!
        $codeMods: AWSJSON!
        $fileString: String!
    ){
        codeMorph(
            userOrgId: $userOrgId
            templateOrgId: $templateOrgId
            pathId: $pathId
            recipeId: $recipeId
            stepId: $stepId
            fileType: $fileType
            filePath: $filePath
            codeMods: $codeMods
            fileString: $fileString
        ){
          file
          updates
          parameters {
            defaultValue
            description
            inputType
            name
            paramType
            type
            optionalTypes {
                name
                selected
            }
          }
        }
    }
`
import { VsCodeAuthInfoFragment, VsCodeInstanceFragment } from './vscode.fragments';
import gql from 'graphql-tag';

export const GetVsCodeInstance = gql`
  query GetVsCodeInstance($vsCodeInstanceId: String!, $userId: String!) {
    getVsCodeInstance(vsCodeInstanceId: $vsCodeInstanceId, userId: $userId) {
      ...vsCodeInstanceFields
    }
  }
  ${VsCodeInstanceFragment}
`;

export const GetVsCodeAuthInfo = gql`
  query GetVsCodeAuthInfo($userId: String!){
    getVSCodeAuthInfo(userId: $userId){
      ...vsCodeAuthInfoFields
    }
  }
  ${VsCodeAuthInfoFragment}
`;

import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as BooksActions from './books.actions';
import * as BooksFeature from './books.reducer';
import * as BooksSelectors from './books.selectors';

@Injectable()
export class BooksFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(BooksSelectors.getBooksLoaded));
  allBooks$ = this.store.pipe(select(BooksSelectors.getAllBooks));
  selectedBooks$ = this.store.pipe(select(BooksSelectors.getSelected));
  content$ = this.store.pipe(select(BooksSelectors.getContent));
  selectedId$ = this.store.pipe(select(BooksSelectors.getSelectedId));
  toc$ = this.store.pipe(select(BooksSelectors.getToc));

  constructor(private store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init(orgId: string, userId: string) {
    this.store.dispatch(BooksActions.init({orgId, userId}));
  }

  loadBook(selectedID: string, chapter: string) {
    this.store.dispatch(BooksActions.loadBook({ selectedID, chapter }));
  }

  loadToc(selectedID: string) {
    this.store.dispatch(BooksActions.loadToc({ selectedID }));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RazrooNamePipe } from './razroo-name.pipe';
import { FileNameFromPathPipe } from './file-name-from-path/file-name-from-path.pipe';
import { TestingStatusPipe } from './testing-status/testing-status.pipe';
import { InitialsPipe } from './initials.pipe';
import { IsPersonalWorkspacePipe } from './is-personal-workspace.pipe';
import { FilterUsersByTeamPipe } from './filter-users-by-team/filter-users-by-team.pipe';

@NgModule({
  imports: [CommonModule ],
  declarations: [RazrooNamePipe, FileNameFromPathPipe, TestingStatusPipe, InitialsPipe, IsPersonalWorkspacePipe, FilterUsersByTeamPipe],
  exports: [RazrooNamePipe, InitialsPipe, FileNameFromPathPipe, TestingStatusPipe, IsPersonalWorkspacePipe, FilterUsersByTeamPipe],
})
export class CommonPipesModule {}

import gql from 'graphql-tag';

export const GenerateCodeChat = gql`
  mutation GenerateCodeChat(
    $orgId: String!, $pathOrgId: String!, $pathId: String!, $technologies: [String], $messages:  [ChatCompletionRequestMessageInput], $chatId: String
  ) {
    generateCodeChat(
      orgId: $orgId, pathOrgId: $pathOrgId, pathId: $pathId, technologies: $technologies, messages: $messages, chatId: $chatId
    ) {
      dateCreated
      finishReason
      generationStatus
      id
      lastUpdated
      pathOrgId
      technologies
      title
      usage
      userId
      userOrgId
      messages {
        role
        content
      }
    }
  }
`;

export const DeleteCodeChat = gql`
  mutation DeleteCodeChat($chatId: String!){
    deleteCodeChat(chatId: $chatId){
      dateCreated
      finishReason
      generationStatus
      id
      lastUpdated
      messages {
        role 
        content
        name
      }
      pathOrgId
      technologies
      title
      usage
      userId
      userOrgId
    }
  }
`

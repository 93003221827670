import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TemplatesFacade } from '@razroo-zeta/data-access';

@Component({
  selector: 'razroo-zeta-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  @Input() codeModBug = false;
  @Input() comment: any = '';
  @Input() currentUserId: string;
  @Output() upvote = new EventEmitter();
  @Output() downvote = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() resolveBug = new EventEmitter();
  template$ = this.templateFacade.selectedTemplate$;
  editMode = false;
  constructor(
    private fb: UntypedFormBuilder,
    private templateFacade: TemplatesFacade
  ) {}

  editForm = this.fb.group({
    text: ['', Validators.required]
  })

  editView(){
    this.editMode = true;
    this.editForm.setValue({text: this.comment.comment})
  }

  saveEdit(){
    this.edit.emit(this.editForm.value.text);
    this.editForm.reset();
    this.editMode = false;
  }
  cancelEdit(){
    this.editForm.reset()
    this.editMode = false;
  }

  ngOnInit(): void {}
}

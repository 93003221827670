import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as OrganizationsActions from './organizations.actions';
import { OrganizationsEntity, OrganizationTeamEntity, OrganizationUserEntity } from './organizations.models';
import { Organization, OrganizationUser } from '@razroo-zeta/data-models';

export const ORGANIZATIONS_FEATURE_KEY = 'organizations';

export interface State {
  organizations: OrganizationState;
  organizationUsers: OrganizationUserState;
  organizationTeams: OrganizationTeamsState;
  invitedUsers: any[];
}

export interface OrganizationState extends EntityState<OrganizationsEntity> {
  selectedId?: string; // which Organizations record has been selected
  loaded: boolean; // has the Organizations list been loaded
  error?: string | null; // last known error (if any)
}

export interface OrganizationUserState extends EntityState<OrganizationUserEntity> {
  selectedId?: string; // which Organizations record has been selected
  loaded: boolean; // has the Organizations list been loaded
  error?: string | null; // last known error (if any)
}
export interface OrganizationTeamsState extends EntityState<OrganizationTeamEntity> {
  selectedId?: string; // which Organizations record has been selected
  loaded: boolean; // has the Organizations list been loaded
  error?: string | null; // last known error (if any)
}

export interface OrganizationsPartialState {
  readonly [ORGANIZATIONS_FEATURE_KEY]: State;
}

export function selectOrgId(a: Organization): string {
  //In this case this would be optional since primary key is id
  return a.orgId;
}

export function selectUserId(a: OrganizationUser): string {
  //In this case this would be optional since primary key is id
  return a.userId;
}

export function selectTeamId(a: OrganizationTeamEntity):string {
  return a.teamId;
}

export const organizationsAdapter: EntityAdapter<OrganizationsEntity> =
  createEntityAdapter<OrganizationsEntity>({
    selectId: selectOrgId
  });

export const organizationTeamsAdapter: EntityAdapter<OrganizationTeamEntity> =
  createEntityAdapter<OrganizationTeamEntity>({
    selectId: selectTeamId
  });


export const organizationUsersAdapter: EntityAdapter<OrganizationUserEntity> =
  createEntityAdapter<OrganizationUserEntity>({
    selectId: selectUserId,
  });

export const organizationInitialState: OrganizationState = organizationsAdapter.getInitialState({
  loaded: false,
 });
export const organizationUsersInitialState: OrganizationUserState = organizationUsersAdapter.getInitialState({
  loaded: false,
});
export const organizationTeamsInitialState: OrganizationTeamsState = organizationTeamsAdapter.getInitialState({
  loaded: false,
});

export const initialState: State = organizationsAdapter.getInitialState({
  organizations: organizationInitialState,
  organizationUsers: organizationUsersInitialState,
  organizationTeams: organizationTeamsInitialState,
  invitedUsers: []
});

const organizationsReducer = createReducer(
  initialState,
  on(
    OrganizationsActions.loadOrganizationsSuccess,
    (state, { organizations }) => ({
      ...state,
      organizations: organizationsAdapter.setAll(organizations, {
        ...state.organizations,
        selectedId: organizations[0].orgId
      })
    })
  ),
  on(OrganizationsActions.loadOrganizationsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(OrganizationsActions.loadOrganization, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(OrganizationsActions.loadOrganizationSuccess, (state, { organization }) =>
    ({
      ...state,
      organizations: organizationsAdapter.upsertOne(organization, {
        ...state.organizations,
        selectedId: organization.orgId
      })
    })
  ),
  on(OrganizationsActions.loadOrganizationUsers, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    OrganizationsActions.loadOrganizationUsersSuccess,
    (state, { organizationUsers, selectedId }) => ({
      ...state,
      organizationUsers: organizationUsersAdapter.upsertMany(organizationUsers, {
        ...state.organizationUsers,
        selectedId: selectedId
      })
    })
  ),
  on(
    OrganizationsActions.loadOrganizationUserSuccess,
    (state, { organizationUser }) => ({
      ...state,
      organizationUsers: organizationUsersAdapter.upsertOne(organizationUser, {
        ...state.organizationUsers,
        selectedId: organizationUser.userId,
      })
    })

  ),
  on(
    OrganizationsActions.loadActiveStateSuccess,
    (state, { organizationUser }) => ({
      ...state,
      organizationUsers: organizationUsersAdapter.upsertOne(organizationUser, {
        ...state.organizationUsers,
        selectedId: organizationUser.userId
      })
    })
  ),
  on(
    OrganizationsActions.removeUserFromOrgSuccess,
    (state, { organizationUser }) => ({
      ...state,
      organizationUsers: organizationUsersAdapter.removeOne(organizationUser.userId, state.organizationUsers)
    })
  ),
  on(
    OrganizationsActions.sendJoinOrgEmailSuccess,
    (state, { orgId, userEmail }) => ({
      ...state,
      invitedUsers: [
        ...state.invitedUsers,
        {
          userEmail,
          orgId,
          dateInvited: Date.now()
        }
      ]
    })
  ),
  on(
    OrganizationsActions.loadInvitedUsersSuccess,
    (state, { invitedUsers }) => ({
      ...state,
      invitedUsers: [
        ...state.invitedUsers,
        ...invitedUsers
      ]
    })
  ),
  on(
    OrganizationsActions.undoInviteUserSuccess,
    (state, { invitedUser }) => ({
      ...state,
      invitedUsers: state.invitedUsers.filter(user => user.userEmail !== invitedUser.userEmail)
    })
  ),
  on(OrganizationsActions.getOrgTeams, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    OrganizationsActions.getOrgTeamsSuccess,
    (state, { teams }) => ({
      ...state,
      organizationTeams: organizationTeamsAdapter.upsertMany(teams, {
        ...state.organizationTeams,
        selectedId: teams[0]?.teamId
      })
    })
  ),
  on(
    OrganizationsActions.createTeamSuccess,
    (state, { team }) => ({
      ...state,
      organizationTeams: organizationTeamsAdapter.addOne(team, {
        ...state.organizationTeams,
        selectedId: team.teamId
      })
    })
  ),
  on(
    OrganizationsActions.addTeamMemberSuccess,
    (state, { team }) => ({
      ...state,
      organizationTeams: organizationTeamsAdapter.upsertOne(team, {
        ...state.organizationTeams,
        selectedId: team.teamId
      })
    })
  ),
  on(
    OrganizationsActions.addTeamPermissionsSuccess,
    OrganizationsActions.removeTeamPermissionsSuccess,
    (state, { team }) => ({
      ...state,
      organizationTeams: organizationTeamsAdapter.upsertOne(team, {
        ...state.organizationTeams,
        selectedId: team.teamId
      })
    })
  ),
  on(
    OrganizationsActions.updateOrganization,
    (state, { organization }) => ({
      ...state,
      organizations: organizationsAdapter.upsertOne(organization, {
        ...state.organizations,
        selectedId: organization.orgId
      })
    })
  ),
  on(OrganizationsActions.updateOrgAvatar, (state, { newUrl }) => ({
    ...state,
    organizations: organizationsAdapter.updateOne({
        id: state.organizations.selectedId!,
        changes: {
          picture: newUrl
        }
      }, state.organizations)
    })
  ),
  on(OrganizationsActions.updateOrganizationUserSuccess, (state, { organizationUser }) => ({
    ...state,
    organizationUsers: organizationUsersAdapter.upsertOne(organizationUser as any, {
      ...state.organizationUsers,
      selectedId: organizationUser.userId
    })
    })
  ),
  on(OrganizationsActions.deleteOrgPictureSuccess, (state, { orgId }) => ({
    ...state,
    organizations: organizationsAdapter.updateOne({
        id: state.organizations.selectedId!,
        changes: {
          picture: undefined
        }
      }, state.organizations)
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return organizationsReducer(state, action);
}

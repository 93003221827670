import { UnpaidTicketDialogComponent } from './../../../../../common/ui/src/lib/unpaid-ticket-dialog/unpaid-ticket-dialog.component';
import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as TicketsActions from './tickets.actions';
import { TicketsService } from '@razroo-zeta/data-services';
import { GenerateTicketsService } from '../../../../../tickets/src/lib/services/generate-tickets/generate-tickets.service';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { fetch } from '@ngrx/router-store/data-persistence';
import { RazrooError, Ticket } from '@razroo-zeta/data-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserActions } from '@razroo-zeta/data-access';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';

@Injectable()
export class TicketsEffects {
  private actions$ = inject(Actions);


  loadTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.loadTicket),
      fetch({
        run: ({orgId, workspaceId, ticketNumber, noCache}) => {
          return this.ticketsService.getWorkspaceTicket(orgId, workspaceId, ticketNumber, noCache).pipe(
            map((ticket: any) => {
              return TicketsActions.loadTicketSuccess({ ticket })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.loadTicketFailure({ error });
        },
      })
    )
  );

  loadTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.loadTickets),
      fetch({
        run: ({orgId, workspaceId, boardId}) => {
          return this.ticketsService.getTicketWorkspaceTickets(orgId, workspaceId, boardId).pipe(
            map((tickets: any) => {
              return TicketsActions.loadTicketsSuccess({ tickets })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.loadTicketsFailure({ error });
        },
      })
    )
  );

  generateProductTicketTitlesAndUpload$ = createEffect(() => 
      this.actions$.pipe(
        ofType(TicketsActions.generateProductTicketTitlesAndUpload), 
        fetch({
          run: ({orgId, workspaceId, userProductMessage, currentEpicTickets, parentTicketNumber, epic, startingTicketNumber}) => {
            return this.ticketsService.generateProductTicketTitlesAndUpload(orgId, workspaceId, currentEpicTickets, userProductMessage, parentTicketNumber, epic, startingTicketNumber).pipe(
              map((tickets: any) => {
                return TicketsActions.generateProductTicketTitlesAndUploadSuccess({ tickets, parentTicketNumber })
              })
            )
          },
          onError: (action, error) => {
            console.error('Error', error);
            return TicketsActions.generateProductTicketTitlesAndUploadFailure({ error })
          }
        })
      )
  );

  generateEntireProductTicketUsingAiAndUpload$ = createEffect(() => 
      this.actions$.pipe(
        ofType(TicketsActions.generateEntireProductTicketUsingAiAndUpload), 
        fetch({
          run: ({user, orgId, workspaceId, userTicketPrompt, epic, boardId, clarificationQuestionResponse, columns, board, parentTicketNumber}) => {
            return this.ticketsService.generateEntireProductTicketUsingAiAndUpload(orgId, workspaceId, userTicketPrompt, epic, boardId, clarificationQuestionResponse, parentTicketNumber).pipe(
              map((ticket: any) => {
                const {razrooError} = ticket;
                if(razrooError && razrooError.name === RazrooError.NotPaid) {
                  const dialog = this.dialog.open(UnpaidTicketDialogComponent, {
                    data: {
                      user
                    }
                  });
                  return TicketsActions.generateEntireProductTicketUsingAiAndUploadFailure({ error: razrooError })
                } else {
                  this.snackBar.open('Ticket Created', undefined, {duration: 3000});
                  let updatedColumns = [] as any;
                  if(columns) {
                    updatedColumns = this.ticketsService.addNewTicketToKanbanColumns(columns, ticket);
                  }
                  return TicketsActions.generateEntireProductTicketUsingAiAndUploadSuccess({ ticket, columns: updatedColumns, board})
                }
              })
            )
          },
          onError: (action, error) => {
            console.error('Error', error);
            return TicketsActions.generateEntireProductTicketUsingAiAndUploadFailure({ error })
          }
        })
      )
  );

  createCommunityTicketWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.createCommunityTicketWorkspace),
      fetch({
        run: ({workspaceName, description}) => {
          return this.ticketsService.createCommunityTicketWorkspace(workspaceName, description).pipe(
            map((workspace: any) => {
              this.snackBar.open('Community Workspace Created', undefined, {duration: 3000});
              return TicketsActions.createCommunityTicketWorkspaceSuccess({ workspace })
            })
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.createCommunityTicketWorkspaceFailure({ error })
        }
      })
    )
  );

  getCommunityTicketWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.getCommunityTicketWorkspaces),
      fetch({
        run: () => {
          return this.ticketsService.getCommunityTicketWorkspaces().pipe(
            map((ticketWorkspaces: any) => {
              return TicketsActions.getCommunityTicketWorkspacesSuccess({ ticketWorkspaces })
            })
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.getCommunityTicketWorkspacesFailure({ error })
        }
      })
    )
  );

  manuallyCreateTicket$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TicketsActions.manuallyCreateTicket),
        fetch({
          run: (action) => {
            return this.ticketsService.manuallyCreateTicket(action.orgId, action.workspaceId, action.boardId, action.title, action.parentTicketNumber)
              .pipe(
                map((ticket: any) =>{
                  this.snackBar.open(`Ticket ${ticket.workspaceId}-${ticket.ticketNumber} manually created`, '', {duration: 1000});
                  let updatedColumns = [] as any;
                  if(action.columns) {
                    const updatedTicket = {...ticket, hydrating: true};
                    updatedColumns = this.ticketsService.addNewTicketToKanbanColumns(action.columns, updatedTicket);
                  }
                  return TicketsActions.manuallyCreateTicketSuccess({ ticket, columns: updatedColumns });
                })
              )
          },
          onError: (action, error) => {
            console.error('Error', error);
            return TicketsActions.manuallyCreateTicketFailure({ error });
          },
        })
      )
    );

    manuallyCreateTicketSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TicketsActions.manuallyCreateTicketSuccess),
        fetch({
          run: (action) => {
            const {orgId, workspaceId, ticketNumber} = action.ticket;
            this.snackBar.open(`Hydrating Ticket ${workspaceId}-${ticketNumber} using AI...`, '', {duration: 3000});
            return this.ticketsService.generateEntireProductTicketUsingAiAndUpdate(orgId, workspaceId, ticketNumber)
              .pipe(
                map((ticket: any) =>{
                  this.snackBar.open(`Ticket ${ticket.workspaceId}-${ticket.ticketNumber} hydrated using AI`, 'Open', {duration: 3000})
                  .onAction().subscribe(() => {
                    const url = this.router.serializeUrl(
                      this.router.createUrlTree(['/tickets', ticket.workspaceId, ticket.ticketNumber])
                    );
                    window.open(url, '_blank');
                  });
                  return TicketsActions.generateEntireProductTicketUsingAiAndUpdateSuccess({ ticket });
                })
              )
          },
          onError: (action, error) => {
            console.error('Error', error);
            return TicketsActions.generateEntireProductTicketUsingAiAndUpdateFailure({ error });
          },
        })
      )
    );  

  createEpicProductTicketUsingAi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.createEpicProductTicketUsingAi),
      mergeMap(({orgId, workspaceId, userTicketPrompt}) =>
        this.ticketsService.createEpicProductTicketUsingAi(orgId, workspaceId, userTicketPrompt).pipe(
          map((epic: any) => {
            this.snackBar.open('Epic created', '', {duration: 3000});
            return TicketsActions.createEpicProductTicketUsingAiSuccess({ epic });
          }),
          catchError(error => {
            this.snackBar.open(`Epic creation failed ${error}`, '', {duration: 3000});
            return of(TicketsActions.createEpicProductTicketUsingAiFailure({ error }));
          })
        )
      )
    )
  );

  createEpicProductTicketFromEpicSuggestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.createEpicProductTicketFromEpicSuggestion),
      mergeMap(({orgId, workspaceId, epicSuggestion}) =>
        this.ticketsService.createEpicProductTicketUsingAi(orgId, workspaceId, epicSuggestion?.description).pipe(
          map((epic: any) => {
            this.snackBar.open('Epic created', '', {duration: 3000});
            return TicketsActions.createEpicProductTicketFromEpicSuggestionSuccess({ epic, epicSuggestion });
          }),
          catchError(error => {
            this.snackBar.open(`Epic creation failed ${error}`, '', {duration: 3000});
            return of(TicketsActions.createEpicProductTicketFromEpicSuggestionFailure({ error }));
          })
        )
      )
    )
  );

  epicFromEpicSuggestionViaDocumentUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.epicFromEpicSuggestionViaDocumentUpload),
      mergeMap(({orgId, workspaceId, epicSuggestion}) =>
        this.ticketsService.createEpicProductTicketUsingAi(orgId, workspaceId, epicSuggestion.description).pipe(
          map((epic: any) => {
            this.snackBar.open('Epic created', '', {duration: 3000});
            return TicketsActions.epicFromEpicSuggestionViaDocumentUploadSuccess({ epic, epicSuggestion });
          }),
          catchError(error => {
            this.snackBar.open(`Epic creation failed ${error}`, '', {duration: 3000});
            return of(TicketsActions.epicFromEpicSuggestionViaDocumentUploadFailure({ error }));
          })
        )
      )
    )
  );

  epicFromEpicSuggestionViaDocumentUploadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.epicFromEpicSuggestionViaDocumentUploadSuccess),
      mergeMap(({epic}) => {
        const startingTicketNumber = 0;
        const userProductMessage = this.generateTicketsService.extractEpicText(epic.description);
        return this.ticketsService.generateProductTicketTitlesAndUpload(epic.orgId, epic.workspaceId, [], userProductMessage, 
          epic.ticketNumber, epic.epic, startingTicketNumber, undefined, 20).pipe(
          map((tickets: any) => {
            return TicketsActions.generateProductTicketTitlesAndUploadSuccess({ tickets, parentTicketNumber: epic.ticketNumber });
          }),
          catchError(error => {
            this.snackBar.open(`generateProductTicketTitlesAndUploadFailure: ${error}`, '', {duration: 3000});
            return of(TicketsActions.generateProductTicketTitlesAndUploadFailure({ error }));
          })
        )
      }
      )
    )
  );

  modifyTicketBasedOnTitleUsingAi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.modifyTicketBasedOnTitleUsingAi),
      fetch({
        run: ({orgId, workspaceId, ticketNumber, existingDescription, existingTitle, updatedTitle}) => {
          return this.ticketsService.modifyTicketBasedOnTitleUsingAi(orgId, workspaceId, ticketNumber, existingDescription, existingTitle, updatedTitle).pipe(
            map((ticket: any) => {
              this.snackBar.open(`ticket has been updated based on new title`, '', {duration: 3000});
              return TicketsActions.modifyTicketBasedOnTitleUsingAiSuccess({ ticket })
            })
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.deleteTicketFailure({ error })
        }
      })
    )
  );

  deleteTicket$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.deleteTicket),
      mergeMap((action) => 
        this.ticketsService.deleteTicketWorkspaceTicket(action.orgId, action.workspaceId, action.ticketNumber).pipe(
          map((ticket: any) => {
            this.snackBar.open(`${action.ticketTitle} has been deleted`, '', {duration: 3000});
            return TicketsActions.deleteTicketSuccess({ticket, board: action.board});
          }),
          catchError((error) => of(TicketsActions.deleteTicketFailure({error})))
        )
      )
    )
  );

  generateDescriptionForProductTicketAndUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.generateDescriptionForProductTicketAndUpdate),
      fetch({
        run: ({ticket}) => {
          return this.ticketsService.generateDescriptionForProductTicketAndUpdate(ticket)
          .pipe(
            map((res: any) => {
              this.snackBar.open(`Description updated for ${ticket.title}`, '', {duration: 3000});
              return TicketsActions.generateDescriptionForProductTicketAndUpdateSuccess({ ticket: {...ticket, description: res.description} })
            })
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.generateDescriptionForProductTicketAndUpdateFailure({ error })
        }
      })
    )
  );

  loadTicketHistoryOnEditOrInitialLoad$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.selectTicket, TicketsActions.loadTicketSuccess, TicketsActions.addAiToTicketDescriptionSuccess, TicketsActions.updateTicketWorkspaceTicketSuccess, 
        TicketsActions.generateUserJourneySuccess, TicketsActions.generateAcceptanceCriteriaForProductTicketSuccess, 
        TicketsActions.generateEstimatedStoryPointsReasonForProductTicketSuccess, TicketsActions.generateBusinessValueForProductTicketSuccess, 
        TicketsActions.addBackgroundPlusTechnicalNotesPart1Success, TicketsActions.addBackgroundPlusTechnicalNotesPart2Success, 
        TicketsActions.addTicketScenarioSuccess, TicketsActions.addTicketCodeSnippetSuccess, TicketsActions.modifyTicketBasedOnTitleUsingAiSuccess,
        TicketsActions.updateWorkspaceTicketColumnSuccess),
      map((action) => 
        TicketsActions.loadTicketHistory({orgId: action.ticket.orgId, workspaceId: action.ticket.workspaceId, ticketNumber: action.ticket.ticketNumber})
      )
    )
  );

  addBackgroundPlusTechnicalNotes$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.addBackgroundPlusTechnicalNotes),
      fetch({
        run: (action) => {
          return this.ticketsService.addTicketDescriptionBackground(action.ticket.orgId, action.ticket.workspaceId, action.ticket.ticketNumber, action.ticket.description)
          .pipe(
            map((res: any) => TicketsActions.addBackgroundPlusTechnicalNotesPart1Success({ ticket: {...action.ticket, description: res.description, condensations: res.condensations} }))
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.addBackgroundPlusTechnicalNotesFailure({ error })
        }
      })
    )
  )
  addBackgroundPlusTechnicalNotesPart1Success$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.addBackgroundPlusTechnicalNotesPart1Success),
      fetch({
        run: (action) => {
          return this.ticketsService.addTicketTechnicalNotes(action.ticket.orgId, action.ticket.workspaceId, action.ticket.ticketNumber, action.ticket.description)
          .pipe(
            map((res: any) => {
              this.dialog.closeAll();
              return TicketsActions.addBackgroundPlusTechnicalNotesPart2Success({ ticket: {...action.ticket, description: res.description} })
            })
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.addBackgroundPlusTechnicalNotesFailure({ error })
        }
      })
    )
  )


  addTicketScenario$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.addTicketScenario),
      fetch({
        run: (action) => {
          return this.ticketsService.addTicketScenario(action.orgId, action.workspaceId, action.ticketNumber, action.description, action.userPrompt)
            .pipe(
              map(res => {
                this.snackBar.open('Scenario added successfully', '', {duration: 3000})
                return TicketsActions.addTicketScenarioSuccess({ticket: {orgId: action.orgId, workspaceId: action.workspaceId, ticketNumber: action.ticketNumber}, newDescription: res.description, condensations: res.condensations})
              })
            )
        }, 
        onError: (action, error) => {
          return TicketsActions.addTicketScenarioFailure({error})
        }
      })
    )
  );

  updateWorkspaceTicketColumn$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.updateWorkspaceTicketColumn),
      fetch({
        run: (action) => {
          return this.ticketsService.updateWorkspaceTicketColumn(action.orgId, action.workspaceId, action.ticketNumber, action.ticketUpdateParams)
            .pipe(
              map(res => {
                const ticketForReloadingTicketHistory = {
                  orgId: action.orgId, 
                  workspaceId: action.workspaceId, 
                  ticketNumber: action.ticketNumber  
                } as Ticket;
                this.snackBar.open('Ticket status updated successfully', '', {duration: 3000})
                return TicketsActions.updateWorkspaceTicketColumnSuccess({orgId: action.orgId, workspaceId: action.workspaceId, 
                  ticketNumber: action.ticketNumber, ticketUpdateParams: action.ticketUpdateParams, ticket: ticketForReloadingTicketHistory})
              })
            )
        }, 
        onError: (action, error) => {
          return TicketsActions.addTicketScenarioFailure({error})
        }
      })
    )
  );

  updateWorkspaceDataTableTicketColumn$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.updateWorkspaceDataTableTicketColumn),
      fetch({
        run: (action) => {
          return this.ticketsService.updateWorkspaceTicketColumn(action.orgId, action.workspaceId, action.ticketNumber, action.ticketUpdateParams)
            .pipe(
              map(res => {
                this.snackBar.open('Ticket status updated successfully', '', {duration: 3000});
                return TicketsActions.updateWorkspaceDataTableTicketColumnSuccess({orgId: action.orgId, workspaceId: action.workspaceId, 
                  ticketNumber: action.ticketNumber, ticketUpdateParams: action.ticketUpdateParams})
              })
            )
        }, 
        onError: (action, error) => {
          return TicketsActions.updateWorkspaceDataTableTicketColumnFailure({error})
        }
      })
    )
  );

  addAiToTicketDescription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.addAiToTicketDescription),
      fetch({
        run: ({orgId, ticket, description, generationType}) => {
          if(generationType === 'Background'){
            return this.ticketsService.addTicketDescriptionBackground(orgId, ticket.workspaceId, ticket.ticketNumber, description)
            .pipe(
              map((res: any) => {
                this.dialog.closeAll();
                return TicketsActions.addAiToTicketDescriptionSuccess({ ticket: {...ticket, description: res.description, condensations: res.condensations} })
              })
            )
          } else if(generationType === 'Technical Notes'){
            return this.ticketsService.addTicketTechnicalNotes(orgId, ticket.workspaceId, ticket.ticketNumber, description)
            .pipe(
              map((res: any) => {
                this.dialog.closeAll();
                return TicketsActions.addAiToTicketDescriptionSuccess({ ticket: {...ticket, description: res.description, condensations: res.condensations} })
              })
            )
          } else if(generationType === 'Code Snippet'){
            return this.ticketsService.addTicketCodeSnippet(orgId, ticket.workspaceId, ticket.ticketNumber, description)
            .pipe(
              map((res: any) => {
                this.dialog.closeAll();
                return TicketsActions.addTicketCodeSnippetSuccess({ ticket: {...ticket, codeSnippet: res.codeSnippet} })
              })
            )
          } else {
            console.error('Invalid Type')
            return TicketsActions.addAiToTicketDescriptionFailure({ error: Error('Invalid Type') })
          }
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.addAiToTicketDescriptionFailure({ error })
        }
      })
    )
  );

  updateTicketWorkspaceTicket$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TicketsActions.updateTicketWorkspaceTicket),
        fetch({
          run: (action) => {
            return this.ticketsService.updateTicketWorkspaceTicket(action.orgId, action.ticket.workspaceId, action.ticket.ticketNumber, action.ticketUpdateParams)
              .pipe(
                map((response: any) => {
                  this.snackBar.open(`ticket has been updated`, undefined, { duration: 3000 });
                  return TicketsActions.updateTicketWorkspaceTicketSuccess({ orgId: action.orgId, ticket: action.ticket, ticketUpdateParams: {
                    ...response
                  }});
                })
              )
          },
          onError: (action, error) => {
            console.error('Error', error);
            return TicketsActions.updateTicketWorkspaceTicketFailure({ error });
          },
        })
      )
    );

    unlinkParentTicketFromTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.unlinkParentTicketFromTicket),
      fetch({
        run: (action) => {
          return this.ticketsService.unlinkParentTicketFromTicket(action.orgId, action.workspaceId, action.ticketNumber)
            .pipe(
              map((ticket: any) => {
                this.snackBar.open(`ticket parent has been updated`, undefined, { duration: 3000 });
                return TicketsActions.unlinkParentTicketFromTicketSuccess({ ticket });
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.unlinkParentTicketFromTicketFailure({ error });
        },
      })
    )
  );  

  moveCardInKanbanBoard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.moveCardInKanbanBoard),
      fetch({
        run: (action) => {
          return this.ticketsService.moveCardInKanbanColumn(action.orgId, action.workspaceId, action.boardId!, action.moveKanbanCardParams!)
            .pipe(
              map((response: any) => {
                this.snackBar.open(`Kanban card has been moved`, undefined, { duration: 3000 });
                return TicketsActions.moveCardInKanbanBoardSuccess({ orgId: action.orgId, 
                  workspaceId: action.workspaceId, boardId: action.boardId, moveKanbanCardParams: action.moveKanbanCardParams});
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.moveCardInKanbanBoardFailure({ error });
        },
      })
    )
  );  

  updateTicketSprint$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TicketsActions.updateTicketSprint),
        fetch({
          run: (action) => {
            return this.ticketsService.updateTicketWorkspaceTicket(action.orgId, action.ticket.workspaceId, action.ticket.ticketNumber, {boardId: action.sprint.boardId})
              .pipe(
                map((response: any) => {
                  this.snackBar.open(`ticket has been updated`, undefined, { duration: 3000 });
                  return TicketsActions.updateTicketSprintSuccess({ orgId: action.orgId, ticket: action.ticket, sprint: action.sprint })
                })
              )
          },
          onError: (action, error) => {
            console.error('Error', error);
            return TicketsActions.updateTicketSprintFailure({ error });
          },
        })
      )
    );

  uploadJiraApiKeyAndJiraOrg$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TicketsActions.uploadJiraApiKeyAndJiraOrg),
        fetch({
          run: (action) => {
            return this.ticketsService.uploadJiraApiKeyAndJiraOrg(action.orgId, action.jiraOrgId, action.jiraApiKey)
              .pipe(
                map((res: any) =>{
                  this.snackBar.open(`api key has been updated`, undefined, { duration: 3000 });
                  return TicketsActions.uploadJiraApiKeyAndJiraOrgSuccess({ jiraOrgId: res.jiraOrgId });
                })
              )
          },
          onError: (action, error) => {
            console.error('Error', error);
            return TicketsActions.uploadJiraApiKeyAndJiraOrgFailure({ error });
          },
        })
      )
    );

  uploadJiraApiKeyAndJiraOrgSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.uploadJiraApiKeyAndJiraOrgSuccess),
      map((action) => {
        return UserActions.updateOrgUserJiraOrgId({jiraOrgId: action.jiraOrgId})
      })
    )
  )

  uploadTicketToJira$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TicketsActions.uploadTicketToJira),
        fetch({
          run: (action) => {
            const description = action.ticket.description ? action.ticket.description : action.ticket.shortDescription;
            return this.ticketsService.uploadTicketToJira(action.ticket.orgId, action.ticketWorkspace.workspaceId, 
              action.ticket.ticketNumber, action.jiraInstance, action.ticketWorkspace.jiraProject, 
              action.ticket.type, action.ticket.title, description, action.ticket.labels, action.ticket.estimatedStoryPoints, action.ticket.assignee?.email)
              .pipe(
                map((response: any) => {
                  this.snackBar.open(`${action.ticket.title} has been uploaded to Jira`, undefined, { duration: 3000 });
                  this.router.navigateByUrl(`/tickets`);
                  return TicketsActions.uploadTicketToJiraSuccess({ ticket: action.ticket });
                })
              )
          },
          onError: (action, error) => {
            console.error('Error', error);
            return TicketsActions.uploadTicketToJiraFailure({ error });
          },
        })
      )
    );

  loadTicketHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.loadTicketHistory),
      fetch({
        run: (action) => {
          return this.ticketsService.getTicketHistory(action.orgId, action.workspaceId, action.ticketNumber)
            .pipe(
              map((response: any) => {
                return TicketsActions.loadTicketHistorySuccess({ ticketHistory: response });
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.loadTicketHistoryFailure({ error });
        },
      })
    )
  );

  generateUserJourney$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.generateUserJourney),
      fetch({
        run: (action) => {
          return this.ticketsService.generateUserJourney(action.ticket.orgId, action.ticket.workspaceId, action.ticket.ticketNumber, action.ticket.description)
            .pipe(
              map((response: any) => {
                this.dialog.closeAll();
                return TicketsActions.generateUserJourneySuccess({
                  userJourney: response.userJourney,
                  ticket: action.ticket
                });
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.generateUserJourneyFailure({ error });
        },
      })
    )
  );
  
  generateAcceptanceCriteriaForProductTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.generateAcceptanceCriteriaForProductTicket),
      fetch({
        run: (action) => {
          return this.ticketsService.generateAcceptanceCriteriaForProductTicket(action.ticket.orgId, action.ticket.workspaceId, action.ticket.ticketNumber, action.ticket.description)
            .pipe(
              map((response: any) => {
                this.dialog.closeAll();
                return TicketsActions.generateAcceptanceCriteriaForProductTicketSuccess({
                  acceptanceCriteria: response.acceptanceCriteria,
                  ticket: action.ticket
                });
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.generateAcceptanceCriteriaForProductTicketFailure({ error });
        },
      })
    )
  );
  generateBusinessValueForProductTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.generateBusinessValueForProductTicket),
      fetch({
        run: (action) => {
          return this.ticketsService.generateBusinessValueForProductTicket(action.ticket.orgId, action.ticket.workspaceId, action.ticket.ticketNumber, action.ticket.description)
            .pipe(
              map((response: any) => {
                this.dialog.closeAll();
                return TicketsActions.generateBusinessValueForProductTicketSuccess({
                  businessValue: response.businessValue,
                  ticket: action.ticket
                });
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.generateBusinessValueForProductTicketFailure({ error });
        },
      })
    )
  );

  generateEstimatedStoryPointsReasonForProductTicket$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.generateEstimatedStoryPointsReasonForProductTicket),
      fetch({
        run: (action) => {
          return this.ticketsService.generateEstimatedStoryPointsReasonForProductTicket(action.ticket.orgId, action.ticket.workspaceId, action.ticket.ticketNumber, action.ticket.description, action.ticket.estimatedStoryPoints!)
            .pipe(
              map((response: any) => {
                return TicketsActions.generateEstimatedStoryPointsReasonForProductTicketSuccess({
                  estimatedStoryPointsReason: response.estimatedStoryPointsReason,
                  ticket: action.ticket
                });
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.generateEstimatedStoryPointsReasonForProductTicketFailure({ error });
        },
      })
    )
  );

  removeTicketAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.removeTicketAttachment),
      fetch({
        run: (action) => {
          return this.ticketsService.removeTicketMedia(action.ticket.orgId, action.ticket.workspaceId, action.ticket.ticketNumber, action.attachmentFileName)
            .pipe(
              map((response: any) => {
                this.snackBar.open(`${action.attachmentFileName} has been removed`, undefined, { duration: 3000 });
                return TicketsActions.removeTicketAttachmentSuccess({ ticket: response });
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.removeTicketAttachmentFailure({ error });
        },
      })
    )
  );

  subscribeToTicket$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.subscribeToTicket),
      fetch({
        run: (action) => {
          return this.ticketsService.subscribeToTicket(action.ticket.orgId, action.ticket.workspaceId, action.ticket.ticketNumber)
            .pipe(map((res) => {
              this.snackBar.open(`You are now subscribed to changes on ${action.ticket.workspaceId}-${action.ticket.ticketNumber}`, '', {duration: 3000});
              return TicketsActions.subscribeToTicketSuccess({ticket: res})
            }))
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.subscribeToTicketFailure({error});
        }
      })
    )
  );
  
  unsubscribeFromTicket$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.unsubscribeFromTicket),
      fetch({
        run: (action) => {
          return this.ticketsService.unsubscribeFromTicket(action.ticket.orgId, action.ticket.workspaceId, action.ticket.ticketNumber)
            .pipe(map((res) => {
              this.snackBar.open(`You have been unsubscribed from changes on ${action.ticket.workspaceId}-${action.ticket.ticketNumber}`, '', {duration: 3000});
              return TicketsActions.unsubscribeFromTicketSuccess({ticket: res})
            }))
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.unsubscribeFromTicketFailure({error});
        }
      })
    )
  );

  renameKanbanColumn$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.renameKanbanColumn),
      fetch({
        run: (action) => {
          return this.ticketsService.renameKanbanColumn(action.orgId, action.workspaceId, action.columnId, action.newColumnName, action.boardId).pipe(
            map((res: any) => {
              this.snackBar.open('Column name has been updated', '', {duration: 3000})
              return TicketsActions.renameKanbanColumnSuccess({columnId: action.columnId, newColumnName: action.newColumnName})
            })
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.renameKanbanColumnFailure({error});
        }
      })
    )
  );

  createKanbanColumn$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.createKanbanColumn),
      fetch({
        run: (action) => {
          return this.ticketsService.createKanbanColumn(action.orgId, action.workspaceId, action.columnName, action.boardId).pipe(
            map(columns => {
              this.snackBar.open(`${action.columnName} has been added`, '', {duration: 3000})
              return TicketsActions.createKanbanColumnSuccess({columns})
            })
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.createKanbanColumnFailure({error})
        }
      })
    )
  );

  deleteKanbanColumn$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.deleteKanbanColumn),
      fetch({
        run: (action) => {
          return this.ticketsService.deleteKanbanColumn(action.orgId, action.workspaceId, action.columnId, action.newColumnId, action.boardId).pipe(
            map(ticketWorkspaceBoard => {
              this.snackBar.open(`Column has been deleted`, '', {duration: 3000})
              return TicketsActions.deleteKanbanColumnSuccess({ticketWorkspaceBoard})
            })
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.deleteKanbanColumnFailure({error})
        }
      })
    )
  );

  reorderKanbanColumns$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.reorderKanbanColumns),
      fetch({
        run: (action) => {
          return this.ticketsService.reorderKanbanColumns(action.orgId, action.workspaceId, action.boardColumns, action.boardId).pipe(
            map((res: any) => {
              this.snackBar.open('Kanban columns have been reordered', '', {duration: 3000})
              return TicketsActions.reorderKanbanColumnsSuccess({columnsRes: res})
            })
          )
        }, 
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.reorderKanbanColumnsFailure({error})
        }
      })
    )
  );

  updateTicketOrder$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.updateTicketOrder),
      fetch({
        run: (action) => {
          return this.ticketsService.updateTicketOrder(action.orgId, action.workspaceId, action.ticketsOrder, action.boardId).pipe(
            map((res: any) => {
              this.snackBar.open('Ticket order has been updated', '', {duration: 3000})
              return TicketsActions.updateTicketOrderSuccess({tickets: action.tickets})
            })
          )
        }, 
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.updateTicketOrderFailure({error})
        }
      })
    )
  );

  searchTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.searchTickets),
      fetch({
        run: (action) => {
          return this.ticketsService.searchTickets(action.search, action.searchFields, action.orgId, action.workspaceId, action.size, action.fromNum).pipe(
            map((res: any) => {
              return TicketsActions.searchTicketsSuccess({ticketsSearchResult: res})
            })
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.searchTicketsFailure({error})
        }
      })
    )
  );

  searchTicketWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.searchTicketWorkspaces),
      fetch({
        run: (action) => {
          return this.ticketsService.searchTicketWorkspaces(action.search, action.searchFields, action.orgId, action.workspaceId, action.size, action.fromNum).pipe(
            map((res: any) => {
              return TicketsActions.searchTicketWorkspacesSuccess({ticketWorkspaces: res.results, totalHits: res.totalHits})
            })
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.searchTicketWorkspacesFailure({error})
        }
      })
    )
  );

  getAllTicketWorkspaceEpics$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.getAllTicketWorkspaceEpics),
      fetch({
        run: (action) => {
          return this.ticketsService.getAllTicketWorkspaceEpics(action.orgId, action.workspaceId)
            .pipe(
              map((res: any) => {
                return TicketsActions.getAllTicketWorkspaceEpicsSuccess({epics: res})
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.getAllTicketWorkspaceEpicsFailure({error})
        }
      })
    )
  );

  getAllTitleTicketsForWorkspaceEpic$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.getAllTitleTicketsForWorkspaceEpic),
      fetch({
        run: (action) => {
          return this.ticketsService.getAllTitleTicketsForWorkspaceEpic(action.orgId, action.workspaceId, action.parentTicketNumber)
            .pipe(
              map((res: any) => {
                return TicketsActions.getAllTitleTicketsForWorkspaceEpicSuccess({tickets: res})
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketsActions.getAllTitleTicketsForWorkspaceEpicFailure({error})
        }
      })
    )
  );

  deleteTicketTitle$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketsActions.deleteTicketTitle),
      mergeMap((action) => 
        this.ticketsService.deleteTitleTicket(action.orgId, action.workspaceId, action.parentTicketNumber, action.ticketNumber).pipe(
          map((ticket: any) => {
            this.snackBar.open(`Generated ticket: ${ticket?.title}, has been deleted`, '', {duration: 3000});
            return TicketsActions.deleteTicketTitleSuccess({ticket});
          }),
          catchError((error) => of(TicketsActions.deleteTicketTitleFailure({error})))
        )
      )
    )
  );

  constructor(private ticketsService: TicketsService, private snackBar: MatSnackBar, private router: Router, 
    private dialog: MatDialog, private generateTicketsService: GenerateTicketsService){}
}

import { createAction, props } from '@ngrx/store';
import { CommentsEntity, CreateCommentParams } from './comments.models';

export const init = createAction('[Comments Page] Init');

export const loadComments = createAction(
  '[Comments] Load Comments',
  props<{pageUrl: string; componentName: string; currentUserOrgId: string; fileName?: string}>()
)
export const loadCommentsSuccess = createAction(
  '[Comments/API] Load Comments Success',
  props<{ comments: CommentsEntity[] }>()
);

export const loadCommentsFailure = createAction(
  '[Comments/API] Load Comments Failure',
  props<{ error: any }>()
);

export const createComment = createAction(
  '[Comments] Create Comment',
  props<{createCommentParams: CreateCommentParams, picture: string}>()
);

export const createCommentSuccess = createAction(
  '[Comments] Create Comment Success',
  props<{ comment: CommentsEntity}>()
)

export const addResponse = createAction(
  '[Comments] Add Response',
  props<{comment: CommentsEntity, response: any}>()
);

export const addResponseSuccess = createAction(
  '[Comments] Add Response Success',
  props<{ comment: CommentsEntity, response: any}>()
)
export const upvoteComment = createAction(
  '[Comments] Upvote Comment',
  props<{commentId: string, userId: string, userOrgId: string}>()
);
export const downvoteComment = createAction(
  '[Comments] Downvote Comment',
  props<{commentId: string, userId: string, userOrgId: string}>()
);

export const voteSuccess = createAction(
  '[Comments] Vote Success',
  props<{ comment: CommentsEntity}>()
)

export const deleteComment = createAction(
  '[Comments] Delete Comment',
  props<{commentId: string, userId: string, userOrgId: string}>()
);

export const deleteCommentSuccess = createAction(
  '[Comments] Delete Comment Success',
  props<{ comment: CommentsEntity}>()
);

export const deleteCommentResponse = createAction(
  '[Comments] Delete Comment Response',
  props<{commentId: string, userId: string, userOrgId: string, responseIndex: number}>()
);

export const deleteCommentResponseSuccess = createAction(
  '[Comments] Delete Comment Response Success',
  props<{ response: any, commentId: string}>()
);

export const updateComment = createAction(
  '[Comments] Update Comment',
  props<{userId: string,
    userOrgId: string,
    commentId: string,
    newComment: string}>()
);

export const updateCommentSuccess = createAction(
  '[Comments] Update Comment Success',
  props<{ comment: CommentsEntity}>()
);

export const updateCommentResponse = createAction(
  '[Comments] Update Comment Response',
  props<{userId: string,
    userOrgId: string,
    commentId: string,
    responseIndex: number,
    newComment: string}>()
);

export const updateCommentResponseSuccess = createAction(
  '[Comments] Update Comment Response Success',
  props<{ response: any, commentId: string}>()
);

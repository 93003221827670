import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action, select } from '@ngrx/store';

import * as RecipesActions from './recipes.actions';
import { RecipesEntity } from './recipes.models';
import { Recipe } from '@razroo-zeta/data-models';

export const RECIPES_FEATURE_KEY = 'recipes';

export interface RecipesState extends EntityState<RecipesEntity> {
  selectedId?: string | number; // which Recipes record has been selected
  recipesToAddAsBatch?: Recipe[];
  recipesToRemoveAsBatch?: Recipe[];
  orgId?: string;
  corePathId?: string;
  latest?: boolean;
  pathId?: string;
  loaded: boolean; // has the Recipes list been loaded
  error?: string | null; // last known error (if any)
}

export interface RecipesPartialState {
  readonly [RECIPES_FEATURE_KEY]: RecipesState;
}

export const recipesAdapter: EntityAdapter<RecipesEntity> =
  createEntityAdapter<RecipesEntity>();

export const initialRecipesState: RecipesState =
  recipesAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    recipesToAddAsBatch: [],
    recipesToRemoveAsBatch: []
  });

const reducer = createReducer(
  initialRecipesState,
  on(RecipesActions.loadRecipes, (state, {pathOrgId}) => ({
    ...state,
    orgId: pathOrgId, 
    loaded: false,
    error: null,
  })),
  on(RecipesActions.loadRecipesSuccess, (state, { recipes, path }) =>
    recipesAdapter.setAll(recipes, { 
      ...state,
      pathId: path.id,
      corePathId: path.corePathId,
      latest: path.latest,
      loaded: true
    })
  ),
  on(RecipesActions.reOrderRecipe, (state, { reOrderedRecipes }) =>
    recipesAdapter.setAll(reOrderedRecipes, { 
      ...state, 
      loaded: true
    })
  ),
  on(RecipesActions.toggleRecipe, (state, { recipe }) =>
    recipesAdapter.updateOne({id: recipe.id, changes: {selected: !recipe.selected}}, { 
      ...state, 
      selectedId: !recipe.selected ? recipe.id : undefined
    })
  ),
  on(RecipesActions.setRecipesToAddAsBatch, (state, { recipes }) => ({
    ...state,
    recipesToAddAsBatch: recipes
  })),
  on(RecipesActions.addToRecipesToRemove, (state, { recipe }) => 
    recipesAdapter.updateOne({id: recipe.id, changes: {selected: false, executionId: undefined, batchId: undefined}}, {
      ...state,
      recipesToRemoveAsBatch: state.recipesToRemoveAsBatch ? [...state.recipesToRemoveAsBatch, recipe] : [recipe]
    })
  ),
  on(RecipesActions.updateRecipeSuccess, (state, { recipe }) => 
    recipesAdapter.upsertOne(recipe, {
      ...state,
      recipesToRemoveAsBatch: state.recipesToRemoveAsBatch?.filter(recipeToRemoveAsBatch => recipeToRemoveAsBatch.id !== recipe.id),
      recipesToAddAsBatch: state.recipesToAddAsBatch?.filter(recipeToAddAsBatch => recipeToAddAsBatch.id !== recipe.id)
    })
  ),
  on(RecipesActions.createRecipeSuccess, (state, { recipe }) => 
    recipesAdapter.addOne(recipe, {...state})
  ),
  on(RecipesActions.deleteRecipeSuccess, (state, { recipe }) => 
    recipesAdapter.removeOne(recipe.id, {...state})
  ),
  on(RecipesActions.loadRecipesFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function recipesReducer(
  state: RecipesState | undefined,
  action: Action
) {
  return reducer(state, action);
}

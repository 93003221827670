import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersioningService {

  constructor() { }

  getVersionAndNameString(pathId: string): {name: string, version: string | undefined}  {
    let version: string | undefined;
    const splitPathId = pathId.split('-');
    if(this.hasSemver(pathId)) {
      version = splitPathId.pop();
    }
    const pathIdName = splitPathId.join('-');
    return {name: pathIdName, version: version};
  }

  hasSemver(pathId: string) {
    return /\d+\.\d+\.\d+/.test(pathId);
  }
}

import { FlatFolderFile } from './../../../../common-services/src/lib/file-tree/file-tree.service';
import { Injectable } from '@angular/core';
import { getCodeModOptions, getCodeModParams, PowerUpVariables, powerUpVariablesFlatData, replaceTagParameters, types } from '@codemorph/devkit';
import { CodeMorph } from '@razroo-zeta/data-graphql';
import { Apollo } from 'apollo-angular';
import { from, pluck } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CodeModService {

  constructor(private apollo: Apollo) { }

  getCodeModOptions(fileType: string) {
    return getCodeModOptions(fileType);
  }

  getTypes(programmingLanguage: string) {
    return types(programmingLanguage);
  }

  getCodeModParams(fileType: string, optionSelected: string) {
    return getCodeModParams(fileType, optionSelected);
  }

  getTemplateVariablesAsParameters(textToExtractFrom: string = ''): any {
    const templateVariables = {};
    if(!(textToExtractFrom).match(/<%=\s*(\w+)\s*%>/g)) {
      return {};
    }
    (textToExtractFrom as any).match(/<%=\s*(\w+)\s*%>/g).forEach((variable) => {
      const variableNameOnly = variable.split(' ')[1];
      templateVariables[variableNameOnly] = variableNameOnly;
    })
    return templateVariables
  }

  stubCodeMods(codeMods: any[]) {
    codeMods.map(codeModObject => {
      for (const i in codeModObject) {
        const stubsAsParameters = this.getTemplateVariablesAsParameters(codeModObject[i]);
        codeModObject[i] = replaceTagParameters(stubsAsParameters, codeModObject[i]);
      }
      return codeModObject;
    });
    return codeMods;
  }

  stubsAsParameters(powerUpVariables: PowerUpVariables[]) {
    const parameterObject = {};
    powerUpVariables.forEach(powerUpVariable => {
      if (powerUpVariable.stubValue) {
        parameterObject[powerUpVariable.name] = powerUpVariable.stubValue
      }
    })
    return parameterObject;
  }

  createEditObjectViaCodemods(codeMods, openFile: FlatFolderFile) {
    const fileType = openFile.name.split('.').pop();

    return {
      fileType: fileType,
      fileToBeAddedTo: openFile.fileText,
      edits: codeMods
    }
  }

  createEditObject(updates: string, openFile: FlatFolderFile) {
    const fileType = openFile.name.split('.').pop();

    const parsedUpdates = JSON.parse(updates);
    const currentUpdate = parsedUpdates.find(update => update.filePath === openFile.filesToGenerateFolderName && update.fileName === openFile.name);
    return {
      fileType: fileType,
      fileToBeAddedTo: openFile.fileText,
      edits: currentUpdate.edits
    }
  }

  codeMorph(
      userOrgId: string,
      templateOrgId: string,
      pathId: string,
      recipeId: string,
      stepId: string,
      fileType: string,
      filePath: string,
      codeMods: string,
      fileString: string
    ){
    const mutation = CodeMorph;
    const variables = {
      userOrgId,
      templateOrgId,
      pathId,
      recipeId,
      stepId,
      fileType,
      filePath,
      codeMods,
      fileString
    }
    const codeMorph$ = this.apollo.mutate({mutation, variables});
    return from(codeMorph$).pipe(pluck('data', 'codeMorph'));
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCodeGenerationHistory from './+state/code-generation-history.reducer';
import { CodeGenerationHistoryEffects } from './+state/code-generation-history.effects';
import { CodeGenerationHistoryFacade } from './+state/code-generation-history.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCodeGenerationHistory.CODE_GENERATION_HISTORY_FEATURE_KEY,
      fromCodeGenerationHistory.codeGenerationHistoryReducer
    ),
    EffectsModule.forFeature([CodeGenerationHistoryEffects]),
  ],
  providers: [CodeGenerationHistoryFacade],
})
export class DataAccessCodeGenerationHistoryModule {}

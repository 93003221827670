import { CodeChatService } from '@razroo-zeta/data-services';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import * as CodeChatActions from './code-chat.actions';
import { CodeChatFacade } from './code-chat.facade';
import * as CodeChatFeature from './code-chat.reducer';
import { map, switchMap, take, withLatestFrom } from 'rxjs';
import {
  CodeChatMessageRoleEnum,
  GenerationStatus,
} from '@razroo-zeta/data-models';
import { Store } from '@ngrx/store';

@Injectable()
export class CodeChatEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeChatActions.initCodeChat),
      fetch({
        run: (action) => {
          return this.codeChatService
            .generateCodeChat(
              action.userOrgId,
              action.pathOrgId,
              action.pathId,
              action.technologies,
              action.messages,
              action.chatId
            )
            .pipe(
              map((codeChat: any) => {
                this.reloadCounter = 0;
                if (codeChat.generationStatus === GenerationStatus.Generating) {
                  return CodeChatActions.loadCodeChatPolling({
                    codeChat,
                  });
                } else {
                  return CodeChatActions.loadCodeChatSuccess({
                    codeChat,
                  });
                }
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return this.codeChatFacade.selectedCodeChat$.pipe(
            take(1),
            map((codeChat: any) => {
              const errorMessage = {
                role: CodeChatMessageRoleEnum.Assistant,
                content: 'Aw shucks. System error. Please try again.',
              };
              const updatedCodeChat = {
                ...codeChat,
                messages: [...codeChat.messages, errorMessage],
              };
              return CodeChatActions.loadCodeChatFailure({
                error,
                codeChat: updatedCodeChat,
              });
            })
          );
        },
      })
    )
  );

  initChatHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeChatActions.initChatHistory),
      fetch({
        run: (action) => {
          return this.codeChatService.getCodeChatHistory(action.orgId, 10).pipe(
            map((chatHistory: any) => {
              return CodeChatActions.loadChatHistorySuccess({
                chatHistory: chatHistory.Items,
                lastEvaluatedKey: chatHistory.LastEvaluatedKey,
              });
            })
          );
        },
      })
    )
  );

  searchChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeChatActions.searchChat),
      fetch({
        run: (action) => {
          return this.codeChatService
            .searchCodeChat(action.orgId, action.search, undefined, 20, 1)
            .pipe(take(1))
            .pipe(
              map((chatHistory: any) => {
                return CodeChatActions.loadChatHistorySuccess({
                  chatHistory: chatHistory,
                });
              })
            );
        },
      })
    )
  );

  loadCodeChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeChatActions.loadCodeChat),
      fetch({
        run: (action) => {
          return this.codeChatService.getCodeChatById(action.chatId).pipe(
            map((codeChat: any) => {
              if (codeChat.generationStatus === GenerationStatus.Generating) {
                return CodeChatActions.loadCodeChatPolling({
                  codeChat,
                });
              } else {
                return CodeChatActions.loadCodeChatSuccess({
                  codeChat,
                });
              }
            })
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return this.codeChatFacade.selectedCodeChat$.pipe(
            take(1),
            map((codeChat: any) => {
              const errorMessage = {
                role: CodeChatMessageRoleEnum.Assistant,
                content: 'Aw shucks. System error. Please try again.',
              };
              const updatedCodeChat = {
                ...codeChat,
                messages: [...codeChat?.messages, errorMessage],
              };
              return CodeChatActions.loadCodeChatFailure({
                error,
                codeChat: updatedCodeChat,
              });
            })
          );
        },
      })
    )
  );

  reloadCounter = 0;

  loadCodeChatPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeChatActions.loadCodeChatPolling), // Replace with your first action type
      fetch({
        run: (action) => {
          if (
            action.codeChat.generationStatus === GenerationStatus.Generating &&
            this.reloadCounter < 24
          ) {
            this.reloadCounter++;
            setTimeout(() => {
              this.store.dispatch(
                CodeChatActions.loadCodeChat({ chatId: action.codeChat.id })
              );
            }, 5000);
          } else {
            this.store.dispatch(
              CodeChatActions.loadCodeChatSuccess({ codeChat: action.codeChat })
            );
            return;
          }
        },
      })
    )
  );

  cancelChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeChatActions.cancelChat),
      fetch({
        run: (action) => {
          return this.codeChatFacade.selectedCodeChat$.pipe(
            take(1),
            map((codeChat: any) => {
              const cancelMessage = {
                role: CodeChatMessageRoleEnum.Assistant,
                content: 'Cancelled',
              };
              const updatedCodeChat = {
                ...codeChat,
                messages: [...codeChat.messages, cancelMessage],
              };
              this.codeChatService.cancelCodeChat();
              return CodeChatActions.cancelChatSuccess({
                codeChat: updatedCodeChat,
              });
            })
          );
        },
      })
    )
  );

  deleteCodeChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeChatActions.deleteCodeChat),
      fetch({
        run: (action) => {
          return this.codeChatService.deleteCodeChat(action.chatId).pipe(
            map((codeChat: any) => {
              return CodeChatActions.deleteCodeChatSuccess({
                codeChat,
              });
            })
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return CodeChatActions.deleteCodeChatFailure({ error });
        },
      })
    )
  );

  loadMoreHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeChatActions.loadMoreHistory),
      withLatestFrom(this.codeChatFacade.lastEvaluatedKey$),
      switchMap(([action, lastEvaluatedKey]) => {
        return this.codeChatService
          .getCodeChatHistory(action.orgId, 10, lastEvaluatedKey)
          .pipe(
            map((chatHistory: any) => {
              return CodeChatActions.loadMoreHistorySuccess({
                chatHistory: chatHistory.Items,
                lastEvaluatedKey: chatHistory.LastEvaluatedKey,
              });
            })
          );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private codeChatService: CodeChatService,
    private codeChatFacade: CodeChatFacade,
    private readonly store: Store<CodeChatFeature.CodeChatState>
  ) {}
}

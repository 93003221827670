export const TemplateFragment = `
  fragment templateFields on Template {
    ai
    author
    userId
    id
    title
    latest
    corePathId
    next
    prev
    isPublished
    description
    generationStatus
    likeCount
    metadata
    pathType
    starter
    codePath
    fileTree
    firstFile
    firstFileContents
    beta
    parameters {
      defaultValue
      description
      inputType
      name
      paramType
      type
      optionalTypes {
        name
        selected
      }
    }
    relevantQuestions
    languageVersion
    count
    orgId
    recipeId
    batchId
    executionId
    paid
    instructionalContent
    baseCommunityPath
    dependentSteps {
      orgId
      pathId
      recipeId
      stepId
    }
    symlinkStep {
      orgId
      pathId
      recipeId
      id
    }
    iconName
    type
    pathId
    updates
    lastUpdated
    screenshot
    scaffold
    project
    documentation {
      content
      title
    }
    previewFiles
    download {
      downloadUrl
      previewUrl
    }
    privacy
    stepper {
      title
      id
      isPublished
      description
      pathId
      recipeId
      iconName
    }
    integrationTestsFailed
    integrationTestsPassed
    unitTestsFailed
    unitTestsPassed
    marketplace
    marketplacePaths {
      pathId
      pathOrgId
      description
    }
    qaStatus {
      qa
      users {
        userId
        firstName
        lastName
        email
      }
    }
  }
`;

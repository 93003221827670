import { gql } from 'graphql-tag';

export const UpdateUserProductAccess = gql`
  mutation UpdateUserProductAccess(
    $userIdToAdd: String!
    $orgId: String!
    $productId: String!
    $access: Boolean!
  ) {
    updateUserProductAccess(
      userIdToAdd: $userIdToAdd
      orgId: $orgId
      productId: $productId
      access: $access
    ) {
      userId
      orgId
      projectsPaid
      codePaid
    }
  }
`;

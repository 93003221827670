import { gql } from 'graphql-tag';
import { TemplateFragment } from '../template/template.fragments';

export const CreatePath = gql`
    mutation createPath($orgId: String!, $userId: String!, $path: String!, $description: String, $baseCommunityPath: String, $privacy: Privacy!, $languageVersion: String!, $marketplace: Boolean, $minimumPrice: Float){
        createPath(orgId: $orgId, userId: $userId, path: $path, description: $description, baseCommunityPath: $baseCommunityPath, privacy: $privacy, languageVersion: $languageVersion, marketplace: $marketplace, minimumPrice: $minimumPrice){
            author
            codePath
            count
            description
            fileTree
            id
            instructionalContent
            baseCommunityPath
            lastUpdated
            next
            orgId
            pathId
            languageVersion
            scaffold
            parameters {
              defaultValue
              description
              inputType
              name
              paramType
              type
              optionalTypes {
                name,
                selected
              }
            }
            recipeId
            prev
            relevantQuestions
            stepper{
                id
                title
            }
            timestamp
            title
            type
            updates
            privacy
            marketplace
            minimumPrice
            beta
        }
    }
`

export const CreateRecipe = gql`
    mutation CreateRecipe(
        $orgId: String!,
        $userId: String!,
        $pathId: String!,
        $recipeName: String!,
        $description: String,
        $previousRecipeId: String
        ){
        createRecipe(orgId: $orgId, userId: $userId, pathId: $pathId, recipeName: $recipeName, description: $description, previousRecipeId: $previousRecipeId){
            ai
            author
            codePath
            count
            description
            fileTree
            id
            instructionalContent
            lastUpdated
            baseCommunityPath
            next
            orgId
            pathId
            recipeId
            parameters {
              defaultValue
              description
              inputType
              name
              paramType
              type
              optionalTypes {
                name,
                selected
              }
            }
            prev
            relevantQuestions
            scaffold
            stepper{
                id
                title
            }
            timestamp
            title
            type
            updates
        }
    }
`

export const UpdateRecipe = gql`
    mutation UpdateRecipe(
        $orgId: String!,
        $pathId: String!,
        $recipeId: String!,
        $recipeUpdateParams: RecipeUpdateParams
    ){
        updateRecipe(orgId: $orgId, pathId: $pathId, recipeId: $recipeId, recipeUpdateParams: $recipeUpdateParams){
            ai
            author
            codePath
            count
            description
            fileTree
            id
            instructionalContent
            lastUpdated
            baseCommunityPath
            next
            orgId
            pathId
            recipeId
            executionId
            batchId
            isPublished
            iconName
            scaffold
            stepper{
                description
                firstPathStep
                firstStep
                id
                isPublished
                pathId
                recipeId
                title
            }
            parameters {
              defaultValue
              description
              inputType
              name
              paramType
              type
              optionalTypes {
                name,
                selected
              }
            }
            prev
            relevantQuestions
            stepper{
                id
                title
            }
            timestamp
            title
            type
            updates
        }
    }
`

export const CreateStep = gql`
    mutation CreateStep(
        $orgId: String!
        $userId: String!
        $pathId: String!
        $recipeId: String!
        $stepName: String!
        $stepType: StepType
        $description: String
        $createStepParams: CreateStepParams
        ){
        createStep(orgId: $orgId, userId: $userId, pathId: $pathId, recipeId: $recipeId, stepName: $stepName, stepType: $stepType, description: $description, createStepParams: $createStepParams){
            ai
            author
            codePath
            count
            description
            fileTree
            id
            instructionalContent
            lastUpdated
            next
            orgId
            pathId
            baseCommunityPath
            recipeId
            parameters {
              defaultValue
              description
              inputType
              name
              paramType
              type
              optionalTypes {
                name,
                selected
              }
            }
            prev
            relevantQuestions
            timestamp
            title
            type
            updates
        }
    }
`

export const UpdateStep = gql`
    mutation UpdateStep(
        $orgId: String!
        $pathId: String!
        $recipeId: String!
        $stepId: String!
        $stepUpdateParams: StepUpdateParams
        ){
        updateStep(orgId: $orgId, pathId: $pathId, recipeId: $recipeId, stepId: $stepId, stepUpdateParams: $stepUpdateParams){
          ...templateFields  
        }
    }
    ${TemplateFragment}
`;

export const ArchiveTemplate = gql`
    mutation ArchiveTemplate(
        $orgId: String!
        $pathId: String!
        $recipeId: String
        $stepId: String
        ){
        archiveTemplate(orgId: $orgId, pathId: $pathId, recipeId: $recipeId, stepId: $stepId){
            ai
            author
            codePath
            isPublished
            lastUpdated
            count
            description
            fileTree
            id
            instructionalContent
            lastUpdated
            next
            orgId
            pathId
            recipeId
            scaffold
            parameters {
              defaultValue
              description
              inputType
              name
              paramType
              type
              optionalTypes {
                name,
                selected
              }
            }
            prev
            privacy
            relevantQuestions
            timestamp
            title
            type
            updates
        }
    }
`

export const AddSecondaryPath = gql`
    mutation addSecondaryPath(
        $orgId: String!
        $userId: String!
        $pathId: String!
        $secondaryPathId: String!
        $secondaryPathOrgId: String!
        ){
        addSecondaryPath(
            orgId: $orgId,
            userId: $userId,
            pathId: $pathId,
            secondaryPathId: $secondaryPathId,
            secondaryPathOrgId: $secondaryPathOrgId
            ){
              id
              orgId
              title
        }
    }
`

export const RemoveSecondaryPath = gql`
    mutation removeSecondaryPath(
        $orgId: String!
        $userId: String!
        $pathId: String!
        $secondaryPathId: String!
        $secondaryPathOrgId: String!
        ){
        removeSecondaryPath(
            orgId: $orgId,
            userId: $userId,
            pathId: $pathId,
            secondaryPathId: $secondaryPathId,
            secondaryPathOrgId: $secondaryPathOrgId
            ){
                id
                orgId
                title

        }
    }
`

export const UpdatePath = gql`
    mutation UpdatePath($orgId: String!,
        $pathId: String!,
        $pathUpdateParams: PathUpdateParams){
        updatePath(orgId: $orgId, pathId: $pathId, pathUpdateParams: $pathUpdateParams){
            id
            orgId
            pathId
            recipeId
            title
            description
            pathType
            baseCommunityPath
            minimumPrice
            paid
            discordUrl
            privacy
            isPublished
            lastUpdated
            type
            marketplace
            beta
        }
    }
`
export const ClonePathWithNewVersionId = gql`
    mutation ClonePathWithNewVersionId(
        $orgId: String!,
        $userId: String!,
        $pathId: String!,
        $newLanguageVersion: String!
    ){
        clonePathWithNewVersionId(
            orgId: $orgId,
            userId: $userId,
            pathId: $pathId,
            newLanguageVersion: $newLanguageVersion
        ){
            newPathId
        }
    }
`

export const LikeTemplate = gql`
    mutation LikeTemplate(
        $userId: String!,
        $templateOrgId: String!,
        $pathId: String!,
        $recipeId: String,
        $stepId: String){
            likeTemplate(
                userId: $userId,
                templateOrgId: $templateOrgId,
                pathId: $pathId,
                recipeId: $recipeId,
                stepId: $stepId
            ){
                ai
                id
                isPublished
                languageVersion
                likeCount
                marketplace
                next
                orgId
                pathId
                prev
                privacy
                recipeId
                title
                type
            }
        }
`
export const DownvoteTemplate = gql`
    mutation DownvoteTemplate(
        $userId: String!,
        $templateOrgId: String!,
        $pathId: String!,
        $recipeId: String,
        $stepId: String){
            downvoteTemplate(
                userId: $userId,
                templateOrgId: $templateOrgId,
                pathId: $pathId,
                recipeId: $recipeId,
                stepId: $stepId
            ){
                ai
                id
                isPublished
                languageVersion
                likeCount
                marketplace
                next
                orgId
                pathId
                prev
                privacy
                recipeId
                title
                type
            }
        }
`


export const CreateCodeMetadata = gql`
    mutation CreateCodeMetadata(
        $userOrgId: String!,
        $pathId: String!,
        $recipeId: String!,
        $stepId: String!,
        $dependencies: AWSJSON,
        $devDependencies: AWSJSON,
        $orgId: String
        ){
        createCodeMetadata(
            userOrgId: $userOrgId,
            pathId: $pathId,
            recipeId: $recipeId,
            stepId: $stepId,
            dependencies: $dependencies,
            devDependencies: $devDependencies,
            orgId: $orgId
        )
    }
`

export const AddMarketplacePathToCommunity = gql`
    mutation addMarketplacePathToCommunity(
        $userOrgId: String!
        $communityPathId: String!
        $marketplaceOrgId: String!
        $marketplacePathId: String!
        $marketplacePathDescription: String!
    ){
        addMarketplacePathToCommunity(
            userOrgId: $userOrgId
            communityPathId: $communityPathId
            marketplaceOrgId: $marketplaceOrgId
            marketplacePathId: $marketplacePathId
            marketplacePathDescription: $marketplacePathDescription
        ){
            author
            baseCommunityPath
            codePath
            corePathId
            description
            discordUrl
            id
            language
            languageVersion
            lastUpdated
            latest
            marketplace
            marketplacePaths {
                pathId
                pathOrgId
                description
            }
            orgId
            parameters
            parentPath {
                id
                orgId
                title
            }
            pathType
            project
            schema
            stepperSearch
            timestamp
            title
            type
            userId
            beta
        }
    }
`

export const RemoveMarketplacePathFromCommunityPath = gql`
    mutation removeMarketplacePathFromCommunityPath(
        $userOrgId: String!
        $communityPathId: String!
        $marketplaceOrgId: String!
        $marketplacePathId: String!
    ){
        removeMarketplacePathFromCommunityPath(
            userOrgId: $userOrgId
            communityPathId: $communityPathId
            marketplaceOrgId: $marketplaceOrgId
            marketplacePathId: $marketplacePathId
        ){
            author
            baseCommunityPath
            codePath
            corePathId
            description
            discordUrl
            id
            language
            languageVersion
            lastUpdated
            latest
            marketplace
            marketplacePaths {
                pathId
                pathOrgId
                description
            }
            orgId
            parameters
            parentPath {
                id
                orgId
                title
            }
            pathType
            project
            schema
            stepperSearch
            timestamp
            title
            type
            userId
            beta
        }
    }
`

export const ReorderCommunityMarketplacePaths = gql`
    mutation reorderCommunityMarketplacePaths(
            $userOrgId: String!
            $communityPathId: String!
            $marketplaceOrgId: String!
            $marketplacePathId: String!
            $previousMarketplaceOrgId: String
            $previousMarketplacePathId: String
        ){
        reorderCommunityMarketplacePaths(
            userOrgId: $userOrgId
            communityPathId: $communityPathId
            marketplaceOrgId: $marketplaceOrgId
            marketplacePathId: $marketplacePathId
            previousMarketplaceOrgId: $previousMarketplaceOrgId
            previousMarketplacePathId: $previousMarketplacePathId
        ){
            author
            baseCommunityPath
            codePath
            corePathId
            description
            discordUrl
            id
            language
            languageVersion
            lastUpdated
            latest
            marketplace
            marketplacePaths {
                pathId
                pathOrgId
                description
            }
            orgId
            parameters
            parentPath {
                id
                orgId
                title
            }
            pathType
            project
            schema
            stepperSearch
            timestamp
            title
            type
            userId
            beta
        }
    }
`

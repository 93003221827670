import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { GetGithubInfo } from '@razroo-zeta/data-graphql';

@Injectable({
  providedIn: 'root',
})
export class GithubInfoService {
  constructor(private apollo: Apollo) {}

  getGithubInfo(org: string) {
    const query = GetGithubInfo;
    const variables = {
      org: org,
    };

    const githubInfo$ = this.apollo.query({ query, variables });
    return from(githubInfo$).pipe(pluck('data', 'githubInfo'));
  }
}

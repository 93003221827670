import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Invoice } from '@razroo-zeta/data-models';
import { BILLING_FEATURE_KEY, State, invoicesAdapter } from './billing.reducer';

// Lookup the 'Billing' feature state managed by NgRx
export const getInvoicesState =
  createFeatureSelector<State>(BILLING_FEATURE_KEY);

const { selectAll, selectEntities } = invoicesAdapter.getSelectors();

export const getInvoicesLoaded = createSelector(
  getInvoicesState,
  (state: State) => state.invoices.loaded
);

export const getInvoicesError = createSelector(
  getInvoicesState,
  (state: State) => state.invoices.error
);

export const getAllInvoices = createSelector(getInvoicesState, (state: State) =>
  selectAll(state.invoices)
);

export const getInvoices = createSelector(
  getAllInvoices,
  (invoices: any) => {
    return invoices.filter((invoice: Invoice) => invoice.id !== null)
  }
);

export const getInvoicesEntities = createSelector(
  getInvoicesState,
  (state) => selectEntities(state.invoices)
);

export const getFutureInvoice = createSelector(
  getInvoicesEntities,
  (invoices: any) => invoices['null']
);

export const getSelectedId = createSelector(
  getInvoicesState,
  (state: State) => state.invoices.selectedId
);

export const getSelected = createSelector(
  getInvoicesEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

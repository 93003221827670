import { Injectable, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as TicketsActions from './tickets.actions';
import * as TicketsSelectors from './tickets.selectors';
import { ClarificationQuestionResponse, OrganizationUser, Ticket, TicketWorkspace, TicketWorkspaceBoard, 
  UpdateTicketColumnParams, TicketWorkspaceTicketUpdateParams, TicketWorkspaceBoardColumn, EpicSuggestion } from '@razroo-zeta/data-models';
import { AbridgedTicket, MoveKanbanCardParamsInputAlt, TitleTicket, EstimatedStoryPointsReason } from 'libs/data-models/src/lib/tickets/tickets.interface';

@Injectable()
export class TicketsFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(TicketsSelectors.selectTicketsLoaded));
  allTickets$ = this.store.pipe(select(TicketsSelectors.selectAllTickets));
  selectedTickets$ = this.store.pipe(select(TicketsSelectors.selectEntity));
  selectedTicket$ = this.store.pipe(select(TicketsSelectors.selectEntity));
  tickets$ = this.store.pipe(select(TicketsSelectors.selectTickets));
  communityTicketWorkspaces$ = this.store.pipe(select(TicketsSelectors.selectCommunityTicketWorkspaces));
  totalHits$ = this.store.pipe(select(TicketsSelectors.selectTotalHits));
  epics$ = this.store.pipe(select(TicketsSelectors.selectEpics));
  columns$ = this.store.pipe(select(TicketsSelectors.selectAllColumns));
  boardId$ = this.store.pipe(select(TicketsSelectors.selectBoardId));
  columnsLoaded$ = this.store.pipe(select(TicketsSelectors.selectColumnsLoaded));
  generatingAI$ = this.store.pipe(select(TicketsSelectors.selectGeneratingAI));
  generatingTicketAI$ = this.store.pipe(select(TicketsSelectors.selectGeneratingTicketAI));
  generatingTicketsAI$ = this.store.pipe(select(TicketsSelectors.selectGeneratingTicketsAI));
  epicGenerating$ = this.store.pipe(select(TicketsSelectors.selectEpicGenerating));
  generatingTitleTickets$ = this.store.pipe(select(TicketsSelectors.selectGeneratingTitleTickets));
  titleTickets$ = this.store.pipe(select(TicketsSelectors.selectTitleTickets));
  ticketUpdating$ = this.store.pipe(select(TicketsSelectors.ticketUpdating));
  ticketUpdatingStatus$ = this.store.pipe(select(TicketsSelectors.selectTicketUpdatingStatus));
  ticketHistory$ = this.store.pipe(select(TicketsSelectors.selectAllTicketHistory));
  historySelectedId$ = this.store.pipe(select(TicketsSelectors.selectHistorySelectedId));
  ticketHistoryLoaded$ = this.store.pipe(select(TicketsSelectors.selectHistoryLoaded));
  ticketHistoryEdited$ = this.store.pipe(select(TicketsSelectors.selectHistoryEdited));
  epicSuggestions$ = this.store.pipe(select(TicketsSelectors.selectEpicSuggestions));
  selectedEpicId$ = this.store.pipe(select(TicketsSelectors.selectSelectedEpicId));
  selectedEpic$ = this.store.pipe(select(TicketsSelectors.selectSelectedEpic));

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  loadTicket(orgId: string, workspaceId: string, ticketNumber: number, noCache?: boolean){
    this.store.dispatch(TicketsActions.loadTicket({orgId, workspaceId, ticketNumber, noCache}));
  }
  loadTicketSuccess(ticket: Ticket){
    this.store.dispatch(TicketsActions.loadTicketSuccess({ticket}));
  }
  loadTickets(orgId: string, workspaceId: string, boardId?: number | undefined) {
    this.store.dispatch(TicketsActions.loadTickets({orgId, workspaceId, boardId}));
  }
  loadTicketsSuccess(tickets: Ticket[]){
    this.store.dispatch(TicketsActions.loadTicketsSuccess({tickets}));
  }

  selectTicket(ticket: Ticket){
    this.store.dispatch(TicketsActions.selectTicket({ticket}))
  }

  generateProductTicketTitlesAndUpload(
    orgId: string,
    workspaceId: string,
    currentEpicTickets: AbridgedTicket[],
    userProductMessage: string,
    parentTicketNumber: number,
    epic: string,
    startingTicketNumber: number
  ){
    this.store.dispatch(TicketsActions.generateProductTicketTitlesAndUpload({orgId, workspaceId, currentEpicTickets, userProductMessage, parentTicketNumber, epic, startingTicketNumber}));
  }

  generateEntireProductTicketUsingAiAndUpload(user: OrganizationUser, orgId: string, workspaceId: string, userTicketPrompt: string, epic?: string, boardId?: number, 
    clarificationQuestionResponse?: ClarificationQuestionResponse[], columns?: TicketWorkspaceBoardColumn[], board?: TicketWorkspaceBoard, parentTicketNumber?: number){
    this.store.dispatch(TicketsActions.generateEntireProductTicketUsingAiAndUpload({user, orgId, workspaceId, userTicketPrompt, epic, boardId, clarificationQuestionResponse, columns, board, parentTicketNumber}));
  }

  generateEntireProductTicketForKanbanUsingAiAndUpload(user: OrganizationUser, orgId: string, workspaceId: string, userTicketPrompt: string, epic?: string, boardId?: number, clarificationQuestionResponse?: ClarificationQuestionResponse[]){
    this.store.dispatch(TicketsActions.generateEntireProductTicketForKanbanUsingAiAndUpload({user, orgId, workspaceId, userTicketPrompt, epic, boardId, clarificationQuestionResponse}));
  }

  generateEntireProductTicketUsingAiAndUploadSuccess(ticket: Ticket, board?: TicketWorkspaceBoard) {
    this.store.dispatch(TicketsActions.generateEntireProductTicketUsingAiAndUploadSuccess({ticket, board}));
  }

  deleteTicket(orgId: string, workspaceId: string, boardId: number, ticketNumber: number, ticketTitle: string, board: TicketWorkspaceBoard){
    this.store.dispatch(TicketsActions.deleteTicket({orgId, workspaceId, boardId, ticketNumber, ticketTitle, board}))
  }

  generateDescriptionForProductTicketAndUpdate(ticket: Ticket){
    this.store.dispatch(TicketsActions.generateDescriptionForProductTicketAndUpdate({ticket}));
  }

  addBackgroundPlusTechnicalNotes(ticket){
    this.store.dispatch(TicketsActions.addBackgroundPlusTechnicalNotes({ticket}))
  }

  addTicketScenario(orgId: string, workspaceId: string, ticketNumber: number, description: string, userPrompt: string){
    this.store.dispatch(TicketsActions.addTicketScenario({orgId, workspaceId, ticketNumber, description, userPrompt}))
  }

  addAiToTicketDescription(orgId: string, ticket: Ticket, description: string, generationType: string){
    this.store.dispatch(TicketsActions.addAiToTicketDescription({orgId, ticket, description, generationType}))
  }

  updateTicketWorkspaceTicket(orgId: string, ticket: Ticket, ticketUpdateParams: TicketWorkspaceTicketUpdateParams) {
    this.store.dispatch(TicketsActions.updateTicketWorkspaceTicket({orgId, ticket, ticketUpdateParams}));
  }

  unlinkParentTicketFromTicket(orgId: string, workspaceId: string, ticketNumber: number) {
    this.store.dispatch(TicketsActions.unlinkParentTicketFromTicket({orgId, workspaceId, ticketNumber}));
  }

  modifyTicketBasedOnTitleUsingAi(orgId: string, workspaceId: string, ticketNumber: number, 
    existingDescription: string, existingTitle: string, updatedTitle: string) {
    this.store.dispatch(TicketsActions.modifyTicketBasedOnTitleUsingAi({orgId, workspaceId, ticketNumber, 
      existingDescription, existingTitle, updatedTitle}));
  }

  updateTicketWorkspaceTicketSuccess(orgId: string, ticket: Ticket, ticketUpdateParams: TicketWorkspaceTicketUpdateParams) {
    this.store.dispatch(TicketsActions.updateTicketWorkspaceTicketSuccess({orgId, ticket, ticketUpdateParams}));
  }

  updateWorkspaceTicketColumn(orgId: string, workspaceId: string, ticketNumber: number, ticketUpdateParams: UpdateTicketColumnParams) {
    this.store.dispatch(TicketsActions.updateWorkspaceTicketColumn({orgId, workspaceId, ticketNumber, ticketUpdateParams}));
  }
  
  updateWorkspaceDataTableTicketColumn(orgId: string, workspaceId: string, ticketNumber: number, ticketUpdateParams: UpdateTicketColumnParams, board: TicketWorkspaceBoard, updatedTickets: Ticket[]) {
    this.store.dispatch(TicketsActions.updateWorkspaceDataTableTicketColumn({orgId, workspaceId, ticketNumber, ticketUpdateParams, board, updatedTickets}));
  }
  
  uploadJiraApiKeyAndJiraOrg(orgId: string, jiraOrgId: string, jiraApiKey: string) {
    this.store.dispatch(TicketsActions.uploadJiraApiKeyAndJiraOrg({orgId, jiraOrgId, jiraApiKey}));
  }

  uploadTicketToJira(jiraInstance: string, ticketWorkspace: TicketWorkspace, ticket: Ticket){
      this.store.dispatch(TicketsActions.uploadTicketToJira({jiraInstance, ticketWorkspace, ticket}));
  }
  loadTicketHistory(orgId:string, workspaceId: string, ticketNumber: number){
    this.store.dispatch(TicketsActions.loadTicketHistory({orgId, workspaceId, ticketNumber}))
  }
  loadTicketHistoryItem(historyItem: Ticket){
    this.store.dispatch(TicketsActions.loadTicketHistoryItem({historyItem}))
  }
  generateUserJourney(ticket: Ticket){
    this.store.dispatch(TicketsActions.generateUserJourney({ticket}))
  }
  generateAcceptanceCriteriaForProductTicket(ticket: Ticket){
    this.store.dispatch(TicketsActions.generateAcceptanceCriteriaForProductTicket({ticket}))
  }
  generateBusinessValueForProductTicket(ticket: Ticket){
    this.store.dispatch(TicketsActions.generateBusinessValueForProductTicket({ticket}));
  }
  generateEstimatedStoryPointsReasonForProductTicket(ticket: Ticket){
    this.store.dispatch(TicketsActions.generateEstimatedStoryPointsReasonForProductTicket({ticket}))
  }

  generateEstimatedStoryPointsReasonForProductTicketSuccess(estimatedStoryPointsReason: EstimatedStoryPointsReason, ticket: Ticket) {
    this.store.dispatch(TicketsActions.generateEstimatedStoryPointsReasonForProductTicketSuccess({ estimatedStoryPointsReason, ticket }));
  }

  updateTicketSprint(orgId: string, ticket: Ticket, sprint: TicketWorkspaceBoard) {
    this.store.dispatch(TicketsActions.updateTicketSprint({orgId, ticket, sprint}));
  }

  moveCardInKanbanBoard(orgId: string, workspaceId: string, boardId?: number, columnId?: string, ticketNumber?: number, moveKanbanCardParams?: MoveKanbanCardParamsInputAlt, newlyArrangedKanban?: any) {
    this.store.dispatch(TicketsActions.moveCardInKanbanBoard({orgId, workspaceId, boardId, columnId, ticketNumber, moveKanbanCardParams, newlyArrangedKanban}));
  }

  addAttachmentsToTicket(ticket: Ticket, attachments: string[]){
    this.store.dispatch(TicketsActions.addAttachmentsToTicket({ticket, attachments}))
  }
  removeTicketAttachment(ticket: Ticket, attachmentFileName: string){
    this.store.dispatch(TicketsActions.removeTicketAttachment({ticket, attachmentFileName}))
  }
  subscribeToTicket(ticket: Ticket){
    this.store.dispatch(TicketsActions.subscribeToTicket({ticket}))
  }
  unsubscribeFromTicket(ticket: Ticket){
    this.store.dispatch(TicketsActions.unsubscribeFromTicket({ticket}))
  }

  renameKanbanColumn(orgId: string, workspaceId: string, columnId: string, newColumnName: string, boardId: number){
    this.store.dispatch(TicketsActions.renameKanbanColumn({orgId, workspaceId, columnId, newColumnName, boardId}))
  }

  createKanbanColumn(orgId: string, workspaceId: string, columnName: string, boardId: number){
    this.store.dispatch(TicketsActions.createKanbanColumn({orgId, workspaceId, columnName, boardId}))
  }

  deleteKanbanColumn(orgId: string, workspaceId: string, columnId: string, newColumnId: string, boardId: number){
    this.store.dispatch(TicketsActions.deleteKanbanColumn({orgId, workspaceId, columnId, newColumnId, boardId}))
  }

  reorderKanbanColumns(orgId: string, workspaceId: string, boardColumns: string[], boardId: number){
    this.store.dispatch(TicketsActions.reorderKanbanColumns({orgId, workspaceId, boardColumns, boardId}))
  }

  updateTicketOrder(orgId: string, workspaceId: string, ticketsOrder: number[], boardId: number, tickets: Ticket[]){
    this.store.dispatch(TicketsActions.updateTicketOrder({orgId, workspaceId, ticketsOrder, boardId, tickets}))
  }

  searchTickets(search: string, searchFields: string[], orgId: string, workspaceId: string, size: number, fromNum: number){
    this.store.dispatch(TicketsActions.searchTickets({search, searchFields, orgId, workspaceId, size, fromNum}))
  }

  searchTicketWorkspaces(search: string, searchFields: string[], orgId: string, workspaceId: string, size: number, fromNum: number){
    this.store.dispatch(TicketsActions.searchTicketWorkspaces({search, searchFields, orgId, workspaceId, size, fromNum}))
  }

  getAllTicketWorkspaceEpics(orgId: string, workspaceId: string) {
    this.store.dispatch(TicketsActions.getAllTicketWorkspaceEpics({orgId, workspaceId}))
  }

  createEpicProductTicketUsingAi(orgId: string, workspaceId: string, userTicketPrompt: string) {
    this.store.dispatch(TicketsActions.createEpicProductTicketUsingAi({orgId, workspaceId, userTicketPrompt}))
  }

  createEpicProductTicketFromEpicSuggestion(orgId: string, workspaceId: string, epicSuggestion: EpicSuggestion) {
    this.store.dispatch(TicketsActions.createEpicProductTicketFromEpicSuggestion({orgId, workspaceId, epicSuggestion}))
  }

  epicFromEpicSuggestionViaDocumentUpload(orgId: string, workspaceId: string, epicSuggestion: EpicSuggestion) {
    this.store.dispatch(TicketsActions.epicFromEpicSuggestionViaDocumentUpload({orgId, workspaceId, epicSuggestion}))
  }

  getAllTitleTicketsForWorkspaceEpic(orgId: string, workspaceId: string, parentTicketNumber?: number) {
    this.store.dispatch(TicketsActions.getAllTitleTicketsForWorkspaceEpic({orgId, workspaceId, parentTicketNumber}));
  }

  deleteTitleTicket(orgId: string, workspaceId: string, parentTicketNumber: number, ticketNumber: number) {
    this.store.dispatch(TicketsActions.deleteTicketTitle({orgId, workspaceId, parentTicketNumber, ticketNumber}));
  }

  generateProductTicketTitlesAndUploadSuccess(tickets: Ticket[], parentTicketNumber: number) {
    this.store.dispatch(TicketsActions.generateProductTicketTitlesAndUploadSuccess({ tickets, parentTicketNumber }));
  }

  ticketTitleGenerating(ticket: Ticket) {
    this.store.dispatch(TicketsActions.ticketTitleGenerating({ ticket }));
  }

  generateEpicsFromDocumentsUsingAiSuccess(epicSuggestions: EpicSuggestion[]) {
    this.store.dispatch(TicketsActions.generateEpicsFromDocumentsUsingAiSuccess({epicSuggestions}));
  }

  suggestNewEpicsSuccess(epicSuggestions: EpicSuggestion[]) {
    this.store.dispatch(TicketsActions.suggestNewEpicsSuccess({ epicSuggestions }));
  }

  createEpicProductTicketUsingAiSuccess(epic: Ticket) {
    this.store.dispatch(TicketsActions.createEpicProductTicketUsingAiSuccess({ epic }));
  }

  selectEpic(epic: Ticket) {
    this.store.dispatch(TicketsActions.selectEpic({ epic }));
  }

  manuallyCreateTicket(orgId: string, workspaceId: string, boardId: number, title: string, 
  parentTicketNumber: number, columns?: TicketWorkspaceBoardColumn[]) {
    this.store.dispatch(TicketsActions.manuallyCreateTicket({ orgId, workspaceId, boardId, title, 
      parentTicketNumber, columns }));
  }

  generateEntireProductTicketUsingAiAndUpdate(orgId: string, workspaceId: string, ticketNumber: number, columns: TicketWorkspaceBoardColumn[]) {
    this.store.dispatch(TicketsActions.generateEntireProductTicketUsingAiAndUpdate({ orgId, workspaceId, ticketNumber, columns }));
  }

  createCommunityTicketWorkspace(workspaceName: string, description: string) {
    this.store.dispatch(TicketsActions.createCommunityTicketWorkspace({workspaceName, description}));
  }

  getCommunityTicketWorkspaces() {
    this.store.dispatch(TicketsActions.getCommunityTicketWorkspaces());
  }
  
}

import { DeleteNotification, MarkNotificationAsRead  } from '@razroo-zeta/data-graphql';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, Observable, of } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  markNotificationAsRead(orgId: string, userId: string, text: string, timestamp: string){
    const mutation = MarkNotificationAsRead;
    const variables = {
      orgId,
      userId,
      text,
      timestamp
    };
    const response$ = this.apollo.mutate({ mutation, variables });
    return from(response$).pipe(pluck('data', 'markNotificationAsRead'));
  }

  deleteNotification(orgId: string, userId: string, text: string, timestamp: string){
    const mutation = DeleteNotification;
    const variables = {
      orgId,
      userId,
      text,
      timestamp,
    };
    const response$ = this.apollo.mutate({ mutation, variables });
    return from(response$).pipe(pluck('data', 'deleteNotification'));
  }

  constructor(private apollo: Apollo) {}
}

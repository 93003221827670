import { createAction, props } from '@ngrx/store';
import { OrganizationsEntity, OrganizationTeamEntity } from './organizations.models';
import { ActiveProject, ActiveStep, GithubRepo, OrganizationUser } from '@razroo-zeta/data-models';
import { ActiveTicketWorkspace } from 'libs/data-models/src/lib/organizations/organizations.interface';

export const loadOrganizationsSuccess = createAction(
  '[Organizations/API] Load Organizations Success',
  props<{ organizations: OrganizationsEntity[] }>()
);

export const loadOrganizationsFailure = createAction(
  '[Organizations/API] Load Organizations Failure',
  props<{ error: any }>()
);

export const loadOrganization = createAction(
  '[Organizations/API] Load Organization',
  props<{ orgId: string }>()
);

export const loadActiveState = createAction(
  '[Organizations/API] Load Active State',
  props<{ userOrgId: string | undefined, templateOrgId:string, pathId:string, recipeId:string, stepId: string, user: OrganizationUser }>()
);

export const loadActiveStateSuccess = createAction(
  '[Organizations/API] Load Active State Success',
  props<{ organizationUser: any }>()
);

export const loadActiveStateFailure = createAction(
  '[Template/API] Load Active State Failue',
  props<{ error: any }>()
);

export const loadOrganizationSuccess = createAction(
  '[Organizations/API] Load Organization Success',
  props<{ organization: OrganizationsEntity }>()
);

export const loadOrganizationFailure = createAction(
  '[Organizations/API] Load Organization Failure',
  props<{ error: any }>()
);

export const loadOrganizationUser = createAction(
  '[Organizations/API] Load Organization User',
  props<{ orgId: string, userId: string }>()
);

export const loadOrganizationUserSuccess = createAction(
  '[Organizations/API] Load Organization User Success',
  props<{ organizationUser: any }>()
);

export const loadOrganizationUserFailure = createAction(
  '[Organizations/API] Load Organization User Failure',
  props<{ error: any }>()
);

export const loadOrganizationUsers = createAction(
  '[Organizations/API] Load Organization Users',
  props<{ orgId: string }>()
);

export const loadOrganizationUsersSuccess = createAction(
  '[Organizations/API] Load Organization Users Success',
  props<{ organizationUsers:any, selectedId: string }>()
);

export const loadOrganizationUsersFailure = createAction(
  '[Organizations/API] Load Organization Users Failure',
  props<{ error: any }>()
);

export const sendJoinOrgEmail = createAction(
  '[Organizations/API] Send Join Org Email',
  props<{ orgId: string, userEmail: string, link: string}>()
);

export const sendJoinOrgEmailSuccess = createAction(
  '[Organizations/API] Send Join Org Email Success',
  props<{ orgId: string, userEmail: string }>()
);

export const sendJoinOrgEmailError = createAction(
  '[Organizations/API] Send Join Org Email Error',
  props<{ error: any }>()
);

export const loadInvitedUsers = createAction(
  '[Organizations/API] Get Invited Users',
  props<{ orgId: string, userId: string}>()
);

export const loadInvitedUsersSuccess = createAction(
  '[Organizations/API] Get Invited Users Success',
  props<{ invitedUsers: any[] }>()
);

export const loadInvitedUsersSuccessError = createAction(
  '[Organizations/API] Get Invited Users Success Error',
  props<{ error: any }>()
);

export const undoInviteUser = createAction(
  '[Organizations/API] Undo Invite User',
  props<{ orgId: string, userEmail: string}>()
);

export const undoInviteUserSuccess = createAction(
  '[Organizations/API] Undo Invite User Success',
  props<{ invitedUser: any }>()
);

export const undoInviteUserError = createAction(
  '[Organizations/API] Undo Invite User Success Error',
  props<{ error: any }>()
);

export const removeUserFromOrg = createAction(
  '[Organizations/API] Remove User From Org',
  props<{ orgId: string, userEmail: string}>()
);

export const removeUserFromOrgSuccess = createAction(
  '[Organizations/API] Remove User From Org Success',
  props<{ organizationUser: any }>()
);

export const removeUserFromOrgError = createAction(
  '[Organizations/API] Remove User From Org Success Error',
  props<{ error: any }>()
);

export const getOrgTeams = createAction(
  '[Organizations/API] Get Org Teams',
  props<{orgId: string, userId: string}>()
)

export const getOrgTeamsSuccess = createAction(
  '[Organizations/API] Get Org Teams Success',
  props<{teams: OrganizationTeamEntity[]}>()
)

export const getOrgTeamsFailure = createAction(
  '[Organizations/API] Get Org Teams Failure',
  props<{error: any}>()
)

export const createTeam = createAction(
  '[Organizations/API] Create Team',
  props<{orgId: string, userId: string, teamName: string}>()
)
export const createTeamSuccess = createAction(
  '[Organizations/API] Create Team Success',
  props<{team: any}>()
)
export const createTeamFailure = createAction(
  '[Organizations/API] Create Team Failure',
  props<{error: any}>()
)

export const addTeamMember = createAction(
  '[Organizations/API] Add Team Member',
  props<{orgId: string, userId: string, teamId: string, newUserId: string}>()
)
export const addTeamMemberSuccess = createAction(
  '[Organizations/API] Add Team Member Success',
  props<{team: any}>()
)
export const addTeamMemberFailure = createAction(
  '[Organizations/API] Add Team Member Failure',
  props<{error: any}>()
)

export const addTeamPermissions = createAction(
  '[Organizations/API] Add Team Permission',
  props<{orgId: string, userId: string, teamId: string, permissions: any}>()
)

export const addTeamPermissionsSuccess = createAction(
  '[Organizations/API] Add Team Permission Success',
  props<{team: any}>()
)

export const addTeamPermissionsFailure = createAction(
  '[Organizations/API] Add Team Permission Failure',
  props<{error: any}>()
)
export const removeTeamPermissions = createAction(
  '[Organizations/API] Remove Team Permission',
  props<{orgId: string, userId: string, teamId: string, permissions: any}>()
)

export const removeTeamPermissionsSuccess = createAction(
  '[Organizations/API] Remove Team Permission Success',
  props<{team: any}>()
)

export const removeTeamPermissionsFailure = createAction(
  '[Organizations/API] Remove Team Permission Failure',
  props<{error: any}>()
)

export const updateOrganization = createAction(
  '[Organizations/API] Update Organization',
  props<{organization: OrganizationsEntity}>()
)

export const updateOrganizationUser = createAction(
  '[Organizations/API] Update Organization User',
  props<{orgId: string, userId: string, activeStep?: ActiveStep, activeProject?: ActiveProject, githubRepos?: GithubRepo[], githubUserId?: string, activeTicketWorkspace?: ActiveTicketWorkspace}>()
)

export const updateOrganizationUserSuccess = createAction(
  '[Organizations/API] Update Organization User Success',
  props<{organizationUser: OrganizationUser}>()
)

export const updateOrganizationUserFailure = createAction(
  '[Organizations/API] Update Organization User Failure',
  props<{error: any}>()
)

export const updateOrgAvatar = createAction(
  '[Organizations/API] Update Org Avatar',
  props<{newUrl: string}>()
)

export const deleteOrgPicture = createAction(
  '[Organizations/API] Delete Org Picture',
  props<{orgId: string, userId: string}>()
)

export const deleteOrgPictureSuccess = createAction(
  '[Organizations/API] Delete Org Picture Success',
  props<{orgId:string}>()
)

export const deleteOrgPictureFailure = createAction(
  '[Organizations/API] Delete Org Picture Failure',
  props<{error: any}>()
)

export const deleteStripeSubscription = createAction(
  '[Organizations/API] Delete Stripe Subscription',
  props<{orgId: string}>()
);

export const deleteStripeSubscriptionSuccess = createAction(
  '[Organizations/API] Delete Stripe Subscription Success',
  props<{orgId: string, result: string}>()
);

export const deleteStripeSubscriptionFailure = createAction(
  '[Organizations/API] Delete Stripe Subscription Failure',
  props<{error: any}>()
);

export const noAction = createAction('No Action');

import gql from 'graphql-tag';
import { OrganizationUserFragment } from './organization-user.fragements';

export const GetCurrentOrganizationUsers = gql`
  query GetCurrentOrganizationUsers($orgId: ID!) {
    organizationUsers(orgId: $orgId) {
      ...organizationUserFields
    }
  }
  ${OrganizationUserFragment}
`;

export const OrganizationUser = gql`
  query organizationUser($orgId: ID!, $userId: String!) {
    organizationUser(userId: $userId, orgId: $orgId) {
     ...organizationUserFields
    }
  }
  ${OrganizationUserFragment}
`;

export const UserAndOrganizationUser = gql`
  query userAndOrganizationUser($orgId: ID) {
    userAndOrganizationUser(orgId: $orgId) {
     ...organizationUserFields
    }
  }
  ${OrganizationUserFragment}
`;

export const GetInvitedUsers = gql`
  query GetInvitedUsers($orgId: String!, $userId: String!) {
    getInvitedUsers(orgId: $orgId, userId: $userId) {
      orgId
      userEmail
      dateInvited
      pk
      sk
    }
  }
`;

import gql from 'graphql-tag';
import { OrganizationUserFragment } from './organization-user.fragements';

export const UpdateOrgRole = gql`
  mutation UpdateOrgUser(
    $orgId: ID!
    $userId: String!
    $orgRole: OrganizationRoles
  ) {
    updateOrganizationUser(orgId: $orgId, userId: $userId, orgRole: $orgRole) {
      ...organizationUserFields
    }
  }
  ${OrganizationUserFragment}
`;

export const UpdateOrganizationUser = gql`
  mutation UpdateOrgUser(
    $orgId: ID!
    $userId: String!
    $orgRole: OrganizationRoles
    $githubRepos: [GithubRepoInput]
    $githubUserId: String
    $activeProject: ActiveProjectInput
    $activeStep: ActiveStepInput
    $activeTicketWorkspace: ActiveTicketWorkspaceInput
  ) {
    updateOrganizationUser(orgId: $orgId, userId: $userId, orgRole: $orgRole, 
      githubRepos: $githubRepos, githubUserId: $githubUserId, 
      activeProject: $activeProject, activeStep: $activeStep, activeTicketWorkspace: $activeTicketWorkspace) {
      ...organizationUserFields
    }
  }
  ${OrganizationUserFragment}
`;

export const UpdateActiveStep = gql`
  mutation UpdateOrgUser(
    $orgId: ID!
    $userId: String!
    $activeStep: ActiveStepInput
  ) {
    updateOrganizationUser(orgId: $orgId, userId: $userId, activeStep: $activeStep) {
      ...organizationUserFields
    }
  }
  ${OrganizationUserFragment}
`;

export const UpdateOrgUserSeniority = gql`
  mutation UpdateOrgUser($orgId: ID!, $userId: String!, $seniority: Seniority) {
    updateOrganizationUser(
      orgId: $orgId
      userId: $userId
      seniority: $seniority
    ) {
      email
      userId
      seniority
    }
  }
`;
export const DeactivateOrgUser = gql`
  mutation UpdateOrgUser($orgId: ID!, $userId: String!, $isActive: Boolean) {
    updateOrganizationUser(
      orgId: $orgId
      userId: $userId
      isActive: $isActive
    ) {
      email
      userId
      isActive
    }
  }
`;

export const AddOrgUserNotification = gql`
  mutation UpdateOrgUser($orgId: ID!, $userId: String!, $orgNotification: String) {
    updateOrganizationUser(
      orgId: $orgId
      userId: $userId
      orgNotification: $orgNotification
    ) {
      email
      orgId
      orgNotifications{
        notification
        read
        timestamp
      }
    }
  }
`;

export const UpdateUserPermission = gql`
  mutation updateUserPermission(
    $orgId: String!,
    $userId: String!,
    $gettersUserId: String!,
    $permission: GranularPermissionLevel!,
    $pathOrgId: String!,
    $pathId: String!,
    $recipeId: String,
    $stepId: String
  ) {
    updateUserPermission(
      orgId: $orgId,
      userId: $userId,
      gettersUserId: $gettersUserId,
      permission: $permission,
      pathOrgId: $pathOrgId,
      pathId: $pathId,
      recipeId: $recipeId,
      stepId: $stepId
    ){
      orgId
      pathId
      permission
      recipeId
      stepId
    }
  }
`

export const RevertToDefaultPermission = gql`
  mutation revertToDefaultPermission(
    $orgId: String!,
    $userId: String!,
    $gettersUserId: String!,
    $pathOrgId: String!,
    $pathId: String!,
    $recipeId: String,
    $stepId: String
  ) {
    revertToDefaultPermission(
      orgId: $orgId,
      userId: $userId,
      gettersUserId: $gettersUserId,
      pathOrgId: $pathOrgId,
      pathId: $pathId,
      recipeId: $recipeId,
      stepId: $stepId
    ){
      orgId
      pathId
      permission
      recipeId
      stepId
    }
  }
`


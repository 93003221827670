@if (template?.privacy === 'Public') {
  <div class="social-container">
    <div class="social-icons">
      <button
        mat-icon-button
        color="warn"
        (click)="shareToTwitter(comment.comment, currentUserId)"
        >
        <i class="fa-brands fa-x-twitter"></i>
      </button>
      <button mat-icon-button color="warn" (click)="shareToReddit(comment)">
        <i class="fa-brands fa-reddit"></i>
      </button>
    </div>
  </div>
}

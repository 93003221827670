import {
  UpdateActiveOrgId,
  CreateUserProfile,
  UpdateUserProfile,
  GetUserProfile,
  GetUserOrganizations,
  GetCurrentOrganizationUsers,
  GrantCertification,
  GetUserRoles
} from '@razroo-zeta/data-graphql';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from } from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apollo: Apollo, private authService: AuthService) { }

  getUserProfile(email: string) {
    const query = GetUserProfile;
    const variables = {
      email: email,
    };
    const userProfile$ = this.apollo.query({ query, variables });
    return from(userProfile$).pipe(pluck('data', 'userProfile'));
  }

  getUserRoles(userId: string) {
    const query = GetUserRoles;
    const variables = {
      userId: userId,
    };
    const getUserRoles$ = this.apollo.query({ query, variables });
    return from(getUserRoles$).pipe(pluck('data', 'getUserRoles'));
  }

  getUserProfileUserId(userId: string) {
    const query = GetUserProfile;
    const variables = {
      userId: userId,
    };
    const userProfile$ = this.apollo.query({ query, variables });
    return from(userProfile$).pipe(pluck('data', 'userProfile'));
  }

  getUserOrganizations(userId: string) {
    const query = GetUserOrganizations;
    const variables = {
      userId,
    };
    const userOrganizations$ = this.apollo.query({ query, variables });
    userOrganizations$.subscribe((res) => res.data);
    return from(userOrganizations$).pipe(pluck('data'));
  }

  getCurrentOrganizationUsers(orgId: string) {
    const query = GetCurrentOrganizationUsers;
    const variables = {
      orgId,
    };
    const organizationUsers$ = this.apollo.query({ query, variables });
    return from(organizationUsers$).pipe(
      pluck('data', 'currentOrganizationUsers')
    );
  }

  updateUserProfile(args: any) {
    const mutation = UpdateUserProfile;
    const variables = args;
    const userProfile$ = this.apollo.mutate({ mutation, variables });
    return from(userProfile$).pipe(pluck('data', 'updateUserProfile'));
  }

  createUserProfile(firstName: string, lastName: string) {
    return this.authService.user$.pipe(
      switchMap((user: any) => {
        const currentUserEmail = user["email"] as string;
        const mutation = CreateUserProfile;
        const variables = {
          firstName,
          lastName,
          currentUserEmail: currentUserEmail
        };
        const createUserProfile$ = this.apollo.mutate({ mutation, variables });
        return from(createUserProfile$).pipe(pluck('data', 'createUserProfile'));
      })
    );
  }

  updateActiveOrgId(activeOrgId: string, userId: string) {
    const mutation = UpdateActiveOrgId;
    const variables = {
      activeOrgId,
      userId,
    };
    const userProfile$ = this.apollo.mutate({ mutation, variables });
    return from(userProfile$).pipe(pluck('data', 'updateUserProfile'));
  }

  grantCertification(userId: string, certification: string, activeOrgId: string, grantedBy: string) {
    const mutation = GrantCertification;
    const variables = {
      userId,
      certification,
      activeOrgId,
      grantedBy
    };
    const userProfile$ = this.apollo.mutate({ mutation, variables });
    return from(userProfile$).pipe(pluck('data', 'updateUserProfile'));
  }
}

import { CreateJwtFromGenerateCodeForGithub } from '../../../../data-graphql/src/lib/github/github.mutations';
import { Injectable } from "@angular/core";
import { GithubFileTree } from '@razroo-zeta/data-graphql';
import { Apollo } from "apollo-angular";
import { from, pluck } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GithubService {
  constructor(private apollo: Apollo) { }

  createJwtFromGenerateCodeForGithubInput(
    generateCodeJwtGithubInput: any,
    ){
      const mutation = CreateJwtFromGenerateCodeForGithub;
      const variables = {
        generateCodeJwtGithubInput
      }
      const createJwtFromGenerateCodeForGithub$ = this.apollo.mutate({mutation, variables});
      return from(createJwtFromGenerateCodeForGithub$).pipe(pluck('data', 'createJwtFromGenerateCodeForGithub'))
  }

  githubFileTree(gitHubOrg: string, gitHubRepoName: string, gitHubBranch: string){
    const query = GithubFileTree;
    const variables = {
      gitHubOrg,
      gitHubRepoName,
      gitHubBranch
    }
    const githubFileTree$ = this.apollo.query({query, variables});
    return from(githubFileTree$).pipe(pluck('data', 'githubFileTree'));
  }
}

import { gql } from 'graphql-tag';
import { GetStepperOfStepsFragment } from "./stepper.fragments";

export const GetStepperOfStepsQuery = gql`
	query GetStepperOfSteps($orgId: String!, $pathId: String!, $recipeIdStart: String, $stepIdStart: String, $amount: Int){
      getStepperOfSteps(orgId: $orgId, pathId: $pathId, recipeIdStart: $recipeIdStart, stepIdStart: $stepIdStart, amount: $amount){
        ...getStepperOfStepsFields
      }
	}
  ${GetStepperOfStepsFragment}
`

import { createAction, props } from '@ngrx/store';
import { CodeGenerationHistoryEntity } from './code-generation-history.models';

export const initCodeGenerationHistory = createAction(
  '[CodeGenerationHistory Page] Init',
  props<{orgId: string, page: number, size: number}>(),
);

export const loadCodeGenerationHistorySuccess = createAction(
  '[CodeGenerationHistory/API] Load CodeGenerationHistory Success',
  props<{ codeGenerationHistory: CodeGenerationHistoryEntity[], page: number, size: number }>()
);

export const loadCodeGenerationHistoryFailure = createAction(
  '[CodeGenerationHistory/API] Load CodeGenerationHistory Failure',
  props<{ error: any }>()
);

export const addGenerationToHistory = createAction(
  '[CodeGenerationHistory/API] Add GenerationToHistory',
  props<{ codeGeneration: any}>()
)

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PathCmsService } from '@razroo-zeta/data-services';

@Component({
  selector: 'razroo-zeta-go-to-next-step-dialog',
  templateUrl: './go-to-next-step-dialog.component.html',
  styleUrls: ['./go-to-next-step-dialog.component.scss'],
})
export class GoToNextStepDialogComponent implements OnInit {
  vsCodeAbsoluteUrl: string;
  currentUrl = window.location.href;
  constructor(
    private pathCmsService: PathCmsService,
    private dialogRef: MatDialogRef<GoToNextStepDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  nextStepFunc() {
    this.dialogRef.close('next');
  }

  ngOnInit(): void {
    const parameters = (this.data.parameters && typeof this.data.parameters === 'string') ? JSON.parse(this.data.parameters) : this.data.parameters;
    // 1. get absolute path
    const vsCodeInstance = this.data.vsCodeInstances && this.data.vsCodeInstances.find(vsCodeInstance => vsCodeInstance.vsCodeInstanceId === this.data.vsCodeInstanceId);
    if(!vsCodeInstance) {
      return;
    }
    const absoluteFolderRoot = vsCodeInstance?.absoluteFolderRoot;
    // 2. get file path
    const filePathParameter = this.data.template.parameters.find(parameter => parameter.paramType === 'filePath');
    const filePathParameterName = filePathParameter && filePathParameter.name;
    const filePathParameterValue = parameters[filePathParameterName];
    // 3. get file name
    const fileTree = this.data.template.fileTree;
    const firstFile = this.replaceCurlyBraces(fileTree[0], parameters);
    // 4. formulate path
  }

  replaceCurlyBraces(str: string, obj: any): string {
    return str.replace(/{([^}]+)}/g, (match, p1) => {
      return obj[p1] || match;
    });
  }
     shareToTwitter() {
  const template = this.data.template;
  const hashtag = `#${template.orgId.charAt(0).toUpperCase()}${template.orgId.slice(1)}${template.corePathId.charAt(0).toUpperCase()}${template.corePathId.slice(1)}${template.recipeId.charAt(0).toUpperCase()}${template.recipeId.slice(1)}${template.id.charAt(0).toUpperCase()}${template.id.slice(1)}`.replace(/[-.]/g, '');
  const formattedHashtag = hashtag.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');

  const tweetText = `I just generated code for razroo.com/${template.orgId}/${template.pathId}/${template.recipeId}/${template.id} 
  
Sharing this hashtag so others can find me:
${formattedHashtag}`;

  const encodedTweetText = encodeURIComponent(tweetText);

  const tweetUrl = `https://twitter.com/intent/tweet?text=${encodedTweetText}`;

  window.open(tweetUrl);}
}
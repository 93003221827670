import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { CodeChat } from '@razroo-zeta/data-models';

import * as CodeChatActions from './code-chat.actions';

export const CODE_CHAT_FEATURE_KEY = 'codeChat';

export interface CodeChatState extends EntityState<CodeChat> {
  selectedId?: string | number; // which CodeChat record has been selected
  loaded: boolean; // has the CodeChat list been loaded
  searchLoaded: boolean;
  chatBotTyping: boolean;
  newMessageText?: string;
  firstMessagePending?: boolean;
  init?: boolean;
  lastEvaluatedKey: {gsiKey: string; pk: string; sk: string;}
  error?: string | null; // last known error (if any)
}

export interface CodeChatPartialState {
  readonly [CODE_CHAT_FEATURE_KEY]: CodeChatState;
}

export const codeChatAdapter: EntityAdapter<CodeChat> =
  createEntityAdapter<CodeChat>();

export const initialCodeChatState: EntityState<CodeChat> =
  codeChatAdapter.getInitialState({
    // set initial required properties
    loaded: true,
    searchLoaded: true,
    newMessageText: undefined
  });

const reducer = createReducer(
  initialCodeChatState,
  on(CodeChatActions.initCodeChat, (state, {newMessageText, messages}) =>
  ({ ...state, loaded: false, init: true, newMessageText, firstMessagePending: messages.length === 1, chatBotTyping: false })
  ),
  on(CodeChatActions.loadChatHistorySuccess, (state, { chatHistory, lastEvaluatedKey }) =>
    codeChatAdapter.setAll(chatHistory, { ...state, loaded: true, searchLoaded: true, newMessageText: undefined, lastEvaluatedKey: (lastEvaluatedKey ? {
      gsiKey: lastEvaluatedKey.gsiKey,
      pk: lastEvaluatedKey.pk,
      sk: lastEvaluatedKey.sk,
    } : undefined) })
  ),
  on(CodeChatActions.loadMoreHistorySuccess, (state, { chatHistory, lastEvaluatedKey }) =>
    codeChatAdapter.addMany(chatHistory, { ...state, loaded: true, lastEvaluatedKey: (lastEvaluatedKey ? {
      gsiKey: lastEvaluatedKey.gsiKey,
      pk: lastEvaluatedKey.pk,
      sk: lastEvaluatedKey.sk
    } : undefined)})
  ),
  on(CodeChatActions.loadCodeChat, (state, { chatId }) => {
    return {
      ...state,
      selectedId: chatId
    }
  }),
  on(CodeChatActions.searchChat, (state) => {
    return {
      ...state,
      searchLoaded: false
    }
  }),
  on(CodeChatActions.loadCodeChatSuccess, (state, { codeChat }) => {
    return codeChatAdapter.upsertOne(codeChat, {
      ...state,
      newMessageText: undefined,
      firstMessagePending: false,
      loaded: true,
      selectedId: codeChat.id
    })
  }),
  on(CodeChatActions.loadCodeChatFailure, (state, { error, codeChat }) =>
    codeChatAdapter.upsertOne(codeChat, {
      ...state,
      loaded: true,
      firstMessagePending: false,
      newMessageText: undefined,
      selectedId: codeChat.id
    })
  ),
  on(CodeChatActions.cancelChatSuccess, (state, { codeChat }) =>
    codeChatAdapter.upsertOne(codeChat, {
      ...state,
      loaded: true,
      firstMessagePending: false,
      newMessageText: undefined,
      selectedId: codeChat.id
    })
  ),
  on(CodeChatActions.resetChat, (state) => ({
    ...state,
    init: false,
    firstMessagePending: false,
    newMessageText: undefined,
    selectedId: undefined
  })),
  on(CodeChatActions.deleteCodeChatSuccess, (state, { codeChat }) => {
    return codeChatAdapter.removeOne(codeChat.id, {...state, selectedId: undefined})
  }),
);

export function codeChatReducer(
  state: CodeChatState | undefined,
  action: Action
) {
  return reducer(state, action);
}

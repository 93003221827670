import gql from 'graphql-tag';
import { UserFragment } from './user.fragments';

export const GetUser = gql`
  query user($user: String) {
    user @rest(type: "User", path: "users/CharlieGreenman") {
      ...userFields
    }
  }
  ${UserFragment}
`;

export const GetUserRoles = gql`
  query GetUserRoles($userId: ID!) {
    getUserRoles(userId: $userId) {
      description
      id
      name
    }
  }
`;

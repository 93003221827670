import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as CodeChatActions from './code-chat.actions';
import * as CodeChatSelectors from './code-chat.selectors';

@Injectable()
export class CodeChatFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(CodeChatSelectors.selectCodeChatLoaded));
  searchLoaded$ = this.store.pipe(select(CodeChatSelectors.selectSearchCodeChatLoaded));
  codeChatInit$ = this.store.pipe(select(CodeChatSelectors.selectCodeChatInit));
  newMessageText$ = this.store.pipe(select(CodeChatSelectors.selectCodeChatNewMessageText));
  firstMessagePending$ = this.store.pipe(select(CodeChatSelectors.selectCodeChatFirstMessagePending));
  allCodeChat$ = this.store.pipe(select(CodeChatSelectors.selectAllCodeChat));
  selectedCodeChat$ = this.store.pipe(select(CodeChatSelectors.selectEntity));
  chatBotTyping$ = this.store.pipe(select(CodeChatSelectors.selectChatBotTyping));
  lastEvaluatedKey$ = this.store.pipe(select(CodeChatSelectors.selectLastEvaluatedKey))
  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  generateCodeChat(orgId: string, pathOrgId: string, pathId: string, technologies: any, messages: any, newMessageText: string, chatId: string) {
    this.store.dispatch(CodeChatActions.initCodeChat({userOrgId: orgId, pathOrgId, pathId, technologies, messages, newMessageText, chatId }));
  }

  initChatHistory(orgId: string){
    this.store.dispatch(CodeChatActions.initChatHistory({orgId}));
  }

  loadChatFromBackendHistory(chatId: string){
    this.store.dispatch(CodeChatActions.loadCodeChat({chatId}))
  }

  resetChat() {
    this.store.dispatch(CodeChatActions.resetChat());
  }

  cancelChat() {
    this.store.dispatch(CodeChatActions.cancelChat());
  }

  deleteCodeChat(chatId: string) {
    this.store.dispatch(CodeChatActions.deleteCodeChat({chatId}));
  }

  loadMoreHistory(orgId: string){
    this.store.dispatch(CodeChatActions.loadMoreHistory({orgId}));
  }
  
  searchCodeChat(orgId: string, search: string) {
    this.store.dispatch(CodeChatActions.searchChat({orgId, search}));
  }
}

/**
 * Finds an element based on a selector string, with support for :contains() to find elements by text content
 * 
 * @param selector The selector string that may contain :contains() pseudo-selector
 * @param root The root element or document to search within (defaults to document)
 * @returns The element matching the selector, or null if not found
 */
export function findElement(selector: string, root: Document | Element = document): Element | null {
  // Check if the selector uses the :contains() pseudo-selector
  const containsMatch = selector.match(/(.+):contains\(['"](.+)['"]\)/);
  
  if (!containsMatch) {
    // If it doesn't use :contains(), just use querySelector directly
    return root.querySelector(selector);
  }
  
  const [_, baseSelector, textContent] = containsMatch;
  
  // Get all elements matching the base selector
  const elements = Array.from(root.querySelectorAll(baseSelector));
  
  // Then filter to find the one containing the specified text
  return elements.find(element => 
    element.textContent && element.textContent.includes(textContent)
  ) || null;
}

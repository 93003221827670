import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTicketWorkspaces from './+state/ticket-workspaces.reducer';
import { TicketWorkspacesEffects } from './+state/ticket-workspaces.effects';
import { TicketWorkspacesFacade } from './+state/ticket-workspaces.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromTicketWorkspaces.TICKET_WORKSPACES_FEATURE_KEY,
      fromTicketWorkspaces.ticketWorkspacesReducer
    ),
    EffectsModule.forFeature([TicketWorkspacesEffects]),
  ],
  providers: [TicketWorkspacesFacade],
})
export class DataAccessTicketWorkspacesModule {}

import { CodeModService } from '../../../../../code-editor/src/lib/services/code-mod.service';
import { PathCmsScaffoldService } from './../../../../../data-services/src/lib/path-cms/path-cms-scaffold/path-cms-scaffold.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import {
  FileTreeService,
  TerminalService,
  VersioningService,
} from '@razroo-zeta/common-services';
import {
  PathService,
  TemplateService,
  PathCmsService,
  DownloadUrlService,
  StepDependencyService,
  VscodeService,
} from '@razroo-zeta/data-services';
import { from, of, zip } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as PathCmsActions from './path-cms.actions';
import * as TemplatesActions from '@razroo-zeta/data-access';
import { ApolloError } from '@apollo/client/errors';

@Injectable()
export class PathCmsEffects {
  loadFilesToGenerate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.loadFilesToGenerate),
      fetch({
        run: (action: any) => {
          return this.templateService
            .getTemplate(
              action.orgId,
              action.pathId,
              action.recipeId,
              action.id,
              true
            )
            .pipe(
              map((template: any) => {
                const filesToGenerateTree =
                  this.fileTreeService.returnStepFileTreeFilesToGenerateFlatFolderStructure(
                    template.fileTree,
                    template.parameters
                  );
                
                return { filesToGenerateTree, template };
              }),
              switchMap(({ filesToGenerateTree, template }) => {
                return this.templateService
                  .getDependentSteps(
                    template.orgId,
                    template.pathId,
                    template.dependentSteps
                  )
                  .pipe(
                    map((dependencySteps: any) => {
                      return { filesToGenerateTree, dependencySteps, template };
                    })
                  );
              }),
              mergeMap(({ filesToGenerateTree, dependencySteps, template}) => [
                TemplatesActions.loadTemplateSuccess({ template }),
                PathCmsActions.loadTerminalFilesToGenerate({
                  template: template,
                }),
                PathCmsActions.loadFirstFile({
                  template,
                  filesToGenerate: [...filesToGenerateTree, ...dependencySteps],
                }),
                PathCmsActions.loadStarterWithFilesToGenerate({
                  template,
                  filesToGenerateTree: [
                    ...filesToGenerateTree,
                    ...dependencySteps,
                  ],
                }),
              ])
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.loadFilesToGenerateFailure({ error });
        },
      })
    )
  );

  portExistingCodeFileToNewStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.portExistingCodeFileToNewStep),
      fetch({
        run: (action) => {
          return this.templateService
            .portExistingCodeFileToNewStep(
              action.userOrgId,
              action.fromPathOrgId,
              action.fromPathId,
              action.fromRecipeId,
              action.fromStepId,
              action.toPathOrgId,
              action.toPathId,
              action.toRecipeId,
              action.toStepId,
              action.staticFilePath,
              action.dynamicFilePath,
              action.contentType
            )
            .pipe(
              map((response: any) => {
                // Show a snackbar notification that the file has been ported over
                this.snackBar.open(`File ${action.staticFilePath} successfully ported to new step`, 'Close', {
                  duration: 3000,
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                });
                return PathCmsActions.portExistingCodeFileToNewStepSuccess({
                  fileContent: response.fileContent,
                  contentType: response.contentType,
                  parameters: response.parameters,
                  orgId: action.toPathOrgId,
                  pathId: action.toPathId,
                  recipeId: action.toRecipeId,
                  stepId: action.toStepId
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.portExistingCodeFileToNewStepFailure({ error });
        },
      })
    )
  );

  loadStarterWithFilesToGenerate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.loadStarterWithFilesToGenerate),
      fetch({
        run: (action) => {
          const starterName = action.template.baseCommunityPath
            ? action.template.baseCommunityPath
            : action.template.pathId;
          const { name: pathId, version: branchName } =
            this.versioningService.getVersionAndNameString(starterName);
          let strippedPathId = pathId;
          if (
            action.template.ai !== false &&
            pathId.substring(pathId.length - 3) === '-ai'
          ) {
            strippedPathId = pathId.slice(0, pathId.length - 3);
          }
          return this.templateService
            .getStarterFileTree('community', `${strippedPathId}-${branchName}`)
            .pipe(
              map((files: any) => {
                //TODO add files.content to the type annotation for files
                let filesAsArr = !(action.template.starter) ? this.fileTreeService.fanOutFilePaths(files.fileTree) : [];
                //if command step then filesToGenerate should not be added to the tree
                let filesToGenerateTree: any;
                if(action.template.type !== "Command"){
                  filesToGenerateTree = action.filesToGenerateTree
                  filesAsArr =
                    this.fileTreeService.replaceStringPathsWithFlatFolderPaths(
                      filesAsArr,
                      filesToGenerateTree
                    );
                }
                let combinedFiles = [
                  ...filesAsArr
                ];
                if(filesToGenerateTree) combinedFiles = [...combinedFiles, ...filesToGenerateTree];
                const starterWithFilesToGenerate =
                  this.fileTreeService.returnFlatFolderStructure(combinedFiles);
                return PathCmsActions.loadStarterWithFilesToGenerateSuccess({
                  starterWithFilesToGenerate, starterTemplate: {orgId: files.orgId, pathId: files.pathId, recipeId: files.recipeId, id: files.id} 
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.loadStarterWithFilesToGenerateFailure({
            error,
          });
        },
      })
    )
  );

  loadTerminalFilesToGenerate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.loadTerminalFilesToGenerate),
      fetch({
        run: (action) => {
          const terminalFiles =
            this.terminalService.createTerminalFiles(action.template.fileTree);
          return PathCmsActions.loadTerminalFilesToGenerateSuccess({
            terminalFiles,
          });
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.loadTerminalFilesToGenerateFailure({ error });
        },
      })
    )
  );

  loadFirstFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.loadFirstFile),
      map((action) => {
        const firstFileToGenerate = action.filesToGenerate.filter(
          (fileToGenerate) => fileToGenerate.expandable !== true && fileToGenerate.dynamicPath == action.template.firstFile
        )[0];
        if (!firstFileToGenerate) {
          return PathCmsActions.loadFirstFileSuccess({
            flatFolderFile: undefined,
          });
        } else {
          const firstFile = action.template.firstFile;
          const firstFileName = firstFile?.split('/').pop();
          if (action.template.type === 'Command') {
            const terminalFile = this.terminalService.createTerminalFile(firstFileName!);                    
            return PathCmsActions.loadFirstTerminalFileSuccess({
              terminalFile: {
                ...terminalFile,
                isActive: true,
                fileText: action.template.firstFileContents,
              },
            });
          } else {
            return PathCmsActions.loadFirstFileSuccess({
              flatFolderFile: {
                ...firstFileToGenerate,
                isActive: true,
                fileText: action.template.firstFileContents,
              },
            });
          }
        }
      })
    )
  );

  loadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.loadFile),
      fetch({
        run: (action: any) => {
          return this.pathCmsService
            .downloadStarterOrNonStarterFile(
              action.flatFolderFile,
              action.template,
              action?.starterTemplate
            )
            .pipe(
              map((fileTextExtracted: any) => {
                return PathCmsActions.loadFileSuccess({
                  flatFolderFile: {
                    ...action.flatFolderFile,
                    isActive: true,
                    fileText: fileTextExtracted,
                  },
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.loadFileError({ error });
        },
      })
    )
  );

  loadDependentStepFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.loadDependentStepFile),
      fetch({
        run: (action: any) => {
          const template = action.template;
          const pathId = template.pathId;
          return zip(
            this.pathCmsService.downloadStarterOrNonStarterFile(
              action.flatFolderFile,
              action.template
            ),
            this.templateService.getTemplate(
              action.flatFolderFile.dependentStep.orgId,
              action.flatFolderFile.dependentStep.pathId,
              action.flatFolderFile.dependentStep.recipeId,
              action.flatFolderFile.dependentStep.stepId
            )
          ).pipe(
            map(([fileTextExtracted, dependentStepTemplate]) => {
              fileTextExtracted =
                this.vscodeService.previewReplaceTagParameters(
                  (dependentStepTemplate as any).parameters,
                  fileTextExtracted
                );
              return PathCmsActions.loadFileSuccess({
                flatFolderFile: {
                  ...action.flatFolderFile,
                  isActive: true,
                  fileText: fileTextExtracted,
                },
              });
            })
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.loadFileError({ error });
        },
      })
    )
  );

  loadTerminalFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.loadTerminalFile),
      fetch({
        run: (action: any) => {
          return this.templateService
            .getFileUrl(
              action.template.orgId,
              action.template.pathId,
              action.template.recipeId,
              action.template.id,
              `{terminalFiles}/${action.terminalFile.name}`
            )
            .pipe(
              switchMap((downloadUrl: any) => {
                const url = downloadUrl['url'];
                return from(this.downloadUrlService.getFileFromUrl(url));
              }),
              map((fileTextExtracted: string) => {
                return PathCmsActions.loadFileTerminalSuccess({
                  terminalFile: {
                    ...action.terminalFile,
                    fileText: fileTextExtracted,
                  },
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.loadTerminalFileError({ error });
        },
      })
    )
  );

  saveFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.saveFile),
      fetch({
        run: (action: any) => {
          const dynamicPath = action.openFile.dynamicPath;
          return this.templateService
            .getStepFileUploadUrl(
              action.userOrgId,
              action.template.orgId,
              action.template.pathId,
              action.template.recipeId,
              action.template.id,
              dynamicPath
            )
            .pipe(
              switchMap((response: any) => {
                const url = response['url'];
                const contentType = response['contentType'];
                return this.downloadUrlService.uploadFileFromUrl(
                  url,
                  action.codeChanged,
                  contentType
                );
              }),
              map((uploadUrlResponse) => {
                this.snackBar.open(
                  `${action.openFile.name} has been saved`,
                  undefined,
                  { duration: 3000 }
                );
                return PathCmsActions.saveFileSuccess({
                  codeChanged: action.codeChanged,
                  openFile: action.openFile,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.saveFileError({
            codeChanged: action.codeChanged,
            openFile: action.openFile,
          });
        },
      })
    )
  );

  loadScaffold$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.loadScaffold),
      fetch({
        run: (action: any) => {
          //TODO - re-assess. For now all scaffolds are based off of community
          return this.templateService
            .getTemplate(
              'community',
              action.scaffold.pathId,
              action.scaffold.recipeId,
              action.scaffold.id
            )
            .pipe(
              switchMap((scaffold: any) => {
                const updatedParameters =
                  this.pathCmsScaffoldService.updateParametersIfRelevant(
                    action.template.parameters,
                    scaffold.parameters,
                    action.folderPath
                  );
                const scaffoldFilesToGenerateTree =
                  this.fileTreeService.returnFilesToGenerateFlatFolderStructure(
                    scaffold.fileTree,
                    updatedParameters
                  );
                return of({
                  scaffold,
                  updatedParameters,
                  scaffoldFilesToGenerateTree,
                });
              }),
              mergeMap(
                ({
                  scaffold,
                  updatedParameters,
                  scaffoldFilesToGenerateTree,
                }) => [
                  TemplatesActions.updateStep({
                    orgId: action.template.orgId,
                    pathId: action.template.pathId,
                    recipeId: action.template.recipeId,
                    id: action.template.id,
                    stepUpdateParams: { parameters: updatedParameters },
                  }),
                  PathCmsActions.loadStarterWithFilesToGenerate({
                    template: action.template,
                    filesToGenerateTree: [...scaffoldFilesToGenerateTree],
                  }),
                  PathCmsActions.injectScaffold({
                    scaffold,
                    template: action.template,
                    folderPath: action.folderPath,
                    userOrgId: action.userOrgId
                  }),
                ]
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.loadScaffoldError({ error });
        },
      })
    )
  );

  injectScaffold$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.injectScaffold),
      fetch({
        run: (action: any) => {
          return this.downloadUrlService
            .copyStepS3Folder(action.userOrgId, action.scaffold.orgId, action.scaffold.pathId, action.scaffold.recipeId, 
              action.scaffold.id, action.template.orgId, action.template.pathId, 
              action.template.recipeId, action.template.id
            )
            .pipe(
              map((folderName: any) => {
                return PathCmsActions.injectScaffoldSuccess({
                  scaffold: action.scaffold,
                  template: action.template,
                  folderPath: action.folderPath,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.injectScaffoldError({ error });
        },
      })
    )
  );

  saveTerminalFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.saveTerminalFile),
      fetch({
        run: (action: any) => {
          const fullFilePath = `${action.terminalFile.filesToGenerateFolderName}/${action.terminalFile.name}`;
          return this.templateService
            .getStepFileUploadUrl(
              action.userOrgId,
              action.template.orgId,
              action.template.pathId,
              action.template.recipeId,
              action.template.id,
              fullFilePath
            )
            .pipe(
              switchMap((response: any) => {
                const url = response['url'];
                const contentType = response['contentType'];
                return this.downloadUrlService.uploadFileFromUrl(
                  url,
                  action.fileText,
                  contentType
                );
              }),
              map((uploadUrlResponse) => {
                this.snackBar.open(
                  `${action.terminalFile.name} has been saved`,
                  undefined,
                  { duration: 3000 }
                );
                return PathCmsActions.saveTerminalFileSuccess({
                  fileText: action.fileText,
                  terminalFile: action.terminalFile,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.saveTerminalFileError({
            fileText: action.fileText,
            terminalFile: action.terminalFile,
          });
        },
      })
    )
  );

  changeDynamicFilePath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.changeDynamicFilePath),
      fetch({
        run: (action: any) => {
          return this.downloadUrlService.renameS3File(action.source, action.destination).pipe(
            tap((_) =>
              this.snackBar.open(
                `${action.openFile.name} has been re-named`,
                undefined,
                { duration: 3000 }
              )
            ),
            mergeMap((renameResponse) => [
              PathCmsActions.changeDynamicFilePathSuccess({
                openFile: action.openFile,
                template: action.template,
                destination: action.destination
              }),
            ])
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.renameFileError({
            openFile: action.openFile,
            template: action.template,
            newName: action.newName,
          });
        },
      })
    )
  );

  renameFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.renameFile),
      fetch({
        run: (action: any) => {
          const { source, destination } =
            this.pathCmsService.createRenameSourceAndDestination(
              action.template,
              action.source,
              action.destination
            );
          return this.downloadUrlService.renameS3File(source, destination).pipe(
            tap((_) =>
              this.snackBar.open(
                `${action.openFile.name} has been re-named`,
                undefined,
                { duration: 3000 }
              )
            ),
            mergeMap((renameResponse) => [
              TemplatesActions.updateStep({
                orgId: action.template.orgId,
                pathId: action.template.pathId,
                recipeId: action.template.recipeId,
                id: action.template.id,
                stepUpdateParams: { parameters: action.template.parameters },
              }),
              PathCmsActions.renameFileSuccess({
                openFile: action.openFile,
                template: action.template,
                newName: action.newName,
              }),
            ])
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.renameFileError({
            openFile: action.openFile,
            template: action.template,
            newName: action.newName,
          });
        },
      })
    )
  );

  renameTerminalFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.renameTerminalFile),
      fetch({
        run: (action: any) => {
          return this.downloadUrlService
            .renameS3File(
              `${action.template.orgId}/${action.template.pathId}/${action.template.recipeId}/${action.template.id}/code/{terminalFiles}/${action.terminalFile.name}`,
              `${action.template.orgId}/${action.template.pathId}/${action.template.recipeId}/${action.template.id}/code/{terminalFiles}/${action.newName}`
            )
            .pipe(
              tap((_) =>
                this.snackBar.open(
                  `${action.terminalFile.name} has been re-named`,
                  undefined,
                  { duration: 3000 }
                )
              ),
              mergeMap((renameResponse) => [
                TemplatesActions.updateStep({
                  orgId: action.template.orgId,
                  pathId: action.template.pathId,
                  recipeId: action.template.recipeId,
                  id: action.template.id,
                  stepUpdateParams: { parameters: action.template.parameters },
                }),
                PathCmsActions.renameTerminalFileSuccess({
                  terminalFile: action.terminalFile,
                  template: action.template,
                  newName: action.newName,
                }),
              ])
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.renameTerminalFileError({
            terminalFile: action.terminalFile,
            template: action.template,
            newName: action.newName,
          });
        },
      })
    )
  );

  renameFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.renameFolder),
      fetch({
        run: (action: any) => {
          return this.downloadUrlService
            .renameS3Folder(
              `${action.template.orgId}/${action.template.pathId}/${action.template.recipeId}/${action.template.id}/code/${action.openFolder.filesToGenerateFolderName}/${action.openFolder.name}`,
              action.newFolderName
            )
            .pipe(
              tap((_) =>
                this.snackBar.open(
                  `${action.openFile.name} has been re-named`,
                  undefined,
                  { duration: 3000 }
                )
              ),
              mergeMap((renameResponse) => [
                PathCmsActions.renameFolderSuccess({
                  openFolder: action.openFile,
                  template: action.template,
                  newFolderName: action.newName,
                }),
              ])
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.renameFolderError({
            openFolder: action.openFile,
            template: action.template,
            newFolderName: action.newName,
          });
        },
      })
    )
  );

  renameStaticFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.renameStaticFolder),
      fetch({
        run: (action: any) => {
          return this.downloadUrlService
            .renameS3Folder(
              `${action.template.orgId}/${action.template.pathId}/${action.template.recipeId}/${action.template.id}/code/${action.openFolder.path}`,
              `${action.newFolderName}`
            )
            .pipe(
              tap((_) =>
                this.snackBar.open(
                  `${action.openFolder.name} has been re-named`,
                  undefined,
                  { duration: 3000 }
                )
              ),
              mergeMap((renameResponse) => [
                PathCmsActions.renameFolderSuccess({
                  openFolder: action.openFile,
                  template: action.template,
                  newFolderName: action.newName,
                }),
              ])
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.renameFolderError({
            openFolder: action.openFile,
            template: action.template,
            newFolderName: action.newName,
          });
        },
      })
    )
  );

  newFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.newFile),
      fetch({
        run: (action: any) => {
          if (
            this.templateService.determineIfExcessFilePathParameters(
              action.template.parameters
            )
          ) {
            const error =
              'Cannot be more than three file path parameters per step.';
            this.snackBar.open(`${error}`, undefined, { duration: 3000 });
            return PathCmsActions.newFileError({ openFile: action.openFile });
          }
          
          return this.templateService
            .getStepFileUploadUrl(
              action.userOrgId,
              action.template.orgId,
              action.template.pathId,
              action.template.recipeId,
              action.template.id,
              action.dynamicPath
            )
            .pipe(
              switchMap((response: any) => {
                const url = response['url'];
                const contentType = response['contentType'];
                return this.downloadUrlService.uploadFileFromUrl(url, '', contentType);
              }),
              mergeMap((parameters) => [
                TemplatesActions.updateStep({
                  orgId: action.template.orgId,
                  pathId: action.template.pathId,
                  recipeId: action.template.recipeId,
                  id: action.template.id,
                  stepUpdateParams: { parameters: action.template.parameters },
                }),
                PathCmsActions.newFileSuccess({
                  openFile: action.openFile,
                  template: action.template,
                  newFileName: action.newFileName,
                  starterFilesToGenerate: action.starterFilesToGenerate,
                }),
                PathCmsActions.loadStarterWithFilesToGenerateSuccess({
                  starterWithFilesToGenerate: action.starterFilesToGenerate,
                }),
              ])
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.newFileError({ openFile: action.openFile });
        },
      })
    )
  );

  newStarterFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.newStarterFile),
      fetch({
        run: (action: any) => {
          const fullFilePath = action.filePath;
          return this.templateService
            .getStepFileUploadUrl(
              action.userOrgId,
              action.template.orgId,
              action.template.pathId,
              action.template.recipeId,
              action.template.id,
              fullFilePath
            )
            .pipe(
              switchMap((response: any) => {
                const url = response['url'];
                const contentType = response['contentType'];
                return this.downloadUrlService.uploadFileFromUrl(url, '', contentType);
              }),
              mergeMap((parameters) => [
                TemplatesActions.updateStep({
                  orgId: action.template.orgId,
                  pathId: action.template.pathId,
                  recipeId: action.template.recipeId,
                  id: action.template.id,
                  stepUpdateParams: { parameters: action.template.parameters },
                }),
                PathCmsActions.newFileSuccess({
                  openFile: action.openFile,
                  template: action.template,
                  newFileName: action.newFileName,
                  starterFilesToGenerate: action.starterFilesToGenerate,
                }),
                PathCmsActions.loadStarterWithFilesToGenerateSuccess({
                  starterWithFilesToGenerate: action.starterFilesToGenerate,
                }),
              ])
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.newFileError({ openFile: action.openFile });
        },
      })
    )
  );

  updateCodemod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.updateCodemod),
      fetch({
        run: (action: any) => {
          const template = action.template;
          return this.codeModService.codeMorph(action.user.orgId, template.orgId, 
            template.pathId, template.recipeId, template.id, 
            action.openFile.name.split('.').slice(-1)[0], action.openFile.path, 
            action.codeMod, action.openFile.fileText as string).pipe(
              map((result: any) => {
                console.log('result');
                console.log(result);
                this.snackBar.open(`${action.openFile.name} has been updated`, undefined, {duration: 3000});
                return PathCmsActions.updateCodemodSuccess({openFile: action.openFile, template, fileText: result.file, updates: result.updates});  
            }));
            // PathCmsActions.loadStarterWithFilesToGenerateSuccess({
            //   starterWithFilesToGenerate: action.starterFilesToGenerate,
            // }),
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.updateCodemodError({
            openFile: action.openFile,
          });
        },
      })
    )
  );

  saveFileWhenCodemodUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.updateCodemod),
      fetch({
        run: (action: any) => {
          return PathCmsActions.saveFile({
            codeChanged: action.openFile.fileText,
            openFile: action.openFile,
            template: action.template,
            userOrgId: action.userOrgId,
          });
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.updateCodemodError({
            openFile: action.openFile,
          });
        },
      })
    )
  );

  newTerminalFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.newTerminalFile),
      fetch({
        run: (action: any) => {
          const fullFilePath = `{terminalFiles}/${action.terminalFile.name}`;
          return this.templateService
            .getStepFileUploadUrl(
              action.userOrgId,
              action.template.orgId,
              action.template.pathId,
              action.template.recipeId,
              action.template.id,
              fullFilePath
            )
            .pipe(
              switchMap((response: any) => {
                const url = response['url'];
                const contentType = response['contentType'];
                return this.downloadUrlService.uploadFileFromUrl(url, '', contentType);
              }),
              map((_) => {
                this.snackBar.open(
                  `${action.terminalFile.name} has been created.`,
                  undefined,
                  { duration: 3000 }
                );
                return PathCmsActions.newTerminalFileSuccess({
                  template: action.template,
                  terminalFile: action.terminalFile,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.newTerminalFileError({
            terminalFile: action.terminalFile,
          });
        },
      })
    )
  );

  newFileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.newFileSuccess),
      fetch({
        run: (action: any) => {
          this.snackBar.open(
            `${action.newFileName} has been created`,
            undefined,
            { duration: 3000 }
          );
        },
      })
    )
  );

  deleteFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.deleteFile),
      fetch({
        run: (action: any) => {
          const source = action.openFile.dynamicPath;
          return this.downloadUrlService
            .deleteS3File(
              `${action.template.orgId}/${action.template.pathId}/${action.template.recipeId}/${action.template.id}/code/${source}`
            )
            .pipe(
              tap((_) =>
                this.snackBar.open(
                  `${action.openFile.name} has been deleted`,
                  undefined,
                  { duration: 3000 }
                )
              ),
              mergeMap((deleteResponse) => [
                TemplatesActions.updateStep({
                  orgId: action.template.orgId,
                  pathId: action.template.pathId,
                  recipeId: action.template.recipeId,
                  id: action.template.id,
                  stepUpdateParams: {
                    updates: action.template.updates,
                    parameters: action.template.parameters,
                  },
                }),
                PathCmsActions.deleteFileSuccess({
                  openFile: action.openFile,
                  template: action.template,
                }),
              ])
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.deleteFileError({
            openFile: action.openFile,
            template: action.template,
          });
        },
      })
    )
  );

  deleteFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.deleteFolder),
      fetch({
        run: (action: any) => {
          return this.downloadUrlService
            .deleteS3Folder(
              `${action.template.orgId}/${action.template.pathId}/${action.template.recipeId}/${action.template.id}/code/${action.folderName}`
            )
            .pipe(
              tap((_) =>
                this.snackBar.open(
                  `${action.folderName} has been deleted`,
                  undefined,
                  { duration: 3000 }
                )
              ),
              mergeMap((deleteResponse) => [
                TemplatesActions.updateStep({
                  orgId: action.template.orgId,
                  pathId: action.template.pathId,
                  recipeId: action.template.recipeId,
                  id: action.template.id,
                  stepUpdateParams: { parameters: action.template.parameters },
                }),
                PathCmsActions.deleteFolderSuccess({
                  folderName: action.folderName,
                  template: action.template,
                  fileIdsToDelete: action.fileIdsToDelete,
                }),
              ])
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.deleteFolderError({
            folderName: action.folderName,
            template: action.template,
            fileIdsToDelete: action.fileIdsToDelete,
          });
        },
      })
    )
  );

  deleteTerminalFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.deleteTerminalFile),
      fetch({
        run: (action: any) => {
          return this.downloadUrlService
            .deleteS3File(
              `${action.template.orgId}/${action.template.pathId}/${action.template.recipeId}/${action.template.id}/code/{terminalFiles}/${action.terminalFile.name}`
            )
            .pipe(
              tap((_) =>
                this.snackBar.open(
                  `${action.terminalFile.name} has been deleted`,
                  undefined,
                  { duration: 3000 }
                )
              ),
              mergeMap((deleteResponse) => [
                TemplatesActions.updateStep({
                  orgId: action.template.orgId,
                  pathId: action.template.pathId,
                  recipeId: action.template.recipeId,
                  id: action.template.id,
                  stepUpdateParams: { parameters: action.template.parameters, type: action.terminalFiles.length > 1 ? 'Command': 'Generate'},
                }),
                PathCmsActions.deleteTerminalFileSuccess({
                  terminalFile: action.terminalFile,
                  template: action.template,
                }),
              ])
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.deleteTerminalFileError({
            terminalFile: action.terminalFile,
            template: action.template,
          });
        },
      })
    )
  );

  addStepDependency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.addStepDependency),
      fetch({
        run: (action: any) => {
          return this.stepDependencyService
            .addStepDependency(
              action.template.orgId,
              action.template.pathId,
              action.template.recipeId,
              action.template.id,
              action.dependentOrgId,
              action.dependentPathId,
              action.dependentRecipeId,
              action.dependentStepId
            )
            .pipe(
              map((template) => {
                this.snackBar.open(
                  `${template.title} has been added as a step depedency.`,
                  undefined,
                  { duration: 3000 }
                );
                return PathCmsActions.addStepDependencySuccess({
                  stepToUpdateTemplate: action.template,
                  dependencyStep: template,
                });
              }),
              catchError((error: any) => {
                if (error instanceof ApolloError) {
                  this.snackBar.open(
                    error.message,
                    undefined,
                    { duration: 3000 }
                  );
                }
                return of(PathCmsActions.addStepDependencyError({ error }));
              })
            );
        }
      })
    )
  );

  addStepDependencySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.addStepDependencySuccess),
      fetch({
        run: (action: any) => {
          const filesToGenerateTree = this.fileTreeService.returnStepFileTreeFilesToGenerateFlatFolderStructure(
            action.stepToUpdateTemplate.fileTree,
            action.stepToUpdateTemplate.parameters
          );
          return this.templateService
            .getDependentSteps(action.dependencyStep.orgId, action.dependencyStep.pathId, [...action.stepToUpdateTemplate.dependentSteps, action.dependencyStep])
            .pipe(
              map((dependencySteps: any) => {
                return PathCmsActions.loadStarterWithFilesToGenerate({
                  template: action.stepToUpdateTemplate,
                  filesToGenerateTree: [
                    ...filesToGenerateTree,
                    ...dependencySteps,
                  ],
                })}
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
        },
      })
    )
  );

  removeStepDependency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.removeStepDependency),
      fetch({
        run: (action: any) => {
          return this.stepDependencyService
            .removeStepDependency(
              action.template.orgId,
              action.template.pathId,
              action.template.recipeId,
              action.template.id,
              action.dependentRecipeId,
              action.dependentStepId
            )
            .pipe(
              map((template) => {
                this.snackBar.open(
                  `${action.dependentStepId} has been removed as a step depedency.`,
                  undefined,
                  { duration: 3000 }
                );
                return PathCmsActions.removeStepDependencySuccess({
                  stepToUpdateTemplate: action.template,
                  template: template,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.removeStepDependencyError({ error });
        },
      })
    )
  );

  saveDocumentation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.saveDocumentation),
      fetch({
        run: (action: any) => {
          return this.templateService
            .saveDocumentation(
              action.pathOrgId,
              action.pathId,
              action.recipeId,
              action.stepId,
              action.markdown
            )
            .pipe(
              map((response) => {
                this.snackBar.open(
                  `${action.stepId} documentation has been updated`,
                  undefined,
                  { duration: 3000 }
                );
                return PathCmsActions.saveDocumentationSuccess({
                  markdownConvertedToHtml: response.markdownConvertedToHtml
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.removeStepDependencyError({ error });
        },
      })
    )
  );

  loadRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.loadRecipe),
      fetch({
        run: (action: any) => {
          return this.templateService
            .getTemplate(
              action.step.orgId,
              action.step.pathId,
              action.step.recipeId,
              undefined,
              false,
              false
            )
            .pipe(
              map((recipeTemplate: any) => {
                return PathCmsActions.loadRecipeSuccess({
                  recipe: recipeTemplate,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.loadRecipeError({ error });
        },
      })
    )
  );

  updateRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PathCmsActions.updateRecipe),
      fetch({
        run: (action: any) => {
          return this.pathService
            .updateRecipe(
              action.orgId,
              action.pathId,
              action.recipeId,
              action.recipeUpdateParams
            )
            .pipe(
              map((recipeTemplate: any) => {
                this.snackBar.open(
                  `${recipeTemplate.title} has been updated`,
                  undefined,
                  { duration: 3000 }
                );
                return PathCmsActions.loadRecipeSuccess({
                  recipe: recipeTemplate,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return PathCmsActions.loadRecipeError({ error });
        },
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private templateService: TemplateService,
    private fileTreeService: FileTreeService,
    private codeModService: CodeModService,
    private pathCmsService: PathCmsService,
    private pathCmsScaffoldService: PathCmsScaffoldService,
    private pathService: PathService,
    private downloadUrlService: DownloadUrlService,
    private snackBar: MatSnackBar,
    private terminalService: TerminalService,
    private vscodeService: VscodeService,
    private stepDependencyService: StepDependencyService,
    private versioningService: VersioningService
  ) {}
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PATH_FEATURE_KEY, State, pathsAdapter, secondaryPathsAdapter, pathVersionsAdapter, projectPathsAdapter } from './path.reducer';

// Lookup the 'Path' feature state managed by NgRx
export const getPathState = createFeatureSelector<State>(PATH_FEATURE_KEY);
const secondaryPathsAdapterSelect = secondaryPathsAdapter.getSelectors();
const projectPathsAdapterSelect = projectPathsAdapter.getSelectors();
const pathVersionsAdapterSelect = pathVersionsAdapter.getSelectors();
const secondaryPathsSelectAll = secondaryPathsAdapterSelect.selectAll;
const projectPathsSelectAll = projectPathsAdapterSelect.selectAll;
const secondaryPathsSelectEntities = secondaryPathsAdapterSelect.selectEntities;
const pathVersionsSelectAll = pathVersionsAdapterSelect.selectAll;
const { selectAll, selectEntities } = pathsAdapter.getSelectors();

export const getPathLoaded = createSelector(
  getPathState,
  (state: State) => state.paths.loaded
);

export const getProjectPathsLoaded = createSelector(
  getPathState,
  (state: State) => state.projectPaths?.loaded
);

export const getPathError = createSelector(
  getPathState,
  (state: State) => state.paths.error
);

export const getAllPaths = createSelector(getPathState, (state: State) =>
  selectAll(state.paths)
);

export const getPathEntities = createSelector(getPathState, (state: State) =>
  selectEntities(state.paths)
);

export const getSelectedId = createSelector(
  getPathState,
  (state: State) => state.paths.selectedId
);

export const getPathPermissions = createSelector(
  getPathState,
  (state: State) => state.pathPermissions
);

export const getPathVersionsLoaded = createSelector(
  getPathState,
  (state: State) => state.pathVersions?.loaded
);

export const getSelected = createSelector(
  getPathEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getAllSecondaryPaths = createSelector(getPathState, (state: State) =>
  secondaryPathsSelectAll(state.secondaryPaths)
);

export const getProjectPaths = createSelector(getPathState, (state: State) =>
  projectPathsSelectAll(state.projectPaths)
);

export const getAllPathVersions = createSelector(getPathState, (state: State) =>
  pathVersionsSelectAll(state.pathVersions)
);
import { FlatFolderFile, TerminalFile } from '@razroo-zeta/common-services';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as PathCmsActions from './path-cms.actions';
import { FilesToGenerateEntity, OpenFilesEntity, PathCmsEntity, StarterWithFilesToGenerateEntity, TerminalOpenFilesEntity } from './path-cms.models';
import { Template } from '@razroo-zeta/data-models';

export const PATH_CMS_FEATURE_KEY = 'pathCms';

export interface ActivityBar {
  folderFileTree: boolean;
  stepScroller: boolean;
  editContent: boolean;
  documentation: boolean;
  download: boolean;
}

const activityBarResetState: ActivityBar = {
  folderFileTree: false,
  stepScroller: false,
  editContent: false,
  documentation: false,
  download: false
}

export interface State {
  filesToGenerate: FilesToGenerateState;
  starterWithFilesToGenerate: StarterWithFilesToGenerateState;
  openFiles: OpenFilesState;
  terminalOpenFiles: TerminalOpenFilesState;
  activityBar: ActivityBar;
  documentationSaving?: boolean;
  recipe?: Template;
  codeModLoaded?: boolean;
  codeMod: boolean;
  splitAreaPercent: any;
  starterTemplate: any;
}

export interface FilesToGenerateState extends EntityState<FilesToGenerateEntity> {
  selectedId?: string | number; // which Organizations record has been selected
  loaded?: boolean; // has the Organizations list been loaded
  error?: string | null; // last known error (if any)
}

export interface StarterWithFilesToGenerateState extends EntityState<StarterWithFilesToGenerateEntity> {
  selectedId?: string | number; // which Organizations record has been selected
  loaded?: boolean; // has the Organizations list been loaded
  error?: string | null; // last known error (if any)
}

export interface OpenFilesState extends EntityState<OpenFilesEntity> {
  selectedId?: string | number; // which Organizations record has been selected
  loaded?: boolean; // has the Organizations list been loaded
  error?: string | null; // last known error (if any)
}

export interface TerminalOpenFilesState extends EntityState<TerminalOpenFilesEntity> {
  selectedId?: string | number; // which Organizations record has been selected
  loaded?: boolean; // has the Organizations list been loaded
  error?: string | null; // last known error (if any)
}

export function selectFileId(flatFolderFile: FlatFolderFile): string {
  //path will be used as the key for each file type
  return flatFolderFile.id;
}

export function selectTerminalFileId(terminalFile: TerminalFile): string {
  //path will be used as the key for each file type
  return terminalFile.id;
}

export const filesToGenerateAdapter: EntityAdapter<FilesToGenerateEntity> =
  createEntityAdapter<FilesToGenerateEntity>({
    selectId: selectFileId
  });

export const starterWithFilesToGenerateAdapter: EntityAdapter<StarterWithFilesToGenerateEntity> =
  createEntityAdapter<StarterWithFilesToGenerateEntity>({
    selectId: selectFileId
  });

export const openFilesAdapter: EntityAdapter<OpenFilesEntity> =
  createEntityAdapter<OpenFilesEntity>({
    selectId: selectFileId
  });

export const terminalOpenFilesAdapter: EntityAdapter<TerminalOpenFilesEntity> =
  createEntityAdapter<TerminalOpenFilesEntity>({
    selectId: selectTerminalFileId
  });

export const pathCmsAdapter: EntityAdapter<PathCmsEntity> = createEntityAdapter<PathCmsEntity>();

export const filesToGenerateInitialState: FilesToGenerateState = filesToGenerateAdapter.getInitialState({
  loaded: false,
  selectedId: '',
  error: null
});

export const starterWithFilesToGenerateState: StarterWithFilesToGenerateState = starterWithFilesToGenerateAdapter.getInitialState({
  loaded: false,
  selectedId: '',
  error: null
});

export const openFilesState: OpenFilesState = openFilesAdapter.getInitialState({
  loaded: false,
  selectedId: '',
  error: null
});

export const terminalOpenFilesState: TerminalOpenFilesState = terminalOpenFilesAdapter.getInitialState({
  loaded: false,
  selectedId: '',
  error: null
});

export interface PathCmsPartialState {
  readonly [PATH_CMS_FEATURE_KEY]: State;
}

export const initialState: State = pathCmsAdapter.getInitialState({
  filesToGenerate: filesToGenerateInitialState,
  starterWithFilesToGenerate: starterWithFilesToGenerateState,
  starterTemplate: undefined,
  openFiles: openFilesState,
  terminalOpenFiles: terminalOpenFilesState,
  recipe: undefined,
  splitAreaPercent: undefined,
  activityBar: {
    folderFileTree: true,
    stepScroller: false,
    editContent: false,
    documentation: false,
    download: false
  },
  codeMod: false,
  codeModLoaded: true,
  loaded: false,
  documentationSaving: false
});

const pathCmsReducer = createReducer(
  initialState,
  on(PathCmsActions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(PathCmsActions.splitAreaDragEnd, (state, {percent}) => ({
    ...state,
    splitAreaPercent: percent
  })),
  on(PathCmsActions.saveDocumentation, (state) => ({
    ...state,
    documentationSaving: true
  })),
  on(PathCmsActions.saveDocumentationSuccess, (state) => ({
    ...state,
    documentationSaving: false
  })),
  on(PathCmsActions.newFile, (state, { starterFilesToGenerate, openFile }) =>
    ({
      ...state,
      openFiles: openFilesAdapter.upsertOne(openFile, {
        ...state.openFiles,
        selectedId: openFile.id,
        loaded: true
      }),
      starterWithFilesToGenerate: starterWithFilesToGenerateAdapter.setAll(starterFilesToGenerate, {
        ...state.starterWithFilesToGenerate,
        selectedId: starterFilesToGenerate ? starterFilesToGenerate[0].id : '',
        loaded: true
      })
    })
  ),
  on(PathCmsActions.newStarterFile, (state, { starterFilesToGenerate, openFile }) =>
    ({
      ...state,
      openFiles: openFilesAdapter.upsertOne(openFile, {
        ...state.openFiles,
        selectedId: openFile.id,
        loaded: true
      }),
      starterWithFilesToGenerate: starterWithFilesToGenerateAdapter.setAll(starterFilesToGenerate, {
        ...state.starterWithFilesToGenerate,
        selectedId: starterFilesToGenerate ? starterFilesToGenerate[0].id : '',
        loaded: true
      })
    })
  ),
  on(PathCmsActions.updateCodemod, (state, { openFile }) =>
    ({
      ...state,
      codeModLoaded: false,
      openFiles: openFilesAdapter.upsertOne(openFile, {
        ...state.openFiles,
        selectedId: openFile.id,
        loaded: true
      })
    })
  ),
  on(PathCmsActions.updateCodemodSuccess, (state, { openFile, fileText }) =>
    ({
      ...state,
      codeModLoaded: true,
      openFiles: openFilesAdapter.upsertOne({...openFile, fileText}, {
        ...state.openFiles,
        selectedId: openFile.id,
        loaded: true
      })
    })
  ),
  on(PathCmsActions.newFileError, (state, { openFile }) =>
    ({
      ...state,
      starterWithFilesToGenerate: starterWithFilesToGenerateAdapter.removeOne(openFile.id, {
        ...state.starterWithFilesToGenerate,
        loaded: true
      })
    })
  ),
  on(PathCmsActions.newTerminalFile, (state, { terminalFile }) =>
    ({
      ...state,
      terminalOpenFiles: terminalOpenFilesAdapter.upsertOne(terminalFile, {
        ...state.terminalOpenFiles,
        selectedId: terminalFile.id,
        loaded: false
      })
    })
  ),
  on(PathCmsActions.newTerminalFileError, (state, { terminalFile }) =>
    ({
      ...state,
      terminalOpenFiles: terminalOpenFilesAdapter.removeOne(terminalFile.id, {
        ...state.terminalOpenFiles,
        selectedId: state.terminalOpenFiles && state.terminalOpenFiles[0] && state.terminalOpenFiles[0].id,
        loaded: true
      })
    })
  ),
  on(PathCmsActions.newTerminalFileSuccess, (state, { terminalFile }) =>
    ({
      ...state,
      terminalOpenFiles: terminalOpenFilesAdapter.upsertOne(terminalFile, {
        ...state.terminalOpenFiles,
        selectedId: terminalFile.id,
        loaded: true
      })
    })
  ),
  on(PathCmsActions.loadTerminalFilesToGenerateSuccess, (state, { terminalFiles }) =>
    ({
      ...state,
      terminalOpenFiles: terminalOpenFilesAdapter.setAll(terminalFiles, {
        ...state.terminalOpenFiles,
        selectedId: terminalFiles && terminalFiles[0] ? terminalFiles[0].id : undefined,
        loaded: true
      })
    })
  ),
  on(PathCmsActions.loadFileTerminalSuccess, (state, { terminalFile }) =>
    ({
      ...state,
      terminalOpenFiles: terminalOpenFilesAdapter.upsertOne(terminalFile, {
        ...state.terminalOpenFiles,
        selectedId: terminalFile ? terminalFile.id : undefined,
        loaded: false
      })
    })
  ),
  on(PathCmsActions.loadFirstFileSuccess, (state, { flatFolderFile }) =>
    ({
      ...state,
      openFiles: flatFolderFile ? openFilesAdapter.upsertOne(flatFolderFile, {
        ...state.openFiles,
        selectedId: flatFolderFile && flatFolderFile.id ? flatFolderFile.id : undefined,
        loaded: true
      }) : {
        ...state.openFiles
      }
    })
  ),
  on(PathCmsActions.loadFirstTerminalFileSuccess, (state, { terminalFile }) =>
    ({
      ...state,
      terminalOpenFiles: terminalFile ? terminalOpenFilesAdapter.upsertOne(terminalFile, {
        ...state.terminalOpenFiles,
        selectedId: terminalFile && terminalFile.id ? terminalFile.id : undefined,
        loaded: true
      }) : {
        ...state.terminalOpenFiles
      }
    })
  ),
  on(PathCmsActions.selectOpenFile, (state, { openFile }) =>{
    let newIdsArray = [...state.openFiles.ids].filter(id => id !== openFile.id);
    newIdsArray.push(openFile.id);
    return ({
      ...state,
      openFiles: openFilesAdapter.upsertOne(openFile, {
        ...state.openFiles,
        ids: newIdsArray as string[],
        selectedId: openFile.id
      })
    })
  }),
  
  on(PathCmsActions.loadFile, (state, { flatFolderFile }) =>
    ({
      ...state,
      openFiles: openFilesAdapter.upsertOne(flatFolderFile, {
        ...state.openFiles,
        selectedId: flatFolderFile ? flatFolderFile.id : undefined,
        isPreview: !flatFolderFile.filesToGenerateFolderName,
        loaded: false
      })
    })
  ),
  on(PathCmsActions.loadFileSuccess, (state, { flatFolderFile }) =>
    ({
      ...state,
      openFiles: openFilesAdapter.upsertOne(flatFolderFile, {
        ...state.openFiles,
        selectedId: flatFolderFile ? flatFolderFile.id : undefined,
        isPreview: !flatFolderFile.filesToGenerateFolderName,
        loaded: true
      })
    })
  ),
  on(PathCmsActions.editFile, (state, { codeChanged, openFile }) =>
    ({
      ...state,
      openFiles: openFilesAdapter.upsertOne({...openFile,
        isEdited: true
        // fileText: codeChanged
      }, {
        ...state.openFiles,
      })
    })
  ),
  on(PathCmsActions.editTerminalFile, (state, { terminalFile, terminalTextChanged }) =>
    ({
      ...state,
      terminalOpenFiles: terminalOpenFilesAdapter.upsertOne({...terminalFile,
        isEdited: true
      }, {
        ...state.terminalOpenFiles,
      })
    })
  ),
  on(PathCmsActions.saveFile, (state, { codeChanged, openFile }) =>
    ({
      ...state,
      openFiles: openFilesAdapter.upsertOne({...openFile,
        isEdited: false
      }, {
        ...state.openFiles,
      })
    })
  ),
  on(PathCmsActions.saveTerminalFile, (state, { fileText, terminalFile }) =>
    ({
      ...state,
      terminalOpenFiles: terminalOpenFilesAdapter.upsertOne({...terminalFile,
        isEdited: false
      }, {
        ...state.terminalOpenFiles,
      })
    })
  ),
  // DONT DO THIS - WILL CAUSE CODE EDITOR TO RESET SELF
  // DUE TO CODE EDITOR BEING NATIVE HTML AND ANGULAR USING ZONE
  // LEAVING HERE COMMENTED OUT FOR WHAT NOT TO DO
  // on(PathCmsActions.saveFileSuccess, (state, { codeChanged, openFile }) =>
  //   ({
  //     ...state,
  //     openFiles: openFilesAdapter.upsertOne({...openFile,
  //       isEdited: false,
  //       --> don't do this fileText: codeChanged
  //     }, {
  //       ...state.openFiles,
  //     })
  //   })
  // ),
  on(PathCmsActions.saveFileError, (state, { codeChanged, openFile }) =>
    ({
      ...state,
      openFiles: openFilesAdapter.upsertOne({...openFile,
        isEdited: true
      }, {
        ...state.openFiles,
      })
    })
  ),
  on(PathCmsActions.renameFileSuccess, (state, { openFile, newName }) =>
    ({
      ...state,
      openFiles: openFilesAdapter.updateOne({
        id: openFile.id,
        changes: {
          path: openFile.path.split('/').slice(0, -1).concat(newName).join('/'),
          name: newName
        }
      }, state.openFiles),
      starterWithFilesToGenerate: starterWithFilesToGenerateAdapter.updateOne({
        id: openFile.id,
        changes: {
          path: openFile.path.split('/').slice(0, -1).concat(newName).join('/'),
          name: newName
        }
      }, state.starterWithFilesToGenerate)
    })
  ),

  on(PathCmsActions.renameTerminalFileSuccess, (state, { terminalFile, newName }) =>
    ({
      ...state,
      terminalOpenFiles: terminalOpenFilesAdapter.updateOne({
        id: terminalFile.id,
        changes: {
          name: newName
        }
      }, state.terminalOpenFiles),
    })
  ),
  on(PathCmsActions.closeFile, (state, { flatFolderFile }) =>{
    let otherFileIds = [...state.openFiles.ids].filter(id => id !== flatFolderFile.id);
    return ({
      ...state,
      openFiles: openFilesAdapter.removeOne(flatFolderFile.id as string, {
        ...state.openFiles,
        selectedId: otherFileIds[otherFileIds.length - 1],
        loaded: true
      })
    })
  }),
  on(PathCmsActions.deleteFile, (state, { openFile }) =>
    ({
      ...state,
      openFiles: openFilesAdapter.removeOne(openFile.id, state.openFiles),
      starterWithFilesToGenerate: starterWithFilesToGenerateAdapter.removeOne(openFile.id, state.starterWithFilesToGenerate)
    })
  ),
  on(PathCmsActions.deleteFolder, (state, { fileIdsToDelete }) =>
    ({
      ...state,
      openFiles: openFilesAdapter.removeMany(fileIdsToDelete, state.openFiles),
      starterWithFilesToGenerate: starterWithFilesToGenerateAdapter.removeMany(fileIdsToDelete, state.starterWithFilesToGenerate)
    })
  ),
  on(PathCmsActions.deleteTerminalFile, (state, { terminalFile }) =>
    ({
      ...state,
      terminalOpenFiles: terminalOpenFilesAdapter.removeOne(terminalFile.id, state.terminalOpenFiles),
    })
  ),
  on(PathCmsActions.loadStarterWithFilesToGenerateSuccess, (state, { starterWithFilesToGenerate, starterTemplate }) =>
    ({
      ...state,
      starterTemplate,
      starterWithFilesToGenerate: starterWithFilesToGenerateAdapter.setAll(starterWithFilesToGenerate, {
        ...state.starterWithFilesToGenerate,
        selectedId: starterWithFilesToGenerate && starterWithFilesToGenerate.length > 0 ? starterWithFilesToGenerate[0].id : '',
        loaded: true
      })
    })
  ),
  on(PathCmsActions.toggleFolder, (state, { folder, treeName }) =>
    ({
      ...state,
      [treeName]: starterWithFilesToGenerateAdapter.upsertOne({...folder, isExpanded: !folder.isExpanded}, {
        ...state[treeName],
      })
    })
  ),
  on(PathCmsActions.activateActivity, (state, { activeActivity }) =>
    ({
      ...state,
      activityBar: {
        ...activityBarResetState,
        [activeActivity]: true
      }
    })
  ),
  on(PathCmsActions.activateCodeMod, (state, { editFileStatus }) =>
    ({
      ...state,
      codeMod: editFileStatus
    })
  ),

  on(PathCmsActions.loadRecipeSuccess, (state, { recipe }) =>
    ({
      ...state,
      recipe
    })
  ),
  on(PathCmsActions.resetStore, () =>
    ({
      ...initialState
    })
  ),
  on(PathCmsActions.addUploadedFilesToTree, (state, {uploadedFiles}) =>
    ({
      ...state,
      starterWithFilesToGenerate: starterWithFilesToGenerateAdapter.addMany(
        uploadedFiles, 
        state.starterWithFilesToGenerate
      )
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return pathCmsReducer(state, action);
}

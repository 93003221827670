import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { TrialDaysLeftPipeModule } from '@razroo-zeta/common/pipes';
import { LetDirective, PushPipe } from '@ngrx/component';
import { CorePathIdPipe } from '@razroo-zeta/data-pipes';
import { NgArrayPipesModule } from 'ngx-pipes';
import { NonReadNotificationsPipeModule } from './non-read-notifications/non-read-notifications.pipe';
import { OrgInitialsPipe } from './header/org-initials/org-initials.pipe';
import { OrgNamePipe } from './header/org-name/org-name.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgOptimizedImage } from '@angular/common';
import { CommonUiModule } from '@razroo-zeta/common/ui';
import { REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';
@NgModule({
  imports: [
    MatSelectModule,
    CommonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatMenuModule,
    TrialDaysLeftPipeModule,
    LetDirective, PushPipe,
    NgArrayPipesModule,
    NonReadNotificationsPipeModule,
    MatProgressSpinnerModule,
    NgOptimizedImage,
    CommonUiModule
  ],
  declarations: [HeaderComponent, OrgInitialsPipe, OrgNamePipe],
  providers: [
    CorePathIdPipe,
    {provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: false} 
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}

/*
 * This code snippet is based on
 * https://juristr.com/blog/2016/09/ng2-get-window-ref/
 */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class WindowRefService {
  constructor(@Inject(DOCUMENT) private _doc: Document) {}

    get nativeWindow(): Window {
      return this._doc.defaultView!;
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromPathCms from './+state/path-cms.reducer';
import { PathCmsEffects } from './+state/path-cms.effects';
import { PathCmsFacade } from './+state/path-cms.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromPathCms.PATH_CMS_FEATURE_KEY,
      fromPathCms.reducer
    ),
    EffectsModule.forFeature([PathCmsEffects]),
  ],
  providers: [PathCmsFacade],
})
export class DataAccessPathCmsModule {}

import { Injectable } from '@angular/core';
import { NanoService } from '../nano/nano.service';
import { TerminalFile } from './terminal.interface';

@Injectable({
  providedIn: 'root'
})
export class TerminalService {
  // idPathDictionary put in place to keep ids unique across different
  idPathDictionary: {[name: string]: {id: string}} = {};

  constructor(private nanoService: NanoService) { }

  createTerminalFile(terminalFileName: string): TerminalFile {
    if(!this.idPathDictionary[terminalFileName]) {
      this.idPathDictionary[terminalFileName] = {id: this.nanoService.nanoid()};
    }
    return {
      name: terminalFileName,
      id: this.idPathDictionary[terminalFileName].id,
      fileText: '',
      filesToGenerateFolderName: '{terminalFiles}',
      isEdited: false,
      isActive: false,
    }
  }

  createTerminalFiles(fileTree: string[] | undefined): TerminalFile[] {
    const terminalFiles = fileTree && fileTree.filter((file: string) => file.indexOf('{terminalFiles}') > -1);
    if(!terminalFiles || !(terminalFiles.length)) {
      return [];
    }
    else {
      return terminalFiles.map((terminalFilePath: string) => {
        const terminalFileName = terminalFilePath.split('/').pop();
        return this.createTerminalFile(terminalFileName as string);
      })
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: string): string {
    return value && value.split(' ').map(word => word[0].toUpperCase()).join('');
  }

}

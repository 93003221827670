import gql from 'graphql-tag';
import { OrganizationFragment } from './organizations.fragments';

export const GetOrganization = gql`
  query organization($orgId: ID!) {
    organization(orgId: $orgId) {
      ...organizationFields
    }
  }
  ${OrganizationFragment}
`;

export const GetOrganizationUsers = gql`
  query organizationUsers($orgId: ID!) {
    organizationUsers(orgId: $orgId) {
      email
      orgId
      userId
      orgRole
      firstName
      lastName
      picture
      seniority
      isActive
      thirdPartyPreferences {
        thirdPartyPreference
        thirdPartyType
      }
      dateJoinedOrg
      orgNotifications {
        notification
        read
        timestamp
        templateCommentParams {
          commentType
          componentName
          fileName
          languageVersion
          pageUrl
        }
      }
      pathPermissions {
        codeCommunityPaths
        codeOrgPaths
        contentCommunityPaths
        contentOrgPaths
      }
      activeStep {
        orgId
        pathId
        recipeId
        id
        start
        end
      }
      granularPermissions {
        orgId
        pathId
        permission
        recipeId
        stepId
      }
    }
  }
`;

export const GetOrgTeams = gql`
  query GetOrgTeams($orgId: String!, $userId: String!){
    getOrgTeams(orgId: $orgId, userId: $userId){
      createdBy
      dateCreated
      orgId
      pathPermissions{
        codeCommunityPaths
        codeOrgPaths
        contentCommunityPaths
        contentOrgPaths
      }
      teamId
      teamMembers
      teamName
    }
  }
`

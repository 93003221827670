import { TicketWorkspaceService } from '../../../../../tickets/src/lib/tickets/services/ticket-workspace/ticket-workspace.service';
import { TicketsService } from '@razroo-zeta/data-services';
import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';

import * as TicketWorkspacesActions from './ticket-workspaces.actions';
import * as OrganizationsActions from '@razroo-zeta/data-access/organizations';
import { first, map, of, switchMap } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { selectEntity } from './ticket-workspaces.selectors';
import { Store, select } from '@ngrx/store';

@Injectable()
export class TicketWorkspacesEffects {
  private actions$ = inject(Actions);

  loadTicketWorkspace$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.loadTicketWorkspace),
      fetch({
        run: (action) => {
          return this.ticketsService.getTicketWorkspace(action.orgId, action.workspaceId).pipe(
            map((ticketWorkspace: any) => {
              return TicketWorkspacesActions.loadTicketWorkspaceSuccess({ userId: action.userId, ticketWorkspace })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.loadTicketWorkspaceFailure({ error });
        },
      })
    )
  );

  loadTicketWorkspaceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.loadTicketWorkspaceSuccess),
      map((action) => {
        const { ticketWorkspace } = action;
        return OrganizationsActions.updateOrganizationUser({
          orgId: ticketWorkspace.orgId,
          userId: action.userId,
          activeTicketWorkspace: {
            workspaceId: ticketWorkspace.workspaceId,
            workspaceName: ticketWorkspace.workspaceName,
            activeBoard: ticketWorkspace.activeBoard
          },
        });
      })
    )
  );

  loadTicketWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.loadTicketWorkspaces),
      fetch({
        run: (action) => {
          return this.ticketsService.getTicketWorkspaces(action.orgId).pipe(
            map((ticketWorkspaces: any) => {
              return TicketWorkspacesActions.loadTicketWorkspacesSuccess({ ticketWorkspaces })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.loadTicketWorkspacesFailure({ error });
        },
      })
    )
  );

  createTicketWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.createTicketWorkspace),
      fetch({
        run: ({orgId, workspaceName, jiraProject, description}) => {
          return this.ticketsService.createTicketWorkspace(orgId, workspaceName, jiraProject, description).pipe(
            map((ticketWorkspace: any) => {
              this.snackbar.open(`${workspaceName} ticket workspace has been created`, '', {duration: 3000});
              return TicketWorkspacesActions.createTicketWorkspaceSuccess({ ticketWorkspace })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.loadTicketWorkspaceFailure({ error });
        },
      })
    )
  );

  createTicketWorkspaceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.createTicketWorkspaceSuccess),
      fetch({
        run: ({ticketWorkspace}) => {
          this.ticketWorkspaceService.navigateToBoard(ticketWorkspace);
        },
      })
    )
  );

  updateTicketWorkspace$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.updateTicketWorkspace),
      fetch({
        run: ({orgId, workspaceId, ticketWorkspaceUpdateParams}) => {
          return this.ticketsService.updateTicketWorkspace(orgId, workspaceId, ticketWorkspaceUpdateParams).pipe(
            map((ticketWorkspace: any) => {
              this.snackbar.open('Ticket workspace has been updated', '', {duration: 3000})
              return TicketWorkspacesActions.updateTicketWorkspaceSuccess({ ticketWorkspace })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.updateTicketWorkspaceFailure({ error });
        },
      })
    )
  )
  
  startSprint$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.startSprint),
      fetch({
        run: ({orgId, workspaceId, boardId}) => {
          return this.ticketsService.updateTicketWorkspace(orgId, workspaceId, {activeBoard: boardId}).pipe(
            switchMap((ticketWorkspace: any) => {
              return of(
                TicketWorkspacesActions.updateTicketWorkspaceSuccess({ ticketWorkspace }),
                TicketWorkspacesActions.loadTicketWorkspaceSprintBoards({ orgId, workspaceId }),
                TicketWorkspacesActions.getTicketWorkspaceBoard({ orgId, workspaceId, boardId })
              )
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.startSprintFailure({ error });
        },
      })
    )
  )    

  deleteTicketWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.deleteTicketWorkspace),
      fetch({
        run: ({orgId, workspaceId}) => {
          return this.ticketsService.deleteTicketWorkspace(orgId, workspaceId).pipe(
            map((ticketWorkspace: any) => {
              return TicketWorkspacesActions.deleteTicketWorkspaceSuccess({ ticketWorkspace })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.deleteTicketWorkspaceFailure({ error });
        },
      })
    )
  );

  createTicketWorkspaceBoard$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.createTicketWorkspaceBoard),
      fetch({
        run: ({orgId, workspaceId, boardName, startDate, endDate, description}) => {
          return this.ticketsService.createTicketWorkspaceBoard(orgId, workspaceId, boardName, startDate, endDate, description).pipe(
            map((ticketWorkspaceBoard: any) => {
              this.snackbar.open(`${ticketWorkspaceBoard.boardName} board has been created`, '', {duration: 3000});
              return TicketWorkspacesActions.createTicketWorkspaceBoardSuccess({ ticketWorkspaceBoard })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.createTicketWorkspaceBoardFailure({ error });
        },
      })
    )
  );

  loadTicketWorkspaceSprintBoards$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.loadTicketWorkspaceSprintBoards),
      fetch({
        run: ({orgId, workspaceId, includeBacklog}) => {
          return this.ticketsService.getTicketWorkspaceSprintBoards(orgId, workspaceId, includeBacklog).pipe(
            map((output: {boards: any, tickets: any}) => {
              const { boards, tickets } = output;
              return TicketWorkspacesActions.loadTicketWorkspaceSprintBoardsSuccess({ orgId, workspaceId, boards, tickets })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.loadTicketWorkspaceSprintBoardsFailure({ error });
        },
      })
    )
  );

  updateTicketWorkspaceBoard$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.updateTicketWorkspaceBoard),
      fetch({
        run: ({orgId, workspaceId, boardId, boardUpdateParams}) => {
          let completeSprint = !!boardUpdateParams.sprintCompleted;
          return this.ticketsService.updateTicketWorkspaceBoard(orgId, workspaceId, boardId, boardUpdateParams).pipe(
            map((ticketWorkspaceBoard: any) => {
              this.snackbar.open(`${ticketWorkspaceBoard.boardName} board has been updated`, '', {duration: 3000});
              return TicketWorkspacesActions.updateTicketWorkspaceBoardSuccess({ ticketWorkspaceBoard, completeSprint })
            }));
        },
        onError: (error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.updateTicketWorkspaceBoardFailure({ error });
        },
      })
    )
  );

  getTicketWorkspaceBoard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.getTicketWorkspaceBoard),
      fetch({
        run: ({orgId, workspaceId, boardId}) => {
          return this.ticketsService.getTicketWorkspaceBoard(orgId, workspaceId, boardId).pipe(
            switchMap((ticketWorkspaceBoard: any) => {
              return this.store.pipe(select(selectEntity), first(res => !!res),
                switchMap((ticketWorkspace) => {
                  this.title.setTitle(`${ticketWorkspace?.workspaceName} Ticket Project - ${ticketWorkspaceBoard.boardName}`) 
                  return of(TicketWorkspacesActions.getTicketWorkspaceBoardSuccess({ ticketWorkspaceBoard }))
                })
              )
            }));
        },
        onError: (error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.getTicketWorkspaceBoardFailure({ error });
        },
      })
      )
  );

  getCompletedWorkspaceBoards$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TicketWorkspacesActions.getCompletedWorkspaceBoards),
        fetch({
          run: (action) => {
            return this.ticketsService.getCompletedWorkspaceBoards(action.orgId, action.workspaceId)
              .pipe(
                map((ticketWorkspaceBoards: any) =>{
                  return TicketWorkspacesActions.getCompletedWorkspaceBoardsSuccess({ ticketWorkspaceBoards });
                })
              )
          },
          onError: (action, error) => {
            console.error('Error', error);
            return TicketWorkspacesActions.getCompletedWorkspaceBoardsFailure({ error });
          },
        })
      )
    );
                    
  deleteTicketWorkspaceBoard$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.deleteTicketWorkspaceBoard),
      fetch({
        run: ({orgId, workspaceId, boardId, newBoardId}) => {
          return this.ticketsService.deleteTicketWorkspaceBoard(orgId, workspaceId, boardId, newBoardId).pipe(
            map((ticketWorkspaceBoard: any) => {
              this.snackbar.open(`${ticketWorkspaceBoard.boardName} board has been deleted`, '', {duration: 3000});
              return TicketWorkspacesActions.deleteTicketWorkspaceBoardSuccess({ ticketWorkspaceBoard })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.deleteTicketWorkspaceBoardFailure({ error });
        },
      })
    )
  );

  deleteTicketWorkspaceBoardSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.deleteTicketWorkspaceBoardSuccess),
      map((action) => {
        const { ticketWorkspaceBoard } = action;
        this.router.navigateByUrl(`/tickets/${ticketWorkspaceBoard.workspaceId}/backlog`);
        return TicketWorkspacesActions.loadTicketWorkspaceSprintBoards({ orgId: ticketWorkspaceBoard.orgId, workspaceId: ticketWorkspaceBoard.workspaceId });
      })
    )
  )
  updateTicketWorkspaceBoardSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.updateTicketWorkspaceBoardSuccess),
      map((action) => {
        const { ticketWorkspaceBoard, completeSprint  } = action;
        if(completeSprint){
          this.router.navigateByUrl(`/tickets/${ticketWorkspaceBoard.workspaceId}/backlog`);
          return TicketWorkspacesActions.loadTicketWorkspaceSprintBoards({ orgId: ticketWorkspaceBoard.orgId, workspaceId: ticketWorkspaceBoard.workspaceId });
        } else return TicketWorkspacesActions.noAction()
      })
    )
  )

  addUserToTicketWorkspace$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.addUserToTicketWorkspace),
      fetch({
        run: ({orgId, workspaceId, newUserId}) => {
          return this.ticketsService.addUserToTicketWorkspace(orgId, workspaceId, newUserId).pipe(
            map((ticketWorkspace: any) => {
              return TicketWorkspacesActions.addUserToTicketWorkspaceSuccess({ ticketWorkspace })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.addUserToTicketWorkspaceFailure({ error });
        },
      })
    )
  )

  removeUserFromTicketWorkspace$ = createEffect(() => 
    this.actions$.pipe(
      ofType(TicketWorkspacesActions.removeUserFromTicketWorkspace),
      fetch({
        run: ({orgId, workspaceId, userId}) => {
          return this.ticketsService.removeUserFromTicketWorkspace(orgId, workspaceId, userId).pipe(
            map((ticketWorkspace: any) => {
              return TicketWorkspacesActions.removeUserFromTicketWorkspaceSuccess({ ticketWorkspace })
            }));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TicketWorkspacesActions.removeUserFromTicketWorkspaceFailure({ error });
        },
      })
    )
  );

  constructor(private ticketsService: TicketsService, private snackbar: MatSnackBar, private store: Store,
    private router: Router, private ticketWorkspaceService: TicketWorkspaceService, private title: Title){}
}
import { DownloadUrlService, PathService } from '@razroo-zeta/data-services';
import { FileTreeService, FlatFolderFile, VersioningService } from '@razroo-zeta/common-services';
import { Injectable } from '@angular/core';
import { COMMUNITY, ParameterType, Template, TemplateInputParameter, TemplateUpdate } from '@razroo-zeta/data-models';
import { TemplateService } from '../template/template.service';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { camelCase, lowerCase, some } from 'lodash';
import { powerUpVariables, PowerUpVariables } from '@codemorph/devkit';
import { TemplatesFacade } from '@razroo-zeta/data-access';

@Injectable({
  providedIn: 'root'
})
export class PathCmsService {

  constructor(private fileTreeService: FileTreeService, private pathService: PathService,
    private templateService: TemplateService, private downloadUrlService: DownloadUrlService,
    private versioningService: VersioningService, private templatesFacade: TemplatesFacade) { }

  getNewlyCreatedTemplateVariables(templateVariables: string[], codeChanged: string) {
    const uniqueTemplateVariables = this.findAllUniqueTemplateVariables(codeChanged);
    // logic for determining if two arrays are different
    return uniqueTemplateVariables.filter(tempVar => !templateVariables.includes(tempVar));
  }

  findAllUniqueTemplateVariables(codeChanged: string) {
    const keyString = `<%= [^<>]* %>`;
    const keyStringRegex = new RegExp(keyString, 'g');
    let templateVariables = codeChanged.match(keyStringRegex);
    templateVariables = templateVariables?.filter(val => {
      return !!val?.match(/^[a-zA-Z0-9<>=% ]*$/)
    }) as any || [];
    return templateVariables ? templateVariables.filter((v,i) => !templateVariables?.includes(v,i+1)) : [];
  }

  // will return the string of the file
  downloadStarterOrNonStarterFile(flatFolderFile: FlatFolderFile, template: Template, starterTemplate?: any): Observable<string> {
    console.log('flatFolderFile: ', flatFolderFile)
    let downloadFileUrl;
    if(flatFolderFile.dependentStep) {
      const dynamicFilePath = flatFolderFile.dynamicPath!;
      downloadFileUrl = this.templateService
        .getFileUrl(flatFolderFile.dependentStep.orgId, flatFolderFile.dependentStep.pathId, flatFolderFile.dependentStep.recipeId, flatFolderFile.dependentStep.stepId, dynamicFilePath)
    } else if(template.symlinkStep) {
      const dynamicPath = flatFolderFile.dynamicPath;
      const symlinkStep = template.symlinkStep;
      downloadFileUrl = this.templateService
        .getFileUrl(symlinkStep.orgId, symlinkStep.pathId, symlinkStep.recipeId, symlinkStep.id, dynamicPath as string);
    } else if(flatFolderFile.filesToGenerateFolderName) {
      const dynamicFilePath = flatFolderFile.dynamicPath;
      downloadFileUrl = this.templateService
        .getFileUrl(template.orgId, template.pathId,  template.recipeId, template.id, dynamicFilePath as string)
    }
    else {
      //get starter file download url
      downloadFileUrl = this.templateService
        .getFileUrl(starterTemplate.orgId, starterTemplate.pathId,  starterTemplate.recipeId, starterTemplate.id, flatFolderFile.path as string)
    }
    return downloadFileUrl.pipe(
      switchMap((downloadUrl: any) => {
        // github api(which is .content) vs razroo api logic
        const url = downloadUrl.content ? JSON.parse(downloadUrl.content).download_url : downloadUrl['url'];
        return this.downloadUrlService.getFileFromUrl(url)
      }),
      switchMap((fileText: Promise<string>) => {
        return of(fileText)
      })
    );
  }

  constructFilePathForSave(flatFolderFile: FlatFolderFile) {
    if(flatFolderFile.filesToGenerateFolderName === flatFolderFile.name) {
      // root 
      return flatFolderFile.name;
    } else {
      // not root file
      return `${flatFolderFile.filesToGenerateFolderName}/${flatFolderFile.name}`;
    }
  } 

  determineIfTerminalFilesExist(fileTree: string[] | undefined) {
    if(!fileTree) {
      return false;
    }
    for (let i = 0; i < fileTree.length; i++) {
      if (fileTree[i].includes('{terminalFiles}')) {
          return true;
      }
    }
    return false;
  }

  determineIfCodeEditorFilesExist(fileTree: string[] | undefined) {
    if(!fileTree) {
      return false;
    }
    const filteredFilePaths = fileTree.filter(file => !file.includes('{terminalFiles}'));
    if(filteredFilePaths.length > 0) {
      return true;
    } 
    return false;
  }

  determineIfDownloadExist(template: Template) {
    if(!template.download || template.download.downloadUrl === "" && template.download.previewUrl === "") {
      return false;
    }
    else {
      return true
    }
  }

  // e.g. libs/ui/fileName.ts will become {fileNamePath}
  createDynamicFilePathFromStaticPath(path: string) {
    const templateVariable = this.createTemplateVariableFromFileName(path);
    const fileName = path.split('/').pop();
    return `${templateVariable}/${fileName}`;
  }

  extractTextFromTemplateVariable(templateVariable: string) {
    //Gets the part of the string inbetween the : and the ;
    return templateVariable.substring(
      templateVariable.lastIndexOf("<%=") + 4,
      templateVariable.lastIndexOf("%>") - 1
    );
  }

  determineParameterFields(templateVariable: string, parameters: any[]) {
    const templateVariableName = this.extractTextFromTemplateVariable(templateVariable);
    const parametersIndex = this.findParameterIndex(templateVariable, parameters);
    if(parametersIndex > -1) {
      return parameters[parametersIndex]
    }
    else {
      return {
        name: templateVariableName,
        defaultValue: '',
        inputType: 'text',
        description: ''
      }
    }
  }

  findParameterIndex(templateVariable: string, parameters: TemplateInputParameter[]) {
    const templateVariableName = this.extractTextFromTemplateVariable(templateVariable);
    return parameters ? parameters.findIndex(param => param.name === templateVariableName) : -1;
  }

  createTemplateVariableFromFileName(fileName: string) {
    const extractNameFromCurlyBraces = this.extractNameFromFilePath(fileName);
    const camelCasedName = camelCase(extractNameFromCurlyBraces);
    return `{${camelCasedName}FilePath}`;
  }

  addFilePathParameterIfDoesNotExist(fileName: string, filePath: string, parameters: TemplateInputParameter[] = [], dynamicFolderName?: string, oldFileName?: string): TemplateInputParameter[] {
    if(dynamicFolderName && this.shouldCreateFilePathTemplateVariable(parameters, dynamicFolderName)) {
      const templateName = dynamicFolderName ? dynamicFolderName.replace('{', '').replace('}', '') : this.createFilePathTemplateVariableName(fileName);
      if(filePath === fileName || filePath === oldFileName) {
        filePath = ''
      }
      return this.createFilePathParameter(templateName, parameters, 'filePath', filePath);
    }
    return parameters
  }

  deleteFilePathParameterIfNoFilesLeft(filesToGenerateFolderName: string, parameters: TemplateInputParameter[] = [], fileTree: string[]) {
    if(this.shouldDeleteFilePathParameter(filesToGenerateFolderName, fileTree)) {
      const parameterName = this.extractNameFromFilePath(filesToGenerateFolderName);
      return parameters && parameters.filter(param => param.paramType === 'filePath' && param.name !== parameterName);
    }
    else {
      return parameters;
    }
  }

  // for use with file rename
  createRenameSourceAndDestination(template: Template, source: string, destination: string): {source: string, destination: string} {
    return {
      source: `${template.orgId}/${template.pathId}/${template.recipeId}/${template.id}/code/${source}`,
      destination: `${template.orgId}/${template.pathId}/${template.recipeId}/${template.id}/code/${destination}`
    }
  }

  deleteFilePathUpdate(openFile: FlatFolderFile, updates: string | undefined): (TemplateUpdate[] | undefined) {
    if(updates) {
      const parsedUpdates: TemplateUpdate[] = JSON.parse(updates);
      return parsedUpdates.filter(update => update.filePath !== openFile.filesToGenerateFolderName || update.fileName !== openFile.name);
    } else {
      return updates as undefined;
    }
  }

  // updates are updates from template
  // codeMod all are codeMods being saved
  // only take difference from updates v codeMod and return
  getNewUpdatesToCodemod(openFile: FlatFolderFile, codeMods: any[], templateUpdates: string | undefined | null) {
    const parsedTemplateUpdates = JSON.parse(templateUpdates as string);
    const templateUpdateToCompareAgainst = parsedTemplateUpdates?.filter(update => update.filePath === openFile.filesToGenerateFolderName || update.fileName === openFile.name);
    const templateEditsToCompareAgainst = templateUpdateToCompareAgainst?.[0]?.edits;
    return codeMods.filter(codeMod =>  !some(templateEditsToCompareAgainst, codeMod));
  }

  createFilePathParameter(templateVariableToAdd: string, parameters: TemplateInputParameter[] = [], parameterType: ParameterType, defaultValue: string = ''): TemplateInputParameter[] {
    const fileName = lowerCase(templateVariableToAdd).split(' ')[0];
    const parametersArr = parameters ? parameters : [];
    const parameter: TemplateInputParameter = {
      name: templateVariableToAdd,
      defaultValue: defaultValue,
      inputType: 'text',
      description: `File path for ${fileName} file(s)`,
      paramType: parameterType
    };

    return [
      ...parametersArr,
      parameter
    ];
  }

  createTemplateVariableParameter(paramName: string, templateParameters: TemplateInputParameter[], parameterType: ParameterType, defaultValue?: string, description?: string) {
    if(!this.shouldCreateTemplateVariable(templateParameters, paramName)) {
      return;
    }
    const parameters = templateParameters ? [...templateParameters] : [];
    const globalVariable = powerUpVariables.find(globalVariable => globalVariable.name === paramName);
    const parameter: TemplateInputParameter = {
      name: paramName,
      defaultValue: globalVariable && globalVariable.defaultValue ? globalVariable.defaultValue : defaultValue ? defaultValue : '',
      inputType: 'text',
      description: description ? description : '',
      paramType: parameterType,
      type: globalVariable && globalVariable.type ? globalVariable.type : undefined,
      stubValue: globalVariable && globalVariable.stubValue ? globalVariable.stubValue : undefined
    };

    return [
      ...parameters,
      parameter
    ]
  }

  // assumption is it will always be when param is of type filePath
  findFileIdsToDelete(paramName: string, getStarterWithFilesToGenerate: FlatFolderFile[]) {
    const filteredFlatFolderFiles = getStarterWithFilesToGenerate.filter(folderFile => folderFile.filesToGenerateFolderName === `{${paramName}}`);
    return filteredFlatFolderFiles.map(folderFile => folderFile.id);
  }

  modifyTemplateUpdates(codeMod: any, updates: TemplateUpdate[] = [], openFile: FlatFolderFile) {
    const indexInExistingTemplate = updates.findIndex(update => update.fileName === openFile.name && update.filePath === openFile.filesToGenerateFolderName);
    if(indexInExistingTemplate > -1) {
      updates[indexInExistingTemplate].edits = codeMod;
    }
    else {
      const newUpdateObject = this.createUpdateObject(codeMod, openFile);
      updates.push(newUpdateObject);
    }

    return updates;
  }

  // via template variable file to edit is now customizable
  createUpdateObject(codeMod: any, openFile: FlatFolderFile) {
    const templateVariable = this.createTemplateVariableFromFileName(openFile.name);
    return {
      fileName: openFile.name,
      filePath: templateVariable,
      fileType: openFile.name.split('.').pop() as string,
      edits: codeMod
    }
  }

  getFileTypeFromName(fileName: string): string | undefined {
    return fileName.split('.').pop();
  }

  createFilePathTemplateVariableName(fileName: string) {
    const filePathTemplateVariableName = this.extractNameFromFilePath(fileName);
    const camelCasedTemplateVariableName = camelCase(filePathTemplateVariableName);
    return camelCasedTemplateVariableName + 'FilePath';
  }

  shouldDeleteFilePathParameter(filesToGenerateFolderName: string, fileTree: string[]): boolean {
    const folderName = filesToGenerateFolderName;
    const fileTreeUpdated = fileTree.filter((file: string) => file.indexOf(folderName) > -1 );

    // if there is exactly one item, which means after this delete there will be non left
    // then it should delete file path parameter
    if(fileTreeUpdated && fileTreeUpdated.length === 1) {
      return true;
    }
    else {
      return false;
    }
  }

  // used for deleting folder, so we only return relevant data needed for method
  getFolderAndChildFlatFolderFilesToDelete(folder: FlatFolderFile, starterWithFilesToGenerate: FlatFolderFile[]): {
    idsToDelete: string[],
    filesToGenerateFolderNames: string[]
  } {
    const folderAndChildFlatFolderFiles = starterWithFilesToGenerate.filter(flatFolderFile => flatFolderFile.path.includes(folder.path));
    const flatFolderFileIds = folderAndChildFlatFolderFiles.map(flatFolderFile => flatFolderFile.id);
    // last filter i.e. (x, i, a) is a little bit of ninja code to make sure only unique results returned
    const filesToGenerateFolderNames = folderAndChildFlatFolderFiles
      .filter(flatFolderFile => flatFolderFile.expandable === false)
      .map(flatFolderFile => flatFolderFile.filesToGenerateFolderName)
      .filter((x, i, a) => a.indexOf(x) === i) as string[];

    return {
      idsToDelete: flatFolderFileIds,
      filesToGenerateFolderNames: filesToGenerateFolderNames?.length ? filesToGenerateFolderNames : [folder.path]
    }
  }

  /**
   * convertStarterFileToRazrooFile - Will take existing starter file and convert to a razroo file
   */
  convertStarterFileToRazrooFile(getStarterWithFilesToGenerate: FlatFolderFile[], openFile: FlatFolderFile): FlatFolderFile[] {
    const dynamicFolderName = this.createTemplateVariableFromFileName(openFile.name);
    return getStarterWithFilesToGenerate.map(fileToGenerate => {
      if(fileToGenerate.path === openFile.path) {
        return {
          ...fileToGenerate,
          filesToGenerateFolderName: dynamicFolderName,
          fileText: openFile.fileText,
          dependentStep: undefined
        }
      }
      else {
        return fileToGenerate
      }
    });
  }

  convertStarterOpenFileToRazrooOpenFile(openFile: FlatFolderFile): FlatFolderFile {
    const dynamicFolderName = this.createTemplateVariableFromFileName(openFile.name);

    return {
      ...openFile,
      dependentStep: undefined,
      filesToGenerateFolderName: dynamicFolderName
    }
  }

  // all file path parameters will have file name in their name
  shouldCreateFilePathTemplateVariable(parameters: TemplateInputParameter[], dynamicFolderName: string) {
    const filePathTemplateVariableName = dynamicFolderName.replace('{', '').replace('}', '');
    const parametersIndex = parameters && parameters.length > 0 ? parameters.findIndex(param => param.name === filePathTemplateVariableName) : -1;

    if(parametersIndex > -1) {
      return false
    }
    else {
      return true
    }
  }

  findParentTemplateVariableIfExists(paramName: string, globalVariables: PowerUpVariables[]): string {
    const globalVariable = globalVariables.find(globalVariable => globalVariable.name === paramName);
    if(globalVariable) {
      return globalVariable.variableDependency;
    }
    else {
      return paramName;
    }
  }

  // all file path parameters will have file name in their name
  shouldCreateTemplateVariable(parameters: TemplateInputParameter[], paramName: string) {
    const parametersIndex = parameters && parameters.length > 0 ? parameters.findIndex(param => param.name === paramName) : -1;

    if(parametersIndex > -1) {
      return false
    }
    else {
      return true
    }
  }

  // also works if just file name is passed in
  extractNameFromFilePath(fileName: string) {
    fileName = fileName.split(".").filter(i => i)[0];
    const keyString = `{[^<>]*}`;
    const keyStringRegex = new RegExp(keyString, 'g');
    const templateVariables = fileName?.match(keyStringRegex);
    if(templateVariables) {
      return templateVariables[0].replace('{', '').replace('}', '');
    }
    else {
      return fileName?.split('.')[0];
    }
  }

  getFileNameFromFullPath(fullPath: string) {
    return fullPath.split('/').pop();
  }

  getFilePathFromFullPath(fullPath: string) {
    if(Array.from(fullPath)[0] === '/') {
      fullPath = fullPath.slice(1);
    }
    if(fullPath.includes('/')) {
      return fullPath.split('/').slice(0, -1).join('/');
    }
    else {
      return fullPath;
    }
  }

  getDynamicFilePath(fullFilePath: string): string {
    const indexOfCurlyBrace = fullFilePath.indexOf('{');
    
    if (indexOfCurlyBrace !== -1) {
      const slicedFilePath = fullFilePath.slice(indexOfCurlyBrace);
      const curlyBraceRegex = /{([^}]+)}/; // Regular expression to match content inside curly braces
      const curlyBraceMatch = slicedFilePath.match(curlyBraceRegex);
      const contentInsideBraces = curlyBraceMatch && curlyBraceMatch[1];
      const replacement = slicedFilePath.replace(curlyBraceRegex, `{${contentInsideBraces}FilePath}`);
      return replacement;
    }
    return fullFilePath;
  }

  shouldUpdateTemplateVariable(nameParam: TemplateInputParameter, desiredValue: boolean){
    if(nameParam.optionalTypes && nameParam.optionalTypes.find(val => val.name === 'schema')?.selected === desiredValue){
      return false
    } else return true
  }

  updateTemplateParam(template: Template, parameters: TemplateInputParameter[]){
    this.templatesFacade.updateStep(template.orgId, template.pathId, template.recipeId, template.id, { parameters });
  }
  
}

import { PathService } from '@razroo-zeta/data-services';
import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';

import * as RecipesActions from './recipes.actions';
import * as RecipesFeature from './recipes.reducer';
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApolloError } from '@apollo/client/errors';

@Injectable()
export class RecipesEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipesActions.loadRecipes),
      fetch({
        run: (action) => {
          return this.pathService
            .searchPath(
              action.userOrgId,
              action.pathOrgId,
              action.pathId,
              action.search,
              action.start,
              action.end,
              action.analyticData
            )
            .pipe(
              map((path: any) => {
                const recipes = path.stepper;
                return RecipesActions.loadRecipesSuccess({ recipes, path });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return RecipesActions.loadRecipesFailure({ error });
        },
      })
    )
  );

  updateRecipeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipesActions.updateRecipeSuccess),
      fetch({
        run: (action) => {
          this.snackBar.open(
            `${action.recipe.title} has been updated`,
            undefined,
            { duration: 3000 }
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return RecipesActions.loadRecipesFailure({ error });
        },
      })
    )
  );

  reorderRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipesActions.reOrderRecipe),
      fetch({
        run: (action) => {
          return this.pathService
            .reorderRecipe(
              action.orgId,
              action.pathId,
              action.recipeId,
              action.previousRecipe,
              action.updateOrder
            )
            .pipe(
              map((recipe: any) => {
                this.snackBar.open(
                  `${recipe.title} has been re-ordered`,
                  undefined,
                  { duration: 3000 }
                );
                return RecipesActions.reOrderRecipeSuccess({ recipe });
              })
            );
        },
        onError: (action, error) => {
          if (error instanceof ApolloError) {
            this.snackBar.open(
              error.message,
              undefined,
              { duration: 3000 }
            );
          }
          return RecipesActions.reOrderRecipeFailure({ error });
        },
      })
    )
  );

  createRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipesActions.createRecipe),
      fetch({
        run: (action) => {
          return this.pathService
            .createRecipe(
              action.orgId,
              action.userId,
              action.pathId,
              action.recipeName,
              action.description,
              action.previousRecipeId
            )
            .pipe(
              map((recipe: any) => {
                this.snackBar.open(
                  `${recipe.title} has been created`,
                  undefined,
                  { duration: 3000 }
                );
                return RecipesActions.createRecipeSuccess({ recipe: recipe });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return RecipesActions.createRecipeFailure({ error });
        },
      })
    )
  );

  deleteRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipesActions.deleteRecipe),
      fetch({
        run: (action) => {
          return this.pathService
            .deleteTemplate(
              action.orgId,
              'Recipe',
              action.pathId,
              action.recipeId
            )
            .pipe(
              map((recipe: any) => {
                this.snackBar.open(
                  `${recipe.title} has been deleted`,
                  undefined,
                  { duration: 3000 }
                );
                return RecipesActions.deleteRecipeSuccess({ recipe: recipe });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return RecipesActions.deleteRecipeFailure({ error });
        },
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private pathService: PathService,
    private snackBar: MatSnackBar,
    private titleService: Title
  ) {}
}

import { createAction, props } from '@ngrx/store';
import { GitInfoEntity } from './git-info.models';

export const init = createAction('[GitInfo Page] Init');

export const loadGitInfoSuccess = createAction(
  '[GitInfo/API] Load GitInfo Success',
  props<{ gitInfo: GitInfoEntity[] }>()
);

export const loadGitInfoFailure = createAction(
  '[GitInfo/API] Load GitInfo Failure',
  props<{ error: any }>()
);

export const loadOrgGitInfo = createAction(
  '[GitInfo/API] Load Org GitInfo',
  props<{ org: string }>()
);

export const loadOrgGitInfoSuccess = createAction(
  '[GitInfo/API] Load Org GitInfo Success',
  props<{ gitInfo: GitInfoEntity }>()
);

export const loadOrgGitInfoFailure = createAction(
  '[GitInfo/API] Load Org GitInfo Failure',
  props<{ error: any }>()
);

<div class="container">
  <button class="share-button" mat-icon-button color="warn" (click)="shareToTwitter()">
    <i class="fa-brands fa-x-twitter"></i>
  </button>
  <h2><span class="template-title">{{data.template?.executionId ? (data.template?.executionId | idToTitle) : (data?.template?.title | titlecase)}}</span> Code Has Been Generated @if (data.codeGenPlatform !== 'github') {
  <span>To
    <a matTooltip="Click here to go to generated file(s) in code editor" [href]="'vscode://file' + vsCodeAbsoluteUrl + '?reuse-window=true'">Local Code Editor</a></span>
  }
</h2>
<i class="fa-solid fa-rocket-launch fa-7x"></i>
<ng-container>
  <h2>We Recommend <span class="template-title">{{data.recommendedStep?.executionId ? (data.recommendedStep?.executionId | idToTitle) : (data?.recommendedStep?.title | titlecase)}}</span> Next</h2>
  <h2 >Go To {{data.recommendedStep?.executionId ? (data.recommendedStep?.executionId | idToTitle) : (data?.recommendedStep?.title | titlecase)}}?</h2>
</ng-container>
<mat-dialog-actions class="actions-container" align="end">
  <div>
    <button mat-flat-button color="primary" [mat-dialog-close]="false">
      Close
    </button>
    <button
      mat-flat-button
      color="accent"
      class="next-step-button"
      (click)="nextStepFunc()"
    > Go </button>
  </div>
</mat-dialog-actions>
</div>

import { GithubRepo } from "../github/github.interface";

export const COMMUNITY = 'community';

export interface Organization {
  orgId: string;
  displayName?: string;
  slackAccessToken?: string;
  slackUserIds?: string[];
  slackWorkspaceChannels?: string[];
  slackWorkspaceId?: string;
  paid?: any;
  gitHubOrg?: string;
  dateCreated?: any;
  planType?: any;
  createdBy?: string;
  stripeCustomerId?: string;
  users?: any;
  owner?: string;
  orgType?: string;
  payoutId?: string;
  picture?: string;
  marketplacePaths?: Array<any>;
}

export interface OrganizationUser {
  email: string;
  orgId: string;
  userId: string;
  paid?: boolean;
  firstName?: string;
  lastName?: string;
  activeStep: ActiveStep;
  activeOrgId: string;
  activeTicketWorkspace?: ActiveTicketWorkspace;
  orgType?: string;
  githubOrg?: string;
  orgRole?: string;
  jiraOrgId?: string;
  hasEncryptedJiraApiKey?: boolean;
  githubRepos: GithubRepo[];
  pathPermissions?: {
    codeOrgPaths: Array<string>;
    codeCommunityPaths: Array<string>;
    contentOrgPaths: Array<string>;
    contentCommunityPaths: Array<string>;
  }
  activeProject?: ActiveProject;
  stripeCustomerId?: string;
  marketplacePaths?: Array<any>;
  slackTeamName?: string;
  slackTeamId?: string;
  granularPermissions?: [{
    orgId: string;
    pathId: string;
    permission: string;
    recipeId: string;
    stepId: string;
  }]
  payoutId?: string;
}

export interface ActiveTicketWorkspace {
  workspaceId: string;
  workspaceName: string;
  activeBoard?: number;
}

export interface ActiveProject {
  orgId: string;
  id: string;
  languageVersion: string;
  baseCommunityPath: string;
  project: boolean;
  title: string;
}

export interface UserOrganization {
  orgId: string;
  orgRole: string;
  userId: string;
}

export interface ActiveStep {
  orgId: string;
  pathId: string;
  batchId?: string;
  recipeId?: string;
  id?: string;
}

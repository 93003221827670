import { Directive, ElementRef, NgModule, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Directive({
    selector: '[razrooZetaAutofocus]',
    standalone: false
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private host: ElementRef) {}

  ngAfterViewInit() {
    this.host.nativeElement.focus();
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [AutofocusDirective],
  exports: [AutofocusDirective],
})
export class AutofocusDirectiveModule {}

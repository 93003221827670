import { StepperService } from './../../../../../data-services/src/lib/stepper/stepper.service';
import { UserFacade } from './../user/user.facade';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import * as TemplatesActions from './templates.actions';
import * as TemplatesFeature from './templates.reducer';
import {
  GetStepService,
  OrganizationsService,
  PathBatchService,
  TemplateService,
} from '@razroo-zeta/data-services';
import { map, switchMap, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  loadActiveState,
  loadActiveStateSuccess,
  updateOrganizationUser
} from '@razroo-zeta/data-access/organizations';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as PathCmsActions from '@razroo-zeta/data-access/path-cms';
import { delay, of } from 'rxjs';
import { LocalStorageService, SEOService } from '@razroo-zeta/common-services';
import { UserActions } from '@razroo-zeta/data-access';
import { Store } from '@ngrx/store';
import { environment } from '@razroo-zeta/common-environment';

@Injectable()
export class TemplatesEffects {
  loadTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.loadTemplate),
      fetch({
        run: (action) => {
          return this.templateService
            .getTemplate(
              action.orgId,
              action.pathId,
              action.recipeId,
              action?.templateID,
              action?.networkOnly,
              action?.published,
              action?.displayDocumentationHtml
            )
            .pipe(
              map((template: any) => {
                const templateTitle = template.title;
                // function takes aws and converts to Aws
                const pathId = template.latest ? template.corePathId : template.pathId;
                const pathTitle = template.latest
                  ? template.corePathId.replace(/./, (c: any) =>
                      c.toUpperCase()
                    )
                  : pathId
                      .replaceAll('-', ' ')
                      .replace(/./, (c: any) => c.toUpperCase());
                const newTemplateUrl = `/${template.orgId}/${template.pathId}/${template.recipeId ? template.recipeId : template.id}${template.recipeId ? '/' + template.id : ''}`;
                const canonicalUrl = this.seoService.createCanonicalLinkFromStep(template);

                this.seoService.setSEOTags(
                  templateTitle + ' - ' + pathTitle,
                  template.instructionalContent,
                  newTemplateUrl,
                  canonicalUrl
                );
                return TemplatesActions.loadTemplateSuccess({
                  template: template,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.loadTemplateFailure({ error });
        },
      })
    )
  );

  loadStepInSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.loadStepInSearch),
      fetch({
        run: (action) => {
          return this.getStepService
            .getStep(
              action.userOrgId,
              action.templateOrgId,
              action.pathId,
              action.recipeId,
              action.stepId,
              action?.displayDocumentationHtml
            )
            .pipe(
              map((template: any) => {
                const templateTitle = template.title;
                // function takes aws and converts to Aws
                const pathId = template.latest ? template.corePathId : template.pathId;
                const pathTitle = template.latest
                  ? template.corePathId.replace(/./, (c: any) =>
                      c.toUpperCase()
                    )
                  : pathId
                      .replaceAll('-', ' ')
                      .replace(/./, (c: any) => c.toUpperCase());
                const newTemplateUrl = `/${template.orgId}/${template.pathId}/${template.recipeId ? template.recipeId : template.id}${template.recipeId ? '/' + template.id : ''}`;
                const canonicalUrl = this.seoService.createCanonicalLinkFromStep(template);

                this.seoService.setSEOTags(
                  templateTitle + ' - ' + pathTitle,
                  template.instructionalContent,
                  newTemplateUrl,
                  canonicalUrl
                );
                return TemplatesActions.loadTemplateSuccess({
                  template: template,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.loadTemplateFailure({ error });
        },
      })
    )
  );

  loadAiTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.loadAiTemplate),
      fetch({
        run: (action) => {
          return this.templateService
            .getTemplate(
              action.orgId,
              action.pathId,
              action.recipeId,
              action?.templateID,
              action?.networkOnly,
              action?.published
            )
            .pipe(
              map((template: any) => {
                if (template.generationStatus === 'Complete') {
                  const templateTitle = template.title;
                  // function takes aws and converts to Aws
                  const pathId = template.pathId
                    ? template.pathId
                    : template.id;
                  const pathTitle = template.latest
                    ? template.corePathId.replace(/./, (c: any) =>
                        c.toUpperCase()
                      )
                    : pathId
                        .replaceAll('-', ' ')
                        .replace(/./, (c: any) => c.toUpperCase());
                  const newTemplateUrl = `/${template.orgId}/${
                    template.pathId
                  }/${template.recipeId ? template.recipeId : template.id}${
                    template.recipeId ? '/' + template.id : ''
                  }`;
                  this.seoService.setSEOTags(
                    templateTitle + ' - ' + pathTitle,
                    template.instructionalContent,
                    environment.codeHostUrl,
                    newTemplateUrl
                  );
                  return TemplatesActions.loadTemplateSuccess({
                    template: template,
                  });
                } else if (template.generationStatus === 'Generating') {
                  return TemplatesActions.loadTemplatePolling({ template });
                } else {
                  return TemplatesActions.loadTemplateFailure({
                    error: 'AI Generation Failed',
                  });
                }
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.loadTemplateFailure({ error });
        },
      })
    )
  );

  loadTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.loadTemplateSuccess),
      fetch({
        run: (action: any) => {
          return this.userFacade.currentUserID$.pipe(
            withLatestFrom(this.userFacade.currentOrgId$),
            switchMap(([userId, orgId]) => {
              return of({
                userId,
                orgId,
              });
            }),
            map((userData) => {
              let activeStep = {
                orgId: action.template.orgId,
                pathId: action.template.pathId,
              };
              if (action.template.recipeId) {
                (activeStep['recipeId'] = action.template.recipeId),
                  (activeStep['id'] = action.template.id);
              } else {
                activeStep['recipeId'] = action.template.id;
              }

              return updateOrganizationUser({
                orgId: userData.orgId as string,
                userId: userData.userId as string,
                activeStep: activeStep,
              })
            })
          );
        },
      })
    )
  );

  reloadCounter = 0;
  loadTemplatePolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.loadTemplatePolling),
      fetch({
        run: (action) => {
          if (
            action.template.generationStatus === 'Generating' &&
            this.reloadCounter < 24
          ) {
            this.reloadCounter++;
            setTimeout(() => {
              this.store.dispatch(
                TemplatesActions.loadAiTemplate({
                  orgId: action.template.orgId,
                  pathId: action.template.pathId,
                  recipeId: action.template.recipeId,
                  templateID: action.template.id,
                  networkOnly: true,
                })
              );
            }, 5000);
          } else if (action.template.generationStatus === 'Complete') {
            this.store.dispatch(
              TemplatesActions.loadTemplateSuccess({
                template: action.template,
              })
            );
            return;
          }
        },
      })
    )
  );

  
  addDocumentationViaAiToStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.addDocumentationViaAiToStep),
      fetch({
        run: (action) => {
          return this.templateService
            .addDocumentationViaAiToStep(
              action.pathOrgId,
              action.pathId,
              action.recipeId,
              action.stepId
            )
            .pipe(
              map((response: any) => {
                return TemplatesActions.addDocumentationViaAiToStepSuccess(
                  {
                    stepId: action.stepId,
                    markdown: response.markdown
                  }
                );
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.loadTemplateFailure({ error });
        },
      })
    )
  );

  loadStepper$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.loadStepper),
      fetch({
        run: (action: any) => {
          this.router.navigateByUrl(
            this.router.createUrlTree([
              action.orgId,
              action.pathId,
              action.recipeId,
              action.stepId,
            ])
          );
          return this.stepperService
            .getStepperOfSteps(
              action.orgId,
              action.pathId,
              action.recipeId,
              action.stepId,
              action.amount
            )
            .pipe(
              mergeMap((stepper: any) => {
                const activeStep = {
                  orgId: action.orgId,
                  pathId: action.pathId,
                  recipeId: action.recipeId,
                  id: action.stepId,
                };
                this.localStorageService.setItem(
                  'activeStep',
                  JSON.stringify(activeStep)
                );
                // let newStep = stepper.find(step => (step.id === action.stepId && step.recipeId === action.recipeId));
                return [
                  TemplatesActions.loadStepperSuccess({
                    stepper,
                    amount: action.amount,
                    orgId: action.orgId,
                    pathId: action.pathId,
                  }),
                  // TemplatesActions.updateActiveStep({ activeStepInput: {
                  //   orgId: action.orgId,
                  //   pathId: action.pathId,
                  //   recipeId: newStep.firstPathStep ? stepper[0].recipeId : stepper[1].recipeId,
                  //   id: newStep.firstPathStep ? stepper[0].id : stepper[1].id,
                  //   start: action.start,
                  //   end: action.end}
                  // })
                ];
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.loadStepperError({ error });
        },
      })
    )
  );

  // side effect to determine if it's at end. If it is, it will call from beginning again
  loadStepperSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.loadStepperSuccess),
      fetch({
        run: (action: any) => {
          if (action.stepper.length < action.amount) {
            return this.stepperService
              .getStepperOfSteps(
                action.orgId,
                action.pathId,
                undefined,
                undefined,
                1
              )
              .pipe(
                switchMap(([stepper]) => {
                  return this.stepperService.getStepperOfSteps(
                    action.orgId,
                    action.pathId,
                    stepper.recipeId,
                    stepper.id,
                    action.amount
                  );
                }),
                map((stepper: any) => {
                  return TemplatesActions.loadStepperSupplementSuccess({
                    stepper,
                    amount: action.amount,
                  });
                })
              );
          } else {
            return;
          }
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.loadStepperError({ error });
        },
      })
    )
  );

  loadStepperDuece$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.loadStepper),
      fetch({
        run: (action: any) => {
          return TemplatesActions.loadTemplate({
            orgId: action.orgId,
            pathId: action.pathId,
            recipeId: action.recipeId,
            templateID: action.stepId,
          });
        },
      })
    )
  );

  updateStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.updateStep),
      fetch({
        run: (action) => {
          return this.templateService
            .updateStep(
              action.orgId,
              action.pathId,
              action.recipeId,
              action?.id,
              action.stepUpdateParams
            )
            .pipe(
              map((template: any) => {
                if (
                  this.templateService.determineIfExcessFilePathParameters(
                    template.parameters
                  )
                ) {
                  const error =
                    'Cannot be more than three file path parameters per step.';
                  this.snackBar.open(`${error}`, undefined, { duration: 3000 });
                  return TemplatesActions.updateStepFailure({ error });
                } else {
                  this.snackBar.open(
                    `${template.id} has been updated.`,
                    undefined,
                    { duration: 3000 }
                  );
                  return TemplatesActions.updateStepSuccess({
                    template: template,
                  });
                }
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.updateStepFailure({ error });
        },
      })
    )
  );

  createStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.createStep),
      fetch({
        run: (action) => {
          return this.templateService
            .createStep(
              action.template.orgId,
              action.userId,
              action.template.pathId,
              action.template.recipeId || action.template.id,
              action.stepName,
              action.stepType,
              action.description
            )
            .pipe(
              delay(2000),
              tap((template: any) => {
                this.snackBar.open(
                  `${template.id} has been created.`,
                  undefined,
                  { duration: 3000 }
                );
                this.router.navigateByUrl(
                  this.router.createUrlTree([
                    'finder',
                    template.orgId,
                    template.pathId,
                    template.recipeId,
                    template.id,
                  ])
                );
              }),
              mergeMap((template: any) => [
                PathCmsActions.loadStarterWithFilesToGenerate({
                  template,
                  filesToGenerateTree: [],
                }),
                TemplatesActions.createStepSuccess({
                  template: template,
                  stepper: action.template.stepper,
                }),
              ])
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.createStepFailure({ error });
        },
      })
    )
  );

  updateActiveStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.updateActiveStep),
      fetch({
        run: (action: any) => {
          return this.userFacade.currentUserID$.pipe(
            withLatestFrom(this.userFacade.currentOrgId$),
            switchMap(([userId, orgId]) => {
              return this.organizationsService.setActiveStep(
                orgId as string,
                userId as string,
                action.activeStepInput
              );
            }),
            map((organizationUser: any) => {
              return loadActiveStateSuccess({
                organizationUser: {
                  ...organizationUser.activeStep,
                  userId: organizationUser.userId,
                },
              });
            })
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.loadActiveStateFailure({ error });
        },
      })
    )
  );

  loadPathBatchTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.loadPathBatchTemplate),
      fetch({
        run: (action) => {
          return this.pathBatchService
            .getPathBatchTemplate(
              action.orgId,
              action.pathId,
              action.batchId,
              action.recipeId,
              action.stepId,
              true
            )
            .pipe(
              map((template: any) => {
                const templateTitle = template.title;
                // function takes aws and converts to Aws
                const pathId = template.pathId ? template.pathId : template.id;
                const pathTitle = template.latest
                  ? template.corePathId.replace(/./, (c: any) =>
                      c.toUpperCase()
                    )
                  : pathId
                      .replaceAll('-', ' ')
                      .replace(/./, (c: any) => c.toUpperCase());
                const newTemplateUrl = `/${template.orgId}/${
                  template.batchId
                }/${template.pathId}/${
                  template.recipeId ? template.recipeId : template.id
                }${template.recipeId ? '/' + template.id : ''}`;
                const canonicalUrl = this.seoService.createCanonicalLinkFromStep(template);
                this.seoService.setSEOTags(
                  templateTitle + ' - ' + pathTitle,
                  template.instructionalContent,
                  newTemplateUrl,
                  canonicalUrl
                );
                return TemplatesActions.loadPathBatchTemplateSuccess({
                  template,
                  openGenerateDialog: action.openGenerateDialog,
                });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.loadTemplateFailure({ error });
        },
      })
    )
  );

  loadPathBatchTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.loadPathBatchTemplateSuccess),
      fetch({
        run: (action: any) => {
          const activeStep = {
            orgId: action.template.orgId,
            pathId: action.template.pathId,
            batchId: action.template.batchId,
            recipeId: action.template.recipeId,
            id: action.template.id,
          };

          this.localStorageService.setItem(
            'activeStep',
            JSON.stringify(activeStep)
          );
          if (action.openGenerateDialog) {
            // this.generateCodeService.generateCodeDialog(action.template, userId: string, userOrgId: string, vsCodeInstances: VsCodeInstance[], searchPath: Template, commandText?: string) {
          }
        },
      })
    )
  );

  loadActiveState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadActiveState),
      fetch({
        run: (action) => {
            return this.getStepService.getStep(
              action.userOrgId as string,
              action.templateOrgId,
              action.pathId,
              action.recipeId,
              action.stepId,
              true
            )
            .pipe(
              map((template: any) => {
                const templateTitle = template.title;
                // function takes aws and converts to Aws
                const pathId = template.latest ? template.corePathId : template.pathId;
                const pathTitle = template.latest
                  ? template.corePathId.replace(/./, (c: any) =>
                      c.toUpperCase()
                    )
                  : pathId
                      .replaceAll('-', ' ')
                      .replace(/./, (c: any) => c.toUpperCase());
                const newTemplateUrl = `/${template.orgId}/${pathId}/${template.recipeId}/${template.id}`;
                const canonicalUrl = this.seoService.createCanonicalLinkFromStep(template);
                const templateDescription = this.seoService.createDescription(templateTitle, template.instructionalContent);
                this.seoService.setSEOTags(
                  templateTitle + ' - ' + pathTitle,
                  templateDescription,
                  newTemplateUrl,
                  canonicalUrl
                );
                return TemplatesActions.loadActiveStateSuccess({ template });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.loadTemplateFailure({ error });
        },
      })
    )
  );

  likeTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.likeTemplate),
      fetch({
        run: (action) => {
          return this.templateService
            .likeTemplate(
              action.userId,
              action.templateOrgId,
              action.pathId,
              action.recipeId,
              action.stepId
            )
            .pipe(
              map((template: any) => {
                return TemplatesActions.likeTemplateSuccess({ template });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.likeTemplateFailure({ error });
        },
      })
    )
  );
  downvoteTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.downvoteTemplate),
      fetch({
        run: (action) => {
          return this.templateService
            .downvoteTemplate(
              action.userId,
              action.templateOrgId,
              action.pathId,
              action.recipeId,
              action.stepId
            )
            .pipe(
              map((template: any) => {
                return TemplatesActions.downvoteTemplateSuccess({ template });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return TemplatesActions.downvoteTemplateFailure({ error });
        },
      })
    )
  );

  likeTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.likeTemplateSuccess),
      fetch({
        run: (action) =>
          UserActions.likeTemplate({
            likedTemplate: {
              orgId: action.template.orgId,
              pathId: action.template.pathId,
              recipeId: action.template.recipeId,
              stepId: action.template.id,
            },
          }),
      })
    )
  );
  downvoteTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.downvoteTemplateSuccess),
      fetch({
        run: (action) =>
          UserActions.downvoteTemplate({
            downvotedTemplate: {
              orgId: action.template.orgId,
              pathId: action.template.pathId,
              recipeId: action.template.recipeId,
              stepId: action.template.id,
            },
          }),
      })
    )
  );

  constructor(
    private actions$: Actions,
    private templateService: TemplateService,
    private stepperService: StepperService,
    private router: Router,
    private organizationsService: OrganizationsService,
    private userFacade: UserFacade,
    private snackBar: MatSnackBar,
    private seoService: SEOService,
    private localStorageService: LocalStorageService,
    private pathBatchService: PathBatchService,
    private getStepService: GetStepService,
    private readonly store: Store<TemplatesFeature.State>
  ) {}
}

import gql from 'graphql-tag';
import { TemplateFragment } from './template.fragments';

export const GetTemplate = gql`
  query Template(
      $orgId: String!,
      $pathId: String!,
      $recipeId: String,
      $stepId: String
      $published: Boolean,
      $displayDocumentationHtml: Boolean
    ) {
    template(
      orgId: $orgId,
      pathId: $pathId,
      recipeId: $recipeId,
      stepId: $stepId,
      published: $published,
      displayDocumentationHtml: $displayDocumentationHtml
    ) {
      ...templateFields
    }
  }
  ${TemplateFragment}
`;
export const GetTemplateParams = gql`
  query template(
    $orgId: String!,
    $pathId: String!,
    $recipeId: String,
    $stepId: String
    $published: Boolean
  ) {
    template(
      orgId: $orgId,
      pathId: $pathId,
      recipeId: $recipeId,
      stepId: $stepId,
      published: $published
    ) {
      ai
      userId
      orgId,
      pathId,
      recipeId,
      id,
      privacy
      pathType,
      likeCount,
      parameters {
        defaultValue
        description
        inputType
        name
        paramType
        type
        optionalTypes {
          name
          selected
        }
      }
      download
    }
  }
`;

export const GetTemplateFileUrl = gql`
  query GetTemplateFileUrl(
    $fileName: String!,
    $orgId: String!,
    $pathId: String!,
    $recipeId: String,
    $stepId: String
    ) {
    getS3TemplateFile(
      fileName: $fileName,
      orgId: $orgId,
      pathId: $pathId,
      recipeId: $recipeId,
      stepId: $stepId,
      ) {
      url
    }
  }
`;

export const GetStepFileUploadUrl = gql`
  query getStepFileUploadUrl(
    $userOrgId: String!
    $pathOrgId: String!
    $pathId: String!
    $recipeId: String!
    $stepId: String!
    $fullFilePath: String
    $isNewFile: Boolean
  ) {
    getStepFileUploadUrl(
      userOrgId: $userOrgId,
      pathOrgId: $pathOrgId,
      pathId: $pathId,
      recipeId: $recipeId,
      stepId: $stepId,
      fullFilePath: $fullFilePath
      isNewFile: $isNewFile
      ) {
      url
      contentType
    }
  }
`;

export const GetTemplatePermissions = gql`
  query GetTemplatePermissions(
    $orgId: String!
    $pathId: String!
    $recipeId: String
    $stepId: String
  ){
    getTemplatePermissions(
      orgId: $orgId,
      pathId: $pathId,
      recipeId: $recipeId,
      stepId: $stepId
    ){
      granularPermissionLevel
      parentPermission
      userId
    }
  }
`;

export const GetStep = gql`
  query GetStep(
    $userOrgId: String
    $templateOrgId: String!
    $pathId: String!
    $recipeId: String!
    $stepId: String!
    $displayDocumentationHtml: Boolean
  ){
    getStep(
      userOrgId: $userOrgId,
      templateOrgId: $templateOrgId,
      pathId: $pathId,
      recipeId: $recipeId,
      stepId: $stepId
      displayDocumentationHtml: $displayDocumentationHtml
    ){
      ...templateFields
    }
  }
  ${TemplateFragment}
`;

export const GetStarterFileTree = gql`
  query GetStarterFileTree(
    $pathOrgId: String!
    $pathId: String!
  ){
    getStarterFileTree(
      pathOrgId: $pathOrgId
      pathId: $pathId
    ){
      fileTree
      orgId
      pathId
      recipeId
      id
    }
  }
`

import gql from 'graphql-tag';
import { BookFragment } from './book.fragments';

export const GetBook = gql`
  query Book($id: ID!, $chapter: ID!) {
    book(id: $id) {
      chapters(ids: [$chapter]) {
        bookId
        id
        content
        title
        next
        previous
      }
      ...bookFields
    }
  }
  ${BookFragment}
`;

export const GetToc = gql`
  query Book($id: ID!) {
    book(id: $id) {
      id
      toc {
        id
        title
      }
    }
  }
`;

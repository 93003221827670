import { FlatFolderFile } from './../../../../../common-services/src/lib/file-tree/file-tree.service';
import { Template, TemplateInputParameter, VsCodeInstance } from '@razroo-zeta/data-models';
import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { directoriesLoading, loadParameters, updateParameterValue,loadFilesToGenerateFolderFileTree, loadVscodeFolderTree, loadVscodeFolderTreeSuccess, loadVscodeInstance, loadVscodeInstances, selectVscodeInstance, toggleVsCodeFolder, updateVscodeDirectories, updateVscodeInstanceParameter } from '../actions/vscode.actions';
import { getVscodeLoaded, getSelected, getVsCodeParameters, getVsCodeFilesToGenerate, getVsCodeDirectories, getVsCodeDirectoriesLoaded, getVscodeInstances } from '../selectors/vscode.selectors';

// import * as fromSearch from './reducers/vscode.reducer';
// import * as SearchSelectors from './vscode.selectors';

@Injectable()
export class VscodeFacade {
  loaded$ = this.store.pipe(select(getVscodeLoaded));
  vsCodeInstances$ = this.store.pipe(select(getVscodeInstances));
  vsCodeInstance$ = this.store.pipe(select(getSelected));
  vsCodeParameters$ = this.store.pipe(select(getVsCodeParameters));
  vsCodeFilesToGenerate$ = this.store.pipe(select(getVsCodeFilesToGenerate));
  vsCodeDirectories$ = this.store.pipe(select(getVsCodeDirectories));
  vsCodeDirectoriesLoaded$ = this.store.pipe(select(getVsCodeDirectoriesLoaded));

  constructor(private store: Store<any>) {}

  directoriesLoading(){
    this.store.dispatch(directoriesLoading())
  }

  loadParameters(parameters: any){
    this.store.dispatch(loadParameters({parameters}));
  }

  updateParameterValue(parameterName, newValue){
    this.store.dispatch(updateParameterValue({parameterName, newValue}))
  }

  loadVscodeInstances(userId: string){
    this.store.dispatch(loadVscodeInstances({userId}))
  }

  loadVscodeInstance(vsCodeInstanceId: string, template: Template, userId: string){
    this.store.dispatch(loadVscodeInstance({vsCodeInstanceId, template, userId}))
  }

  selectVscodeInstance(vsCodeInstance: VsCodeInstance, template: Template) {
    this.store.dispatch(selectVscodeInstance({vsCodeInstance, template}))
  }

  updateVscodeInstanceParameter(parameter: TemplateInputParameter, newInputValue: string) {
    this.store.dispatch(updateVscodeInstanceParameter({parameter, newInputValue}))
  }

  updateVscodeDirectories(directories: FlatFolderFile[]) {
    this.store.dispatch(updateVscodeDirectories({directories}))
  }

  loadVscodeFolderTree(privateDirectories: string, parameters: TemplateInputParameter[], fileTree: string[]) {
    this.store.dispatch(loadVscodeFolderTree({privateDirectories, parameters, fileTree}))
  }
  
  loadVscodeFolderTreeSuccess(generateCodeFlatFolderFileTree: FlatFolderFile[]) {
    this.store.dispatch(loadVscodeFolderTreeSuccess({generateCodeFlatFolderFileTree}))
  }

  loadFilesToGenerateFolderFileTree(filesToGenerate: FlatFolderFile[]) {
    this.store.dispatch(loadFilesToGenerateFolderFileTree({filesToGenerate}))
  }

  toggleVsCodeFolder(folder: FlatFolderFile) {
    this.store.dispatch(toggleVsCodeFolder({folder}))
  }
}

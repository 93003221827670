<h3 class="Recipes__title"> Features </h3>
<cdk-virtual-scroll-viewport #viewport class="viewport" itemSize="35">
  <div
    *cdkVirtualFor="let step of (template?.stepper); trackBy: trackByFn; let index = index; "
    #virtualForViewport
    [class.active-recipe]="index === (template | stepIndex)"
    class="recipe-scroll-item"
    >
    <div>0{{index + 1}}</div>
    <div class="RecipeScroll__icon">
      @if (!recipe?.iconName) {
        <i class="fa-solid fa-cubes"></i>
      }
      @if (recipe?.iconName) {
        <i [class]="'fa-solid fa-' + recipe?.iconName"></i>
      }
    </div>
    <div class="RecipesScroll__recipe-title">{{ step?.title }}</div>
  </div>
</cdk-virtual-scroll-viewport>
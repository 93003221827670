import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromRecipes from './+state/recipes.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RecipesEffects } from './+state/recipes.effects';
import { RecipesFacade } from './+state/recipes.facade';

@NgModule({
  imports: [CommonModule,
    StoreModule.forFeature(fromRecipes.RECIPES_FEATURE_KEY, fromRecipes.recipesReducer),
    EffectsModule.forFeature([RecipesEffects])
  ],
  providers: [RecipesFacade]
})
export class DataAccessRecipesModule {}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromOrganizations from './+state/organizations/organizations.reducer';
import { OrganizationsEffects } from './+state/organizations/organizations.effects';
import { OrganizationsFacade } from './+state/organizations/organizations.facade';
import * as fromGenerateCode from './+state/generate-code/generate-code.reducer';
import { GenerateCodeEffects } from './+state/generate-code/generate-code.effects';
import { GenerateCodeFacade } from './+state/generate-code/generate-code.facade';
import { VscodeService } from '@razroo-zeta/data-services';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromOrganizations.ORGANIZATIONS_FEATURE_KEY,
      fromOrganizations.reducer
    ),
    EffectsModule.forFeature([OrganizationsEffects]),
    StoreModule.forFeature(
      fromGenerateCode.GENERATECODE_FEATURE_KEY,
      fromGenerateCode.reducer
    ),
    EffectsModule.forFeature([GenerateCodeEffects]),
  ],
  providers: [OrganizationsFacade, GenerateCodeFacade, VscodeService],
})
export class DataAccessOrganizationsModule {}

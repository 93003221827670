import { CreateStripeSession, UpdateStripeCheckoutSession } from '@razroo-zeta/data-graphql';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, Observable, of } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { WindowRefService } from '@razroo-zeta/common-services';

@Injectable({
  providedIn: 'root',
})
export class CreateSessionService {
  constructor(private apollo: Apollo, private windowRefService: WindowRefService) {}
  createStripeCheckoutSession(priceId :string, orgId:string, quantity: number, productId: string, customerId?:string, successURL?:string) {
    const successUrl = successURL || this.windowRefService.nativeWindow.location.href;
    const cancelUrl = this.windowRefService.nativeWindow.location.href;
    const mutation = CreateStripeSession;
    const variables = {
      productId,
      priceId,
      orgId,
      successUrl,
      quantity,
      cancelUrl,
      customerId
    };

    const session$ = this.apollo.mutate({ mutation, variables });
    return from(session$).pipe(pluck('data', 'createStripeCheckoutSession'));
  }
  updateStripeCheckoutSession(
    subscriptionId: string,
    customerId: string,
    successURL: string
  ) {
    const successUrl = successURL || this.windowRefService.nativeWindow.location.href;
    const cancelUrl = this.windowRefService.nativeWindow.location.href;
    const mutation = UpdateStripeCheckoutSession;
    const variables = {
      customerId,
      successUrl,
      cancelUrl,
      subscriptionId
    };

    let session$ = this.apollo.mutate({ mutation, variables });
    return from(session$).pipe(pluck('data', 'updateStripeCheckoutSession'));
  }

}

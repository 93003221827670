import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromPath from './+state/path.reducer';
import { PathEffects } from './+state/path.effects';
import { PathFacade } from './+state/path.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromPath.PATH_FEATURE_KEY, 
      fromPath.reducer
    ),
    EffectsModule.forFeature([PathEffects]),
  ],
  providers: [PathFacade, TitleCasePipe],
})
export class DataAccessPathModule {}

import { createAction, props } from '@ngrx/store';
import { Path, Recipe, Template } from '@razroo-zeta/data-models';
import { RecipesEntity } from './recipes.models';

export const loadRecipes = createAction(
  '[Recipes/API] Load Recipes',
  props<{ userOrgId: string, pathOrgId: string, pathId: string, search: string, start: number, end: number, analyticData?: {analyticData: boolean; userOrgId: string;} }>()
);

export const loadRecipesSuccess = createAction(
  '[Recipes/API] Load Recipes Success',
  props<{ recipes: RecipesEntity[], path: Path }>()
);

export const updateRecipe = createAction(
  '[Recipes/API] Update Recipe',
  props<{ pathOrgId: string, pathId: string, recipeId: string }>()
);

export const updateRecipeSuccess = createAction(
  '[Recipes/API] Load Recipe Success',
  props<{ recipe: Recipe }>()
);

export const loadRecipesFailure = createAction(
  '[Recipes/API] Load Recipes Failure',
  props<{ error: any }>()
);

export const reOrderRecipe = createAction(
  '[Recipes/API] Re Order Recipe',
  props<{ orgId: string, pathId: string, recipeId: string, previousRecipe: string, updateOrder: boolean,
    pageStart: number, pageSize: number, reOrderedRecipes: Recipe[] }>()
);

export const reOrderRecipeSuccess = createAction(
  '[Recipes/API] Re Order Recipe Success',
  props<{recipe: Recipe}>()
);

export const reOrderRecipeFailure = createAction(
  '[Recipes/API] Re Order Recipe Failure',
  props<{ error: any }>()
);

export const toggleRecipe = createAction(
  '[Recipes/API] Toggle Recipe',
  props<{ recipe: Recipe}>()
);

export const setRecipesToAddAsBatch = createAction(
  '[Recipes/API] Set Recipes To Add As Batch',
  props<{ recipes: Recipe[]}>()
);

export const addToRecipesToRemove = createAction(
  '[Recipes/API] Add To Recipes To Remove',
  props<{ recipe: Recipe}>()
);

export const createRecipe = createAction(
  '[Recipes/API] Create Recipe',
  props<{orgId: string,
    userId: string,
    pathId: string,
    recipeName: string,
    description: string,
    previousRecipeId: string | null,
  }>()
);

export const createRecipeSuccess = createAction(
  '[Recipes/API] Create Recipe Success',
  props<{ recipe: any}>()
);

export const createRecipeFailure = createAction(
  '[Recipes/API] Create Recipe Failure',
  props<{ error: any }>()
);

export const deleteRecipe = createAction(
  '[Recipes/API] Delete Recipe',
  props<{orgId: string, pathId: string, recipeId: string}>()
);

export const deleteRecipeSuccess = createAction(
  '[Recipes/API] Delete Recipe Success',
  props<{ recipe: Template}>()
);

export const deleteRecipeFailure = createAction(
  '[Recipes/API] Delete Recipe Failure',
  props<{ error: any }>()
);

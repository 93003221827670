import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import localForage from "localforage";
import { NanoService } from '../nano/nano.service';
import { isPlatformBrowser } from '@angular/common';

class LocalForage implements Partial<LocalForage> {
  [name: string]: any;
  readonly length: number;

  clear(): Promise<void> {
    return Promise.resolve();
  }

  getItem<T>(key: string): Promise<T | null> {
    return Promise.resolve(null);
  }

  key(index: number): Promise<string | null> {
    return Promise.resolve(null);
  }

  removeItem(key: string): Promise<void> {
    return Promise.resolve();
  }

  setItem<T>(key: string, value: T): Promise<void> {
    return Promise.resolve();
  }
}



@Injectable({
  providedIn: 'root'
})
export class LocalForageService implements Partial<LocalForage> {
  updateHistorySub$ = new Subject();
  private storage: LocalForage;

  constructor(@Inject(PLATFORM_ID) private platformId: any, private nanoService: NanoService) {
    if (isPlatformBrowser(this.platformId) && window?.localStorage) {
        this.storage = localForage as any;
    } else {
      this.storage = new LocalForage() as any;
    };
  }

  [name: string]: any;

  length: any;

  clear(): Promise<void> {
    return this.storage?.clear() ?? Promise.resolve(null);;
  }

  getItem(key: string): any {
    return this.storage?.getItem(key) ?? Promise.resolve(null);;
  }

  key(index: number): Promise<string | null> {
    return this.storage?.key(index) ?? Promise.resolve(null);
  }

  removeItem(key: string): Promise<void> {
    return this.storage?.removeItem(key) ?? Promise.resolve(null);
  }

  setItem(key: string, value: any): Promise<any> {
    return this.storage?.setItem(key, value) ?? Promise.resolve(null);
  }

  setCodeGenHistory(userId, orgId, action){
    let dateTime = new Date();
    let date = dateTime.toJSON().slice(0,10)
    let historyKey = `${userId}/${orgId}/home/history`;
    const parameters = typeof action.generateCodeParameters.parameters === 'string' ? JSON.parse(action.generateCodeParameters.parameters) : action.generateCodeParameters.parameters;
    this.getItem(historyKey).then(history => {
      let newHistoryItem = {
        time: moment().format('LT'),
        orgId: action.template.orgId,
        pathId: action.template.pathId,
        recipeId: action.template.recipeId,
        batchId: action.template.batchId,
        nanoId: this.nanoService.nanoid(),
        id: action.template.id,
        vsCodeInstanceId: action.generateCodeParameters.vsCodeInstanceId,
        parameters: parameters
      }
      if(history){
        if(history[date]){
          history[date].unshift(newHistoryItem)
        } else {
          history[date] = [newHistoryItem]
        }
      } else {
        history = {}
        history[date] = [newHistoryItem];
      }
      this.setItem(historyKey, history).then(data => {
        this.updateHistorySub$.next(true);
      });
    });
  }
}

import { Injectable } from '@angular/core';
import { nanoid } from 'nanoid';

@Injectable({
  providedIn: 'root'
})
export class NanoService {

  constructor() { }

  nanoid() {
    return nanoid();
  }
}

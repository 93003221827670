import { createAction, props } from '@ngrx/store';
import { BooksEntity } from './books.models';

export const init = createAction(
  '[Books Page] Init',
  props<{ userId: string, orgId: string }>()
);

export const loadBook = createAction(
  '[Book] Load',
  props<{ selectedID: string; chapter: string }>()
);

export const loadBookSuccess = createAction(
  '[Book] Load Book Success',
  props<{ book: BooksEntity }>()
);

export const loadBookFailure = createAction(
  '[Books/API] Load Book Failure',
  props<{ error: any }>()
);

export const loadBooksSuccess = createAction(
  '[Books/API] Load Books Success',
  props<{ books: BooksEntity[] }>()
);

export const loadBooksFailure = createAction(
  '[Books/API] Load Books Failure',
  props<{ error: any }>()
);

export const loadToc = createAction(
  '[Book] LoadToc',
  props<{ selectedID: string }>()
);

export const loadTocSuccess = createAction(
  '[Book] Load Toc Success',
  props<{ book: BooksEntity }>()
);

export const loadTocFailure = createAction(
  '[Toc] Load Toc Failure',
  props<{ error: any }>()
);

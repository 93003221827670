import { CommonModule } from '@angular/common';
import { Component, OnInit, NgModule, Inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { VscodeFacade } from '@razroo-zeta/data-access/vscode';
import { Template } from '@razroo-zeta/data-models';
import { first } from 'rxjs';

@Component({
  selector: 'razroo-zeta-confirm-generation-dialog',
  templateUrl: './confirm-generation-dialog.component.html',
  styleUrls: ['./confirm-generation-dialog.component.scss'],
})
export class ConfirmGenerationDialogComponent implements OnInit {
  vsCodeInstances;
  noVSCodeConnected = false;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ConfirmGenerationDialogComponent>,
    private vscodeFacade: VscodeFacade,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit(): void {
    this.vscodeFacade.loadVscodeInstances(this.data.userId);
    this.vscodeFacade.loaded$.pipe(first(res => !!res)).subscribe(res => {
      this.vscodeFacade.vsCodeInstances$.pipe(first()).subscribe(vsCodeInstances => {
        if(vsCodeInstances.length){
          this.vsCodeInstances = vsCodeInstances;
        } else this.noVSCodeConnected = true;
      });
    })
  }
  vsCodeForm = this.fb.group({
    vsCodeSelect:  ['', Validators.required]
  });
  go(){
    if(this.vsCodeForm.valid){
      this.dialogRef.close({
        vsCodeInstance: this.vsCodeForm.value.vsCodeSelect,
        vsCodeInstances: this.vsCodeInstances
      })
    }
  }
} 
@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatSelectModule, ReactiveFormsModule],
  declarations: [ConfirmGenerationDialogComponent],
  exports: [ConfirmGenerationDialogComponent],
})
export class ConfirmGenerationDialogComponentModule {}
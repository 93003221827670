export const InvoiceFragment = `
fragment invoiceFields on Invoice {
  created
  id
  lines {
    description
    planType
    quantity
  }
  customerEmail
  customerName
  status
  total
}
`;

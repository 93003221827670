import gql from 'graphql-tag';

export const DeleteS3File = gql`
    mutation DeleteS3File($deleteTemplateFiles: DeleteTemplateInput){
        deleteS3File(deleteTemplateFiles: $deleteTemplateFiles){
            VersionId
        }
    }
`;

export const DeleteS3Folder = gql`
    mutation DeleteS3Folder($deleteTemplateFiles: DeleteTemplateInput){
        deleteS3Folder(deleteTemplateFiles: $deleteTemplateFiles) {
            VersionId
        }
    }
`;

export const RenameS3File = gql`
    mutation RenameS3File($key: String!, $newKey: String!){
        renameS3File(key: $key, newKey: $newKey){
            newKey
        }
    }
`;

export const RenameS3Folder = gql`
  mutation renameS3Folder($key: String!, $newFolderName: String!){
    renameS3Folder(key: $key, newFolderName: $newFolderName){
      newFolderName
    }
  }
`;

export const CopyStepS3Folder = gql`
  mutation copyStepS3Folder(
      $userOrgId: String!, $pathOrgId: String!, 
      $pathId: String!, $recipeId: String!, 
      $stepId: String!, $destinationPathOrgId: String!, 
      $destinationPathId: String!, $destinationRecipeId: String!, 
      $destinationStepId:String!
    ){
    copyStepS3Folder(
      userOrgId: $userOrgId, pathOrgId: $pathOrgId, 
      pathId: $pathId, recipeId: $recipeId, 
      stepId: $stepId, destinationPathOrgId: $destinationPathOrgId , 
      destinationPathId: $destinationPathId, destinationRecipeId: $destinationRecipeId, 
      destinationStepId: $destinationStepId
      ){
      newFolderName
    }
  }
`;

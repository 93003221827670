export const OrganizationFragment = `
  fragment organizationFields on Organization{
    orgId
    displayName
    paid
    planType
    createdBy
    dateCreated
    stripeCustomerId
    slackUserIds
    slackWorkspaceId
    slackWorkspaceChannels
    slackAccessToken
    gitHubOrg
    owner
    payoutId
    subscriptionId
    orgType
    picture
    marketplacePaths {
      pathId
      pathOrgId
      description
    }
  }
`;

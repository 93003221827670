import { environment } from '@razroo-zeta/common-environment';
import { AuthConfig } from '@auth0/auth0-angular';

export const authConfig: AuthConfig = {
  // Your Auth0 app's domain
  // Important: Don't forget to start with https://
  //  AND the trailing slash!
  // The app's clientId configured in Auth0
  clientId: environment.clientId,
  domain: environment.issuer,
  // issuer: environment.issuer,
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  cacheLocation: 'localstorage',
  // https://auth0.github.io/auth0-angular/interfaces/AuthConfig.html#skipRedirectCallback
  // for slack code we don't want auth0 to use that
  skipRedirectCallback: window.location.pathname === '/add-slack-app',
  authorizationParams: {
    scope: 'openid profile email offline_access read:roles',
    redirect_uri: environment.landingPageUrl + '/',
    audience: environment.apiIdentifier
  },
};

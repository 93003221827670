import { Injectable } from '@angular/core';
import { GetPaidStripeProductsForUser, GetRazrooProducts, GetStripeProduct, UpdateUserProductAccess } from '@razroo-zeta/data-graphql';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private apollo: Apollo) {}

  getRazrooProducts(orgId: string) {
    const query = GetRazrooProducts;
    const variables = { orgId };

    const razrooProducts$ = this.apollo.query({ query, variables });
    return from(razrooProducts$).pipe(map((razrooProducts: any) => razrooProducts?.data?.razrooProducts));
  }

  updateUserProductAccess(userIdToAdd: string, orgId: string, productId: string, access: boolean) {
    const mutation = UpdateUserProductAccess;
    const variables = { userIdToAdd, orgId, productId, access };

    const updateUserProductAccess$ = this.apollo.mutate({ mutation, variables });
    return from(updateUserProductAccess$).pipe(map((result: any) => result?.data?.updateUserProductAccess));
  }

  getPaidStripeProductsForUser(orgId: string, userId: string) {
    const query = GetPaidStripeProductsForUser;
    const variables = { orgId, userId };

    const paidStripeProducts$ = this.apollo.query({ query, variables });
    return from(paidStripeProducts$).pipe(map((result: any) => result?.data?.getPaidStripeProductsForUser));
  }

  getStripeProduct(productName: string) {
    const query = GetStripeProduct;
    const variables = { productName };

    const stripeProduct$ = this.apollo.query({ query, variables });
    return from(stripeProduct$).pipe(map((result: any) => result?.data?.stripeProduct));
  }

}

import gql from "graphql-tag";
import { TemplateFragment } from "../template/template.fragments";

export const NextStepRecommendation = gql`
    query nextStepRecommendation($userOrgId: String!, $projectName: String, $recommendationsStep: RecommendationsStepInput){
        nextStepRecommendation(userOrgId: $userOrgId, projectName: $projectName, recommendationsStep: $recommendationsStep){
            ...templateFields
        }
    }
    ${TemplateFragment}
`
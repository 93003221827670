import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { OrganizationsEntity } from '@razroo-zeta/data-access/organizations';
import * as OrganizationsActions from './organizations.actions';
import {
  DeletePictureService,
  OrganizationsService,
} from '@razroo-zeta/data-services';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OrganizationUser } from '@razroo-zeta/data-models';
import { UserActions, UserFacade } from '@razroo-zeta/data-access';
import { EMPTY, of } from 'rxjs';
import { capitalize, map as lodashMap } from 'lodash';
import { LocalStorageService } from '@razroo-zeta/common-services';

@Injectable()
export class OrganizationsEffects {
  loadOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.loadOrganization),
      fetch({
        run: (action) => {
          // Your custom service 'load' logic goes here. For now just return a success action...
          return this.organizationsService.getOrganization(action.orgId).pipe(
            map((organization: OrganizationsEntity) => {
              return OrganizationsActions.loadOrganizationSuccess({
                organization: organization,
              });
            })
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return OrganizationsActions.loadOrganizationFailure({ error });
        },
      })
    )
  );

  sendJoinOrgEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.sendJoinOrgEmail),
      fetch({
        run: (action: any) => {
          return this.organizationsService
            .sendJoinOrgEmail(
              action.orgId,
              action.userEmail,
              action.link
            )
            .pipe(
              map((_) => {
                this.snackBar.open(`Invite Sent To ${action.userEmail}!`, ' ', {
                  duration: 3000,
                });
                return OrganizationsActions.sendJoinOrgEmailSuccess({
                  orgId: action.orgId,
                  userEmail: action.userEmail,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return OrganizationsActions.sendJoinOrgEmailError({ error });
        },
      })
    )
  );

  loadInvitedUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.loadInvitedUsers),
      fetch({
        run: (action: any) => {
          return this.organizationsService
            .getInvitedUsers(action.orgId, action.userId)
            .pipe(
              map((invitedUsers) => {
                return OrganizationsActions.loadInvitedUsersSuccess({
                  invitedUsers: invitedUsers as any[],
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return OrganizationsActions.sendJoinOrgEmailError({ error });
        },
      })
    )
  );

  undoInviteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.undoInviteUser),
      fetch({
        run: (action: any) => {
          return this.organizationsService
            .removeUserInvite(action.orgId, action.userEmail)
            .pipe(
              map((invitedUser) => {
                this.snackBar.open(
                  `${action.userEmail} is no longer invited to join this organization`,
                  undefined,
                  { duration: 3000 }
                );
                return OrganizationsActions.undoInviteUserSuccess({
                  invitedUser: invitedUser as any,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return OrganizationsActions.sendJoinOrgEmailError({ error });
        },
      })
    )
  );

  removeUserFromOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.removeUserFromOrg),
      fetch({
        run: (action: any) => {
          return this.organizationsService
            .removeMemberFromOrg(action.orgId, action.userEmail)
            .pipe(
              map((organizationUser: any) => {
                this.snackBar.open(
                  `${organizationUser.email} is no longer a part of this organization`,
                  undefined,
                  { duration: 3000 }
                );
                return OrganizationsActions.removeUserFromOrgSuccess({
                  organizationUser: organizationUser,
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return OrganizationsActions.removeUserFromOrgError({ error });
        },
      })
    )
  );

  updateOrganizationUser$ = createEffect(() =>
      this.actions$.pipe(
        ofType(OrganizationsActions.updateOrganizationUser),
        fetch({
          run: (action) => {
            return this.organizationsService.updateOrganizationUser(action.orgId, action.userId, action.githubRepos, action.githubUserId, undefined, action.activeProject, action.activeStep, action.activeTicketWorkspace)
              .pipe(
                map((organizationUser: any) => {
                  return OrganizationsActions.updateOrganizationUserSuccess({ organizationUser });
                })
              )
          },
          onError: (action, error) => {
            console.error('Error', error);
            return OrganizationsActions.updateOrganizationUserFailure({ error });
          },
        })
      )
    );

    updateOrganizationUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.updateOrganizationUserSuccess),
      withLatestFrom(this.userFacade.currentUser$),
      switchMap(([action, user]) => {
        const organizationUser = (action).organizationUser;

        if ((user as any).userId === organizationUser.userId) {
          const updatedUser = {...user, ...organizationUser};
          return of(UserActions.loadUserProfileSuccess({ 
            update: { id: updatedUser.userId, changes: updatedUser as any},
            userId: updatedUser.userId })
          );
        } else {
          return EMPTY;
        }
      }),
      catchError(() => EMPTY)
    )
  );
  //   this.actions$.pipe(
  //     ofType(OrganizationsActions.loadOrganizationSuccess),
  //     mergeMap((action) =>
  //       this.userFacade.currentUser$.pipe(
  //         map((user: any) => {
  //           if (user.userId != action.organization.orgId) {
  //             return OrganizationsActions.loadOrganizationUsers({
  //               orgId: action.organization.orgId,
  //             });
  //           } else {
  //             return OrganizationsActions.noAction();
  //           }
  //         }),
  //         catchError(() => EMPTY)
  //       )
  //     )
  //   )
  // );

  loadOrganizationUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.loadOrganizationUsers),
      fetch({
        run: (action) => {
          let userId: string;
          if (this.localStorageService.getItem('id_token')) {
            const idTokenClaimsObj = JSON.parse(
              this.localStorageService.getItem('id_token_claims_obj') as string
            );
            if (idTokenClaimsObj) {
              userId = idTokenClaimsObj['https://razroo.com/razrooUserId'];
            }
          }
          // Your custom service 'load' logic goes here. For now just return a success action...
          return this.organizationsService
            .getOrganizationUsers(action.orgId)
            .pipe(
              map((organizationUsers: any[]) => {
                return OrganizationsActions.loadOrganizationUsersSuccess({
                  organizationUsers: organizationUsers,
                  selectedId: userId ? userId : organizationUsers[0].userId,
                });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return OrganizationsActions.loadOrganizationUsersFailure({ error });
        },
      })
    )
  );

  loadOrganizationUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.loadOrganizationUser),
      fetch({
        run: (action) => {
          return this.organizationsService
            .getOrganizationUser(action.userId, action.orgId)
            .pipe(
              map((organizationUser: any) => {
                return OrganizationsActions.loadOrganizationUserSuccess({
                  organizationUser: organizationUser,
                });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return OrganizationsActions.loadOrganizationUserFailure({ error });
        },
      })
    )
  );

  loadActiveStateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.loadActiveStateSuccess),
      fetch({
        run: (action: any) => {
          const activeStep = action.organizationUser;
          const titleCaseStep = lodashMap(
            activeStep.id.split('-'),
            capitalize
          ).join(' ');
          this.snackBar.open(`Active step is now ${titleCaseStep}`, undefined, {
            duration: 3000,
          });
        },

        onError: (action, error) => {},
      })
    )
  );

  getOrgTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.getOrgTeams),
      fetch({
        run: (action: any) => {
          return this.organizationsService
            .getOrgTeams(action.orgId, action.userId)
            .pipe(
              map((teams: any) => {
                return OrganizationsActions.getOrgTeamsSuccess({ teams });
              })
            );
        },
        onError: (action, error) => {},
      })
    )
  );

  createTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.createTeam),
      fetch({
        run: (action: any) => {
          return this.organizationsService
            .createTeam(action.orgId, action.userId, action.teamName)
            .pipe(
              map((team: any) => {
                return OrganizationsActions.createTeamSuccess({ team });
              })
            );
        },
        onError: (action, error) => {},
      })
    )
  );

  addTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.addTeamMember),
      fetch({
        run: (action: any) => {
          return this.organizationsService
            .addTeamMember(
              action.orgId,
              action.userId,
              action.teamId,
              action.newUserId
            )
            .pipe(
              map((team: any) => {
                return OrganizationsActions.addTeamMemberSuccess({ team });
              })
            );
        },
        onError: (action, error) => {},
      })
    )
  );

  addTeamPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.addTeamPermissions),
      fetch({
        run: (action: any) => {
          return this.organizationsService
            .addTeamPermissions(
              action.orgId,
              action.userId,
              action.teamId,
              action.permissions
            )
            .pipe(
              map((team: any) => {
                return OrganizationsActions.addTeamPermissionsSuccess({ team });
              })
            );
        },
        onError: (action, error) => {},
      })
    )
  );

  removeTeamPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.removeTeamPermissions),
      fetch({
        run: (action: any) => {
          return this.organizationsService
            .removeTeamPermissions(
              action.orgId,
              action.userId,
              action.teamId,
              action.permissions
            )
            .pipe(
              map((team: any) => {
                return OrganizationsActions.removeTeamPermissionsSuccess({
                  team,
                });
              })
            );
        },
        onError: (action, error) => {},
      })
    )
  );

  deleteOrgPicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.deleteOrgPicture),
      fetch({
        run: (action: any) => {
          return this.deletePictureService
            .deletePicture(action.userId, action.orgId)
            .pipe(
              map(() => {
                this.snackBar.open(
                  'Organization picture has been deleted',
                  '',
                  { duration: 3000 }
                );
                return OrganizationsActions.deleteOrgPictureSuccess({
                  orgId: action.orgId,
                });
              })
            );
        },
        onError: (error) => {
          console.error('Error', error);
          return OrganizationsActions.deleteOrgPictureFailure({ error });
        },
      })
    )
  );

  deleteStripeSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.deleteStripeSubscription),
      fetch({
        run: (action: any) => {
          return this.organizationsService.deleteStripeSubscription(action.orgId).pipe(
            map((result: any) => {
              this.snackBar.open('Your subscription has been canceled', '', {duration: 3000})
              return OrganizationsActions.deleteStripeSubscriptionSuccess({orgId: action.orgId,result})
            })
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return OrganizationsActions.deleteStripeSubscriptionFailure({ error });
        },
      })
    )
  );

  deleteStripeSubscriptionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsActions.deleteStripeSubscriptionSuccess),
      switchMap((action: any) => {
        return [
          OrganizationsActions.loadOrganization({orgId: action.orgId}),
          UserActions.loadUser({orgId: action.orgId})
        ]
      })
    )
  );

  constructor(
    private actions$: Actions,
    private organizationsService: OrganizationsService,
    private localStorageService: LocalStorageService,
    private deletePictureService: DeletePictureService,
    private userFacade: UserFacade,
    private snackBar: MatSnackBar
  ) {}
}

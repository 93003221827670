import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericDeleteDialogComponent } from './generic-delete-dialog/generic-delete-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { MomentModule } from 'ngx-moment';
import { GenericDialogComponent } from './generic-dialog-component/generic-dialog.component';
import { PushPipe } from '@ngrx/component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MomentModule,
    PushPipe,
  ],
  declarations: [GenericDeleteDialogComponent, NotificationCardComponent, GenericDialogComponent],
  exports: [NotificationCardComponent, GenericDialogComponent],
})
export class CommonUiModule {}


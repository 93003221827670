import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as CommentsActions from './comments.actions';
import { CreateCommentParams } from './comments.models';
import * as CommentsSelectors from './comments.selectors';

@Injectable()
export class CommentsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(CommentsSelectors.getCommentsLoaded));
  allComments$ = this.store.pipe(select(CommentsSelectors.getAllComments));
  selectedComments$ = this.store.pipe(select(CommentsSelectors.getSelected));
  commentsCount$ = this.store.pipe(select(CommentsSelectors.getCommentsCount));
  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(CommentsActions.init());
  }

  loadComments(pageUrl: string,
    componentName: string,
    currentUserOrgId: string,
    fileName?: string){
    this.init();
    this.store.dispatch(CommentsActions.loadComments({pageUrl, componentName, currentUserOrgId, fileName}))
  }

  createComment(createCommentParams: CreateCommentParams, picture: string){
    this.store.dispatch(CommentsActions.createComment({createCommentParams: createCommentParams, picture}))
  }

  addResponse(comment, response){
    this.store.dispatch(CommentsActions.addResponse({comment: comment, response: response}))
  }
  upvoteComment(commentId: string, userId: string, userOrgId: string){
    this.store.dispatch(CommentsActions.upvoteComment({commentId, userId, userOrgId}))
  }
  downvoteComment(commentId: string, userId: string, userOrgId: string){
    this.store.dispatch(CommentsActions.downvoteComment({commentId, userId, userOrgId}))
  }
  deleteComment(commentId: string, userId: string, userOrgId: string){
    this.store.dispatch(CommentsActions.deleteComment({commentId, userId, userOrgId}))
  }
  deleteCommentResponse(commentId: string, userId: string, userOrgId: string, responseIndex: number){
    this.store.dispatch(CommentsActions.deleteCommentResponse({commentId, userId, userOrgId, responseIndex}))
  }

  updateComment(userId: string,
    userOrgId: string,
    commentId: string,
    newComment: string){
      this.store.dispatch(CommentsActions.updateComment({userId, userOrgId, commentId, newComment}))
  }

  updateCommentResponse(userId: string,
    userOrgId: string,
    commentId: string,
    responseIndex: number,
    newComment: string){
      this.store.dispatch(CommentsActions.updateCommentResponse({userId, userOrgId, commentId, responseIndex, newComment}))
  }
}

//excerpt from https://github.com/d-koppenhagen/angular/commit/a710ed2366b6134431301c1815fec7d8ced51f47

import {
    DOCUMENT,
    ɵDomAdapter as DomAdapter,
    ɵgetDOM as getDOM
  } from '@angular/common';
import { Inject, Injectable, ɵɵinject } from '@angular/core';

export type LinkDefinition = {
    rel: string;
    [prop: string]: string;
};

export function createLink() {
    return new Link(ɵɵinject(DOCUMENT));
}

@Injectable({ providedIn: 'root', useFactory: createLink, deps: [] })
export class Link {
    private _dom: DomAdapter;
    constructor(@Inject(DOCUMENT) private _doc: any) {
        this._dom = getDOM();
    }

    getLink(attrSelector: string): HTMLLinkElement | null {
        if (!attrSelector) return null;
        return this._doc.querySelector(`link[${attrSelector}]`) || null;
    }

    updateLink(link: LinkDefinition, selector?: string): HTMLLinkElement | null {
        if (!link) return null;
        selector = selector || this._parseSelector(link);
        const linkEl: HTMLLinkElement = this.getLink(selector)!;
        if (linkEl) {
            return this._setLinkElementAttributes(link, linkEl);
        }
        return this._getOrCreateElement(link, true);
    }

    private _getOrCreateElement(
        link: LinkDefinition,
        forceCreation: boolean = false
    ): HTMLLinkElement {
        if (!forceCreation) {
            const selector: string = this._parseSelector(link);
            const elem: HTMLLinkElement = this.getLink(selector) !;
            if (elem && this._containsAttributes(link, elem)) return elem;
        }
        const element: HTMLLinkElement = this._dom.createElement(
            'link'
        ) as HTMLLinkElement;
        this._setLinkElementAttributes(link, element);
        const head = this._doc.getElementsByTagName('head')[0];
        head.appendChild(element);
        return element;
    }

    private _setLinkElementAttributes(
        link: LinkDefinition,
        el: HTMLLinkElement
    ): HTMLLinkElement {
        Object.keys(link).forEach((prop: string) =>
            el.setAttribute(prop, link[prop])
        );
        return el;
    }

    private _parseSelector(link: LinkDefinition): string {
        const attr: string = link.rel ? 'rel' : 'property';
        return `${attr}="${link[attr]}"`;
    }

    private _containsAttributes(link: LinkDefinition, elem: HTMLLinkElement): boolean {
        return Object.keys(link).every((key: string) => elem.getAttribute(key) === link[key]);
    }
}

import { CreateSessionService, OrganizationsService, PricesService, ProductsService } from '@razroo-zeta/data-services';
import { Component, Inject, OnInit, NgModule, input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { OrganizationUser } from '@razroo-zeta/data-models';
import { WindowRefService } from '@razroo-zeta/common-services';
import { take } from 'rxjs';

@Component({
  selector: 'razroo-zeta-billing-upgrade-dialog',
  templateUrl: './billing-upgrade-dialog.component.html',
  styleUrls: ['./billing-upgrade-dialog.component.scss'],
})
export class BillingUpgradeDialogComponent implements OnInit {
  upgradeMonthlyRedirecting = false;
  upgradeAnnualRedirecting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      user: OrganizationUser,
      appName: 'Projects' | 'Code'
    },
    private dialogRef: MatDialogRef<BillingUpgradeDialogComponent>,
    private createSessionService: CreateSessionService,
    private windowRefService: WindowRefService,
    private organizationsService: OrganizationsService,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {  }
  
  billingUpgrade($event: any) {
    this.dialogRef.close($event);
  }

  billingCodeUpgradeMonthly(user: OrganizationUser) {
    this.upgradeMonthlyRedirecting = true;
    this.productsService.getStripeProduct('code').pipe(take(1)).subscribe((product :any) => {
      const price = product.monthly.priceId;
      const productId = product.id;
      this.createSessionService
        .createStripeCheckoutSession(price, user?.orgId, 1, productId, user?.stripeCustomerId)
        .pipe(take(1))
        .subscribe((res:any) => {
          this.upgradeMonthlyRedirecting = false;
          this.windowRefService.nativeWindow.location.href = res['url'];
        });
    });
  }

  billingUpgradeMonthly(user: OrganizationUser) {
    this.upgradeMonthlyRedirecting = true;
    this.productsService.getStripeProduct('projects').pipe(take(1)).subscribe((product :any) => {
      const price = product.monthly.priceId;
      const productId = product.id;
      this.createSessionService
        .createStripeCheckoutSession(price, user?.orgId, 1, productId, user?.stripeCustomerId)
        .pipe(take(1))
        .subscribe((res:any) => {
          this.upgradeMonthlyRedirecting = false;
          this.windowRefService.nativeWindow.location.href = res['url'];
        });
    });
  }

  billingUpgradeAnnual(user: OrganizationUser) {
    this.upgradeAnnualRedirecting = true;
    this.productsService.getStripeProduct('projects').pipe(take(1)).subscribe((product :any) => {
      const price = product.yearly.priceId;
      const productId = product.id;
      this.createSessionService
        .createStripeCheckoutSession(price, user?.orgId, 1, productId, user?.stripeCustomerId)
        .pipe(take(1))
        .subscribe((res:any) => {
          this.upgradeMonthlyRedirecting = false;
          this.windowRefService.nativeWindow.location.href = res['url'];
        });
    });
  }

  billingCodeUpgradeAnnual(user: OrganizationUser) {
    this.upgradeAnnualRedirecting = true;
    this.productsService.getStripeProduct('code').pipe(take(1)).subscribe((product :any) => {
      const price = product.yearly.priceId;
      const productId = product.id;
      this.createSessionService
        .createStripeCheckoutSession(price, user?.orgId, 1, productId, user?.stripeCustomerId)
        .pipe(take(1))
        .subscribe((res:any) => {
          this.upgradeMonthlyRedirecting = false;
          this.windowRefService.nativeWindow.location.href = res['url'];
        });
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule
  ],
  declarations: [BillingUpgradeDialogComponent],
  exports: [BillingUpgradeDialogComponent],
})
export class BillingUpgradeComponentModule {}

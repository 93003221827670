<div
  class="Notification__action-item"
  [class.unread]="!notification?.read"
  (mouseover)="markReadClickEmit(notification)"
  >
  <div class="Notification__type">
    @if (notification?.templateCommentParams?.commentType === 'Bug') {
      <i class="fa-solid fa-bug"></i>
    }
    @if (notification?.templateCommentParams?.commentType !== 'Bug') {
      <i class="fa-solid fa-star-exclamation"></i>
    }
  </div>
  <div class="Notification__text" (click)="notificationClickEmit(notification)">
    <p>{{ notification?.notification }}</p>
    <p class="Notification__date">{{ (notification?.timestamp | amTimeAgo) }}</p>
  </div>
  <button class="Notification__actions" mat-icon-button [matMenuTriggerFor]="notifActionsMenu">
    <i class="fa-solid fa-ellipsis"></i>
  </button>
  <mat-menu #notifActionsMenu>
    <button mat-menu-item (click)="deleteNotificationClickEmit(notification); $event.stopPropagation();">
      <div class="Notification__menu-item">
        <i class="fa-solid fa-trash"></i>
        <div class="Notification__menu-item-text"> Delete </div>
      </div>
    </button>
  </mat-menu>
</div>

import gql from "graphql-tag";
import { TicketFragment, TicketWorkspaceBoardFragment, TicketWorkspaceBoardsFragment, TicketWorkspaceFragment } from "./tickets.fragments";

export const GetTicketWorkspace = gql`
  query GetTicketWorkspace($orgId: String!, $workspaceId: String!){
    getTicketWorkspace(orgId: $orgId, workspaceId: $workspaceId){
      ...ticketWorkspaceFields
    }
  }
  ${TicketWorkspaceFragment}
`;

export const GetTicketWorkspaces = gql`
  query GetTicketWorkspaces($orgId: String!) {
    getTicketWorkspaces(orgId: $orgId) {
        ...ticketWorkspaceFields
    }
  }
  ${TicketWorkspaceFragment}
`;

export const GetCommunityTicketWorkspaces = gql`
  query GetCommunityTicketWorkspaces {
    getCommunityTicketWorkspaces {
      ...ticketWorkspaceFields
    }
  }
  ${TicketWorkspaceFragment}
`;


export const GetAllTitleTicketsForWorkspaceEpic = gql`
  query GetAllTitleTicketsForWorkspaceEpic($orgId: String!, $workspaceId: String!, $parentTicketNumber: Int) {
    getAllTitleTicketsForWorkspaceEpic(orgId: $orgId, workspaceId: $workspaceId, parentTicketNumber: $parentTicketNumber) {
      ...ticketFields
    }
  }
  ${TicketFragment}
`;

export const GetWorkspaceTicket = gql`
  query GetWorkspaceTicket(
    $orgId: String!
    $workspaceId: String!
    $ticketNumber: Int
  ){
    getWorkspaceTicket(
      orgId: $orgId
      workspaceId: $workspaceId
      ticketNumber: $ticketNumber
    ){
      ...ticketFields
    }
  }
  ${TicketFragment}
`;

export const GetTicketWorkspaceTickets = gql`
  query GetTicketWorkspaceTickets($orgId: String!, $workspaceId: String!, $boardId: Int) {
    getTicketWorkspaceTickets(orgId: $orgId, workspaceId: $workspaceId, boardId: $boardId) {
        ...ticketFields
    }
  }
  ${TicketFragment}
`;

export const GetSimilarEpics = gql`
  query getSimilarEpics($orgId: String!, $workspaceId: String!, $epicId: String!) {
    getSimilarEpics(orgId: $orgId, workspaceId: $workspaceId, epicId: $epicId) {
      id,
      score,
      metadata {
        epicId,
        epicName,
        orgId,
        ticketNumber,
        workspaceId
      }
    }
  }
`;



export const GetTicketsWithSameEpicId = gql`
  query GetTicketsWithSameEpicId($orgId: String!, $workspaceId: String!, $epicId: String!) {
    getTicketsWithSameEpicId(orgId: $orgId, workspaceId: $workspaceId, epicId: $epicId) {
        ...ticketFields
    }
  }
  ${TicketFragment}
`;

export const GetAllTicketWorkspaceEpics = gql`
  query GetAllTicketWorkspaceEpics($orgId: String!, $workspaceId: String!) {
    getAllTicketWorkspaceEpics(orgId: $orgId, workspaceId: $workspaceId) {
      ...ticketFields
    }
  }
  ${TicketFragment}
`;

export const GetJiraProjects = gql`
  query getJiraProjects($orgId: String!, $jiraInstance: String!){
    getJiraProjects(orgId: $orgId, jiraInstance: $jiraInstance){
      id
      isPrivate
      key
      name
      projectTypeKey
      self
    }
  }
`;

export const GetJiraTicket = gql`
  query getJiraTicket(
      $orgId: String!
      $workspaceId: String!
      $jiraInstance: String!
      $issueNumber: String!
    ){
      getJiraTicket(
        orgId: $orgId
        workspaceId: $workspaceId
        jiraInstance: $jiraInstance
        issueNumber: $issueNumber
      ){
        ...ticketFields
      }
    }
  ${TicketFragment}
`;

export const GetTicketHistory = gql`
  query GetTicketHistory($orgId: String!, $workspaceId: String!, $ticketNumber: Int!){
    getTicketHistory(orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber){
      ...ticketFields
    }
  }
  ${TicketFragment}
`;

export const GetTicketWorkspaceSprintBoards = gql`
  query GetTicketWorkspaceSprintBoards($orgId: String!, $workspaceId: String!, $includeBacklog: Boolean){
    getTicketWorkspaceSprintBoards(orgId: $orgId, workspaceId: $workspaceId, includeBacklog: $includeBacklog){
      boards {
        ...ticketWorkspaceBoardFields
      }
      tickets {
        ...ticketFields
      }
    }
  }
  ${TicketWorkspaceBoardsFragment}
  ${TicketFragment}
`;

export const GetTicketWorkspaceBoard = gql`
  query GetTicketWorkspaceBoard($orgId: String!, $workspaceId: String!, $boardId: Int!){
    getTicketWorkspaceBoard(orgId: $orgId, workspaceId: $workspaceId, boardId: $boardId){
      ...ticketWorkspaceIndividualBoardFields
    }
  }
  ${TicketWorkspaceBoardFragment}
`;

export const GetTicketMediaUploadUrl = gql`
  query GetTicketMediaUploadUrl($orgId: String!, $workspaceId: String!, $ticketNumber: Int!, $fileName: String!){
    getTicketMediaUploadUrl(orgId: $orgId, workspaceId: $workspaceId, ticketNumber: $ticketNumber, fileName: $fileName){
      url
      contentType
    }
  }
`;

export const GetCompletedWorkspaceBoards = gql`
  query GetCompletedWorkspaceBoards($orgId: String!, $workspaceId: String!){
    getCompletedWorkspaceBoards(orgId: $orgId, workspaceId: $workspaceId){
      ...ticketWorkspaceIndividualBoardFields
    }
  }
  ${TicketWorkspaceBoardFragment}
`;

export const GetDocumentTextDetectionResult = gql`
  query GetDocumentTextDetectionResult($jobId: String!) {
    getDocumentTextDetectionResult(jobId: $jobId) {
      text
      jobId
      status
    }
  }
`;


export const GetJiraBoards = gql`
  query getJiraBoards($orgId: String!, $jiraOrgId: String!){
    getJiraBoards(orgId: $orgId, jiraOrgId: $jiraOrgId){
      id
      self
      name
      type
      location {
        projectId
        displayName
        projectName
        projectKey
        projectTypeKey
        avatarURI
        name
      }
    }
  }
`;

export const SearchTickets = gql`
  query searchTickets($search: String, $searchFields: [String], $orgId: String, $workspaceId: String, $size: Int, $from: Int){
    searchTickets(search: $search, searchFields: $searchFields, orgId: $orgId, workspaceId: $workspaceId, size: $size, from: $from){
      totalHits
      results {
        score
        dateCreated
        description
        email
        epic
        firstName
        labels
        lastName
        lastUpdated
        orgId
        pk
        prompt  
        sk
        ticketNumber
        title
        type
        userId
        workspaceId
      }
      
    }
  }
`;

export const SearchTicketWorkspaces = gql`
  query SearchTicketWorkspaces($search: String, $searchFields: [String], $orgId: String, $workspaceId: String, $size: Int, $from: Int) {
    searchTicketWorkspaces(search: $search, searchFields: $searchFields, orgId: $orgId, workspaceId: $workspaceId, size: $size, from: $from) {
      totalHits
      results {
        score
        dateCreated
        lastUpdated
        workspaceId
        workspaceName
        description
      }
    }
  }
`;


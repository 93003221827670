import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as CodeGenerationHistoryActions from './code-generation-history.actions';
import { CodeGenerationHistoryEntity } from './code-generation-history.models';

export const CODE_GENERATION_HISTORY_FEATURE_KEY = 'codeGenerationHistory';

export interface CodeGenerationHistoryState
  extends EntityState<CodeGenerationHistoryEntity> {
  selectedId?: string | number; // which CodeGenerationHistory record has been selected
  loaded: boolean; // has the CodeGenerationHistory list been loaded
  error?: string | null; // last known error (if any)
  page?: number
  size?: number
}

export interface CodeGenerationHistoryPartialState {
  readonly [CODE_GENERATION_HISTORY_FEATURE_KEY]: CodeGenerationHistoryState;
}

export const codeGenerationHistoryAdapter: EntityAdapter<CodeGenerationHistoryEntity> =
  createEntityAdapter<CodeGenerationHistoryEntity>({
    selectId: (item: CodeGenerationHistoryEntity) => item.generateId
  });

export const initialCodeGenerationHistoryState: CodeGenerationHistoryState =
  codeGenerationHistoryAdapter.getInitialState({
    selectedId: '',
    loaded: false,
    error: null
  });

const reducer = createReducer(
  initialCodeGenerationHistoryState,
  on(CodeGenerationHistoryActions.initCodeGenerationHistory, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(
    CodeGenerationHistoryActions.loadCodeGenerationHistorySuccess,
    (state, { codeGenerationHistory, page, size }) =>
      codeGenerationHistoryAdapter.addMany(codeGenerationHistory, {
        ...state,
        loaded: true,
        page,
        size
      })
  ),
  on(
    CodeGenerationHistoryActions.loadCodeGenerationHistoryFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(
    CodeGenerationHistoryActions.addGenerationToHistory,
    (state, { codeGeneration }) => {
      let parsedCodeGen = {
        generateId: codeGeneration.generateId,
        parameters: codeGeneration.parameters,
        pathId: codeGeneration.template.pathId,
        pathOrgId: codeGeneration.template.orgId,
        recipeId: codeGeneration.template.recipeId,
        stepId: codeGeneration.template.id,
        timestamp: new Date().toLocaleString(),
        title: codeGeneration.template.title
      }
      return codeGenerationHistoryAdapter.addOne(parsedCodeGen, {
        ...state,
        loaded: true
      })
    }
  )
);

export function codeGenerationHistoryReducer(
  state: CodeGenerationHistoryState | undefined,
  action: Action
) {
  return reducer(state, action);
}

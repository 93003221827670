import { createAction, props } from '@ngrx/store';
import { Template } from '@razroo-zeta/data-models';
import { SearchEntity } from './search.models';

export const loadSearch = createAction(
  '[Search] Load Search',
  props<{search: string; path: string, orgId?: string}>()
);

export const updateSearchPath = createAction(
  '[Search] Update Search Path',
  props<{ path: Template}>()
);

export const loadSearchSuccess = createAction(
  '[Search] Load Search Success',
  props<{ search: SearchEntity[] }>()
);

export const loadSearchFailure = createAction(
  '[Search] Load Search Failure',
  props<{ error: any }>()
);

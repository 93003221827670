import gql from 'graphql-tag';

export const GetUserProfile = gql`
  query GetUserProfile($userId: String, $email: String) {
    userProfile(userId: $userId, email: $email) {
      dateCreated
      dateCreatedFirstOrg
      userId
      activeOrgId
      email
      firstName
      lastName
      nickname
      superuser
      picture
      activeStep {
        orgId
        pathId
        recipeId
        id
        start
        end
      }
      certifications {
        name,
        grantedBy,
        orgId,
        dateGranted
      }
      analytics {
        events {
          date
        }
        type
      }
      timezone
      books {
        id
        chaptersRead
        percentageRead
      }
      globalNotifications {
        notification
        read
        templateCommentParams{
          commentType
          componentName
          fileName
          languageVersion
          pageUrl
        }
        timestamp
      }
      likedTemplates{
        orgId
        pathId
        recipeId
        stepId
        timestamp
      }
    }
  }
`;

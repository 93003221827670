import gql from "graphql-tag";

export const GetCodeChatById = gql`
    query GetCodeChatById(
        $chatId: String!
    ){
        getCodeChatById(
            chatId: $chatId
        ){
            dateCreated
            finishReason
            generationStatus
            id
            lastUpdated
            messages {
                role
                content
                name
            }
            pathOrgId
            technologies
            title
            usage
            userId
            userOrgId
        }
    }
`

export const GetCodeChatHistory = gql`
    query GetCodeChatHistory(
        $orgId: String!
        $amountOfItems: Int
        $lastEvaluatedKey: StartKeyInput
        $pathId: String
    ){
        getCodeChatHistory(
            orgId: $orgId,
            amountOfItems: $amountOfItems,
            lastEvaluatedKey: $lastEvaluatedKey,
            pathId: $pathId
        ){
            Items {
                dateCreated
                id
                lastUpdated
                pathOrgId
                technologies
                title
                userId
                userOrgId
            }
            LastEvaluatedKey {
                gsiKey
                pk
                sk
            }
        }
    }
`

export const SearchCodeChat = gql`
    query SearchCodeChat(
        $orgId: String!
        $search: String
        $pathId: String
        $size: Int
        $page: Int
    ){
        searchCodeChat(
            orgId: $orgId,
            search: $search,
            pathId: $pathId,
            size: $size,
            page: $page
        ){
            dateCreated
            id
            lastUpdated
            messages {
                role
                content
                name
            }
            pathOrgId
            title
            technologies
            userId
            userOrgId
        }
    }
`